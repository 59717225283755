import React from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { mdiClose } from "@mdi/js";
import { Field, Form } from "react-final-form";

import Card from "../../../newComponents/Card";
import Typography from "../../../newComponents/Typography";
import Spacer from "../../../newComponents/Spacer";
import Button from "../../../newComponents/Button";
import IconButton from "../../../newComponents/IconButton";
import Select from "../../../newComponents/Select";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import FlexBox from "../../../newComponents/FlexBox";
import DatePicker from "../../../newComponents/DatePicker";

import { closeModal } from "../../reusable/Popup";
import { openSnackbar } from "../../reusable/Notifier";

import { orderStatuses } from "../../../helpers/queries/Order";

const GET_DEBT_REPORT = gql`
  query getDebtReportDownloadUrl(
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $summarizeBy: String!
    $statuses: [String]!
  ) {
    getDebtReportDownloadUrl(
      dateFrom: $dateFrom
      dateTo: $dateTo
      summarizeBy: $summarizeBy
      statuses: $statuses
    )
  }
`;

export default function DebtReportModal() {
  const [getDebtReportDownloadUrl, { loading }] = useLazyQuery(
    GET_DEBT_REPORT,
    {
      onError: () => {
        openSnackbar({
          message: "Failed to get download, please try again.",
          type: "error",
        });
      },
      onCompleted: ({ getDebtReportDownloadUrl }) => {
        window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${getDebtReportDownloadUrl}`;
        closeModal();
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  return (
    <Card>
      <FlexBox justifyContent="space-between">
        <Typography variant="h2">
          Which dates would you like the report for?
        </Typography>
        <IconButton
          color="black"
          onClick={() => {
            closeModal();
          }}
          path={mdiClose}
          title="Close"
          size="24px"
        />
      </FlexBox>
      <Spacer height="16px" />
      <Form
        initialValues={{
          summarizeBy: "month",
          statuses: {
            Cancelled: false,
            Complete: true,
            Active: true,
            "Collecting Card Details": true,
            "Awaiting Deposit": true,
          },
        }}
        validate={(values) => {
          const errors = {};

          if (Object.values(values.statuses).every((item) => item === false))
            errors.hiddenErrorField = "Select at least one";

          return errors;
        }}
        onSubmit={({ dateFrom, dateTo, summarizeBy, statuses }) => {
          let statusesArray = [];

          for (const property in statuses) {
            if (statuses[property]) statusesArray.push(property);
          }

          getDebtReportDownloadUrl({
            variables: {
              dateFrom,
              dateTo,
              summarizeBy,
              statuses: statusesArray,
            },
          });
        }}
        render={({ handleSubmit, pristine, values: { dateFrom } }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FlexBox>
              <DatePicker
                label="From"
                required
                name="dateFrom"
                showMonthYearPicker
                showFullMonthYearPicker
                showYearDropdown
                hideMonthSelector
                dateFormat="MM/yyyy"
                placeholderText="mm/yyyy"
                maxDate={new Date()}
              />
              <Spacer height="16px" />
              <DatePicker
                label="To"
                required
                name="dateTo"
                showMonthYearPicker
                showFullMonthYearPicker
                showYearDropdown
                hideMonthSelector
                dateFormat="MM/yyyy"
                placeholderText="mm/yyyy"
                minDate={dateFrom}
                maxDate={new Date()}
              />
            </FlexBox>
            <Select
              name="summarizeBy"
              label="Summarize by:"
              required
              options={[
                {
                  value: "month",
                  label: "Month",
                },
                { value: "store", label: "Store" },
                { value: "agent", label: "Agent" },
              ]}
            />

            <Typography variant="label">
              Which order statuses you like the report for?
            </Typography>
            {orderStatuses.map((status) => (
              <Field name={`statuses.${status}`} type="checkbox" key={status}>
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ name: status }}
                        {...input}
                        value={status}
                      />
                    }
                    label={status}
                  />
                )}
              </Field>
            ))}

            <Field name="hiddenErrorField">
              {({ meta }) => {
                let errorMessage;

                if (meta.error && meta.touched) {
                  errorMessage = meta.error;
                } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
                  errorMessage = meta.submitError;
                }

                return !!errorMessage ? (
                  <Typography
                    variant="caption"
                    colour="red"
                    className="helper-text"
                  >
                    {errorMessage}
                  </Typography>
                ) : null;
              }}
            </Field>

            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <Button
              colour="#f7941d"
              disabled={pristine}
              type="submit"
              loading={loading}
              style={{ marginTop: "1rem" }}
            >
              Download
            </Button>
          </form>
        )}
      />
    </Card>
  );
}
