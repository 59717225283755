import React from 'react'
import { Link } from 'react-router-dom'
import validator from 'validator'
import { Form, Field } from 'react-final-form'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'

import {
  TextField,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'

import { Input } from '@material-ui/icons'

const LOGIN = gql`
  mutation loginUser(
    $email: String!
    $password: String!
    $rememberMe: Boolean
  ) {
    loginUser(email: $email, password: $password, rememberMe: $rememberMe) {
      _id
    }
  }
`

export default function Login({ history }) {
  const [loginUser, { loading, client }] = useMutation(LOGIN, {
    onCompleted: () => {
      client.resetStore()
      history.push('/')
    },
  })

  //redirect user to home page if they try to login whilst already logged in

  if (Cookies.get('ppw')) history.push('/')

  return (
    <Form
      onSubmit={async ({ email, password, rememberMe }) => {
        const response = await loginUser({
          variables: {
            email: email,
            password: password,
            rememberMe: rememberMe || false,
          },
        }).catch(() => {
          return {
            email: 'Incorrect email or password',
            password: 'Incorrect email or password',
          }
        })

        if (!response.data) return response
      }}
      validate={(values) => {
        const errors = {}

        const requiredFields = ['email', 'password']

        requiredFields.forEach((requiredField) => {
          if (!values[requiredField]) {
            errors[requiredField] = 'Required'
          }
        })

        if (
          values.email &&
          !validator.isEmail(values.email) &&
          !values.email.includes('.con') &&
          !values.email.includes('.coma')
        ) {
          errors.email = 'Invalid Email'
        }

        return errors
      }}
      render={({
        handleSubmit,
        pristine,
        submitting,
        invalid,
        dirtySinceLastSubmit,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: '600px', margin: 'auto' }}
          noValidate
        >
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h2"
                gutterBottom
                align="center"
              >
                Login
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field name="email">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="email"
                    label="Email"
                    fullWidth
                    required
                    type="email"
                    variant="outlined"
                    error={
                      (meta.error && meta.touched) ||
                      (!!meta.submitError &&
                        !dirtySinceLastSubmit &&
                        !submitting)
                    }
                    helperText={
                      meta.error && meta.touched
                        ? meta.error
                        : !!meta.submitError &&
                          !dirtySinceLastSubmit &&
                          !submitting
                        ? meta.submitError
                        : ''
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="password">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="password"
                    label="Password"
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    error={
                      (meta.error && meta.touched) ||
                      (!!meta.submitError &&
                        !dirtySinceLastSubmit &&
                        !submitting)
                    }
                    helperText={
                      meta.error && meta.touched
                        ? meta.error
                        : !!meta.submitError &&
                          !dirtySinceLastSubmit &&
                          !submitting
                        ? meta.submitError
                        : ''
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="rememberMe" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ name: 'rememberMe' }}
                        {...input}
                        value="rememberMe"
                      />
                    }
                    label="Remember Me"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  pristine || (invalid && !dirtySinceLastSubmit) || loading
                }
                type="submit"
              >
                Login
                <Input
                  style={{
                    marginLeft: '10px',
                    fontSize: 20,
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Button component={Link} to="forgotten-password" fullWidth>
                Forgotten Password
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}
