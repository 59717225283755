import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import {
  Typography,
  Card,
  CardContent,
  IconButton,
  CardHeader,
  CardActions,
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { Clear } from '@material-ui/icons'
import { Button } from '@web-applications/daffodil-component-library'

import { openSnackbar } from '../../../../components/reusable/Notifier'

const UPDATE_CARD_PAGE = gql`
  mutation updateCardPage($invoice_number: Int!) {
    updateCardPage(invoice_number: $invoice_number) {
      payment_page
    }
  }
`

export default function UpdateCardDetailsModal({ hideModal }) {
  const { invoiceNumber } = useParams()

  const [updateCardPageUrl, setUpdateCardPageUrl] = useState('')

  const [updateCardPage, { loading: updateCardPageLoading }] = useMutation(
    UPDATE_CARD_PAGE,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to update card, please try again',
          type: 'error',
        })
      },
      onCompleted: ({ updateCardPage }) => {
        setUpdateCardPageUrl(updateCardPage.payment_page)
        document.getElementById('updateCardForm').submit()
      },
    }
  )

  return (
    <Card>
      <CardHeader
        action={
          <IconButton aria-label="Close Pop-up" onClick={hideModal}>
            <Clear />
          </IconButton>
        }
        title="Update Card Details"
      />
      <CardContent>
        <Typography variant="body1" gutterBottom>
          Updating the card details will replace the current card being used.
          Please confirm that you would like to do this.
        </Typography>
      </CardContent>
      <CardActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={hideModal}>Cancel</Button>

        <Button
          loading={updateCardPageLoading}
          onClick={() => {
            updateCardPage({
              variables: {
                invoice_number: parseInt(invoiceNumber),
              },
            })
          }}
        >
          Continue
        </Button>
      </CardActions>
      {!!updateCardPageUrl && (
        <form
          action={updateCardPageUrl}
          method="post"
          id="updateCardForm"
          style={{ display: 'none' }}
        >
          <input type="submit" value="Do Stuff!" />
        </form>
      )}
    </Card>
  )
}
