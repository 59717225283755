import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import moment from 'moment'
import { Form } from 'react-final-form'
import styled from 'styled-components'

//images

import linkExpired from './../../../assets/images/link-expired-img.svg'
import paymentMade from './../../../assets/images/payment-already-made-img.svg'
import paymentPending from './../../../assets/images/payment-in-progress.svg'

import Typography from '../../../newComponents/Typography'
import Spacer from '../../../newComponents/Spacer'
import Card from '../../../newComponents/Card'
import Select from '../../../newComponents/Select'
import Button from '../../../newComponents/Button'
import FlexBox from '../../../newComponents/FlexBox'
import Divider from '../../../newComponents/Divider'
import Loading from '../../reusable/Loading'

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

const CHECK_LINK_VALIDITY = gql`
  query checkLinkValidity($linkReference: Int!) {
    checkLinkValidity(linkReference: $linkReference) {
      _id
      created_date
      customer {
        _id
        first_name
      }
      order {
        _id
        card_number
      }
      payment {
        _id
        invoice_number
        amount
      }
    }
  }
`

const CREATE_NEW_CARD_CUSTOMER_PAYMENT_LINK = gql`
  mutation createNewCardCustomerPaymentLink($linkReference: Int!) {
    createNewCardCustomerPaymentLink(linkReference: $linkReference)
  }
`

const PAY_FAILED_PAYMENT_WITH_EXISTING_CARD = gql`
  mutation payFailedPaymentWithExistingCard($linkReference: Int!) {
    payFailedPaymentWithExistingCard(linkReference: $linkReference)
  }
`

export default function FailedPaymentLanding({
  match: {
    params: { linkReference },
  },
  history,
}) {
  const [payWithNewCard, setPayWithNewCard] = useState(null)

  useEffect(() => {
    if (payWithNewCard) {
      document.getElementById('payWithNewCardForm').submit()
    }
  }, [payWithNewCard])
  //check if this link is allright to go ahead with the payment

  const { loading, error, data } = useQuery(CHECK_LINK_VALIDITY, {
    variables: { linkReference: parseInt(linkReference) },
    pollInterval: 600000,
  })

  const [
    createNewCardCustomerPaymentLink,
    { loading: createNewCardCustomerPaymentLinkLoading },
  ] = useMutation(CREATE_NEW_CARD_CUSTOMER_PAYMENT_LINK, {
    onError: () => {
      history.push(`/failed-payment/${linkReference}/failure`)
    },
    onCompleted: ({ createNewCardCustomerPaymentLink }) => {
      setPayWithNewCard(createNewCardCustomerPaymentLink)
    },
  })

  const [
    payFailedPaymentWithExistingCard,
    { loading: payFailedPaymentWithExistingCardLoading },
  ] = useMutation(PAY_FAILED_PAYMENT_WITH_EXISTING_CARD, {
    onError: () => {
      history.push(`/failed-payment/${linkReference}/failure`)
    },
    onCompleted: () => {
      history.push(`/failed-payment/${linkReference}/success`)
    },
  })

  if (loading) return <Loading />

  if (
    error &&
    (error.message.includes('Link Expired') ||
      error.message.includes('No Link Found'))
  ) {
    return (
      <ContentWrapper>
        <FlexBox flexDirection="column">
          <Typography variant="h1" center>
            Link Expired
          </Typography>
          <Spacer height="56px" />
          <img src={linkExpired} alt="Link Expired" />
          <Spacer height="56px" />
          <Typography variant="bodySmall" center>
            This link has expired. Please call our payments team on:
          </Typography>
          <Spacer height="24px" />
          <Typography variant="h1" component="a" href="tel:01709732641">
            01709 732 641
          </Typography>
          <Spacer height="8px" />
          <Typography variant="bodySmall" colour="#999B9F">
            (select option 1)
          </Typography>
        </FlexBox>
      </ContentWrapper>
    )
  } else if (error && error.message.includes('Pending Payment')) {
    return (
      <ContentWrapper>
        <FlexBox flexDirection="column">
          <Typography variant="h1" center>
            This payment attempt is already in progress
          </Typography>
          <Spacer height="56px" />
          <img src={paymentPending} alt="Link Expired" />
          <Spacer height="56px" />
          <Typography variant="bodySmall" center>
            It looks like you’re already trying to make this payment. Please
            wait 10 minutes for this payment to clear. If this issue persists
            please call our payments team on:
          </Typography>
          <Spacer height="24px" />
          <Typography variant="h1" component="a" href="tel:01709732641">
            01709 732 641
          </Typography>
          <Spacer height="8px" />
          <Typography variant="bodySmall" colour="#999B9F">
            (select option 1)
          </Typography>
        </FlexBox>
      </ContentWrapper>
    )
  } else if (error && error.message.includes('Already Paid')) {
    return (
      <ContentWrapper>
        <FlexBox flexDirection="column">
          <Typography variant="h1" center>
            This payment has already been made
          </Typography>
          <Spacer height="56px" />
          <img src={paymentMade} alt="Payment Already Taken" />
          <Spacer height="56px" />
          <Typography variant="bodySmall" center>
            It looks like you’ve already made this payment.
          </Typography>
        </FlexBox>
      </ContentWrapper>
    )
  } else if (error) {
    return (
      <ContentWrapper>
        <FlexBox flexDirection="column">
          <Typography variant="h1" center>
            Something Went Wrong
          </Typography>
          <Spacer height="56px" />
          <img src={linkExpired} alt="Something Went Wrong" />
          <Spacer height="56px" />
          <Typography variant="bodySmall" center>
            Please refresh the page and try again. To make this payment over the
            phone, please call our payments team on:
          </Typography>
          <Spacer height="24px" />
          <Typography variant="h1" component="a" href="tel:01709732641">
            01709 732 641
          </Typography>
          <Spacer height="8px" />
          <Typography variant="bodySmall" colour="#999B9F">
            (select option 1)
          </Typography>
        </FlexBox>
      </ContentWrapper>
    )
  }

  return (
    <ContentWrapper>
      <FlexBox flexDirection="column" alignItems="flex-start">
        <Typography variant="h1">
          Welcome, {data.checkLinkValidity.customer.first_name}
        </Typography>
        <Spacer height="16px" />
        <Typography variant="bodySmall">
          {`You missed a payment on
        ${moment(data.checkLinkValidity.created_date).format('DD/MM/YYYY')}.
        Please select a payment option below to make this payment now.`}
        </Typography>
        <Spacer height="16px" />
        <Typography variant="bodySmall">
          Alternatively call{' '}
          <Typography variant="bodySmall" component="a" href="tel:01709732641">
            01709 732 641
          </Typography>
        </Typography>
        <Spacer height="32px" />
      </FlexBox>
      <Card>
        <Typography variant="h2">
          {`Order No. ${data.checkLinkValidity.payment.invoice_number}`}
        </Typography>
        <Spacer height="24px" />
        <Divider />
        <Spacer height="24px" />
        <Typography variant="label">Amount Due</Typography>
        <Spacer height="8px" />
        <Typography variant="h2">
          {new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(data.checkLinkValidity.payment.amount)}
        </Typography>
        <Spacer height="16px" />
        <Form
          onSubmit={({ paymentType }) => {
            if (paymentType === 'newCard') {
              // this is a new card, get the link and submit it
              createNewCardCustomerPaymentLink({
                variables: {
                  linkReference: parseInt(linkReference),
                },
              })
            } else {
              payFailedPaymentWithExistingCard({
                variables: {
                  linkReference: parseInt(linkReference),
                },
              })
            }
          }}
          render={({ handleSubmit, pristine }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Select
                name="paymentType"
                label="Payment Type"
                required
                fullwidth
                options={[
                  {
                    value: 'existingCard',
                    label: `Pay With Existing Card ${data.checkLinkValidity.order.card_number}`,
                  },
                  { value: 'newCard', label: 'Pay With New Card' },
                ]}
              />
              <Button
                colour="#86B80B"
                loading={
                  createNewCardCustomerPaymentLinkLoading ||
                  payFailedPaymentWithExistingCardLoading
                }
                disabled={pristine}
                type="submit"
                fullwidth
              >
                Pay Now
              </Button>
            </form>
          )}
        />
      </Card>
      {!!payWithNewCard && (
        <form
          action={payWithNewCard}
          method="post"
          id="payWithNewCardForm"
          style={{ display: 'none' }}
        >
          <input type="submit" value="Do Stuff!" />
        </form>
      )}
    </ContentWrapper>
  )
}
