import React, { isValidElement } from 'react'
import styled from 'styled-components'
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useMountedLayoutEffect,
} from 'react-table'
import {
  mdiMenuDown,
  mdiChevronDoubleLeft,
  mdiChevronLeft,
  mdiArrowDown,
} from '@mdi/js'
import Icon from '@mdi/react'
import matchSorter from 'match-sorter'

import Typography from './Typography'
import FlexBox from './FlexBox'
import Spacer from './Spacer'
import Dropdown from './Dropdown'
import MenuItem from './MenuItem'
import IconButton from './IconButton'
import { StyledCheckbox } from './Checkbox'

const StyledTableHeadCell = styled.th`
  text-align: left;
  padding: 0 0 8px;
  vertical-align: middle;
  white-space: nowrap;
  label {
    cursor: ${({ onClick }) => {
      if (onClick) return 'pointer'
    }};
    color: black;
    display: inline-flex;
    padding-right: 32px;
    position: relative;
    line-height: 25px;
    align-items: center;
    svg {
      position: absolute;
      right: 8px;
    }
  }
`

const StyledTableHeader = styled.thead`
  border-bottom: solid 1px #e0e0e0;
  width: 100%;
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const StyledTableRow = styled.tr`
  text-align: left;
  border-bottom: solid 1px #e0e0e0;
`

const StyledTableDataCell = styled.td`
  padding: 16px 15px 16px 0;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 14px;
`

const StyledPagination = styled.div`
  p {
    font-size: 14px;
    line-height: 23px;
    white-space: nowrap;
  }
  .dropdown-menu {
    min-width: 0px;
  }
  .page-select {
    outline: none;
    border: none;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    padding-left: 6px;
    border-radius: 3px;
    &:hover {
      background-color: lightgrey;
      transition: all 150ms linear;
    }
  }
`

const StyledFilter = styled.div`
  .select-toggle {
    cursor: pointer;
    p {
      font-size: 12px;
      cursor: pointer;
    }
  }

  .dropdown-menu {
    min-width: 0;
  }

  input {
    min-width: 0;
    width: 100%;
    font-size: 12px;
    border: none;
    outline: none;
    font-family: 'Roboto';
    line-height: 26px;
  }
`

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search`}
    />
  )
}

//sorting exports

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <div className="select-filter">
      <Dropdown>
        <FlexBox dropdownTrigger className="select-toggle">
          <Typography variant="bodySmall" colour="#848586">
            {filterValue || 'All'}
          </Typography>
          <Icon
            path={mdiMenuDown}
            title="Dropdown"
            size="24px"
            color="#848586"
          />
        </FlexBox>
        <MenuItem
          onClick={() => {
            setFilter(undefined)
          }}
        >
          All
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setFilter(option)
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Dropdown>
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val

export default function Table({
  headers,
  data,
  selectedRows = {},
  onSelectedRowsChange,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns: headers,
      data,
      initialState: {
        selectedRowIds: selectedRows,
      },
      defaultColumn: {
        Filter: DefaultColumnFilter,
      },
      filterTypes: {
        fuzzyText: fuzzyTextFilterFn,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    // Here we will use a plugin to add our selection column
    ({ visibleColumns }) => {
      visibleColumns.push((columns) => {
        if (onSelectedRowsChange) {
          return [
            {
              id: 'selection',
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => {
                const { indeterminate, ...toggleAllRowsSelectedProps } =
                  getToggleAllRowsSelectedProps()

                return (
                  <StyledCheckbox noMargin>
                    <input
                      type="checkbox"
                      indeterminate={indeterminate ? 1 : 0}
                      {...toggleAllRowsSelectedProps}
                    />
                  </StyledCheckbox>
                )
              },
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => {
                const { indeterminate, ...toggleRowSelectedProps } =
                  row.getToggleRowSelectedProps()

                return (
                  <StyledCheckbox noMargin>
                    <input type="checkbox" {...toggleRowSelectedProps} />
                  </StyledCheckbox>
                )
              },
            },
            ...columns,
          ]
        }
        return [...columns]
      })
    }
  )

  // pass selected data to onSelected function

  useMountedLayoutEffect(() => {
    if (onSelectedRowsChange) {
      const selectedIds = Object.keys(selectedRowIds)
      const selectedRowsData = selectedIds
        .map((x) => data[x])
        .filter((x) => x != null)

      onSelectedRowsChange(selectedRowsData) //we store an array of selected row values, this component handles seelcted state
    }
  }, [selectedRowIds])

  return (
    <>
      <StyledTable {...getTableProps()}>
        <StyledTableHeader>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledTableHeadCell {...column.getHeaderProps()}>
                  <Typography
                    variant="label"
                    {...column.getSortByToggleProps()}
                  >
                    {column.render('Header')}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <Icon
                          path={mdiArrowDown}
                          title="Sort Descending"
                          size="18px"
                          color="#7b7b7b"
                        />
                      ) : (
                        <Icon
                          path={mdiArrowDown}
                          title="Sort Descending"
                          size="18px"
                          color="#7b7b7b"
                          rotate={180}
                        />
                      )
                    ) : (
                      ''
                    )}
                  </Typography>
                  <StyledFilter>
                    {column.canFilter ? column.render('Filter') : null}
                  </StyledFilter>
                </StyledTableHeadCell>
              ))}
            </tr>
          ))}
        </StyledTableHeader>
        <tbody {...getTableBodyProps()}>
          {data.length ? (
            <>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <StyledTableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <StyledTableDataCell {...cell.getCellProps()}>
                        {cell.column.id === 'selection' ||
                        isValidElement(cell.value) ? (
                          cell.render('Cell')
                        ) : (
                          <Typography variant="bodySmall">
                            {cell.render('Cell')}
                          </Typography>
                        )}
                      </StyledTableDataCell>
                    ))}
                  </StyledTableRow>
                )
              })}
            </>
          ) : (
            <tr>
              <StyledTableDataCell colSpan={headerGroups[0].headers.length}>
                <FlexBox flexDirection="column">
                  <Spacer height="80px" />
                  <Typography variant="h5" center>
                    Nothing to see here
                  </Typography>
                  <Spacer height="24px" />
                  <Typography variant="bodySmall" colour="#999B9F" center>
                    We don't have any information for this table.
                  </Typography>
                  <Spacer height="64px" />
                </FlexBox>
              </StyledTableDataCell>
            </tr>
          )}
        </tbody>
      </StyledTable>
      {!!pageOptions.length && (
        <StyledPagination>
          <Spacer height="16px" />
          <FlexBox justifyContent="flex-end">
            <Typography variant="bodySmall" colour="black">
              Rows Per Page:
            </Typography>
            <Spacer height="8px" />
            <div>
              <Dropdown>
                <FlexBox dropdownTrigger>
                  <button className="page-select" type="button">
                    <Typography variant="bodySmall">{pageSize}</Typography>
                    <Icon
                      path={mdiMenuDown}
                      title="Dropdown"
                      size="24px"
                      color="black"
                    />
                  </button>
                </FlexBox>
                {[10, 25, 50, 75, 100].map((pageSize) => (
                  <MenuItem
                    key={pageSize}
                    onClick={() => {
                      setPageSize(pageSize)
                    }}
                  >
                    {pageSize}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
            <Spacer height="24px" />
            <Typography variant="bodySmall" colour="black">
              {`Page ${pageIndex + 1} of ${pageOptions.length}`}
            </Typography>
            <Spacer height="24px" />
            <IconButton
              path={mdiChevronDoubleLeft}
              title="Jump To Beginning"
              size="24px"
              color="#7b7b7b"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <IconButton
              path={mdiChevronLeft}
              title="Previous Page"
              size="24px"
              color="#7b7b7b"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <IconButton
              path={mdiChevronLeft}
              title="Next Page"
              size="24px"
              color="#7b7b7b"
              onClick={() => nextPage()}
              disabled={!canNextPage}
              rotate={180}
            />
            <IconButton
              path={mdiChevronDoubleLeft}
              title="Jump To End"
              size="24px"
              color="#7b7b7b"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              rotate={180}
            />
          </FlexBox>
        </StyledPagination>
      )}
    </>
  )
}
