import React from "react";
import { default as ReactDatePicker } from "react-datepicker";
import { Field } from "react-final-form";
import styled from "styled-components";
import { getYear, getMonth } from "date-fns";

import Icon from "@mdi/react";
import { mdiAlertCircleOutline, mdiChevronLeft, mdiMenuDown } from "@mdi/js";

import Typography from "./Typography";
import FlexBox from "./FlexBox";
import Spacer from "./Spacer";
import Dropdown from "./Dropdown";
import MenuItem from "./MenuItem";

//default css for this, most gets overwritten by our styling
import "react-datepicker/dist/react-datepicker.css";
import IconButton from "./IconButton";

const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ fullwidth }) => {
    if (!fullwidth) return "320px";
  }};
  input {
    resize: none;
    width: 100%;
    max-width: ${({ fullwidth }) => {
      if (!fullwidth) return "320px";
    }};
    height: 56px;
    border-width: 1px;
    border-style: solid;
    transition: all ease-in-out 150ms;
    border-color: ${({ error }) => {
      if (error) return "red";

      return "#E0E0E0";
    }};
    border-radius: 4px;
    background-color: ${({ disabled }) => {
      if (disabled) return "#E0E0E0";

      return "white";
    }};
    padding: ${({ error }) => {
      if (error) return "16px 40px 16px 16px";

      return "16px";
    }};
    font-family: "Roboto";
    font-size: 16px;
    line-height: 26px;
    color: ${({ disabled }) => {
      if (disabled) return "#7b7b7b";

      return "black";
    }};
    cursor: ${({ disabled }) => {
      if (disabled) return "not-allowed";
    }};
    margin-bottom: 24px;
    &:active,
    &:focus {
      border-color: ${({ disabled }) => {
        if (!disabled) return "#f7941d";
      }};
      outline: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
    }
  }
  .date-picker-container {
    position: relative;
    .react-datepicker-popper {
      margin-top: 0;
      top: -16px !important;
    }
    .react-datepicker {
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.08);
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    .react-datepicker__header {
      padding: 16px 24px 8px;
      border-bottom: 1px solid #e0e0e0;
      background-color: rgba(235, 237, 244, 0.48);
      svg {
        position: static;
      }
      .month-picker {
        width: 100%;
        p {
          font-weight: 500;
          line-height: 24px;
        }
      }

      .react-datepicker__day-name {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
      }
      .year-select {
        cursor: pointer;
        padding-left: 10px;
        p {
          font-size: 14px;
          line-height: 24px;
        }
      }
      .dropdown-menu {
        right: 0;
        left: 0;
        max-width: 88px;
        margin-left: auto;
        margin-right: auto;
        min-width: 0;
      }
    }

    .react-datepicker__month {
      margin: 18px 24px 24px;
    }
    .react-datepicker__day--today {
      background-color: transparent;
      font-weight: 400;
    }
    .react-datepicker__day {
      font-family: Roboto;
      font-size: 14px;
      color: #000000;
      border-radius: 2px;
      &:hover {
        background-color: #f6f7fa;
        outline: none;
      }
    }

    .react-datepicker__day--selected {
      background-color: #ff9800;
      color: black;
      font-weight: 500;
    }
    .react-datepicker__day--disabled {
      color: #ccc;
    }
  }
  .optional-text {
    margin-left: 4px;
  }
  .helper-text {
    margin-top: -24px;
  }
  svg {
    position: absolute;
    top: 16px;
    right: 8px;
  }
`;

// const years = range(1990, getYear(new Date()) + 1, 1)

let years = [];
for (let i = 2018; i <= getYear(new Date()) + 1; i++) {
  years.push(i);
}
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function DatePicker({
  label,
  required,
  name,
  helperText,
  fullwidth,
  hideMonthSelector,
  dateFormat,
  placeholderText,
  ...rest
}) {
  const validate = (value) => {
    //required validator
    if (required && (!JSON.stringify(value) || !JSON.stringify(value).trim())) {
      return "Required";
    }
  };
  return (
    <Field name={name} validate={validate}>
      {({ input: { value, ...inputRest }, meta }) => {
        //get error

        let errorMessage;

        if (meta.error && meta.touched) {
          errorMessage = meta.error;
        } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
          errorMessage = meta.submitError;
        }
        return (
          <StyledDatePicker
            {...rest}
            fullwidth={fullwidth ? 1 : 0}
            error={!!errorMessage}
          >
            <FlexBox>
              <Typography variant="label">{label}</Typography>
              {required && (
                <Typography variant="label" className="optional-text">
                  *
                </Typography>
              )}
            </FlexBox>
            <Spacer height="8px" />
            <div className="date-picker-container">
              <ReactDatePicker
                {...inputRest}
                selected={value}
                {...rest}
                dateFormat={dateFormat || "dd/MM/yyyy"}
                showPopperArrow={false}
                scrollableYearDropdown
                placeholderText={placeholderText || "dd/MM/yyyy"}
                renderCustomHeader={({
                  date,
                  changeYear,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <FlexBox flexDirection="column">
                    {!hideMonthSelector && (
                      <FlexBox
                        justifyContent="space-between"
                        className="month-picker"
                      >
                        <IconButton
                          path={mdiChevronLeft}
                          title="Previous Month"
                          size="24px"
                          color="#7b7b7b"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        />
                        <Typography variant="bodySmall">
                          {months[getMonth(date)]}
                        </Typography>
                        <IconButton
                          path={mdiChevronLeft}
                          title="Previous Month"
                          size="24px"
                          color="#7b7b7b"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                          rotate={180}
                        />
                      </FlexBox>
                    )}

                    <Dropdown>
                      <FlexBox
                        justifyContent="center"
                        className="year-select"
                        dropdownTrigger
                      >
                        <Typography variant="bodySmall" colour="#848586">
                          {getYear(date)}
                        </Typography>
                        <Icon
                          path={mdiMenuDown}
                          title="Dropdown"
                          size="24px"
                          color="#848586"
                        />
                      </FlexBox>
                      {years.map((option) => (
                        <MenuItem
                          key={option}
                          onClick={() => {
                            changeYear(option);
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Dropdown>
                    <Spacer height="8px" />
                  </FlexBox>
                )}
              />
              {!!errorMessage && (
                <Icon
                  path={mdiAlertCircleOutline}
                  title="Error"
                  size="24px"
                  color="red"
                />
              )}
            </div>

            {!!errorMessage ? (
              <Typography
                variant="caption"
                colour="red"
                className="helper-text"
              >
                {errorMessage}
              </Typography>
            ) : !!helperText ? (
              <Typography
                variant="caption"
                colour="#7b7b7b"
                className="helper-text"
              >
                {helperText}
              </Typography>
            ) : null}
          </StyledDatePicker>
        );
      }}
    </Field>
  );
}
