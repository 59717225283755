import React, { Fragment, useContext } from "react";
import { Query } from "@apollo/react-components";
import { Field, FormSpy } from "react-final-form";

import Dropdown from "../../reusable/Dropdown";

import { GET_STORES } from "./../../../helpers/queries/User";

import {
  TextField,
  Grid,
  MenuItem,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { Clear } from "@material-ui/icons";
import { UserContext } from "../../util/PageWrapper";

export default function UserEdit({ archived }) {
  const user = useContext(UserContext);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} md={6}>
            <Field name="firstName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="First Name"
                  fullWidth
                  required
                  disabled={archived}
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field name="lastName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Last Name"
                  fullWidth
                  required
                  disabled={archived}
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field name="role">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  select
                  label="Role"
                  fullWidth
                  required
                  disabled={archived}
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                >
                  <MenuItem value={1}>Sales Agent</MenuItem>
                  <MenuItem value={2}>Manager</MenuItem>
                  <MenuItem value={3}>Office Member</MenuItem>
                  <MenuItem value={4}>Admin</MenuItem>
                  {user && user.role_id > 4 && (
                    <MenuItem value={10}>Super Admin</MenuItem>
                  )}
                </TextField>
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field name="email">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Email"
                  fullWidth
                  required
                  disabled={archived}
                  type="email"
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormSpy
          subscription={{
            values: true,
          }}
        >
          {({
            values,
            form: {
              mutators: { removeStore },
            },
          }) => (
            <Query query={GET_STORES}>
              {({ loading, error, data }) => {
                if (error || loading) {
                  return null;
                }

                const { stores } = data;

                const allStores = stores.map((store) => {
                  return {
                    value: store.name,
                    label: store.name,
                  };
                });

                //don't show already selected stores

                const selectedStoreNames = values.chosenStores.map((store) => {
                  return store.name;
                });

                const formattedStores = allStores.filter((store) => {
                  return !selectedStoreNames.includes(store.label);
                });

                return (
                  <Grid container spacing={3} justify="center">
                    {!archived && (
                      <Grid item xs={12}>
                        <Field name="store">
                          {({ input, meta }) => (
                            <Dropdown
                              input={input}
                              name="store"
                              items={formattedStores}
                              placeholder="Type a Store"
                              label="Add A Store"
                              required
                              error={meta.error && meta.dirty}
                              helperText={
                                meta.error && meta.dirty ? meta.error : ""
                              }
                            />
                          )}
                        </Field>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Fragment>
                        <Typography variant="subtitle1" gutterBottom>
                          Stores
                        </Typography>
                        {values.chosenStores.map((store, index) => {
                          return (
                            <div
                              key={store._id}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="subtitle2">
                                {store.name}
                              </Typography>
                              {!archived && (
                                <IconButton
                                  aria-label="Clear File"
                                  style={{ marginLeft: "auto" }}
                                  onClick={() => {
                                    removeStore(index);
                                  }}
                                >
                                  <Clear />
                                </IconButton>
                              )}
                            </div>
                          );
                        })}
                      </Fragment>
                    </Grid>
                  </Grid>
                );
              }}
            </Query>
          )}
        </FormSpy>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field name="canCancel" type="checkbox">
          {({ input }) => (
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ name: input.name }}
                  {...input}
                  value={input.name}
                />
              }
              label={
                <Typography variant="body2">User can cancel orders</Typography>
              }
            />
          )}
        </Field>
      </Grid>
    </Grid>
  );
}
