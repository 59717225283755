import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import Icon from '@mdi/react'
import { mdiMenuDown } from '@mdi/js'

import Typography from './Typography'
import FlexBox from './FlexBox'
import Dropdown from './Dropdown'
import MenuItem from './MenuItem'
import Spacer from './Spacer'
import { StyledSelect, StyledSelectWrapper } from './Select'

const StyledTimeSelectWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  .individual-time-select {
    width: auto;
  }
  .time-divider {
    align-self: center;
    margin: 0 8px 24px;
  }
`

export default function TimeSelect({
  hourName,
  minuteName,
  required,
  label,
  helperText,
  ...rest
}) {
  const validate = (value, allValues) => {
    //required validator
    if (required && (!JSON.stringify(value) || !JSON.stringify(value).trim())) {
      return 'Required'
    }

    //if one has been entered, the other must be required too

    if (
      !!allValues[hourName] &&
      (!JSON.stringify(value) || !JSON.stringify(value).trim())
    ) {
      return 'Required'
    }

    if (
      !!allValues[minuteName] &&
      (!JSON.stringify(value) || !JSON.stringify(value).trim())
    ) {
      return 'Required'
    }
  }

  //create hours
  let hours = required ? [] : [{ value: undefined, label: 'hh' }]

  for (let i = 0; i < 24; i++) {
    if (JSON.stringify(i).length === 1) {
      hours.push({ value: i, label: `0${i}` })
    } else {
      hours.push({ value: i, label: i })
    }
  }

  //create minutes

  let minutes = required ? [] : [{ value: undefined, label: 'mm' }]

  for (let i = 0; i < 60; i++) {
    if (i % 5 === 0) {
      if (JSON.stringify(i).length === 1) {
        minutes.push({ value: i, label: `0${i}` })
      } else {
        minutes.push({ value: i, label: i })
      }
    }
  }

  return (
    <div>
      <FlexBox>
        <Typography variant="label">{label}</Typography>
        {required && (
          <Typography variant="label" className="optional-text">
            *
          </Typography>
        )}
      </FlexBox>
      <Spacer height="8px" />
      <StyledTimeSelectWrapper>
        <Field
          name={hourName}
          validate={validate}
          format={(value) => {
            const selectedOption = hours.find(
              (option) => option.value === value
            )
            return selectedOption?.label
          }}
        >
          {({ input: { onChange, value, ...inputRest }, meta }) => {
            //get error

            let errorMessage

            if (meta.error && meta.touched) {
              errorMessage = meta.error
            } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
              errorMessage = meta.submitError
            }

            return (
              <StyledSelectWrapper
                {...rest}
                error={!!errorMessage}
                {...inputRest}
                className="individual-time-select"
              >
                <div className="select-components-wrapper">
                  <Dropdown disabled={rest.disabled}>
                    <StyledSelect
                      {...rest}
                      small
                      error={!!errorMessage}
                      type="button"
                      dropdownTrigger
                    >
                      <Typography variant="bodySmall">
                        {JSON.stringify(value) ? value : 'hh'}
                      </Typography>
                      <Icon
                        path={mdiMenuDown}
                        title="Dropdown"
                        size="24px"
                        color="black"
                      />
                    </StyledSelect>
                    {hours.map((option) => (
                      <MenuItem
                        key={option.label}
                        onClick={() => {
                          onChange(option.value)
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Dropdown>
                </div>
                {!!errorMessage ? (
                  <Typography
                    variant="caption"
                    colour="red"
                    className="helper-text"
                  >
                    {errorMessage}
                  </Typography>
                ) : !!helperText ? (
                  <Typography
                    variant="caption"
                    colour="#7b7b7b"
                    className="helper-text"
                  >
                    {helperText}
                  </Typography>
                ) : null}
              </StyledSelectWrapper>
            )
          }}
        </Field>
        <Typography variant="bodySmall" className="time-divider">
          :
        </Typography>
        <Field
          name={minuteName}
          validate={validate}
          format={(value) => {
            const selectedOption = minutes.find(
              (option) => option.value === value
            )
            return selectedOption?.label
          }}
        >
          {({ input: { onChange, value, ...inputRest }, meta }) => {
            //get error

            let errorMessage

            if (meta.error && meta.touched) {
              errorMessage = meta.error
            } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
              errorMessage = meta.submitError
            }

            return (
              <StyledSelectWrapper
                {...rest}
                error={!!errorMessage}
                {...inputRest}
                className="individual-time-select"
              >
                <div className="select-components-wrapper">
                  <Dropdown disabled={rest.disabled}>
                    <StyledSelect
                      {...rest}
                      small
                      error={!!errorMessage}
                      type="button"
                      dropdownTrigger
                    >
                      <Typography variant="bodySmall">
                        {JSON.stringify(value) ? value : 'mm'}
                      </Typography>
                      <Icon
                        path={mdiMenuDown}
                        title="Dropdown"
                        size="24px"
                        color="black"
                      />
                    </StyledSelect>
                    {minutes.map((option) => (
                      <MenuItem
                        key={option.label}
                        onClick={() => {
                          onChange(option.value)
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Dropdown>
                </div>
                {!!errorMessage ? (
                  <Typography
                    variant="caption"
                    colour="red"
                    className="helper-text"
                  >
                    {errorMessage}
                  </Typography>
                ) : !!helperText ? (
                  <Typography
                    variant="caption"
                    colour="#7b7b7b"
                    className="helper-text"
                  >
                    {helperText}
                  </Typography>
                ) : null}
              </StyledSelectWrapper>
            )
          }}
        </Field>
      </StyledTimeSelectWrapper>
    </div>
  )
}
