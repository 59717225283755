import gql from "graphql-tag";

export const NEW_PARKED_ORDER = gql`
  mutation ($storeId: String) {
    newParkedOrder(storeId: $storeId) {
      _id
      time_started
      agent {
        _id
        first_name
        last_name
      }
      store {
        _id
      }
      status
      items
      files
      total_discount
      total_increase
      discounts
      increases
      payments_before_release
      weekly_payment
      customer_type
      order_type
      existing_customer
      address_city
      address_county
      address_line_1
      address_line_2
      address_postcode
      email
      first_name
      last_name
      mobile
      phone
      preferred_contact
      title
      marketing_news
      marketing_offers
      total_retail
      to_pay
      grand_total
      complete_payments
      lead_generation
      customer_notes
      delivery_charge
      fitting_charge
      fitting_delivery_total
      deposit
      fitting_delivery_notes
      deposit_method
      deposit_date
      first_payment_date
      release_date
      address_matches_residence
      billing_first_name
      billing_last_name
      billing_address_line_1
      billing_address_city
      billing_address_county
      billing_address_postcode
      billing_address_line_2
      billing_title
      terms_agreed
      room_cleared
      vinyl_cleared
      doors_removed
      waste_created
      fitting_fee
      cancellation_policy
      signature
    }
  }
`;

export const CANCEL_NEW_ORDER = gql`
  mutation ($parked_order_id: String!, $reason: String!) {
    cancelNewOrder(parked_order_id: $parked_order_id, reason: $reason) {
      _id
      time_started
      agent {
        _id
        first_name
        last_name
      }
      store {
        _id
      }
      status
      items
      files
      total_discount
      total_increase
      payments_before_release
      weekly_payment
      customer_type
      order_type
      existing_customer
      address_city
      address_county
      address_line_1
      address_line_2
      address_postcode
      email
      first_name
      last_name
      mobile
      phone
      preferred_contact
      title
      marketing_news
      marketing_offers
      total_retail
      to_pay
      grand_total
      complete_payments
      lead_generation
      customer_notes
      delivery_charge
      fitting_charge
      fitting_delivery_total
      deposit
      fitting_delivery_notes
      deposit_method
      deposit_date
      first_payment_date
      release_date
      address_matches_residence
      billing_first_name
      billing_last_name
      billing_address_line_1
      billing_address_city
      billing_address_county
      billing_address_postcode
      billing_address_line_2
      billing_title
      terms_agreed
      room_cleared
      vinyl_cleared
      doors_removed
      waste_created
      fitting_fee
      cancellation_policy
      signature
    }
  }
`;

export const DELETE_PARKED_ORDER = gql`
  mutation ($parkedOrderId: String!) {
    deleteParkedOrder(parkedOrderId: $parkedOrderId) {
      _id
    }
  }
`;

export const GET_PARKED_ORDER = gql`
  query parkedOrder($parked_order_id: String!) {
    parkedOrder(parked_order_id: $parked_order_id) {
      _id
      time_started
      agent {
        _id
        first_name
        last_name
      }
      store {
        _id
      }
      status
      items
      files
      total_discount
      total_increase
      payments_before_release
      weekly_payment
      customer_type
      order_type
      existing_customer
      address_city
      address_county
      address_line_1
      address_line_2
      address_postcode
      email
      first_name
      last_name
      mobile
      phone
      preferred_contact
      title
      marketing_news
      marketing_offers
      total_retail
      to_pay
      grand_total
      complete_payments
      lead_generation
      customer_notes
      delivery_charge
      fitting_charge
      fitting_delivery_total
      deposit
      fitting_delivery_notes
      deposit_method
      deposit_date
      first_payment_date
      release_date
      address_matches_residence
      billing_first_name
      billing_last_name
      billing_address_line_1
      billing_address_city
      billing_address_county
      billing_address_postcode
      billing_address_line_2
      billing_title
      terms_agreed
      room_cleared
      vinyl_cleared
      doors_removed
      waste_created
      fitting_fee
      cancellation_policy
      signature
      payment_start_time
    }
  }
`;

export const NEW_PARKED_CUSTOMER_ORDER = gql`
  mutation ($agent_id: String!, $store_id: String, $customer_id: String!) {
    newParkedCustomerOrder(
      agent_id: $agent_id
      store_id: $store_id
      customer_id: $customer_id
    ) {
      error {
        message
      }
      payment_page
      parked_order {
        _id
        time_started
        agent {
          _id
          first_name
          last_name
        }
        store {
          _id
        }
        status
        items
        files
        total_discount
        total_increase
        payments_before_release
        weekly_payment
        customer_type
        order_type
        existing_customer
        address_city
        address_county
        address_line_1
        address_line_2
        address_postcode
        email
        first_name
        last_name
        mobile
        phone
        preferred_contact
        title
        marketing_news
        marketing_offers
        total_retail
        to_pay
        grand_total
        complete_payments
        lead_generation
        customer_notes
        delivery_charge
        fitting_charge
        fitting_delivery_total
        deposit
        fitting_delivery_notes
        deposit_method
        deposit_date
        first_payment_date
        release_date
        address_matches_residence
        billing_first_name
        billing_last_name
        billing_address_line_1
        billing_address_city
        billing_address_county
        billing_address_postcode
        billing_address_line_2
        billing_title
        terms_agreed
        room_cleared
        vinyl_cleared
        doors_removed
        waste_created
        fitting_fee
        cancellation_policy
        signature
      }
    }
  }
`;
