import React, { useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { openModal, closeModal } from "./../../reusable/Popup";

import {
  Button,
  Typography,
  Grid,
  Card,
  CardHeader,
  IconButton,
  CardContent,
} from "@material-ui/core";

import { Add, Clear } from "@material-ui/icons";

import ParkedOrdersSearch from "./../ParkedOrders/ParkedOrdersSearch";
import ParkedOrdersAgent from "./../ParkedOrders/ParkedOrdersAgent";

import OrdersSearch from "./OrdersSearch";

import { UserContext } from "./../../util/PageWrapper";
import { useNewParkedOrderHook } from "../../reusable/useNewParkedOrderHook";

const NEW_PARKED_ORDER = gql`
  mutation newParkedOrder($storeId: String!) {
    newParkedOrder(storeId: $storeId) {
      _id
    }
  }
`;

export default function Orders({ history }) {
  //removed for bug hunting, imported from ../Dashboard/Home.js
  // const [newParkedOrder] = useMutation(NEW_PARKED_ORDER, {
  //   onCompleted: ({ newParkedOrder }) => {
  //     closeModal();
  //     history.push(`new-order/choose-customer/${newParkedOrder._id}`);
  //   },
  // });
  const [newParkedOrder, { loading }] = useNewParkedOrderHook(history);
  const user = useContext(UserContext);

  let title;

  switch (user.role_id) {
    case 1:
      // Agent

      title = `Orders - ${user.first_name} ${user.last_name}`;

      break;

    case 2:
      // Store owner

      const names = user.stores.map((store) => {
        return store.name;
      });

      title = `Orders - ${names.join(", ")}`;

      break;

    case 3:
    case 4:
    default:
      // Admin

      title = `All Orders`;

      break;
  }

  // calcualte what view this user should see

  let view;

  if (
    user.role_id >= 4 ||
    [
      "karlmarkell@payperweekcarpetsandbeds.co.uk",
      "laurabuck@payperweekcarpetsandbeds.co.uk",
      "darrenmessam@unitedcarpets.net",
    ].includes(user.email)
  ) {
    view = <ParkedOrdersSearch />;
  } else if (user.role_id === 1) {
    view = <ParkedOrdersAgent />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Typography component="h1" variant="h4">
          {title}
        </Typography>

        <Button
          variant="contained"
          style={{
            marginLeft: "auto",
          }}
          color="secondary"
          onClick={(e) => {
            if (user.stores.length > 1) {
              //ask user which store their order is for
              openModal({
                content: (
                  <Card>
                    <CardHeader
                      action={
                        <IconButton
                          aria-label="Close Pop-up"
                          onClick={closeModal}
                        >
                          <Clear />
                        </IconButton>
                      }
                      title="Which store is this order for?"
                    />
                    <CardContent>
                      <Grid container spacing={3}>
                        {user.stores.map((store) => {
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                              <Button
                                key={store._id}
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                  newParkedOrder({
                                    variables: {
                                      storeId: store._id,
                                    },
                                  });
                                }}
                              >
                                {store.name}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </CardContent>
                  </Card>
                ),
              });
            } else {
              //order can only be for one store so!
              newParkedOrder({
                variables: {
                  storeId: user.stores[0]._id,
                },
              });
            }
          }}
        >
          <Add style={{ marginRight: "10px" }} />
          New Order
        </Button>
      </Grid>
      {view && (
        <Grid item xs={12}>
          {view}
        </Grid>
      )}
      <Grid item xs={12}>
        <OrdersSearch />
      </Grid>
    </Grid>
  );
}
