import styled from 'styled-components'

const StyledImage = styled.img`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  object-fit: contain;
  object-position: center;
`

export default StyledImage
