import React from 'react'
import { Link } from 'react-router-dom'
import validator from 'validator'
import { Form, Field } from 'react-final-form'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'

import { TextField, Typography, Grid, Button } from '@material-ui/core'

import { openSnackbar } from '../../reusable/Notifier'

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      _id
      email
    }
  }
`

export default function ForgottenPassword({ history }) {
  // Don't allow user to forget password if already logged in
  if (Cookies.get('ppw')) history.push('/')

  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: ({ forgotPassword }) => {
      openSnackbar({
        message: `A password reset request has been sent to ${forgotPassword.email}`,
        type: 'success',
      })
      history.push('/login')
    },
    onError: ({ message }) => {
      openSnackbar({
        message: message.replace('GraphQL error: ', ''),
        type: 'error',
      })
    },
  })

  return (
    <Form
      onSubmit={({ email }) => {
        forgotPassword({
          variables: {
            email: email,
          },
        })
      }}
      validate={({ email }) => {
        const errors = {}

        if (!email || !email.trim()) {
          errors['email'] = 'Required'
        }

        if (
          email &&
          !validator.isEmail(email) &&
          !email.includes('.con') &&
          !email.includes('.coma')
        ) {
          errors.email = 'Invalid Email'
        }

        return errors
      }}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: '600px', margin: 'auto' }}
        >
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h2"
                gutterBottom
                align="center"
              >
                Forgotten Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field name="email">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="email"
                    label="Email"
                    fullWidth
                    required
                    type="email"
                    variant="outlined"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ''}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} sm={6} md={5}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
                type="submit"
              >
                Request Reset
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Button component={Link} to="login" fullWidth>
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}
