import React from 'react'
import { Link } from 'react-router-dom'

import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
} from '@material-ui/core'

export default function FailedUpdateCard({ match }) {
  const invoiceNumber = parseInt(match.params.invoice_number)
  return (
    <Card>
      <CardContent>
        <Typography component="h2" variant="h5" gutterBottom>
          Card failed to update
          <Divider />
        </Typography>

        <Typography variant="body1" gutterBottom>
          Something went wrong when attempting to update the card details for
          this order. Please try again.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={'/orders/single/' + invoiceNumber}
        >
          Return To Order
        </Button>
      </CardContent>
    </Card>
  )
}
