import gql from 'graphql-tag'

export const TAKE_DEPOSIT_NOW = gql`
  mutation ($payment_id: String!, $agent_id: String!) {
    takeDepositNow(payment_id: $payment_id, agent_id: $agent_id) {
      error {
        message
      }
      order {
        _id
        invoice_number
        items {
          room
          name
          colour
          size
          type
          code
          subfloor
          underlay
          grippers
          doorbars {
            code
            description
          }
        }
        total_discount
        total_increase
        agent {
          _id
          first_name
          last_name
        }
        store {
          _id
          name
        }
        payments_before_release
        order_type
        lead_generation
        billing_first_name
        billing_last_name
        billing_address_line_1
        billing_address_city
        billing_address_county
        billing_address_postcode
        billing_address_line_2
        billing_title
        card_number
        card_expiry
        order_status
        created_date
        customer {
          _id
          first_name
          last_name
          first_name
          last_name
          address_city
          address_county
          address_line_1
          address_line_2
          address_postcode
          title
          phone
          email
          mobile
          preferred_contact
        }
        delivery_date
        fitting_date
        delivery_charge
        fitting_charge
        total_retail
        to_pay
        fitting_delivery_total
        grand_total
        first_payment_date
        deposit
        weekly_payment
        release_date
        signature
        deposit_method
        deposit_date
        deposit_paid
        card_due_to_expire
        store_transaction_number
        supplier_invoice_number
        account_manager {
          _id
        }
        fitting_delivery_notes
      }
    }
  }
`
