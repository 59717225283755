import React, { useState, Fragment, useContext } from 'react'
import MUIDataTable from 'mui-datatables'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'
import Icon from '@mdi/react'

import { openSnackbar } from './../../reusable/Notifier'

import Loading from '../../reusable/Loading'

import {
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Tooltip,
  IconButton,
} from '@material-ui/core'

import Button from './../../../newComponents/Button'
import FlexBox from './../../../newComponents/FlexBox'

import { UserContext } from './../../util/PageWrapper'

import {
  mdiMagnify,
  mdiPlus,
  mdiEye,
  mdiClipboardText,
  mdiCloudDownload,
} from '@mdi/js'

const GET_CUSTOMERS = gql`
  query customers(
    $firstName: String
    $lastName: String
    $email: String
    $postcode: String
  ) {
    customers(
      firstName: $firstName
      lastName: $lastName
      email: $email
      postcode: $postcode
    ) {
      _id
      first_name
      last_name
      address_line_1
      address_postcode
      address_city
      address_county
      address_line_2
      email
      mobile
      phone
      preferred_contact
      title
      customer_type
      marketing_news
      marketing_offers
    }
  }
`

const DOWNLOAD_ALL_CUSTOMER_INFORMATION = gql`
  query getAllCustomerInformationDownload {
    getAllCustomerInformationDownload
  }
`

export default function Customers() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [postcode, setPostcode] = useState('')
  const [email, setEmail] = useState('')

  const user = useContext(UserContext)

  const [
    getAllCustomerInformationDownload,
    { loading: getAllCustomerInformationDownloadLoading },
  ] = useLazyQuery(DOWNLOAD_ALL_CUSTOMER_INFORMATION, {
    onError: () => {
      openSnackbar({
        message: 'Failed to get download of all customers',
        type: 'error',
      })
    },
    onCompleted: ({ getAllCustomerInformationDownload }) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${getAllCustomerInformationDownload}`
    },
  })

  const [
    getCustomers,
    { loading: getCustomersLoading, error: getCustomersError, data },
  ] = useLazyQuery(GET_CUSTOMERS)

  let variables = {
    email: email,
    firstName: firstName,
    lastName: lastName,
    postcode: postcode,
  }

  let view = (
    <Typography variant="body1" style={{ color: 'grey' }}>
      Search for a customer or create a new one
    </Typography>
  )

  if (getCustomersLoading) {
    view = <Loading></Loading>
  }

  if (getCustomersError) {
    view = (
      <Typography variant="body1">
        Failed to get customers, please try again.
      </Typography>
    )
  }

  if (data) {
    const { customers } = data

    //format data
    const formattedCustomersArray = customers.map((customer) => {
      //format postcode
      let postcode = customer.address_postcode.match(
        /^([a-zA-Z]{1,2}\d{1,2}[a-zA-Z]?)\s*(\d[a-zA-Z]{2})$/
      )
      postcode.shift()
      postcode = postcode.join(' ')

      const actions = (
        <div style={{ display: 'flex' }}>
          <Tooltip title="View Customer Details" placement="top">
            <IconButton
              aria-label="Edit"
              component={Link}
              to={'customers/edit/' + customer._id}
            >
              <Icon path={mdiEye} title="View Customer Details" size="24px" />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Customer Orders" placement="top">
            <IconButton
              aria-label="Orders"
              component={Link}
              to={'customers/orders/' + customer._id}
            >
              <Icon
                path={mdiClipboardText}
                title="View Customer Orders"
                size="24px"
              />
            </IconButton>
          </Tooltip>
        </div>
      )

      //format customers for table
      return [
        customer.first_name,
        customer.last_name,
        customer.address_line_1,
        postcode,
        actions,
      ]
    })

    const columns = [
      'First Name',
      'Last Name',
      'Address Line 1',
      'Postcode',
      {
        name: 'Actions',
        options: {
          sort: false,
        },
      },
    ]

    const options = {
      selectableRows: 'none',
      print: false,
      download: false,
      responsive: 'standard',
      search: false,
      filter: false,
      elevation: 0,
      viewColumns: true,
    }

    view = (
      <div style={{ width: '100%', height: '100%' }}>
        <MUIDataTable
          title="Existing Customers"
          data={formattedCustomersArray}
          columns={columns}
          options={options}
        />
      </div>
    )
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '16px',
            }}
          >
            <Typography component="h1" variant="h4" gutterBottom>
              Customers
            </Typography>
            <Button
              variant="contained"
              style={{
                marginLeft: 'auto',
              }}
              color="#f7941d"
              component={Link}
              to="customers/add"
              icon={
                <Icon path={mdiPlus} title="Close" size="24px" color="black" />
              }
            >
              New Customer
            </Button>
          </div>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ paddingBottom: '0px' }}>
              <FlexBox justifyContent="space-between">
                <Typography variant="subtitle2" style={{ color: 'grey' }}>
                  Search for customer
                </Typography>
                {user.role_id >= 3 && (
                  <Fragment>
                    <Button
                      style={{ marginLeft: '20px' }}
                      loading={getAllCustomerInformationDownloadLoading}
                      icon={
                        <Icon
                          path={mdiCloudDownload}
                          title="Close"
                          size="24px"
                        />
                      }
                      colour="white"
                      onClick={() => {
                        openSnackbar({
                          message: 'Your download is being created.',
                          type: 'success',
                        })

                        getAllCustomerInformationDownload()
                      }}
                    >
                      Download All Customers
                    </Button>
                  </Fragment>
                )}
              </FlexBox>
            </Grid>

            <Grid item xs={12} md={2}>
              <TextField
                label="First Name"
                value={firstName}
                fullWidth
                onChange={(e) => {
                  setFirstName(e.target.value)

                  variables.firstName = e.target.value
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Last Name"
                value={lastName}
                fullWidth
                onChange={(e) => {
                  setLastName(e.target.value)

                  variables.lastName = e.target.value
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Email Address"
                value={email}
                fullWidth
                onChange={(e) => {
                  setEmail(e.target.value)

                  variables.email = e.target.value
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Postcode"
                value={postcode}
                fullWidth
                onChange={(e) => {
                  setPostcode(e.target.value)

                  variables.postcode = e.target.value
                }}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                type="submit"
                loading={getCustomersLoading}
                disabled={
                  !email.trim() &&
                  !firstName.trim() &&
                  !lastName.trim() &&
                  !postcode.trim()
                }
                icon={<Icon path={mdiMagnify} title="Close" size="24px" />}
                onClick={async (e) => {
                  e.preventDefault()

                  getCustomers({ variables: variables })
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={12} style={{ padding: '0' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: data ? '1px' : '350px',
                }}
              >
                {view}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  )
}
