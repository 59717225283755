import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { Form, Field } from "react-final-form";
import Icon from "@mdi/react";
import { mdiContentSave } from "@mdi/js";
import NumberFormat from "react-number-format";
import { DatePicker } from "@material-ui/pickers";

import {
  Grid,
  TextField,
  Typography,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import {
  Loading,
  ErrorMessage,
  Button,
} from "@web-applications/daffodil-component-library";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

import { openSnackbar } from "../../../components/reusable/Notifier";
import { UserContext } from "../../../components/util/PageWrapper";

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      total_retail
      total_increase
      total_discount
      fitting_delivery_total
      grand_total
      card_number
      order_type
      finance_agreement_number
      finance_agreement_term_months
      weekly_payment
      first_payment_date
      deposit
      total_paid
      second_product_eligible_date
      deposit_date
      deposit_method
      deposit_paid
      to_pay
    }
  }
`;

export const GET_PAYMENTS = gql`
  query getPayments($orderId: ID!) {
    getPayments(orderId: $orderId) {
      _id
      payment_number
      time
      timezone
      approval_code
      status
      invoice_number
      amount
      transaction_id
      reference_number
      payment_type
      card_number
      refunded
      cancelled_due_to_restructure
      customer_paid
    }
  }
`;

const CHANGE_DEPOSIT_DETAILS = gql`
  mutation changeDepositDetails(
    $invoiceNumber: Int!
    $depositAmount: Float!
    $depositDate: DateTime!
  ) {
    changeDepositDetails(
      invoiceNumber: $invoiceNumber
      depositAmount: $depositAmount
      depositDate: $depositDate
    ) {
      _id
      deposit_date
      deposit
    }
  }
`;

export default function PaymentPlan() {
  const { invoiceNumber } = useParams();

  const { role_id: userRole } = useContext(UserContext);

  const [changeDepositDetails, { loading: changeDepositDetailsLoading }] =
    useMutation(CHANGE_DEPOSIT_DETAILS, {
      onError: () => {
        openSnackbar({
          message: "Changing deposit details failed",
          type: "error",
        });
      },
      onCompleted: () => {
        openSnackbar({
          message: "Deposit details changed successfully",
          type: "success",
        });
      },
    });

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  });

  const {
    data: paymentsData,
    loading: paymentsLoading,
    error: paymentsError,
  } = useQuery(GET_PAYMENTS, {
    variables: {
      orderId: data?.getOrder?._id,
    },
    fetchPolicy: "network-only",
    skip: !data?.getOrder?._id,
  });

  if (loading || paymentsLoading) return <Loading />;

  if (error || paymentsError)
    return <ErrorMessage error={error || paymentsError} />;

  const { getOrder: order } = data;

  // console.log("order", order);

  const { getPayments: payments } = paymentsData;

  const filteredPayments = payments
    .filter(
      (payment) =>
        payment.status === "PENDING" &&
        (payment.payment_type === "Scheduled Payment" ||
          payment.payment_type === "Automatic Retry" ||
          payment.payment_type === "Card Deposit")
    )
    .sort((a, b) => parseInt(a.time) - parseInt(b.time));

  let nextPaymentDate = "-";

  if (filteredPayments.length) {
    nextPaymentDate = filteredPayments[0].time;
  }

  const secondProductAmount =
    (order.total_retail - order.total_discount + order.total_increase) * 0.75;

  return (
    <Form
      initialValues={{
        depositDate: moment(order.deposit_date),
        depositAmount: parseFloat(order.deposit),
      }}
      onSubmit={({ depositAmount, depositDate }) => {
        changeDepositDetails({
          variables: {
            invoiceNumber: parseInt(invoiceNumber),
            depositDate,
            depositAmount: parseFloat(depositAmount),
          },
        });
      }}
      validate={({ depositAmount, depositDate }) => {
        let errors = {};

        if (!depositAmount) {
          errors.depositAmount = "Required";
        }

        if (!depositDate) {
          errors.depositDate = "Required";
        }

        // max deposit is what there is left to pay on the order inlcuding the deposit
        const maxDeposit = parseFloat(order.to_pay) + parseFloat(order.deposit);

        if (parseFloat(depositAmount) > maxDeposit) {
          errors.depositAmount = `Deposit cannot be more than £${maxDeposit}`;
        }

        if (parseFloat(depositAmount) < 1) {
          errors.depositAmount = `Deposit must be at least £1`;
        }

        return errors;
      }}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h5" gutterBottom>
                Payment Plan
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Order Totals
              </Typography>
              <Typography variant="body1">{`Product Price Total: ${new Intl.NumberFormat(
                "en-GB",
                {
                  style: "currency",
                  currency: "GBP",
                }
              ).format(order.total_retail)}`}</Typography>
              <Typography variant="body1">{`Total Increase: ${new Intl.NumberFormat(
                "en-GB",
                {
                  style: "currency",
                  currency: "GBP",
                }
              ).format(order.total_increase)}`}</Typography>
              <Typography variant="body1">{`Total Discount: ${new Intl.NumberFormat(
                "en-GB",
                {
                  style: "currency",
                  currency: "GBP",
                }
              ).format(order.total_discount)}`}</Typography>
              <Typography variant="body1">{`Fitting and Delivery Total: ${new Intl.NumberFormat(
                "en-GB",
                {
                  style: "currency",
                  currency: "GBP",
                }
              ).format(order.fitting_delivery_total)}`}</Typography>
              <Typography variant="body1">{`Grand Total: ${new Intl.NumberFormat(
                "en-GB",
                {
                  style: "currency",
                  currency: "GBP",
                }
              ).format(order.grand_total)}`}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Payment Plan
              </Typography>
              <Typography variant="body1">
                {order.order_type === "weekly" && "Order Type: Weekly"}
                {order.order_type === "monthly" && "Order Type: Monthly"}
                {order.order_type === "fortnightly" &&
                  "Order Type: Fortnightly"}
                {order.order_type === "4 weekly" && "Order Type: 4 Weekly"}
                {order.order_type === "financeAgreement" &&
                  "Order Type: Finance Agreement"}
                {order.order_type === "cashOnly" && "Order Type: Сash Only"}
              </Typography>
              {order.order_type === "financeAgreement" && (
                <Typography variant="body1">{`Finance Agreement Number: ${order.finance_agreement_number}`}</Typography>
              )}
              {order.order_type !== "financeAgreement" &&
                order.order_type !== "cashOnly" && (
                  <Typography variant="body1">{`Card Used: ${order.card_number}`}</Typography>
                )}

              {order.order_type === "financeAgreement" && (
                <Typography variant="body1">{`Finance Agreement Term: ${order.finance_agreement_term_months} Months`}</Typography>
              )}

              {order.order_type !== "financeAgreement" &&
                order.order_type !== "cashOnly" && (
                  <Typography variant="body1">{` ${
                    order.order_type === "weekly"
                      ? "Total Weeks:"
                      : "Total Months:"
                  } ${Math.ceil(
                    (order.total_retail -
                      order.deposit +
                      order.total_increase -
                      order.total_discount) /
                      order.weekly_payment
                  )}`}</Typography>
                )}

              {order.order_type !== "financeAgreement" && (
                <Typography variant="body1">
                  {order.order_type === "weekly" && "Weekly Payment:"}
                  {order.order_type === "monthly" && "Monthly Payment:"}
                  {order.order_type === "fortnightly" && "Fortnightly Payment:"}
                  {order.order_type === "4weekly" && "4 Weekly Payment:"}
                  {` ${new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  }).format(order.weekly_payment)}`}
                </Typography>
              )}
              <Typography variant="body1">{`First Payment Date: ${moment(
                order.first_payment_date,
                "x"
              ).format("DD/MM/YYYY")}`}</Typography>
              <Typography variant="body1">{`Next Payment Date: ${
                nextPaymentDate !== "-"
                  ? moment(nextPaymentDate).format("DD/MM/YYYY")
                  : nextPaymentDate
              }`}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Second Product
              </Typography>
              <Typography variant="body1">{`Payment Value for Eligibility: ${new Intl.NumberFormat(
                "en-GB",
                {
                  style: "currency",
                  currency: "GBP",
                }
              ).format(secondProductAmount)}`}</Typography>
              <Typography variant="body1">{`Remaining to pay before eligible: ${new Intl.NumberFormat(
                "en-GB",
                {
                  style: "currency",
                  currency: "GBP",
                }
              ).format(
                order.total_paid > secondProductAmount
                  ? 0
                  : secondProductAmount - order.total_paid
              )}`}</Typography>
              <Typography variant="body1">{`Estimated Date for Eligibility: ${
                order.second_product_eligible_date
                  ? moment(order.second_product_eligible_date).format(
                      "DD/MM/YYYY"
                    )
                  : "-"
              }`}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Deposit
              </Typography>
              <Typography variant="body1">{`Deposit Method: ${
                order.deposit_method === "cash"
                  ? "Cash"
                  : order.deposit_method === "cardLater"
                  ? "Card Later"
                  : "Card Now"
              }`}</Typography>
              <Typography variant="body1">{`Deposit Paid: ${
                order.deposit_paid ? "Yes" : "No"
              }`}</Typography>
              <Field name="depositAmount">
                {({ input, meta }) => (
                  <NumberFormat
                    customInput={TextField}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                    }}
                    {...input}
                    disabled={userRole === 1 || order.deposit_paid}
                    label="Deposit Amount"
                    displayType="tel"
                    decimalScale={2}
                    fixedDecimalScale
                    required
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                  />
                )}
              </Field>
              <Field name="depositDate">
                {({ input }) => (
                  <DatePicker
                    style={{
                      margin: "10px 0",
                    }}
                    margin="dense"
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    autoOk
                    disabled={userRole === 1 || order.deposit_paid}
                    error={false}
                    showTodayButton={false}
                    minDate={new Date(new Date().getTime() + 86400000)}
                    helperText=""
                    disablePast
                    label="Deposit Date"
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                icon={<Icon path={mdiContentSave} size="24px" />}
                loading={changeDepositDetailsLoading}
                disabled={pristine || invalid}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
}
