import React from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import { orange, lightGreen } from '@material-ui/core/colors'

import ParkedOrderOptions from './ParkedOrderOptions'

const secondary = lightGreen[500]
const primary = orange[500]

export default function ParkedOrdersTable({ parkedOrders }) {
  let agentName
  if (parkedOrders.length && parkedOrders[0].agent) {
    agentName = `${parkedOrders[0].agent.first_name} ${parkedOrders[0].agent.last_name}`
  }

  const formattedParkedOrders = parkedOrders.map((parkedOrder) => {
    return [
      moment(parkedOrder.time_started, 'x').format('DD/MM/YYYY H:mm:ss'),
      parkedOrder.payment_start_time
        ? moment(parkedOrder.payment_start_time, 'x').format(
            'DD/MM/YYYY H:mm:ss'
          )
        : 'Payment Not Started',
      parkedOrder.status,
      parkedOrder.first_name
        ? `${parkedOrder.first_name} ${parkedOrder.last_name}`
        : 'Unknown',
      <ParkedOrderOptions parkedOrder={parkedOrder} />,
    ]
  })

  const columns = [
    {
      name: 'Created Date',
      options: {
        filter: false,
      },
    },
    {
      name: 'Payment Start Time',
      options: {
        filter: false,
      },
    },
    'Status',
    'Customer',
    {
      name: 'Actions',
      options: {
        filter: false,
        sort: false,
      },
    },
  ]

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    print: false,
    download: false,
    responsive: 'standard',
    elevation: 0,
  }

  const columnWidthTheme = createMuiTheme({
    palette: {
      primary: { main: primary },
      secondary: { main: secondary },
    },
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'auto',
        },
        responsiveScrollFullHeight: {
          zIndex: 1,
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '14px',
        },
      },
      MUIDataTablePagination: {
        root: {
          borderBottom: '0',
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '13px',
        },
      },
    },
  })

  return (
    <MuiThemeProvider theme={columnWidthTheme}>
      <MUIDataTable
        title={agentName ? `${agentName}'s Parked Orders` : 'Parked Orders'}
        data={formattedParkedOrders}
        columns={columns}
        options={options}
      />
    </MuiThemeProvider>
  )
}
