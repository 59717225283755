import React from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { FormSpy } from 'react-final-form'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import { Typography } from '@material-ui/core'
import {
  CashDeposit,
  OneOffPayment,
  ScheduledPayment,
  CardDeposit,
  Retry,
} from '../../../assets/images/Icons'
import Error from '../../../newComponents/Error'
import Loading from '../../reusable/Loading'

const GET_PAYMENTS = gql`
  query getPayments($orderId: ID!) {
    getPayments(orderId: $orderId) {
      _id
      status
      payment_type
      refunded
      time
      amount
    }
  }
`

export default function CancelOrderPayments({ orderId }) {
  // get payments
  const { loading, error, data } = useQuery(GET_PAYMENTS, {
    variables: {
      orderId: orderId,
    },
  })
  if (loading) return <Loading />

  if (error) return <Error />

  // format payments

  const payments = data.getPayments

  // only approved payments can be refunded!
  let approvedPayments = payments.filter(
    (payment) =>
      (payment.status === 'APPROVED' &&
        payment.payment_type !== 'Refund' &&
        payment.payment_type !== 'Card Validation' &&
        !payment.refunded) ||
      (payment.status === 'COLLECTED' && !payment.refunded)
  )

  // Remove any payments that are older than six months as they cant be
  // refunded by keeping payments that are made after this date 6 months ago
  approvedPayments = approvedPayments.filter(({ time }) =>
    moment(time).isAfter(moment().subtract(6, 'months'))
  )

  //sort payments by time
  approvedPayments = approvedPayments.sort(
    (a, b) => parseInt(a.time) - parseInt(b.time)
  )

  const formattedPaymentsArray = approvedPayments.map((payment) => {
    //Figure out icon

    let icon

    switch (payment.payment_type) {
      case 'Cash Deposit':
        icon = <CashDeposit colour="#8BC34A" style={{ marginRight: '10px' }} />

        break

      case 'Card Deposit':
        icon = <CardDeposit colour="#8BC34A" style={{ marginRight: '10px' }} />

        break

      case 'One-Off Payment':
        icon = (
          <OneOffPayment colour="#8BC34A" style={{ marginRight: '10px' }} />
        )

        break

      case 'Scheduled Payment':
        icon = (
          <ScheduledPayment colour="#8BC34A" style={{ marginRight: '10px' }} />
        )

        break

      case 'Automatic Retry':
        icon = <Retry colour="#8BC34A" style={{ marginRight: '10px' }} />

        break

      case 'Manual Retry':
        icon = <Retry colour="#8BC34A" style={{ marginRight: '10px' }} />

        break

      default:
        break
    }
    return [
      payment._id,
      moment(payment.time).format('DD/MM/YY, HH:mm:ss'),
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
        {icon}
        {payment.payment_type}
      </div>,
      payment.status,
      new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(payment.amount),
    ]
  })

  const columns = [
    {
      name: 'Payment ID',
      options: {
        display: 'false',
      },
    },
    'Time',
    'Payment Type',
    'Status',
    'Amount',
  ]

  //load Table

  return (
    <FormSpy subscription={{ values: true }}>
      {({
        values,
        form: {
          mutators: { setPaymentsToRefund },
        },
      }) => {
        // get currently selected rows

        const options = {
          selectableRows: 'multiple',
          print: false,
          download: false,
          responsive: 'standard',
          filter: false,
          elevation: 0,
          viewColumns: false,
          search: false,
          rowsSelected: values.selectedIndexes,
          onRowsSelect: (rowSelected, allSelectedRows) => {
            //get ids of selected rows

            const selectedIndexes = allSelectedRows.map((index) => index.index)

            const selectedPayments = selectedIndexes.map(
              (index) => formattedPaymentsArray[index]
            )

            setPaymentsToRefund(selectedPayments, selectedIndexes)
          },
          customToolbarSelect: () => <div style={{ height: '50px' }}></div>,
        }

        return (
          <>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
              Only payments that were made in the last 6 months are able to be
              refunded to the card they were taken from. For any other payments,
              please issue a manual refund on the order.
            </Typography>
            <MUIDataTable
              title="Select Payments To Refund (if any)"
              data={formattedPaymentsArray}
              columns={columns}
              options={options}
            />
          </>
        )
      }}
    </FormSpy>
  )
}
