import React, { Fragment } from 'react'
import { Field, FormSpy } from 'react-final-form'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { DatePicker } from '@material-ui/pickers'
import { openSnackbar } from '../reusable/Notifier'
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons'
import {
  TextField,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from '@material-ui/core'
import { GET_CUSTOMER_VISIT_OUTCOMES } from '../../helpers/queries/CustomerVisitOutcome'

const FIND_ADDRESS = gql`
  query findAddress($postcode: String!) {
    findAddress(postcode: $postcode) {
      addressLine1
      addressLine2
      city
      county
      postcode
    }
  }
`

export default function Customer() {
  const {
    data: cvoData,
  } = useQuery(GET_CUSTOMER_VISIT_OUTCOMES)

  const [findAddress, { loading, data }] = useLazyQuery(FIND_ADDRESS, {
    onError: () => {
      openSnackbar({
        message: 'Failed to find address, please try again.',
        type: 'error',
      })
    },
  })

  let foundAdresses = []

  if (data) {
    foundAdresses = data.findAddress
  }

  return (
    <FormSpy
      subscription={{
        values: true,
        dirtySinceLastSubmit: true,
        submitting: true,
      }}
    >
      {({ values, dirtySinceLastSubmit, submitting }) => (
        <Grid
          container
          spacing={3}
          justify="center"
          style={{ marginTop: '15px' }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <Field name="title">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  select
                  label="Title"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                >
                  <MenuItem value="miss">Miss</MenuItem>
                  <MenuItem value="mrs">Mrs</MenuItem>
                  <MenuItem value="mr">Mr</MenuItem>
                  <MenuItem value="ms">Ms</MenuItem>
                  <MenuItem value="dr">Dr</MenuItem>
                </TextField>
              )}
            </Field>
          </Grid>
          <Grid item md={5} sm={4} xs={12}>
            <Field name="firstName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="First Name"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item md={5} sm={4} xs={12}>
            <Field name="lastName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Last Name"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name="addressLine1">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Address Line 1"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name="addressLine2">
              {({ input }) => (
                <TextField
                  {...input}
                  label="Address Line 2"
                  fullWidth
                  variant="outlined"
                />
              )}
            </Field>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name="addressCity">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="City"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name="addressCounty">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="County"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Field name="addressPostcode">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Postcode"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {!!values.addressPostcode && (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                size="large"
                disabled={loading}
                onClick={() => {
                  findAddress({
                    variables: {
                      postcode: values.addressPostcode,
                    },
                  })
                }}
              >
                {loading ? 'Searching' : 'Find Address'}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {!!foundAdresses.length && (
              <Field name="chosenAddress">
                {({ input }) => (
                  <TextField
                    {...input}
                    select
                    label="Addresses"
                    fullWidth
                    required
                    variant="outlined"
                  >
                    {foundAdresses.map((address, index) => (
                      <MenuItem
                        key={index}
                        value={`${address.addressLine1}, ${address.addressLine2}, ${address.city}, ${address.county}, ${address.postcode}`}
                      >{`${address.addressLine1}, ${address.addressLine2}, ${address.city}, ${address.county}, ${address.postcode}`}</MenuItem>
                    ))}
                  </TextField>
                )}
              </Field>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Field name="email">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Email"
                  fullWidth
                  required
                  type="email"
                  variant="outlined"
                  error={
                    (meta.error && meta.touched) ||
                    (!!meta.submitError && !dirtySinceLastSubmit && !submitting)
                  }
                  helperText={
                    meta.error && meta.touched
                      ? meta.error
                      : !!meta.submitError &&
                        !dirtySinceLastSubmit &&
                        !submitting
                      ? meta.submitError
                      : ''
                  }
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Field name="phone">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Phone"
                  fullWidth
                  required={
                    values.preferredContact === 'phone' || !!!values.mobile
                  }
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Field name="mobile">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Mobile"
                  fullWidth
                  required={
                    values.preferredContact === 'mobile' || !!!values.phone
                  }
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          {window.location.pathname.includes('/new-order') ? (
            <Fragment>
              <Grid item xs={12} sm={6} md={8}>
                <Field name="customerNotes">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="Notes"
                      multiline
                      rows="9"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                </Field>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Field name="preferredContact">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      select
                      label="Preferred Contact"
                      fullWidth
                      required
                      variant="outlined"
                      style={{ paddingBottom: '15px' }}
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                    >
                      <MenuItem value="phone">Phone</MenuItem>
                      <MenuItem value="email">Email</MenuItem>
                      <MenuItem value="mobile">Mobile</MenuItem>
                      <MenuItem value="post">Post</MenuItem>
                    </TextField>
                  )}
                </Field>
                <Field name="customerType">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      select
                      disabled={window.location.href.includes('new-order')}
                      label="Customer Type"
                      fullWidth
                      required
                      variant="outlined"
                      style={{ paddingBottom: '15px' }}
                    >
                      <MenuItem value="New Customer">New Customer</MenuItem>
                      <MenuItem value="Platinum">Platinum</MenuItem>
                      <MenuItem value="Gold">Gold</MenuItem>
                      <MenuItem value="Silver">Silver</MenuItem>
                      <MenuItem value="Bronze">Bronze</MenuItem>
                      <MenuItem value="DNT">DNT</MenuItem>
                    </TextField>
                  )}
                </Field>

                <Field name="leadGeneration">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      select
                      label={
                        window.screen.width > 360
                          ? 'Where Did You Hear About Us?'
                          : 'Why Did You Choose Us?'
                      }
                      fullWidth
                      required
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                    >
                      <MenuItem value="facebook">Facebook</MenuItem>
                      <MenuItem value="instagram">Instagram</MenuItem>
                      <MenuItem value="google">Google</MenuItem>
                      <MenuItem value="website">Website</MenuItem>
                      <MenuItem value="freephone">Freephone</MenuItem>
                      <MenuItem value="inbound call">Inbound Call</MenuItem>
                      <MenuItem value="chat">Chat</MenuItem>
                      <MenuItem value="existing customer">Existing Customer</MenuItem>
                      <MenuItem value="mail campaign">Mail Campaign</MenuItem>
                      <MenuItem value="web lead">Web Lead</MenuItem>
                      <MenuItem value="referral">Referral</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </TextField>
                  )}
                </Field>
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Grid item xs={12} sm={6} md={3}>
                <Field name="preferredContact">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      select
                      label="Preferred Contact"
                      fullWidth
                      required
                      variant="outlined"
                      style={{ paddingBottom: '15px' }}
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                    >
                      <MenuItem value="phone">Phone</MenuItem>
                      <MenuItem value="email">Email</MenuItem>
                      <MenuItem value="mobile">Mobile</MenuItem>
                      <MenuItem value="post">Post</MenuItem>
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Field name="customerType">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      select
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                      label="Customer Type"
                      fullWidth
                      required
                      variant="outlined"
                      style={{ paddingBottom: '15px' }}
                    >
                      <MenuItem value="New Customer">New Customer</MenuItem>
                      <MenuItem value="Platinum">Platinum</MenuItem>
                      <MenuItem value="Gold">Gold</MenuItem>
                      <MenuItem value="Silver">Silver</MenuItem>
                      <MenuItem value="Bronze">Bronze</MenuItem>
                      <MenuItem value="DNT">DNT</MenuItem>
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Field name="marketingNews" type="checkbox">
                  {({ input }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          Customer agrees to receive marketing news.
                        </Typography>
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Field name="marketingOffers" type="checkbox">
                  {({ input }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          Customer agrees to receive marketing offers.
                        </Typography>
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="doorstepDate">
                  {({ input, meta }) => (
                    <DatePicker
                      clearable
                      format="DD/MM/YYYY"
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value ? input.value : null}
                      onBlur={input.onBlur}
                      fullWidth
                      required
                      disablePast={false}
                      autoOk={true}
                      disabled={false}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : ''
                      }
                      /*maxDate={moment(values.depositDate).add(
                        1,
                        values.orderType === 'weekly' ? 'weeks' : 'months'
                      )}
                      minDate={moment(values.depositDate).add(1, 'days')}*/
                      label="Customer Visit Date"
                      leftArrowIcon={<KeyboardArrowLeft />}
                      inputVariant="outlined"
                      rightArrowIcon={<KeyboardArrowRight />}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field name="doorstepOutcome">
                  {({ input, meta }) => (
                    /*
                    {...input}
                      select
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                      label="Customer Type"
                      fullWidth
                      required
                      variant="outlined"
                      style={{ paddingBottom: '15px' }}
                    */
                    <TextField
                      {...input}
                      select
                      fullWidth
                      variant="outlined"
                      label="Customer Visit Outcome"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : ''
                      }
                      style={{ paddingBottom: '15px' }}
                    >
                      {cvoData && cvoData.customerVisitOutcomes.map((outcome) => {
                        return (
                          <MenuItem
                            value={outcome.value}
                            key={outcome._id}
                          >
                            {outcome.name}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  )}
                </Field>
              </Grid>
            </Fragment>
          )}
        </Grid>
      )}
    </FormSpy>
  )
}
