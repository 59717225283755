import React from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { mdiClose } from '@mdi/js'
import { Form } from 'react-final-form'

import Card from './../../../newComponents/Card'
import Typography from './../../../newComponents/Typography'
import Spacer from './../../../newComponents/Spacer'
import Button from './../../../newComponents/Button'
import IconButton from './../../../newComponents/IconButton'

import FlexBox from '../../../newComponents/FlexBox'
import DatePicker from '../../../newComponents/DatePicker'

import { closeModal } from './../../reusable/Popup'
import { openSnackbar } from '../../reusable/Notifier'

const GET_ALL_PAYMENTS_DOWNLOAD = gql`
  query getPaymentsDownloadUrl($dateFrom: DateTime!, $dateTo: DateTime!) {
    getPaymentsDownloadUrl(dateFrom: $dateFrom, dateTo: $dateTo)
  }
`

export default function DownloadPaymentsModal() {
  const [
    getPaymentsDownloadUrl,
    { loading: downloadAllPaymentsLoading },
  ] = useLazyQuery(GET_ALL_PAYMENTS_DOWNLOAD, {
    onError: () => {
      openSnackbar({
        message: 'Failed to get download, please try again.',
        type: 'error',
      })
    },
    onCompleted: ({ getPaymentsDownloadUrl }) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${getPaymentsDownloadUrl}`
      closeModal()
    },
  })

  return (
    <Card>
      <FlexBox justifyContent="space-between">
        <Typography variant="h2">
          Which dates would you like payments for?
        </Typography>
        <IconButton
          color="black"
          onClick={() => {
            closeModal()
          }}
          path={mdiClose}
          title="Close"
          size="24px"
        />
      </FlexBox>
      <Spacer height="16px" />
      <Form
        onSubmit={({ dateFrom, dateTo }) => {
          getPaymentsDownloadUrl({
            variables: {
              dateFrom,
              dateTo,
            },
          })
        }}
        render={({ handleSubmit, pristine, values: { dateFrom } }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FlexBox>
              <DatePicker
                label="From"
                required
                name="dateFrom"
                showYearDropdown
                maxDate={new Date()}
              />
              <Spacer height="16px" />
              <DatePicker
                label="To"
                required
                name="dateTo"
                showYearDropdown
                minDate={dateFrom}
                maxDate={new Date()}
              />
            </FlexBox>
            <Button
              colour="#f7941d"
              disabled={pristine}
              type="submit"
              loading={downloadAllPaymentsLoading}
            >
              Download
            </Button>
          </form>
        )}
      />
    </Card>
  )
}
