import React, { Fragment, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { openModal } from './../../reusable/Popup'

import {
  Typography,
  Card,
  CardContent,
  Button,
  CardActions,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core'

import CancelNewOrderPopup from './CancelNewOrderPopup'
import Loading from '../../reusable/Loading'

export default function NewOrderWrapper({
  children,
  pageNumber,
  nextButtonLabel,
  nextPageFunction,
  loading,
  nextButtonLoading,
  previousPage,
  error,
}) {
  const params = useParams()
  const history = useHistory()

  let view

  if (loading) {
    view = <Loading />
  } else if (error) {
    view = (
      <Typography variant="body1" gutterBottom>
        {error}
      </Typography>
    )
  } else {
    view = children
  }

  // scroll page to top when opening new page
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography component="h1" variant="h4" gutterBottom>
          New Order
        </Typography>
        <Button
          variant="contained"
          style={{
            backgroundColor: 'red',
            color: 'white',
            marginLeft: 'auto',
            marginBottom: '10px',
          }}
          onClick={() => {
            openModal({
              content: (
                <CancelNewOrderPopup parkedOrderId={params.parkedOrderId} />
              ),
            })
          }}
        >
          Cancel Order
        </Button>
      </div>
      <Card>
        <CardContent style={{ minHeight: '350px' }}>{view}</CardContent>
        <Stepper activeStep={pageNumber}>
          {[
            {
              label: 'Choose Customer',
              url: `/new-order/choose-customer/${params.parkedOrderId}`,
            },
            {
              label: 'Customer Details',
              url: `/new-order/customer-details/${params.parkedOrderId}`,
            },
            {
              label: 'Order Details',
              url: `/new-order/order-details/${params.parkedOrderId}`,
            },
            {
              label: 'Payment Details',
              url: `/new-order/payment-details/${params.parkedOrderId}`,
            },
            {
              label: 'Billing Details',
              url: `/new-order/billing-details/${params.parkedOrderId}`,
            },
            {
              label: 'Order Review',
              url: `/new-order/order-review/${params.parkedOrderId}`,
            },
            {
              label: 'Order Confirmation',
              url: `/new-order/order-confirmation/${params.parkedOrderId}`,
            },
          ].map((step, index) => {
            return (
              <Step
                key={step.label}
                onClick={() => {
                  if (index < pageNumber) {
                    history.push(step.url)
                  }
                }}
                style={{ cursor: index < pageNumber ? 'pointer' : 'default' }}
                completed={index < pageNumber}
              >
                <StepLabel>{step.label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        <CardActions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button
            disabled={pageNumber === 0}
            onClick={() => {
              if (previousPage) {
                history.push(
                  `/new-order/${previousPage}/${params.parkedOrderId}`
                )
              } else {
                history.goBack()
              }
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={nextPageFunction}
            disabled={nextButtonLoading}
          >
            {nextButtonLoading ? 'Loading' : nextButtonLabel}
          </Button>
        </CardActions>
      </Card>
    </Fragment>
  )
}
