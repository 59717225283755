import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'

import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'

import { closeModal } from './../../reusable/Popup'
import Loading from '../../reusable/Loading'
import ErrorMessage from './../../../newComponents/Error'
import { openSnackbar } from '../../reusable/Notifier'

const GET_CUSTOMER = gql`
  query getCustomerByEmail($email: String!) {
    getCustomerByEmail(email: $email) {
      _id
      customer_type
      address_line_1
      address_postcode
      first_name
      last_name
    }
  }
`

const MERGE_CUSTOMERS = gql`
  mutation mergeCustomers($originalCustomerId: ID!, $newCustomerId: ID!) {
    mergeCustomers(
      originalCustomerId: $originalCustomerId
      newCustomerId: $newCustomerId
    ) {
      _id
    }
  }
`

export default function OverwriteCustomerEmail({
  originalCustomerId,
  newCustomerEmail,
}) {
  const history = useHistory()

  const { loading, data, error } = useQuery(GET_CUSTOMER, {
    variables: { email: newCustomerEmail },
  })

  const [mergeCustomers, { loading: mergeCustomersLoading }] = useMutation(
    MERGE_CUSTOMERS,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to merge customers, please try again',
          type: 'error',
        })
      },
      onCompleted: ({ mergeCustomers: { _id } }) => {
        closeModal()
        history.push(`/customers/edit/${_id}`)
      },
    }
  )

  if (loading)
    return (
      <Card>
        <CardContent>
          <Loading />
        </CardContent>
      </Card>
    )

  if (error)
    return (
      <Card>
        <CardContent>
          <ErrorMessage error={error} />
        </CardContent>
      </Card>
    )

  const customerDetails = data.getCustomerByEmail

  return (
    <Card>
      <CardHeader title="Email already in use" />
      <CardContent>
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1" paragraph>
                This email is already in use by another customer:
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0, 0.4)',
                      }}
                    >
                      First Name
                    </TableCell>
                    <TableCell
                      style={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0, 0.4)',
                      }}
                    >
                      Last Name
                    </TableCell>
                    <TableCell
                      style={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0, 0.4)',
                      }}
                    >
                      Address Line 1
                    </TableCell>
                    <TableCell
                      style={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0, 0.4)',
                      }}
                    >
                      Postcode
                    </TableCell>
                    <TableCell
                      style={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0, 0.5)',
                      }}
                    >
                      Customer Type
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}>
                      {customerDetails.first_name}
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                      {customerDetails.last_name}
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                      {customerDetails.address_line_1}
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                      {customerDetails.address_postcode}
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                      {customerDetails.customer_type}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                You can choose to merge these customers to merge all orders,
                payments, and related activity under the email address{' '}
                {newCustomerEmail}. Merging these customers cannot be undone,
                please ensure you know what you are doing before continuing.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  closeModal()
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={mergeCustomersLoading}
                onClick={() => {
                  mergeCustomers({
                    variables: {
                      originalCustomerId,
                      newCustomerId: customerDetails._id,
                    },
                  })
                }}
              >
                Merge Customers
              </Button>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}
