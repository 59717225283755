import React, { Fragment, useContext, useState } from 'react'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import { IconButton, Menu, MenuItem } from '@material-ui/core'

import { MoreVert } from '@material-ui/icons'

import { openSnackbar } from './../../reusable/Notifier'

import { UserContext } from '../../util/PageWrapper'

const CLAIM_CANCELLATION = gql`
  mutation claimCancellation($cancellationId: String!, $unclaim: Boolean) {
    claimCancellation(cancellationId: $cancellationId, unclaim: $unclaim) {
      _id
      claimed_by {
        _id
        first_name
        last_name
      }
    }
  }
`

export default function CancellationOptions({ cancellation }) {
  const user = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null)

  const [
    claimCancellation,
    { loading: claimCancellationLoading },
  ] = useMutation(CLAIM_CANCELLATION, {
    onError: () => {
      openSnackbar({
        message: 'Failed to change cancellation assignee, please try again',
        type: 'error',
      })
    },
  })

  return (
    <Fragment>
      <IconButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
        }}
      >
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
      >
        {cancellation.claimed_by && cancellation.claimed_by._id === user._id ? (
          <MenuItem
            disabled={claimCancellationLoading}
            onClick={() => {
              setAnchorEl(null)
              claimCancellation({
                variables: {
                  cancellationId: cancellation._id,
                  unclaim: true,
                },
              }).then(() => {
                openSnackbar({
                  message: `Cancellation unassigned from ${user.first_name} ${user.last_name}`,
                  type: 'success',
                })
              })
            }}
          >
            Unassign Me
          </MenuItem>
        ) : (
          <MenuItem
            disabled={claimCancellationLoading}
            onClick={() => {
              setAnchorEl(null)
              claimCancellation({
                variables: {
                  cancellationId: cancellation._id,
                },
              }).then(() => {
                openSnackbar({
                  message: `Cancellation assigned to ${user.first_name} ${user.last_name}`,
                  type: 'success',
                })
              })
            }}
          >
            Assign To Me
          </MenuItem>
        )}
        {user.can_cancel && (
          <MenuItem component={Link} to={`/cancel-request/${cancellation._id}`}>
            View Cancellation
          </MenuItem>
        )}

        <MenuItem
          component={Link}
          to={`/orders/single/${cancellation.order.invoice_number}`}
        >
          View Order
        </MenuItem>
      </Menu>
    </Fragment>
  )
}
