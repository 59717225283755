import React, { Fragment, useState, useRef } from "react";
import { Field } from "react-final-form";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";
import { Form } from "react-final-form";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import createDecorator from "final-form-focus";
import useDimensions from "react-use-dimensions";
import { useHistory } from "react-router-dom";

import { openSnackbar } from "./../../reusable/Notifier";

import NewOrderWrapper from "./NewOrderWrapper";

import {
  Grid,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  Button,
} from "@material-ui/core";

const GET_PARKED_ORDER = gql`
  query parkedOrder($parkedOrderId: String!) {
    parkedOrder(parkedOrderId: $parkedOrderId) {
      _id
      marketing_news
      marketing_offers
      deposit_date
      first_payment_date
      to_pay
      weekly_payment
      deposit
      order_type
      finance_agreement_number
      finance_agreement_term_months
    }
  }
`;

const CREATE_NEW_ORDER = gql`
  mutation createNewOrder(
    $parked_order_id: String!
    $marketing_news: Boolean
    $marketing_offers: Boolean
    $signature: String
  ) {
    createNewOrder(
      parked_order_id: $parked_order_id
      marketing_news: $marketing_news
      marketing_offers: $marketing_offers
      signature: $signature
    )
  }
`;

export default function OrderConfirmation({ match: { params } }) {
  const [paymentPage, setPaymentPage] = useState("");
  const sigCanvas = useRef({});
  const [ref, { width: signatureWidth }] = useDimensions({
    liveMeasure: false,
  });

  const history = useHistory();

  const focusOnErrors = createDecorator();

  const { loading, error, data } = useQuery(GET_PARKED_ORDER, {
    variables: { parkedOrderId: params.parkedOrderId },
  });

  const [createNewOrder, { loading: updateLoading }] = useMutation(
    CREATE_NEW_ORDER,
    {
      onError: (err) => {
        let message="Failed to create new order, please try again."
        if(err && err.message){
          message = message + ' ' + err.message
        }
        openSnackbar({
          message: message,
          type: "error",
        });
      },
      onCompleted: ({ createNewOrder }) => {
        if (createNewOrder.includes("/orders/single/")) {
          console.log(createNewOrder);
          history.push(createNewOrder);
        } else {
          setPaymentPage(createNewOrder);
          document.getElementById("payment-form").submit();
        }
      },
    }
  );

  //get current parked order details
  let initialValues = {};
  let paymentSchedule = [];

  if (data) {
    const { parkedOrder } = data;

    initialValues = {
      marketingNews: parkedOrder.marketing_news,
      marketingOffers: parkedOrder.marketing_offers,
      financeAgreementNumber: parkedOrder.finance_agreement_number,
      financeAgreementTerm: parkedOrder.finance_agreement_term_months,
      orderType: parkedOrder.order_type,
    };

    let depositDate = parkedOrder.deposit_date;
    if (depositDate == null) {
      depositDate = moment();
    }
    let firstPaymentDate = parkedOrder.first_payment_date;
    let toPay = parseFloat(parkedOrder.to_pay);

    const weeklyPayment = parseInt(parkedOrder.weekly_payment);

    const deposit = parseFloat(parkedOrder.deposit);

    paymentSchedule = [];

    if (parkedOrder.order_type !== "cashOnly") {
      paymentSchedule.push(
        `${moment(depositDate, "x").format("DD/MM/YYYY")} - £${deposit.toFixed(
          2
        )} (Deposit)`
      );
    }

    while (weeklyPayment <= toPay) {
      paymentSchedule.push(
        `${moment(firstPaymentDate, "x").format(
          "DD/MM/YYYY"
        )} - £${weeklyPayment.toFixed(2)}`
      );
      toPay = toPay - weeklyPayment;

      firstPaymentDate = moment(firstPaymentDate, "x").add(
        parkedOrder.order_type === "fortnightly"
          ? 2
          : parkedOrder.order_type === "4weekly"
          ? 4
          : 1,
        parkedOrder.order_type === "weekly" ||
          parkedOrder.order_type === "fortnightly" ||
          parkedOrder.order_type === "4weekly"
          ? "weeks"
          : "months"
      );
    }

    if (toPay !== 0) {
      paymentSchedule.push(
        `${moment(firstPaymentDate, "x").format(
          "DD/MM/YYYY"
        )} - £${toPay.toFixed(2)}`
      );
    }
  }

  return (
    <Form
      onSubmit={(values) => {
        createNewOrder({
          variables: {
            parked_order_id: params.parkedOrderId,
            marketing_news: values.marketingNews,
            marketing_offers: values.marketingOffers,
            signature: values.signature,
          },
        });
      }}
      decorators={[focusOnErrors]}
      validate={(values) => {
        const errors = {};

        const requiredFields = [
          "consecutivePayments",
          "roomCleared",
          "vinylCleared",
          "doorsRemoved",
          "wasteCreated",
          "fittingFee",
          "cancellationPolicy",
          "signature",
          "bedAccess",
          "accidentalDamage",
          "noReturn",
          "notHome",
        ];

        requiredFields.forEach((requiredField) => {
          if (!values[requiredField]) {
            errors[requiredField] = "Required";
          }
        });

        return errors;
      }}
      initialValues={initialValues}
      mutators={{
        saveSignature: (args, state, utils) => {
          utils.changeValue(state, "signature", () => args[0]);
        },
        eraseSignature: (args, state, utils) => {
          utils.changeValue(state, "signature", () => "");
        },
      }}
      render={({
        handleSubmit,
        values,
        form: {
          mutators: { saveSignature, eraseSignature },
        },
      }) => (
        <NewOrderWrapper
          pageNumber={6}
          nextButtonLabel="Finish and Pay"
          nextPageFunction={() => {
            handleSubmit();
          }}
          loading={loading}
          nextButtonLoading={updateLoading}
          error={
            error
              ? "Failed to get order details, please refresh the page."
              : null
          }
        >
          <form autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography component="h2" variant="h5" gutterBottom>
                  Please agree to these terms to proceed with your order
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Field name="consecutivePayments" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          I am aware that I must make the agreed number of
                          automated Consecutive scheduled payments before
                          fitting/delivery can be arranged. I am aware these
                          payments are taken at around 3am in the morning and
                          that if I fail a payment and subsequently catch up, I
                          still need to make the agreed Consecutive number of
                          Scheduled payments again.
                        </Typography>
                      }
                    />
                  )}
                </Field>

                <Field name="roomCleared" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          I am aware that the room must be cleared of all
                          furniture and existing flooring prior to the fitters
                          arriving.
                        </Typography>
                      }
                    />
                  )}
                </Field>
                <Field name="vinylCleared" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          When installing vinyl flooring in a kitchen I am aware
                          that it is my responsibility to ensure all appliances
                          including cookers are moved out of the room prior to
                          the fitter arriving.
                        </Typography>
                      }
                    />
                  )}
                </Field>
                <Field name="doorsRemoved" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          I am aware that fitters are able to take doors off to
                          carry out flooring installation, however I understand
                          they will not trim them down and reinstall them.
                        </Typography>
                      }
                    />
                  )}
                </Field>
                <Field name="wasteCreated" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          I am aware that the waste created from laying the new
                          floor will not be removed from my property and will be
                          left to me to dispose of.
                        </Typography>
                      }
                    />
                  )}
                </Field>
                <Field name="fittingFee" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          I am aware that the fitting fee is payable in cash
                          directly to the fitter on the day of installation.
                        </Typography>
                      }
                    />
                  )}
                </Field>
                <Field name="cancellationPolicy" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          I am aware of the cancellation policy and that if I
                          fail any payments before fitting, it will delay my
                          fitting.
                        </Typography>
                      }
                    />
                  )}
                </Field>

                <Field name="bedAccess" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          I am aware in order for my bed to be delivered it is
                          my responsibility to allow free and easy access to my
                          property and room of choice.
                        </Typography>
                      }
                    />
                  )}
                </Field>

                <Field name="accidentalDamage" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          I am aware that Pay Per Week Carpets and Beds accept
                          no responsibility for any accidental damage to
                          paintwork, wallpaper, flooring, light fittings or
                          picture frames as a result of my bed being delivered.
                        </Typography>
                      }
                    />
                  )}
                </Field>

                <Field name="noReturn" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          I am aware that once the bed has been removed from its
                          packaging it cannot be returned.
                        </Typography>
                      }
                    />
                  )}
                </Field>

                <Field name="notHome" type="checkbox">
                  {({ input, meta }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: input.name }}
                          {...input}
                          value={input.name}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          style={{
                            color: meta.touched && meta.error ? "red" : "black",
                          }}
                        >
                          I am aware that if I am not at home for the agreed
                          home delivery, it may result in a £20 delivery fee
                          being charged before an alternative delivery date
                          being given.
                        </Typography>
                      }
                    />
                  )}
                </Field>
              </Grid>
              {(!!!initialValues.marketingNews ||
                !!!initialValues.marketingOffers) && (
                <Fragment>
                  <Grid item xs={12}>
                    <Typography component="h2" variant="h5" gutterBottom>
                      Marketing Preferences
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {!!!initialValues.marketingNews && (
                      <Field name="marketingNews" type="checkbox">
                        {({ input }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                inputProps={{ name: input.name }}
                                {...input}
                                value={input.name}
                              />
                            }
                            label="I would like to hear news about Pay Per Week Carpets and Beds."
                          />
                        )}
                      </Field>
                    )}
                    {!!!initialValues.marketingOffers && (
                      <Field name="marketingOffers" type="checkbox">
                        {({ input }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                inputProps={{ name: input.name }}
                                {...input}
                                value={input.name}
                              />
                            }
                            label="I would like to hear about offers from Pay Per Week Carpets and Beds."
                          />
                        )}
                      </Field>
                    )}
                  </Grid>
                </Fragment>
              )}

              {values.orderType === "financeAgreement" ? (
                <>
                  <Grid item xs={12}>
                    <Typography component="h2" variant="h5" gutterBottom>
                      Payment Assist
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      {`Finance agreement number: ${values.financeAgreementNumber}`}
                    </Typography>
                    <Typography variant="body1">
                      {`Finance agreement term: ${values.financeAgreementTerm} months`}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography component="h2" variant="h5" gutterBottom>
                      Payment Plan
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {paymentSchedule.map((payment, index) => (
                      <Typography key={index} variant="body1">
                        {payment}
                      </Typography>
                    ))}
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Typography component="h2" variant="h5" gutterBottom>
                  Signature
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Field name="signature">
                  {({ meta }) => (
                    <Fragment>
                      <div
                        ref={ref}
                        style={{
                          borderStyle: "solid",
                          borderWidth: "2px",
                          borderColor:
                            meta.touched && meta.error
                              ? "red"
                              : " rgba(0, 0, 0, 0.12)",
                          borderRadius: "10px",
                          marginBottom: "10px",
                          overflow: "hidden",
                        }}
                      >
                        <SignatureCanvas
                          ref={sigCanvas}
                          canvasProps={{
                            width: signatureWidth,
                            height: 200,
                          }}
                          onEnd={() => {
                            const signature = sigCanvas.current
                              .getTrimmedCanvas()
                              .toDataURL();
                            saveSignature(signature);
                          }}
                        />
                      </div>
                      {meta.touched && meta.error && (
                        <Typography
                          variant="body2"
                          style={{ color: "red" }}
                          align="center"
                        >
                          {meta.error}
                        </Typography>
                      )}
                    </Fragment>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    sigCanvas.current.clear();
                    eraseSignature();
                  }}
                >
                  Clear Signature
                </Button>
              </Grid>
            </Grid>
          </form>
          {!!paymentPage && (
            <form
              action={paymentPage}
              method="post"
              id="payment-form"
              style={{ display: "none" }}
            >
              <input type="submit" value="Do Stuff!" />
            </form>
          )}
        </NewOrderWrapper>
      )}
    />
  );
}
