import React, { Fragment, useContext } from "react";
import { Query, Mutation } from "@apollo/react-components";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Form, Field } from "react-final-form";
import gql from "graphql-tag";
import {
  Editor,
  EditorState,
  convertToRaw,
  convertFromRaw,
  RichUtils,
} from "draft-js";

import { openSnackbar } from "./../../reusable/Notifier";
import Loading from "../../reusable/Loading";

import { GET_SETTINGS } from "./../../../helpers/queries/Other";

import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Toolbar,
  Tooltip,
  IconButton,
  TextField,
} from "@material-ui/core";

import { DatePicker } from "@material-ui/pickers";

import {
  Save,
  FormatItalic,
  FormatBold,
  FormatUnderlined,
  FormatListBulleted,
  FormatListNumbered,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";

import { UserContext } from "../../util/PageWrapper";
import Checkbox from "../../../newComponents/Checkbox";

const TAKE_PENDING_PAYMENTS = gql`
  mutation takePendingPayments($force: Boolean!) {
    takePendingPayments(force: $force)
  }
`;

const RESEND_FAILED_PAYMENT_TEXTS = gql`
  mutation resendFailedPaymentTexts {
    resendFailedPaymentTexts
  }
`;

const GET_TEXTS_IN_TIME_FRAME = gql`
  query getTextsInTimeFrame($dateFrom: DateTime!, $dateTo: DateTime!) {
    getTextsInTimeFrame(dateFrom: $dateFrom, dateTo: $dateTo)
  }
`;

const UPDATE_TERMS_AND_CONDITIONS = gql`
  mutation ($termsAndConditions: String!) {
    updateTermsAndConditions(termsAndConditions: $termsAndConditions) {
      _id
      terms_and_conditions
    }
  }
`;

const REGENERATE_ORDER_CONFIRMATION = gql`
  mutation regenerateOrderConfirmation($invoiceNumber: String!) {
    regenerateOrderConfirmation(invoiceNumber: $invoiceNumber)
  }
`;
const REGENERATE_RELEASE_EMAIL = gql`
  mutation regenerateReleaseEmail($invoiceNumber: String!) {
    regenerateReleaseEmail(invoiceNumber: $invoiceNumber)
  }
`;

const CHANGE_DEPOSIT_DATE = gql`
  mutation changeDepositDate(
    $invoiceNumber: String!
    $newDepositDate: String!
  ) {
    changeDepositDate(
      invoiceNumber: $invoiceNumber
      newDepositDate: $newDepositDate
    ) {
      _id
      deposit_date
    }
  }
`;

const UPDATE_SEND_RELEASE_TEXTS = gql`
  mutation updateSendReleaseTexts($send_release_texts: Boolean!) {
    updateSendReleaseTexts(send_release_texts: $send_release_texts) {
      _id
    }
  }
`;
const UPDATE_CUSTOMER_HTML_TEMLPATE = gql`
  mutation updateCustomerHtmlTemplate($customer_html_template: String!) {
    updateCustomerHtmlTemplate(
      customer_html_template: $customer_html_template
    ) {
      _id
    }
  }
`;

export default function GeneralSettings() {
  const user = useContext(UserContext);

  const [changeDepositDate, { loading: changeDepositDateLoading }] =
    useMutation(CHANGE_DEPOSIT_DATE, {
      onError: (error) => {
        openSnackbar({
          message: error,
          type: "error",
        });
      },
      onCompleted: () => {
        openSnackbar({
          message: "Deposit Date updated successfully",
          type: "success",
        });
      },
    });

  const [getTextsInTimeFrame, { loading: getTextsInTimeFrameLoading }] =
    useLazyQuery(GET_TEXTS_IN_TIME_FRAME, {
      onError: () => {
        openSnackbar({
          message: "Failed to get texts, please try again.",
          type: "error",
        });
      },
      onCompleted: ({ getTextsInTimeFrame }) => {
        openSnackbar({
          message: `Texts: ${getTextsInTimeFrame}`,
          type: "success",
        });
      },
    });

  const [takePayments, { loading: takePaymentsLoading }] = useMutation(
    TAKE_PENDING_PAYMENTS,
    {
      onError: (error) => {
        const errorMessage = error?.message || "No error message available";
        openSnackbar({
          message: `Failed to take payments. ${errorMessage}`,
          type: "error",
          link: [
            "https://github.com/github-daffodil/daffodilPPWV1?tab=readme-ov-file#payments-did-not-attempt-to-take",
            "Need help?",
          ],
        });
      },
      onCompleted: ({ takePendingPayments }) => {
        openSnackbar({
          message: `Processing ${takePendingPayments} Payments`,
          type: "success",
        });
      },
    }
  );

  const [
    resendFailedPaymentTexts,
    { loading: resendFailedPaymentTextsLoading },
  ] = useMutation(RESEND_FAILED_PAYMENT_TEXTS, {
    onError: () => {
      openSnackbar({
        message:
          "Failed to send failed payment texts. Ask Hollie what happened.",
        type: "error",
      });
    },
    onCompleted: ({ resendFailedPaymentTexts }) => {
      openSnackbar({
        message: `Links Sent: ${resendFailedPaymentTexts}`,
        type: "success",
      });
    },
  });

  const [
    updateTermsAndConditions,
    { loading: updateTermsAndConditionsLoading },
  ] = useMutation(UPDATE_TERMS_AND_CONDITIONS, {
    onError: () => {
      openSnackbar({
        message: "Failed to update terms and conditions, please try again",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: `Terms and Conditions Updated`,
        type: "success",
      });
    },
  });

  const [
    regenerateOrderConfirmation,
    { loading: regenerateOrderConfirmationLoading },
  ] = useMutation(REGENERATE_ORDER_CONFIRMATION, {
    onError: () => {
      openSnackbar({
        message: "Failed to regnerate order confirmation",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Order confirmation was regenerated",
        type: "success",
      });
    },
  });

  const [regenerateReleaseEmail, { loading: regenerateReleaseEmailLoading }] =
    useMutation(REGENERATE_RELEASE_EMAIL, {
      onError: () => {
        openSnackbar({
          message: "Failed to regenerate release email",
          type: "error",
        });
      },
      onCompleted: () => {
        openSnackbar({
          message: "Release email was regenerated",
          type: "success",
        });
      },
    });

  const [updateSendReleaseTexts, { loading: updateSendReleaseTextsLoading }] =
    useMutation(UPDATE_SEND_RELEASE_TEXTS, {
      refetchQueries: [{ query: GET_SETTINGS }],
      onError: () => {
        openSnackbar({
          message: "Failed to update Send Release Texts",
          type: "error",
        });
      },
      onCompleted: () => {
        openSnackbar({
          message: "Send Release Texts updated",
          type: "success",
        });
      },
    });

  const [
    updateCustomerHtmlTemplate,
    { loading: updateCustomerHtmlTemplateLoading },
  ] = useMutation(UPDATE_CUSTOMER_HTML_TEMLPATE, {
    refetchQueries: [{ query: GET_SETTINGS }],
    onError: () => {
      openSnackbar({
        message: "Failed to update the template",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Template updated",
        type: "success",
      });
    },
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h4" gutterBottom>
          General Settings
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {(user.first_name === "Hollie" || user.first_name === "Daffodil") &&
              (user.last_name === "Software" ||
                user.last_name === "Pearson" ||
                user.role_id >= 5) && (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      takePayments({ variables: { force: false } });
                    }}
                    style={{ marginBottom: "10px" }}
                    disabled={takePaymentsLoading}
                  >
                    Take Pending Payments
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      takePayments({ variables: { force: true } });
                    }}
                    style={{ marginBottom: "10px" }}
                    disabled={takePaymentsLoading}
                  >
                    Force Take Pending Payments
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      resendFailedPaymentTexts();
                    }}
                    style={{ marginBottom: "10px" }}
                    disabled={resendFailedPaymentTextsLoading}
                  >
                    Resend Failed Payment Messages
                  </Button>
                  <Typography component="h2" variant="h6" gutterBottom>
                    Change Payments Before Release
                  </Typography>
                  <Mutation
                    mutation={gql`
                      mutation changeWeeksBeforeRelease(
                        $invoiceNumber: String!
                        $numberOfWeeks: Int!
                      ) {
                        changeWeeksBeforeRelease(
                          invoiceNumber: $invoiceNumber
                          numberOfWeeks: $numberOfWeeks
                        ) {
                          _id
                          payments_before_release
                          release_date
                        }
                      }
                    `}
                  >
                    {(changeWeeksBeforeRelease) => (
                      <Form
                        onSubmit={(values) => {
                          changeWeeksBeforeRelease({
                            variables: {
                              invoiceNumber: values.invoiceNumber,
                              numberOfWeeks: parseInt(values.numberOfWeeks),
                            },
                          })
                            .then(() => {
                              openSnackbar({
                                message: "Order was updated successfully",
                                type: "success",
                              });
                            })
                            .catch((error) => {
                              openSnackbar({
                                message: "Failed to update order release",
                                type: "error",
                              });
                            });
                        }}
                        render={({ handleSubmit, pristine, submitting }) => (
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={3}>
                                <Field name="invoiceNumber">
                                  {({ input }) => (
                                    <TextField
                                      {...input}
                                      label="Order Number"
                                      fullWidth
                                      required
                                      variant="outlined"
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Field name="numberOfWeeks">
                                  {({ input }) => (
                                    <TextField
                                      {...input}
                                      label="No. Of Weeks Before Release"
                                      fullWidth
                                      required
                                      variant="outlined"
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  disabled={pristine || submitting}
                                >
                                  <Save style={{ marginRight: "10px" }} />
                                  Save
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      />
                    )}
                  </Mutation>
                  <Typography component="h2" variant="h6" gutterBottom>
                    Change Deposit Date
                  </Typography>
                  <Form
                    onSubmit={(values) => {
                      changeDepositDate({
                        variables: {
                          invoiceNumber: values.invoiceNumber,
                          newDepositDate: values.newDepositDate,
                        },
                      });
                    }}
                    render={({ handleSubmit, pristine, submitting }) => (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={3}>
                            <Field name="invoiceNumber">
                              {({ input }) => (
                                <TextField
                                  {...input}
                                  label="Order Number"
                                  fullWidth
                                  required
                                  variant="outlined"
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Field name="newDepositDate">
                              {({ input }) => (
                                <DatePicker
                                  format="DD/MM/YYYY"
                                  name={input.name}
                                  onChange={input.onChange}
                                  value={input.value ? input.value : null}
                                  onBlur={input.onBlur}
                                  fullWidth
                                  required
                                  disablePast
                                  autoOk
                                  label="New Deposit Date"
                                  leftArrowIcon={<KeyboardArrowLeft />}
                                  inputVariant="outlined"
                                  rightArrowIcon={<KeyboardArrowRight />}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={
                                pristine ||
                                submitting ||
                                changeDepositDateLoading
                              }
                            >
                              <Save style={{ marginRight: "10px" }} />
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                  <Typography component="h2" variant="h6" gutterBottom>
                    Get Texts
                  </Typography>

                  <Form
                    onSubmit={(values) => {
                      getTextsInTimeFrame({
                        variables: {
                          dateFrom: values.dateFrom,
                          dateTo: values.dateTo,
                        },
                      });
                    }}
                    render={({ handleSubmit, pristine }) => (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={3}>
                            <Field name="dateFrom">
                              {({ input }) => (
                                <DatePicker
                                  format="DD/MM/YYYY"
                                  name={input.name}
                                  onChange={input.onChange}
                                  value={input.value ? input.value : null}
                                  onBlur={input.onBlur}
                                  fullWidth
                                  required
                                  disableFuture
                                  autoOk
                                  label="Date From"
                                  leftArrowIcon={<KeyboardArrowLeft />}
                                  inputVariant="outlined"
                                  rightArrowIcon={<KeyboardArrowRight />}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Field name="dateTo">
                              {({ input }) => (
                                <DatePicker
                                  format="DD/MM/YYYY"
                                  name={input.name}
                                  onChange={input.onChange}
                                  value={input.value ? input.value : null}
                                  onBlur={input.onBlur}
                                  fullWidth
                                  required
                                  disableFuture
                                  autoOk
                                  label="Date To"
                                  leftArrowIcon={<KeyboardArrowLeft />}
                                  inputVariant="outlined"
                                  rightArrowIcon={<KeyboardArrowRight />}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={pristine || getTextsInTimeFrameLoading}
                            >
                              <Save style={{ marginRight: "10px" }} />
                              Get Texts
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />

                  <Typography component="h2" variant="h6" gutterBottom>
                    Regenerate Order Confirmation
                  </Typography>

                  <Form
                    onSubmit={(values) => {
                      regenerateOrderConfirmation({
                        variables: {
                          invoiceNumber: values.invoiceNumber,
                        },
                      });
                    }}
                    render={({ handleSubmit, pristine, submitting }) => (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={3}>
                            <Field name="invoiceNumber">
                              {({ input }) => (
                                <TextField
                                  {...input}
                                  label="Order Number"
                                  fullWidth
                                  required
                                  variant="outlined"
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={
                                pristine ||
                                submitting ||
                                regenerateOrderConfirmationLoading
                              }
                            >
                              <Save style={{ marginRight: "10px" }} />
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />

                  <Typography component="h2" variant="h6" gutterBottom>
                    Regenerate Release Email
                  </Typography>

                  <Form
                    onSubmit={(values) => {
                      regenerateReleaseEmail({
                        variables: {
                          invoiceNumber: values.invoiceNumber,
                        },
                      });
                    }}
                    render={({ handleSubmit, pristine, submitting }) => (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={3}>
                            <Field name="invoiceNumber">
                              {({ input }) => (
                                <TextField
                                  {...input}
                                  label="Order Number"
                                  fullWidth
                                  required
                                  variant="outlined"
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={
                                pristine ||
                                submitting ||
                                regenerateReleaseEmailLoading
                              }
                            >
                              <Save style={{ marginRight: "10px" }} />
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                </Fragment>
              )}

            <Query query={GET_SETTINGS}>
              {({ loading, error, data }) => {
                if (loading || updateSendReleaseTextsLoading) {
                  return <Loading />;
                }

                if (error) {
                  return null;
                }

                const { settings } = data;

                return (
                  <>
                    <Typography component="h2" variant="h6" gutterBottom>
                      Send text message to customer on order release?
                    </Typography>

                    <Form
                      onSubmit={(values) => {
                        updateSendReleaseTexts({
                          variables: {
                            send_release_texts: values.send_release_texts,
                          },
                        });
                      }}
                      initialValues={{
                        send_release_texts: settings.send_release_texts,
                      }}
                      render={({
                        handleSubmit,
                        values,
                        pristine,
                        submitting,
                      }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                              <Field name="send_release_texts" type="checkbox">
                                {({ input }) => (
                                  <Checkbox
                                    inputProps={{
                                      name: "send_release_texts",
                                    }}
                                    {...input}
                                    value="Send release texts"
                                  />
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={
                                  pristine ||
                                  submitting ||
                                  updateSendReleaseTextsLoading
                                }
                              >
                                <Save style={{ marginRight: "10px" }} />
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    />

                    {user.role_id >= 5 && (
                      <Form
                        onSubmit={(values) => {
                          updateCustomerHtmlTemplate({
                            variables: {
                              customer_html_template:
                                values.customer_html_template,
                            },
                          });
                        }}
                        initialValues={{
                          customer_html_template:
                            settings.customer_html_template,
                        }}
                        render={({
                          handleSubmit,
                          values,
                          pristine,
                          submitting,
                        }) => (
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <Typography
                              component="h2"
                              variant="h6"
                              gutterBottom
                            >
                              Customer release email template
                            </Typography>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Field name="customer_html_template">
                                  {({ input }) => (
                                    <TextField
                                      {...input}
                                      label="Html"
                                      multiline
                                      rows="26"
                                      fullWidth
                                      variant="outlined"
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  disabled={
                                    pristine ||
                                    submitting ||
                                    updateCustomerHtmlTemplateLoading
                                  }
                                >
                                  <Save style={{ marginRight: "10px" }} />
                                  Save
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      />
                    )}

                    <Form
                      initialValues={{
                        terms: EditorState.createWithContent(
                          convertFromRaw(
                            JSON.parse(settings.terms_and_conditions)
                          )
                        ),
                      }}
                      validate={(values) => {
                        let errors = {};

                        if (!values.terms.getCurrentContent().hasText()) {
                          errors.terms = "Required";
                        }

                        return errors;
                      }}
                      onSubmit={(values) => {
                        updateTermsAndConditions({
                          variables: {
                            termsAndConditions: JSON.stringify(
                              convertToRaw(values.terms.getCurrentContent())
                            ),
                          },
                        });
                      }}
                      render={({
                        handleSubmit,
                        pristine,
                        submitting,
                        invalid,
                      }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Field name="terms">
                                {({ input, meta }) => (
                                  <Fragment>
                                    <Typography
                                      component="h2"
                                      variant="h6"
                                      gutterBottom
                                      style={{
                                        color: meta.error ? "red" : "inherit",
                                      }}
                                    >
                                      Terms and Conditions*
                                    </Typography>
                                    <div
                                      style={{
                                        borderStyle: "solid",
                                        borderWidth: "1px",
                                        borderColor: meta.error
                                          ? "red"
                                          : "#E0E0E0",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <Toolbar
                                        variant="dense"
                                        disableGutters
                                        style={{
                                          backgroundColor: "#E0E0E0",
                                          borderTopLeftRadius: "10px",
                                          borderTopRightRadius: "10px",
                                          paddingRight: "10px",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        <Tooltip title="Bold" placement="top">
                                          <IconButton
                                            onClick={() => {
                                              input.onChange(
                                                RichUtils.toggleInlineStyle(
                                                  input.value,
                                                  "BOLD"
                                                )
                                              );
                                            }}
                                          >
                                            <FormatBold />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Italic" placement="top">
                                          <IconButton
                                            onClick={() => {
                                              input.onChange(
                                                RichUtils.toggleInlineStyle(
                                                  input.value,
                                                  "ITALIC"
                                                )
                                              );
                                            }}
                                          >
                                            <FormatItalic />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          title="Underline"
                                          placement="top"
                                        >
                                          <IconButton
                                            onClick={() => {
                                              input.onChange(
                                                RichUtils.toggleInlineStyle(
                                                  input.value,
                                                  "UNDERLINE"
                                                )
                                              );
                                            }}
                                          >
                                            <FormatUnderlined />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          title="Bullet Points"
                                          placement="top"
                                        >
                                          <IconButton
                                            onClick={() => {
                                              input.onChange(
                                                RichUtils.toggleBlockType(
                                                  input.value,
                                                  "unordered-list-item"
                                                )
                                              );
                                            }}
                                          >
                                            <FormatListBulleted />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          title="Numbered List"
                                          placement="top"
                                        >
                                          <IconButton
                                            onClick={() => {
                                              input.onChange(
                                                RichUtils.toggleBlockType(
                                                  input.value,
                                                  "ordered-list-item"
                                                )
                                              );
                                            }}
                                          >
                                            <FormatListNumbered />
                                          </IconButton>
                                        </Tooltip>
                                      </Toolbar>
                                      <div style={{ padding: "20px" }}>
                                        <Editor
                                          editorState={input.value}
                                          onChange={input.onChange}
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </Fragment>
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={
                                  pristine ||
                                  submitting ||
                                  invalid ||
                                  updateTermsAndConditionsLoading
                                }
                              >
                                <Save
                                  style={{
                                    marginRight: "10px",
                                  }}
                                />
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    />
                  </>
                );
              }}
            </Query>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
