import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

import { Stepper, Step, StepLabel, Button, Grid } from '@material-ui/core'

export default class CancelOrderWizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  }
  static Page = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues || {},
    }
  }
  next = (values) =>
    this.setState((state) => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }))

  previous = () =>
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }))

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleSubmit = (values) => {
    const { children, onSubmit } = this.props
    const { page } = this.state
    const isLastPage = page === React.Children.count(children) - 1
    if (isLastPage) {
      return onSubmit(values)
    } else {
      this.next(values)
    }
  }

  render() {
    const { children, submitText, mutators, disableSubmit } = this.props
    const { page, values } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1

    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        mutators={{
          ...mutators,
        }}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            {activePage}

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stepper activeStep={page}>
                  {this.props.steps.map((label) => {
                    const props = {}
                    const labelProps = {}
                    return (
                      <Step key={label} {...props}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
                <Grid container>
                  <Grid
                    item
                    xs
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button disabled={page === 0} onClick={this.previous}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        disableSubmit ||
                        (isLastPage && !values.confirmCancellation)
                      }
                      type="submit"
                    >
                      {isLastPage ? submitText : 'Next'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    )
  }
}
