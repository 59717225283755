import React from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'

import FlexBox from './../../../newComponents/FlexBox'
import Spacer from './../../../newComponents/Spacer'
import Typography from '../../../newComponents/Typography'
import Card from '../../../newComponents/Card'
import CircleIcon from '../../../newComponents/CircleIcon'
import Button from '../../../newComponents/Button'

import FailedPayments from './../../../assets/images/failed-payments-img.svg'
import { Link } from 'react-router-dom'

export default function Reports() {
  return (
    <Container>
      <Row>
        <Col col xs="12">
          <Spacer height="16px" />
          <Typography variant="h1">Reports</Typography>
          <Spacer height="37px" />
        </Col>
      </Row>
      <Row>
        <Col col xs="12" sm="6" md="3">
          <Card>
            <FlexBox flexDirection="column" alignItems="center">
              <CircleIcon
                src={FailedPayments}
                alt="Failed Payment Report Icon"
                size="120px"
              />
              <Spacer height="30px" />
              <Typography variant="h2" center>
                Failed Payments Conversion Report
              </Typography>
              <Spacer height="16px" />
              <Typography variant="bodySmall" center>
                Conversions for failed payment texts/emails
              </Typography>
              <Spacer height="30px" />
              <Button
                colour="#FF9800"
                component={Link}
                to="/reports/failed-payment-conversion"
                target="_blank"
                fullwidth
              >
                View Report
              </Button>
            </FlexBox>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
