import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import { openSnackbar } from './../../reusable/Notifier'

import NewOrderWrapper from './NewOrderWrapper'
import ExistingCustomers from './ExistingCustomers'

const RESET_PARKED_ORDER = gql`
  mutation resetParkedOrder($parkedOrderId: ID!) {
    resetParkedOrder(parkedOrderId: $parkedOrderId) {
      _id
      customer_type
      existing_customer
      address_city
      address_county
      address_line_1
      address_line_2
      address_postcode
      email
      first_name
      last_name
      mobile
      phone
      preferred_contact
      title
      marketing_news
      marketing_offers
    }
  }
`

export default function ChooseCustomer({ match: { params }, history }) {
  const [resetParkedOrder, { loading }] = useMutation(RESET_PARKED_ORDER, {
    onError: () => {
      openSnackbar({
        message: 'Failed to clear selected customer, please try again',
        type: 'error',
      })
    },
    onCompleted: () => {
      history.push(`/new-order/customer-details/${params.parkedOrderId}`)
    },
  })

  return (
    <NewOrderWrapper
      pageNumber={0}
      nextButtonLabel="Create New Customer"
      nextButtonLoading={loading}
      nextPageFunction={() => {
        // clear selected customer
        resetParkedOrder({
          variables: {
            parkedOrderId: params.parkedOrderId,
          },
        })
      }}
    >
      <ExistingCustomers />
    </NewOrderWrapper>
  )
}
