import React, { Fragment } from 'react'
import gql from 'graphql-tag'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import { orange, lightGreen } from '@material-ui/core/colors'

import { Grid, Typography, Card, IconButton, Tooltip } from '@material-ui/core'

import { openSnackbar } from './../../reusable/Notifier'

import { CloudDownload } from '@material-ui/icons'

import Loading from '../../reusable/Loading'

import CancellationOptions from './CancellationOptions'

const secondary = lightGreen[500]
const primary = orange[500]

const GET_CANCELLATIONS = gql`
  query cancellations {
    cancellations {
      _id
      order {
        _id
        invoice_number
      }
      requested_by {
        _id
        first_name
        last_name
      }
      time_requested
      claimed_by {
        _id
        first_name
        last_name
      }
    }
  }
`

const DOWNLOAD_ALL_CANCELLATION_INFORMATION = gql`
  query getAllCancellationInformationDownload {
    getAllCancellationInformationDownload
  }
`

export default function CancellationsPage() {
  // get all pending and in progress cancellations
  const { loading, error, data } = useQuery(GET_CANCELLATIONS)

  const [
    getAllCancellationInformationDownload,
    { loading: getAllCancellationInformationDownloadLoading },
  ] = useLazyQuery(DOWNLOAD_ALL_CANCELLATION_INFORMATION, {
    onError: () => {
      openSnackbar({
        message: 'Failed to get download of all cancellations',
        type: 'error',
      })
    },
    onCompleted: ({ getAllCancellationInformationDownload }) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${getAllCancellationInformationDownload}`
    },
  })

  let view

  if (loading) {
    view = <Loading />
  }

  if (error) {
    view = (
      <Typography variant="body1">
        Failed to get cancellations, please refresh the page and try again.
      </Typography>
    )
  }

  if (data && data.cancellations) {
    // create the table for showing the cancellations

    const formattedCancellations = data.cancellations.map((cancellation) => {
      //calculate status
      let status = 'Pending'
      if (cancellation.claimed_by) status = 'In Progress'

      //create action buttons

      return [
        cancellation._id,
        cancellation.order.invoice_number,
        `${cancellation.requested_by.first_name} ${cancellation.requested_by.last_name}`,
        moment(cancellation.time_requested, 'x').format('DD/MM/YY, HH:mm:ss'),
        status,
        cancellation.claimed_by
          ? `${cancellation.claimed_by.first_name} ${cancellation.claimed_by.last_name}`
          : 'Unassigned',
        <CancellationOptions cancellation={cancellation} />,
      ]
    })

    const columns = [
      {
        name: 'Cancellation ID',
        options: {
          display: 'false',
        },
      },
      'Order Number',
      'Requested By',
      'Time Requested',
      'Status',
      'Assigned User',
      'Actions',
    ]

    const options = {
      selectableRows: 'none',
      print: false,
      download: false,
      responsive: 'standard',
      filter: false,
      elevation: 0,
      viewColumns: false,
      customToolbar: () => (
        <Fragment>
          <Tooltip title="Download All Cancellations" placement="bottom">
            <IconButton
              disabled={getAllCancellationInformationDownloadLoading}
              onClick={() => {
                openSnackbar({
                  message: 'Your download is being created.',
                  type: 'success',
                })

                getAllCancellationInformationDownload()
              }}
            >
              <CloudDownload />
            </IconButton>
          </Tooltip>
        </Fragment>
      ),
    }

    const columnWidthTheme = createMuiTheme({
      palette: {
        primary: { main: primary },
        secondary: { main: secondary },
      },
      overrides: {
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: 'auto',
          },
          responsiveScrollFullHeight: {
            zIndex: 1,
          },
        },
        MuiMenuItem: {
          root: {
            fontSize: '14px',
          },
        },
        MuiTooltip: {
          tooltip: {
            fontSize: '13px',
          },
        },
        MUIDataTablePagination: {
          root: {
            borderBottom: '0',
          },
        },
      },
    })

    view = (
      <MuiThemeProvider theme={columnWidthTheme}>
        <MUIDataTable
          title="Pending Cancellations"
          data={formattedCancellations}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h4" gutterBottom>
          Cancellations
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ minHeight: data && data.cancellations ? '0' : '500px' }}>
          {view}
        </Card>
      </Grid>
    </Grid>
  )
}
