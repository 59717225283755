import React from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/react-components";

import {
  Typography,
  Card,
  CardContent,
  Tooltip,
  CardHeader,
  IconButton,
  CardActions,
} from "@material-ui/core";

import { Button } from "@web-applications/daffodil-component-library";
import { closeModal, openModal } from "../components/reusable/Popup";
import { Clear, Delete } from "@material-ui/icons";
import { openSnackbar } from "../components/reusable/Notifier";

import { GET_ACTIVITIES } from "../pages/Order/Tabs/OrderActivity";

const DELETE_ACTIVITY = gql`
  mutation deleteActivity($activityId: ID!) {
    deleteActivity(activityId: $activityId) {
      _id
    }
  }
`;

const DeleteActivity = ({ activity, orderId }) => {
  return (
    <Mutation
      mutation={DELETE_ACTIVITY}
      refetchQueries={[
        {
          query: GET_ACTIVITIES,

          variables: {
            orderId: orderId,
          },
        },
      ]}
    >
      {(deleteActivity, { loading }) => (
        <Tooltip title="Delete" placement="top">
          <IconButton
            aria-label="Remove"
            onClick={() => {
              openModal({
                content: (
                  <Card>
                    <CardHeader
                      action={
                        <IconButton
                          aria-label="Close Pop-up"
                          onClick={closeModal}
                        >
                          <Clear />
                        </IconButton>
                      }
                      title="Delete Activity"
                    />
                    <CardContent>
                      <Typography variant="body1" gutterBottom>
                        Are you sure you want to delete this activity?
                      </Typography>
                    </CardContent>
                    <CardActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button colour="#f7941d" onClick={closeModal}>
                        Cancel
                      </Button>

                      <Button
                        colour="#8BC34A"
                        loading={loading}
                        onClick={() => {
                          closeModal();

                          deleteActivity({
                            variables: {
                              activityId: activity._id,
                            },
                          })
                            .then(() => {
                              openSnackbar({
                                message: "Activity deleted",
                                type: "success",
                              });
                            })
                            .catch(() => {
                              openSnackbar({
                                message: "Failed to delete activity",
                                type: "error",
                              });
                            });
                        }}
                      >
                        Continue
                      </Button>
                    </CardActions>
                  </Card>
                ),
              });
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      )}
    </Mutation>
  );
};

export default DeleteActivity;
