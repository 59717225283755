import gql from "graphql-tag";

export const GET_SETTINGS = gql`
  query settings {
    settings {
      _id
      terms_and_conditions
      send_release_texts
      customer_html_template
    }
  }
`;
