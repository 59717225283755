import React from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Form } from 'react-final-form'
import moment from 'moment'
import Icon from '@mdi/react'
import { mdiCloseCircleOutline, mdiDownload, mdiDotsVertical } from '@mdi/js'
import { Link } from 'react-router-dom'

import Dropdown from './../../../newComponents/Dropdown'
import MenuItem from './../../../newComponents/MenuItem'
import FlexBox from './../../../newComponents/FlexBox'
import Spacer from './../../../newComponents/Spacer'
import Typography from '../../../newComponents/Typography'
import Card from '../../../newComponents/Card'
import Divider from '../../../newComponents/Divider'
import Button from '../../../newComponents/Button'
import DatePicker from '../../../newComponents/DatePicker'
import TimeSelect from '../../../newComponents/TimeSelect'
import Select from '../../../newComponents/Select'
import Error from '../../../newComponents/Error'
import Image from '../../../newComponents/Image'
import Loading from './../../reusable/Loading'
import Table from './../../../newComponents/Table'

import { openSnackbar } from '../../reusable/Notifier'

import SearchIcon from './../../../assets/images/Search.svg'
import IconButton from '../../../newComponents/IconButton'

const GET_FAILED_PAYMENT_LINKS = gql`
  query getFailedPaymentDays(
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $textHour: Int
    $textMinute: Int
    $daysAfterFailedPayment: Int
  ) {
    getFailedPaymentDays(
      dateFrom: $dateFrom
      dateTo: $dateTo
      textHour: $textHour
      textMinute: $textMinute
      daysAfterFailedPayment: $daysAfterFailedPayment
    ) {
      date
      time
      days_after_failure
      messages_sent
      arrears_value
      payments_taken
      amount_taken
      conversion_rate
    }
  }
`

const GET_FAILED_PAYMENT_LINK_DOWNLOAD = gql`
  query getFailedPaymentDaysDownloadUrl(
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $textHour: Int
    $textMinute: Int
    $daysAfterFailedPayment: Int
  ) {
    getFailedPaymentDaysDownloadUrl(
      dateFrom: $dateFrom
      dateTo: $dateTo
      textHour: $textHour
      textMinute: $textMinute
      daysAfterFailedPayment: $daysAfterFailedPayment
    )
  }
`

const GET_FAILED_PAYMENT_DAY_DOWNLOAD = gql`
  query getFailedPaymentDaysDetailDownloadUrl(
    $dateFrom: String!
    $dateTo: String!
    $time: String
    $daysAfter: Int
  ) {
    getFailedPaymentDaysDetailDownloadUrl(
      dateFrom: $dateFrom
      dateTo: $dateTo
      time: $time
      daysAfter: $daysAfter
    )
  }
`

export default function FailedPaymentLinkReport() {
  const [getFailedPaymentDays, { loading, error, data }] = useLazyQuery(
    GET_FAILED_PAYMENT_LINKS,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to get data, please try again.',
          type: 'error',
        })
      },
    }
  )

  const [
    getFailedPaymentDaysDownloadUrl,
    { loading: downloadLoading },
  ] = useLazyQuery(GET_FAILED_PAYMENT_LINK_DOWNLOAD, {
    onError: () => {
      openSnackbar({
        message: 'Failed to get download, please try again.',
        type: 'error',
      })
    },
    onCompleted: ({ getFailedPaymentDaysDownloadUrl }) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${getFailedPaymentDaysDownloadUrl}`
    },
  })

  const [
    getFailedPaymentDaysDetailDownloadUrl,
    { loading: downloadDetailsLoading },
  ] = useLazyQuery(GET_FAILED_PAYMENT_DAY_DOWNLOAD, {
    onError: () => {
      openSnackbar({
        message: 'Failed to get download, please try again.',
        type: 'error',
      })
    },
    onCompleted: ({ getFailedPaymentDaysDetailDownloadUrl }) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${getFailedPaymentDaysDetailDownloadUrl}`
    },
  })

  //create options for days dropdown

  const days = [{ value: undefined, label: 'Select' }]

  for (let i = 0; i < 7; i++) {
    days.push({
      value: i,
      label: i,
    })
  }

  let formattedData = []

  //format date for table
  if (data?.getFailedPaymentDays) {
    formattedData = data.getFailedPaymentDays.map((failedPaymentDay) => ({
      date: failedPaymentDay.date,
      time: failedPaymentDay.time,
      daysAfterFailure: `+${failedPaymentDay.days_after_failure} Days`,
      messagesSent: failedPaymentDay.messages_sent,
      arrears: new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(failedPaymentDay.arrears_value),
      conversion: `${failedPaymentDay.payments_taken} Payments`,
      amountTaken: new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(failedPaymentDay.amount_taken),
      conversionRate: `${failedPaymentDay.conversion_rate}%`,
      actions: (
        <Dropdown anchor="right">
          <IconButton
            dropdownTrigger
            path={mdiDotsVertical}
            title="Download"
            size="24px"
            color="#818181"
          />

          <MenuItem
            component={Link}
            to={`/reports/failed-payment-conversion/individual?date=${failedPaymentDay.date}&time=${failedPaymentDay.time}&daysAfter=${failedPaymentDay.days_after_failure}`}
            target="_blank"
          >
            View Batch Details
          </MenuItem>
        </Dropdown>
      ),
    }))
  }

  return (
    <Container>
      <Row>
        <Col col xs="12">
          <Spacer height="16px" />
          <Typography variant="h1">
            Failed Payments Conversion Report
          </Typography>
          <Spacer height="37px" />
        </Col>
      </Row>
      <Row>
        <Col col xs="12">
          <Card>
            <Form
              onSubmit={({
                dateFrom,
                dateTo,
                textHour,
                textMinute,
                daysAfterFailedPayment,
              }) => {
                getFailedPaymentDays({
                  variables: {
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    textHour: textHour,
                    textMinute: textMinute,
                    daysAfterFailedPayment: daysAfterFailedPayment,
                  },
                })
              }}
              render={({
                handleSubmit,
                pristine,
                form,
                values: {
                  dateFrom,
                  dateTo,
                  textHour,
                  textMinute,
                  daysAfterFailedPayment,
                },
              }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <FlexBox justifyContent="space-between">
                    <Typography variant="h2">Message Batches</Typography>
                    <FlexBox>
                      <Button
                        variant="text"
                        disabled={!formattedData.length}
                        loading={downloadDetailsLoading}
                        onClick={() => {
                          getFailedPaymentDaysDetailDownloadUrl({
                            variables: {
                              dateFrom: moment(dateFrom).format('DD/MM/YYYY'),
                              dateTo: moment(dateTo).format('DD/MM/YYYY'),
                              time:
                                textHour && textMinute
                                  ? `${textHour}:${textMinute}`
                                  : null,
                            },
                          })

                          openSnackbar({
                            message: 'Your download will begin shortly',
                            type: 'success',
                          })
                        }}
                        icon={
                          <Icon
                            path={mdiDownload}
                            title="Download"
                            size="24px"
                            color="black"
                          />
                        }
                      >
                        Download Detailed Report
                      </Button>
                      <Spacer height="8px" />
                      <Button
                        variant="text"
                        loading={downloadLoading}
                        disabled={!formattedData.length}
                        onClick={() => {
                          getFailedPaymentDaysDownloadUrl({
                            variables: {
                              dateFrom: dateFrom,
                              dateTo: dateTo,
                              textHour: textHour,
                              textMinute: textMinute,
                              daysAfterFailedPayment: daysAfterFailedPayment,
                            },
                          })

                          openSnackbar({
                            message: 'Your download will begin shortly',
                            type: 'success',
                          })
                        }}
                        icon={
                          <Icon
                            path={mdiDownload}
                            title="Download"
                            size="24px"
                            color="black"
                          />
                        }
                      >
                        Download Overview Report
                      </Button>
                    </FlexBox>
                  </FlexBox>
                  <Spacer height="24px" />
                  <Divider />
                  <Spacer height="16px" />
                  <FlexBox>
                    <DatePicker
                      label="From"
                      required
                      name="dateFrom"
                      showYearDropdown
                      maxDate={new Date()}
                    />
                    <Spacer height="16px" />
                    <DatePicker
                      label="To"
                      required
                      name="dateTo"
                      showYearDropdown
                      minDate={dateFrom}
                      maxDate={new Date()}
                    />
                    <Spacer height="16px" />
                    <Typography
                      variant="caption"
                      colour="#7b7b7b"
                      className="helper-text"
                    >
                      at
                    </Typography>
                    <Spacer height="16px" />
                    <TimeSelect
                      hourName="textHour"
                      minuteName="textMinute"
                      label="Time Of Day"
                    />
                    <Spacer height="16px" />
                    <Typography
                      variant="caption"
                      colour="#7b7b7b"
                      className="helper-text"
                    >
                      on
                    </Typography>
                    <Spacer height="16px" />
                    <Select
                      name="daysAfterFailedPayment"
                      label="Days After Failed Payment"
                      options={days}
                    />
                    <Spacer height="16px" />
                    <Button
                      colour="#f7941d"
                      loading={loading}
                      disabled={pristine}
                      type="submit"
                    >
                      Search
                    </Button>
                  </FlexBox>
                  <FlexBox
                    onClick={() => {
                      form.reset()
                    }}
                  >
                    <Typography
                      variant="label"
                      onClick={() => {
                        form.reset()
                      }}
                    >
                      Clear Filters
                    </Typography>
                    <Spacer height="4px" />
                    <Icon
                      path={mdiCloseCircleOutline}
                      title="Clear Filters"
                      size="16px"
                      color="#7b7b7b"
                    />
                  </FlexBox>
                  <Spacer height="16px" />
                </form>
              )}
            />
            {error && <Error />}
            {loading && <Loading />}
            {!loading && !error && !data && (
              <FlexBox flexDirection="column">
                <Spacer height="96px" />
                <Image src={SearchIcon} height="80px" width="62px" />
                <Spacer height="30px" />
                <Typography variant="bodySmall">
                  Your search results will appear here
                </Typography>
                <Spacer height="96px" />
              </FlexBox>
            )}
            {data && (
              <Table
                headers={[
                  {
                    Header: 'Date',
                    id: 'date',
                    accessor: (c) => moment(c.date, 'DD/MM/YYYY'),
                    Cell: (c) => moment(c.cell.value).format('DD/MM/YYYY'),
                    disableFilters: true,
                  },
                  { Header: 'Time', accessor: 'time', disableFilters: true },
                  {
                    Header: 'Days After Failure',
                    accessor: 'daysAfterFailure',
                    disableFilters: true,
                  },
                  {
                    Header: 'No. Messages Sent',
                    accessor: 'messagesSent',
                    disableFilters: true,
                  },
                  {
                    Header: 'Value Of Arrears',
                    accessor: 'arrears',
                    disableFilters: true,
                  },
                  {
                    Header: 'Conversion',
                    accessor: 'conversion',
                    disableFilters: true,
                  },
                  {
                    Header: 'Amount Taken',
                    accessor: 'amountTaken',
                    disableFilters: true,
                  },
                  {
                    Header: 'Conversion Rate',
                    accessor: 'conversionRate',
                    disableFilters: true,
                  },
                  {
                    accessor: 'actions',
                    disableSortBy: true,
                    disableFilters: true,
                  },
                ]}
                data={formattedData}
              />
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
