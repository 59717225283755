import React from 'react'
import { Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import MomentUtils from '@date-io/moment'
import { createUploadLink } from 'apollo-upload-client'
import { createBrowserHistory } from 'history'
import Cookies from 'js-cookie'
import WebFont from 'webfontloader'
import { MuiThemeProvider } from '@material-ui/core'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from '@web-applications/daffodil-component-library'
import DaffodilTheme from './assets/DaffodilTheme.json'

import muiTheme from './assets/ThemeStyling'

import Notifier from './components/reusable/Notifier'
import Popup from './components/reusable/Popup'

import routes from './routes'

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          if (error.extensions.code === 'UNAUTHENTICATED') {
            Cookies.remove('ppw')
          }

          console.error(`[GraphQl error]: ${error.message}`)
        })
      }
      if (networkError) console.error(`[Network error]: ${networkError}`)
    }),
    createUploadLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
})

const history = createBrowserHistory()

// load webfonts
WebFont.load({
  google: {
    families: ['Roboto:400,500,600'],
  },
})

export default function App() {
  return (
    <HelmetProvider>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={DaffodilTheme}>
          <ApolloProvider client={client}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router history={history}>
                <Notifier />
                <Popup />
                {routes}
              </Router>
            </MuiPickersUtilsProvider>
          </ApolloProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </HelmetProvider>
  )
}
