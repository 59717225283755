import React, { Fragment } from 'react'
import { Form } from 'react-final-form'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Icon from '@mdi/react'
import { mdiClose, mdiContentSave } from '@mdi/js'
import createDecorator from 'final-form-calculate'

import Typography from '../../../newComponents/Typography'
import Spacer from '../../../newComponents/Spacer'
import Card from '../../../newComponents/Card'
import Select from '../../../newComponents/Select'
import Error from '../../../newComponents/Error'
import TimeSelect from '../../../newComponents/TimeSelect'
import Input from '../../../newComponents/Input'
import Button from '../../../newComponents/Button'
import FlexBox from '../../../newComponents/FlexBox'
import IconButton from '../../../newComponents/IconButton'
import Divider from '../../../newComponents/Divider'
import Checkbox from '../../../newComponents/Checkbox'

import Loading from './../../reusable/Loading'
import { openModal, closeModal } from './../../reusable/Popup'
import { openSnackbar } from './../../reusable/Notifier'

//Get settings
const GET_SETTINGS = gql`
  query settings {
    settings {
      _id
      failed_payment_text_days_delay
      failed_payment_text_scheduled_hour
      failed_payment_text_scheduled_minute
      failed_payment_link_number_of_texts
      failed_payment_text_content
      failed_payment_maximum_name_length
      failed_payment_link_duration_days
      failed_payment_send_email
      failed_payment_email_days_delay
      failed_payment_email_scheduled_hour
      failed_payment_email_scheduled_minute
    }
  }
`

const SEND_TEST_TEXT = gql`
  mutation sendPaymentFailureTestText(
    $phoneNumber: String!
    $content: String!
    $maximumNameLength: Int!
  ) {
    sendPaymentFailureTestText(
      phoneNumber: $phoneNumber
      content: $content
      maximumNameLength: $maximumNameLength
    )
  }
`

const SEND_TEST_EMAIL = gql`
  mutation sendPaymentFailureTestEmail($email: EmailAddress!) {
    sendPaymentFailureTestEmail(email: $email)
  }
`

const SAVE_FAILED_PAYMENT_SETTINGS = gql`
  mutation saveFailedPaymentSettings(
    $daysAfterPaymentFails: Int!
    $textHour: Int!
    $textMinute: Int!
    $numberOfMessages: Int!
    $textContent: String!
    $maximumNameLength: Int!
    $linkDuration: Int!
    $sendEmail: Boolean!
    $emailDaysAfterPaymentFails: Int
    $emailHour: Int
    $emailMinute: Int
  ) {
    saveFailedPaymentSettings(
      daysAfterPaymentFails: $daysAfterPaymentFails
      textHour: $textHour
      textMinute: $textMinute
      numberOfMessages: $numberOfMessages
      textContent: $textContent
      maximumNameLength: $maximumNameLength
      linkDuration: $linkDuration
      sendEmail: $sendEmail
      emailDaysAfterPaymentFails: $emailDaysAfterPaymentFails
      emailHour: $emailHour
      emailMinute: $emailMinute
    ) {
      _id
      failed_payment_text_days_delay
      failed_payment_text_scheduled_hour
      failed_payment_text_scheduled_minute
      failed_payment_link_number_of_texts
      failed_payment_text_content
      failed_payment_maximum_name_length
      failed_payment_link_duration_days
      failed_payment_send_email
      failed_payment_email_days_delay
      failed_payment_email_scheduled_hour
      failed_payment_email_scheduled_minute
    }
  }
`

export default function FailedPayments() {
  const {
    loading: settingsLoading,
    error: settingsError,
    data: settingsData,
  } = useQuery(GET_SETTINGS)

  const [
    sendPaymentFailureTestText,
    { loading: sendPaymentFailureTestTextLoading },
  ] = useMutation(SEND_TEST_TEXT, {
    onError: () => {
      openSnackbar({
        message: 'Failed to send text, please try again',
        type: 'error',
      })
    },
    onCompleted: () => {
      closeModal()
      openSnackbar({
        message: 'Text Sent',
        type: 'success',
      })
    },
  })

  const [
    sendPaymentFailureTestEmail,
    { loading: sendPaymentFailureTestEmailLoading },
  ] = useMutation(SEND_TEST_EMAIL, {
    onError: () => {
      openSnackbar({
        message: 'Failed to send email, please try again',
        type: 'error',
      })
    },
    onCompleted: () => {
      closeModal()
      openSnackbar({
        message: 'Email Sent',
        type: 'success',
      })
    },
  })

  const [
    saveFailedPaymentSettings,
    { loading: saveFailedPaymentSettingsLoading },
  ] = useMutation(SAVE_FAILED_PAYMENT_SETTINGS, {
    onError: () => {
      openSnackbar({
        message: 'Failed to update settings, please try again',
        type: 'error',
      })
    },
    onCompleted: () => {
      closeModal()
      openSnackbar({
        message: 'Failed payment settings updated',
        type: 'success',
      })
    },
  })

  if (settingsLoading) return <Loading />

  if (settingsError) return <Error />

  const sendEmailAtSameTime =
    settingsData.settings.failed_payment_text_days_delay ===
      settingsData.settings.failed_payment_email_days_delay &&
    settingsData.settings.failed_payment_text_scheduled_hour ===
      settingsData.settings.failed_payment_email_scheduled_hour &&
    settingsData.settings.failed_payment_text_scheduled_minute ===
      settingsData.settings.failed_payment_email_scheduled_minute

  //get default data
  const initialValues = {
    daysAfterPaymentFails: settingsData.settings.failed_payment_text_days_delay,
    textHour: settingsData.settings.failed_payment_text_scheduled_hour,
    textMinute: settingsData.settings.failed_payment_text_scheduled_minute,
    numberOfMessages: settingsData.settings.failed_payment_link_number_of_texts,
    textContent: settingsData.settings.failed_payment_text_content,
    maximumNameLength: settingsData.settings.failed_payment_maximum_name_length,
    linkDuration: settingsData.settings.failed_payment_link_duration_days,
    sendEmail: settingsData.settings.failed_payment_send_email,
    sendEmailAtSameTime,
    emailDaysAfterPaymentFails:
      settingsData.settings.failed_payment_email_days_delay,
    emailHour: settingsData.settings.failed_payment_email_scheduled_hour,
    emailMinute: settingsData.settings.failed_payment_email_scheduled_minute,
  }

  //create options for days dropdown

  const days = []

  for (let i = 0; i < 7; i++) {
    days.push({ value: i, label: i })
  }

  //generate options for maximum character dropdown

  const maximumCharacters = []
  for (let i = 1; i < 21; i++) {
    maximumCharacters.push({ value: i, label: i })
  }

  return (
    <Fragment>
      <Spacer height="16px" />
      <Typography variant="h1">Failed Payments</Typography>
      <Spacer height="37px" />
      <Card>
        <Form
          onSubmit={({
            daysAfterPaymentFails,
            textHour,
            textMinute,
            numberOfMessages,
            textContent,
            maximumNameLength,
            linkDuration,
            sendEmail,
            emailDaysAfterPaymentFails,
            emailHour,
            emailMinute,
          }) => {
            saveFailedPaymentSettings({
              variables: {
                daysAfterPaymentFails,
                textHour,
                textMinute,
                numberOfMessages,
                textContent,
                maximumNameLength,
                linkDuration: parseInt(linkDuration),
                sendEmail,
                emailDaysAfterPaymentFails,
                emailHour,
                emailMinute,
              },
            })
          }}
          initialValues={initialValues}
          validate={({
            numberOfMessages,
            maximumNameLength,
            textContent = '',
            linkDuration,
          }) => {
            let errors = {}

            //check if the text content meets the limit
            const characterLimit = numberOfMessages * 160

            //create test name at max length of maximum name
            let testName = ''

            while (testName.length < maximumNameLength) {
              testName = testName + 'a'
            }

            //replace variables with their full possiblities

            let formattedContent = textContent
              .replace('[amount]', '£100.00')
              .replace('[order]', 'Order No. 12345')
              .replace('[link]', 'https://ddash.uk/123456')
              .replace('[first-name]', testName)

            if (characterLimit < formattedContent.length) {
              errors.textContent = 'Character Limit Exceeded'
            }

            //make sure link expirations isnt over 50 days
            if (linkDuration > 50) {
              errors.linkDuration = 'Link expiry cannot be over 50 days'
            }

            return errors
          }}
          decorators={[
            createDecorator(
              {
                //Calculate how many characters are remaining in the text
                field: 'textContent',
                updates: async (
                  value = '',
                  name,
                  { numberOfMessages, maximumNameLength }
                ) => {
                  const characterLimit = numberOfMessages * 160

                  //create test name at max length of maximum name
                  let testName = ''

                  while (testName.length < maximumNameLength) {
                    testName = testName + 'a'
                  }

                  //replace variables with their full possiblities

                  let formattedContent = value
                    .replace('[amount]', '£100.00')
                    .replace('[order]', 'Order No. 12345')
                    .replace('[link]', 'https://ddash.uk/123456')
                    .replace('[first-name]', testName)

                  return {
                    charactersRemaining:
                      characterLimit - formattedContent.length,
                  }
                },
              },
              {
                //Calculate how many characters are remaining in the text
                field: 'numberOfMessages',
                updates: async (
                  value,
                  name,
                  { textContent = '', maximumNameLength }
                ) => {
                  const characterLimit = value * 160

                  //create test name at max length of maximum name
                  let testName = ''

                  while (testName.length < maximumNameLength) {
                    testName = testName + 'a'
                  }

                  //replace variables with their full possiblities
                  let formattedContent = textContent
                    .replace('[amount]', '£100.00')
                    .replace('[order]', 'Order No. 12345')
                    .replace('[link]', 'https://ddash.uk/123456')
                    .replace('[first-name]', testName)

                  return {
                    charactersRemaining:
                      characterLimit - formattedContent.length,
                  }
                },
              },
              {
                //Calculate how many characters are remaining in the text
                field: 'maximumNameLength',
                updates: async (
                  value,
                  name,
                  { textContent = '', numberOfMessages }
                ) => {
                  const characterLimit = numberOfMessages * 160

                  //create test name at max length of maximum name
                  let testName = ''

                  while (testName.length < value) {
                    testName = testName + 'a'
                  }

                  //replace variables with their full possiblities
                  let formattedContent = textContent
                    .replace('[amount]', '£100.00')
                    .replace('[order]', 'Order No. 12345')
                    .replace('[link]', 'https://ddash.uk/123456')
                    .replace('[first-name]', testName)

                  return {
                    charactersRemaining:
                      characterLimit - formattedContent.length,
                  }
                },
              },
              {
                // If send email same time as text is selected, make email settings match text settings, if not, set them to undefined
                field: 'sendEmailAtSameTime',
                updates: async (
                  value,
                  name,
                  { daysAfterPaymentFails, textHour, textMinute }
                ) => {
                  if (value)
                    return {
                      emailDaysAfterPaymentFails: daysAfterPaymentFails,
                      emailHour: textHour,
                      emailMinute: textMinute,
                    }

                  return {
                    emailDaysAfterPaymentFails: undefined,
                    emailHour: undefined,
                    emailMinute: undefined,
                  }
                },
              },
              {
                // If send email same time as text is selected, make email settings match text settings
                field: 'daysAfterPaymentFails',
                updates: async (
                  value,
                  name,
                  { textHour, textMinute, sendEmailAtSameTime }
                ) => {
                  if (sendEmailAtSameTime)
                    return {
                      emailDaysAfterPaymentFails: value,
                      emailHour: textHour,
                      emailMinute: textMinute,
                    }

                  return {
                    [name]: value,
                  }
                },
              },
              {
                // If send email same time as text is selected, make email settings match text settings
                field: 'textHour',
                updates: async (
                  value,
                  name,
                  { daysAfterPaymentFails, textMinute, sendEmailAtSameTime }
                ) => {
                  if (sendEmailAtSameTime)
                    return {
                      emailDaysAfterPaymentFails: daysAfterPaymentFails,
                      emailHour: value,
                      emailMinute: textMinute,
                    }

                  return {
                    [name]: value,
                  }
                },
              },
              {
                // If send email same time as text is selected, make email settings match text settings
                field: 'textMinute',
                updates: async (
                  value,
                  name,
                  { daysAfterPaymentFails, textHour, sendEmailAtSameTime }
                ) => {
                  if (sendEmailAtSameTime)
                    return {
                      emailDaysAfterPaymentFails: daysAfterPaymentFails,
                      emailHour: textHour,
                      emailMinute: value,
                    }

                  return {
                    [name]: value,
                  }
                },
              }
            ),
          ]}
          render={({
            handleSubmit,
            pristine,
            values: {
              textContent,
              maximumNameLength,
              charactersRemaining,
              sendEmailAtSameTime,
              sendEmail,
            },
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Typography variant="h2">Text Message Details</Typography>
              <Spacer height="32px" />
              <Select
                name="daysAfterPaymentFails"
                label="Days After Payment Fails"
                required
                small
                secondaryLabel="Days"
                options={days}
              />
              <Spacer height="8px" />
              <TimeSelect
                hourName="textHour"
                minuteName="textMinute"
                label="Time Of Day"
                required
              />
              <Spacer height="8px" />
              <Select
                name="numberOfMessages"
                label="Limit Messages To"
                required
                options={[
                  {
                    value: 1,
                    label: '1 Text Message (160 Characters)',
                  },
                  {
                    value: 2,
                    label: '2 Text Messages (320 Characters)',
                  },
                ]}
              />
              <Spacer height="8px" />
              <Input
                name="textContent"
                required
                fullwidth
                label="Message Content"
                type="textarea"
                rows={6}
              />
              <FlexBox justifyContent="space-between">
                <Button
                  colour="#8BC34A"
                  onClick={(e) => {
                    e.preventDefault()
                    openModal({
                      content: (
                        <Form
                          onSubmit={({ testPhoneNumber }) => {
                            sendPaymentFailureTestText({
                              variables: {
                                phoneNumber: testPhoneNumber,
                                content: textContent,
                                maximumNameLength,
                              },
                            })
                          }}
                          initialValues={initialValues}
                          render={({ handleSubmit, pristine, invalid }) => (
                            <form
                              onSubmit={handleSubmit}
                              noValidate
                              autoComplete="off"
                            >
                              <Card>
                                <FlexBox justifyContent="space-between">
                                  <Typography variant="h2">
                                    Send A Test Message
                                  </Typography>
                                  <IconButton
                                    color="black"
                                    onClick={() => {
                                      closeModal()
                                    }}
                                    path={mdiClose}
                                    title="Close"
                                    size="24px"
                                  />
                                </FlexBox>
                                <Spacer height="32px" />
                                <Typography variant="bodySmall">
                                  Enter the phone number you wish to send the
                                  test text message to.
                                </Typography>
                                <Spacer height="13px" />
                                <Input
                                  name="testPhoneNumber"
                                  label="Phone Number"
                                  type="phone"
                                  required
                                />
                                <Spacer height="8px" />
                                <Button
                                  colour="#8BC34A"
                                  type="submit"
                                  disabled={pristine || invalid}
                                  loading={sendPaymentFailureTestTextLoading}
                                >
                                  Send Message
                                </Button>
                              </Card>
                            </form>
                          )}
                        />
                      ),
                    })
                  }}
                >
                  Send A Test Message
                </Button>
                <Typography variant="bodySmall" colour="#757575">
                  {charactersRemaining} characters remaining
                </Typography>
              </FlexBox>
              <Spacer height="32px" />
              <Typography variant="label">Variables:</Typography>
              <Spacer height="11px" />
              <FlexBox alignItems="flex-start">
                <FlexBox flexDirection="column" alignItems="flex-start">
                  <Typography variant="bodySmall">[amount]</Typography>
                  <Spacer height="8px" />
                  <Typography variant="bodySmall">[order]</Typography>
                  <Spacer height="8px" />
                  <Typography variant="bodySmall">[link]</Typography>
                  <Spacer height="8px" />
                  <Typography variant="bodySmall">[first-name]</Typography>
                </FlexBox>
                <FlexBox flexDirection="column" alignItems="flex-start">
                  <Typography variant="bodySmall" colour="#757575">
                    "£XXX.XX" (7 Characters)
                  </Typography>
                  <Spacer height="8px" />
                  <Typography variant="bodySmall" colour="#757575">
                    “Order No. 12345” (15 Characters)
                  </Typography>
                  <Spacer height="8px" />
                  <Typography variant="bodySmall" colour="#757575">
                    “https://ddash.uk/123456” (23 Characters)
                  </Typography>
                </FlexBox>
              </FlexBox>
              <Spacer height="32px" />
              <Select
                name="maximumNameLength"
                label="Maximum Name Length"
                required
                small
                secondaryLabel="Characters"
                helperText="Names with more characters will not be shown"
                options={maximumCharacters}
              />
              <Spacer height="39px" />
              <Divider />
              <Spacer height="32px" />
              <Typography variant="h2">Email Details</Typography>
              <Spacer height="24px" />
              <Typography variant="label">
                Would you like to also send a failed payment email?
              </Typography>
              <Spacer height="16px" />
              <Checkbox name="sendEmail" value="Send failed payment email" />
              <Spacer height="8px" />
              {sendEmail && (
                <>
                  <Typography variant="label">
                    When should the email be sent?
                  </Typography>
                  <Spacer height="16px" />
                  <Checkbox
                    name="sendEmailAtSameTime"
                    value="Send email at the same time as the text message"
                  />
                  <Spacer height="8px" />
                  <Select
                    name="emailDaysAfterPaymentFails"
                    label="Days After Payment Fails"
                    required
                    small
                    secondaryLabel="Days"
                    options={days}
                    disabled={sendEmailAtSameTime}
                  />
                  <Spacer height="8px" />
                  <TimeSelect
                    hourName="emailHour"
                    minuteName="emailMinute"
                    label="Time Of Day"
                    required
                    disabled={sendEmailAtSameTime}
                  />
                  <Spacer height="8px" />
                  <Button
                    colour="#8BC34A"
                    onClick={(e) => {
                      e.preventDefault()
                      openModal({
                        content: (
                          <Form
                            onSubmit={({ testEmailAddress }) => {
                              sendPaymentFailureTestEmail({
                                variables: {
                                  email: testEmailAddress,
                                },
                              })
                            }}
                            render={({ handleSubmit, pristine, invalid }) => (
                              <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                              >
                                <Card>
                                  <FlexBox justifyContent="space-between">
                                    <Typography variant="h2">
                                      Send A Test Email
                                    </Typography>
                                    <IconButton
                                      color="black"
                                      onClick={() => {
                                        closeModal()
                                      }}
                                      path={mdiClose}
                                      title="Close"
                                      size="24px"
                                    />
                                  </FlexBox>
                                  <Spacer height="32px" />
                                  <Typography variant="bodySmall">
                                    Enter the email address you wish to send the
                                    test message to. This can take up to 5
                                    minutes to arrive.
                                  </Typography>
                                  <Spacer height="13px" />
                                  <Input
                                    name="testEmailAddress"
                                    label="Email Address"
                                    type="email"
                                    required
                                  />
                                  <Spacer height="8px" />
                                  <Button
                                    colour="#8BC34A"
                                    type="submit"
                                    disabled={pristine || invalid}
                                    loading={sendPaymentFailureTestEmailLoading}
                                  >
                                    Send Email
                                  </Button>
                                </Card>
                              </form>
                            )}
                          />
                        ),
                      })
                    }}
                  >
                    Send A Test Email
                  </Button>
                  <Spacer height="32px" />
                </>
              )}

              <Divider />
              <Spacer height="32px" />
              <Typography variant="h2">Link Details</Typography>
              <Spacer height="24px" />
              <Input
                name="linkDuration"
                label="Duration Of Link (days before links expire)"
                type="number"
                required
                small
                secondaryLabel="Days"
              />
              <Spacer height="8px" />
              <Button
                colour="#f7941d"
                loading={saveFailedPaymentSettingsLoading}
                disabled={pristine}
                type="submit"
                icon={
                  <Icon
                    path={mdiContentSave}
                    title="Save Settings"
                    size="24px"
                    color="black"
                  />
                }
              >
                Save
              </Button>
            </form>
          )}
        />
      </Card>
    </Fragment>
  )
}
