import React from 'react'
import { Link } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'

import { Tooltip, IconButton } from '@material-ui/core'

import { RemoveRedEye } from '@material-ui/icons'

import {
  AwaitingDeposit,
  Active,
  Complete,
  Cancelled,
} from '../../../assets/images/Icons'

export default function OrdersTable({ orders }) {
  const tableDataArray = []

  orders = orders.filter((order) => order.invoice_number)

  orders.forEach((order) => {
    let orderStatusIcon

    switch (order.order_status) {
      case 'Awaiting Deposit':
        orderStatusIcon = (
          <Tooltip title="Awaiting Deposit" placement="top">
            <div style={{ display: 'inline-block', padding: '10px' }}>
              <AwaitingDeposit />
            </div>
          </Tooltip>
        )
        break
      case 'Active':
        orderStatusIcon = (
          <Tooltip title="Active" placement="top">
            <div style={{ display: 'inline-block', padding: '10px' }}>
              <Active />
            </div>
          </Tooltip>
        )
        break
      case 'Complete':
        orderStatusIcon = (
          <Tooltip title="Complete" placement="top">
            <div style={{ display: 'inline-block', padding: '10px' }}>
              <Complete />
            </div>
          </Tooltip>
        )
        break

      case 'Cancelled':
        orderStatusIcon = (
          <Tooltip title="Cancelled" placement="top">
            <div style={{ display: 'inline-block', padding: '10px' }}>
              <Cancelled />
            </div>
          </Tooltip>
        )
        break

      default:
        break
    }

    const data = [
      {
        columnDetails: {
          name: 'Order Number',
          options: {
            sort: true,
            display: 'true',
          },
        },
        columnData: order.invoice_number,
      },
      {
        columnDetails: {
          name: 'Order Date',
          options: {
            sort: true,
            display: 'true',
          },
        },
        columnData: moment(order.created_date, 'x').format('DD/MM/YYYY'),
      },
      {
        columnDetails: {
          name: 'Agent',
          options: {
            sort: true,
            display: 'true',
          },
        },
        columnData: `${order.agent?.first_name} ${order.agent?.last_name}`,
      },
      {
        columnDetails: {
          name: 'Store',
          options: {
            sort: true,
            display: 'false',
          },
        },
        columnData: order.store.name,
      },
      {
        columnDetails: {
          name: 'Customer',
          options: {
            sort: true,
            display: 'true',
          },
        },
        columnData: `${order.customer?.title.replace(/^\w/, (c) =>
          c.toUpperCase()
        )} ${order.customer?.first_name} ${order.customer?.last_name}`,
      },
      {
        columnDetails: {
          name: 'Customer Type',
          options: {
            sort: true,
            display: 'false',
          },
        },
        columnData: order.customer?.customer_type.replace(/^\w/, (c) =>
          c.toUpperCase()
        ),
      },
      {
        columnDetails: {
          name: 'Card Due To Expire',
          options: {
            sort: true,
            display: 'false',
          },
        },
        columnData: order.card_due_to_expire ? 'Yes' : 'No',
      },
      {
        columnDetails: {
          name: 'Grand Total (£)',
          options: {
            sort: true,
            display: 'false',
          },
        },
        columnData: parseFloat(order.grand_total).toFixed(2),
      },
      {
        columnDetails: {
          name: 'Remaining To Pay (£)',
          options: {
            sort: true,
            display: 'true',
          },
        },
        columnData: parseFloat(order.to_pay).toFixed(2),
      },
      {
        columnDetails: {
          name: 'Weekly Payment (£)',
          options: {
            sort: true,
            display: 'false',
          },
        },
        columnData: parseFloat(order.weekly_payment).toFixed(2),
      },

      {
        columnDetails: {
          name: 'Delivery Threshold Reached',
          options: {
            sort: true,
            display: 'true',
          },
        },
        columnData: order.release_date ? 'Yes' : 'No',
      },
      {
        columnDetails: {
          name: 'Order Status',
          options: {
            sort: true,
            display: 'true',
          },
        },
        columnData: orderStatusIcon,
      },
      {
        columnDetails: {
          name: 'Actions',
          options: {
            sort: false,
            display: 'true',
          },
        },
        columnData: (
          <Tooltip title="View Order" placement="top">
            <IconButton
              aria-label="View"
              component={Link}
              to={'/orders/single/' + order.invoice_number}
            >
              <RemoveRedEye />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        columnDetails: {
          name: 'Deposit Date',
          options: {
            sort: true,
            display: 'false',
          },
        },
        columnData: moment(order.deposit_date).format('DD/MM/YYYY'),
      },
    ]

    tableDataArray.push(data)
  })

  const columnsArray = []
  const orderData = []

  tableDataArray.forEach((columns, index) => {
    const columnData = []

    columns.forEach((column) => {
      columnData.push(column.columnData)
    })

    orderData.push(columnData)

    //only create column titles on first pass
    if (index === 0) {
      columns.forEach((column) => {
        columnsArray.push(column.columnDetails)
      })
    }
  })

  const options = {
    filter: false,
    selectableRows: 'none',
    print: false,
    responsive: 'standard',
    download: false,
    search: false,
    elevation: 0,
  }

  return (
    <MUIDataTable data={orderData} columns={columnsArray} options={options} />
  )
}
