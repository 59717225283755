import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { openModal, closeModal } from './../../reusable/Popup'

import { openSnackbar } from './../../reusable/Notifier'

import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  CardHeader,
  CardActions,
  Grid,
} from '@material-ui/core'

const GET_RETRY_URL = gql`
  mutation getParkedOrderRetryUrl(
    $paymentId: String!
    $parkedOrderId: String!
  ) {
    getParkedOrderRetryUrl(paymentId: $paymentId, parkedOrderId: $parkedOrderId)
  }
`

const DELETE_PARKED_ORDER = gql`
  mutation deleteParkedOrder($parkedOrderId: String!) {
    deleteParkedOrder(parkedOrderId: $parkedOrderId)
  }
`

export default function FailedPayment({ match: { params }, history }) {
  const [paymentPage, setPaymentPage] = useState('')
  const [modalShown, setModalShown] = useState(false)

  const [getParkedOrderRetryUrl, { loading: retryUrlLoading }] = useMutation(
    GET_RETRY_URL,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to get retry url, please try again',
          type: 'error',
        })
      },
      onCompleted: ({ getParkedOrderRetryUrl }) => {
        setPaymentPage(getParkedOrderRetryUrl)
        document.getElementById('payment-form').submit()
      },
    }
  )

  const [
    deleteParkedOrder,
    { loading: deleteParkedOrderLoading },
  ] = useMutation(DELETE_PARKED_ORDER, {
    onError: () => {
      openSnackbar({
        message: 'Failed to delete parked order, please try again',
        type: 'error',
      })
    },
    onCompleted: () => {
      closeModal()
      history.push('/orders')
    },
  })

  if (!modalShown) {
    openModal({
      content: (
        <Card>
          <CardHeader title="Something Went Wrong" />
          <CardContent>
            <Typography variant="body1" gutterBottom>
              Please return the tablet to the sales agent.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                closeModal()
                setModalShown(true)
              }}
            >
              Close
            </Button>
          </CardActions>
        </Card>
      ),
    })
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h5" gutterBottom>
              The payment was not successful.
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Please choose an option below
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled={retryUrlLoading}
              onClick={() => {
                getParkedOrderRetryUrl({
                  variables: {
                    paymentId: params.paymentId,
                    parkedOrderId: params.parkedOrderId,
                  },
                })
              }}
            >
              Retry
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              component={Link}
              to={'/orders'}
            >
              Park Order
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              fullWidth
              style={{ backgroundColor: 'red', color: 'white' }}
              onClick={() => {
                openModal({
                  content: (
                    <Card>
                      <CardHeader title="Are You Sure?" />
                      <CardContent>
                        <Typography variant="body1" gutterBottom>
                          This cannot be undone, if you wish to return to this
                          order in the future you will have to start from the
                          begining.
                        </Typography>
                      </CardContent>
                      <CardActions
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            closeModal()
                          }}
                        >
                          No
                        </Button>

                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={deleteParkedOrderLoading}
                          onClick={() => {
                            deleteParkedOrder({
                              variables: {
                                parkedOrderId: params.parkedOrderId,
                              },
                            })
                          }}
                        >
                          Yes
                        </Button>
                      </CardActions>
                    </Card>
                  ),
                })
              }}
            >
              Abort Order
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      {!!paymentPage && (
        <form
          action={paymentPage}
          method="post"
          id="payment-form"
          style={{ display: 'none' }}
        >
          <input type="submit" value="Do Stuff!" />
        </form>
      )}
    </Card>
  )
}
