import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Typography from '../../../newComponents/Typography'
import Spacer from '../../../newComponents/Spacer'
import Card from '../../../newComponents/Card'
import Button from '../../../newComponents/Button'
import FlexBox from '../../../newComponents/FlexBox'

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

export default function FailedPaymentFailure({
  match: {
    params: { linkReference },
  },
}) {
  return (
    <ContentWrapper>
      <FlexBox flexDirection="column" alignItems="flex-start">
        <Typography variant="h1">Payment Failed</Typography>
        <Spacer height="16px" />
        <Typography variant="bodySmall">
          We were unable to take your payment.
        </Typography>
        <Spacer height="32px" />
      </FlexBox>
      <Card>
        <Typography variant="bodySmall">
          Click the button below to try again. Alternatively you can call our
          payments team on:
        </Typography>
        <Spacer height="16px" />
        <Typography variant="bodySmall" bold>
          <Typography
            variant="bodySmall"
            bold
            component="a"
            href="tel:01709732641"
          >
            01709 732 641
          </Typography>{' '}
          (option 1)
        </Typography>
        <Spacer height="24px" />
        <Button
          colour="#F59300"
          fullwidth
          component={Link}
          to={`/failed-payment/${linkReference}`}
        >
          Try Again
        </Button>
      </Card>
    </ContentWrapper>
  )
}
