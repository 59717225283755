import gql from "graphql-tag";

export const GET_ORDER = gql`
  query order($invoice_number: Int!) {
    order(invoice_number: $invoice_number) {
      _id
      invoice_number
      second_product_eligible_date
      cancellation {
        _id
        requested_by {
          _id
        }
      }
      items {
        room
        name
        colour
        size
        type
        code
        subfloor
        underlay
        grippers
        doorbars {
          code
          description
        }
      }
      total_discount
      total_increase
      increases
      discounts
      files {
        _id
        name
      }
      agent {
        _id
        first_name
        last_name
      }
      store {
        _id
        name
      }
      payments_before_release
      order_type
      lead_generation
      billing_first_name
      billing_last_name
      billing_address_line_1
      billing_address_city
      billing_address_county
      billing_address_postcode
      billing_address_line_2
      billing_title
      card_number
      card_expiry
      order_status
      created_date
      customer {
        _id
        first_name
        last_name
        first_name
        last_name
        address_city
        address_county
        address_line_1
        address_line_2
        address_postcode
        title
        phone
        email
        mobile
        preferred_contact
      }
      delivery_date
      fitting_date
      delivery_charge
      fitting_charge
      total_retail
      to_pay
      fitting_delivery_total
      grand_total
      first_payment_date
      deposit
      weekly_payment
      release_date
      signature
      deposit_method
      deposit_date
      deposit_paid
      card_due_to_expire
      store_transaction_number
      supplier_invoice_number
      account_manager {
        _id
      }
      fitting_delivery_notes
      goods_fitted_date
      goods_delivered_date
      bad_debt
    }
  }
`;

export const UPDATE_ITEM_COLOUR = gql`
  mutation updateItemColour(
    $order_id: String!
    $item_index: Int!
    $colour: String!
    $user_id: String!
  ) {
    updateItemColour(
      order_id: $order_id
      item_index: $item_index
      colour: $colour
      user_id: $user_id
    ) {
      error {
        message
      }
      order {
        _id
        items {
          room
          name
          colour
          size
          type
          code
          subfloor
          underlay
          grippers
          doorbars {
            code
            description
          }
        }
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation ($file: Upload!) {
    uploadFile(file: $file) {
      file {
        _id
        name
      }
      error {
        message
      }
    }
  }
`;

export const DELETE_FILE = gql`
  mutation ($id: String!) {
    deleteFile(id: $id) {
      delete
      error {
        message
      }
    }
  }
`;

export const UPDATE_DELIVERY_DATE = gql`
  mutation (
    $order_id: String!
    $delivery_date: String
    $agent_id: String!
    $goods_delivered_date: String
  ) {
    updateDeliveryDate(
      order_id: $order_id
      delivery_date: $delivery_date
      agent_id: $agent_id
      goods_delivered_date: $goods_delivered_date
    ) {
      error {
        message
      }
      order {
        _id
        delivery_date
        goods_delivered_date
      }
    }
  }
`;

export const UPDATE_FITTING_DATE = gql`
  mutation (
    $order_id: String!
    $fitting_date: String
    $agent_id: String!
    $goods_fitted_date: String
  ) {
    updateFittingDate(
      order_id: $order_id
      fitting_date: $fitting_date
      agent_id: $agent_id
      goods_fitted_date: $goods_fitted_date
    ) {
      error {
        message
      }
      order {
        _id
        fitting_date
        goods_fitted_date
      }
    }
  }
`;

export const UPDATE_CARD_PAGE = gql`
  mutation updateCardPage($invoice_number: Int!, $agent_id: String!) {
    updateCardPage(invoice_number: $invoice_number, agent_id: $agent_id) {
      payment_page
    }
  }
`;

export const UPDATE_BILLING_ADDRESS = gql`
  mutation (
    $invoice_number: Int!
    $billing_title: String!
    $billing_first_name: String!
    $billing_last_name: String!
    $billing_address_line_1: String!
    $billing_address_line_2: String
    $billing_address_city: String!
    $billing_address_county: String!
    $billing_address_postcode: String!
    $agent_id: String!
  ) {
    updateBillingAddress(
      invoice_number: $invoice_number
      billing_title: $billing_title
      billing_first_name: $billing_first_name
      billing_last_name: $billing_last_name
      billing_address_line_1: $billing_address_line_1
      billing_address_line_2: $billing_address_line_2
      billing_address_city: $billing_address_city
      billing_address_county: $billing_address_county
      billing_address_postcode: $billing_address_postcode
      agent_id: $agent_id
    ) {
      error {
        message
      }
      order {
        _id
        billing_title
        billing_first_name
        billing_last_name
        billing_address_line_1
        billing_address_line_2
        billing_address_city
        billing_address_county
        billing_address_postcode
      }
    }
  }
`;

export const TAKE_ONE_OFF_PAYMENT = gql`
  mutation ($orderId: ID!, $amount: String!, $paymentMethod: String!) {
    takeOneOffPayment(
      orderId: $orderId
      amount: $amount
      paymentMethod: $paymentMethod
    )
  }
`;

export const ADD_ORDER_NOTE = gql`
  mutation ($order_id: String!, $content: String!, $agent_id: String!) {
    addOrderNote(order_id: $order_id, content: $content, agent_id: $agent_id) {
      error {
        message
      }
      customer_note {
        _id
        customer {
          _id
          first_name
          last_name
        }
        content
        created_date
        created_by {
          _id
          first_name
          last_name
        }
      }
    }
  }
`;

export const orderStatuses = [
  "Cancelled",
  "Complete",
  "Active",
  "Collecting Card Details",
  "Awaiting Deposit",
];
