import gql from "graphql-tag";

export const GET_USER = gql`
  query user($userId: String!) {
    user(userId: $userId) {
      _id
      first_name
      last_name
      role_id
      email
      created_date
      updated_date
      archived
      can_cancel
      stores {
        _id
        name
      }
    }
  }
`;

export const GET_USERS = gql`
  query users {
    users {
      _id
      first_name
      last_name
      role_id
      email
      created_date
      archived
      updated_date
      can_cancel
      stores {
        _id
        name
      }
    }
  }
`;

export const GET_STORES = gql`
  query stores {
    stores {
      _id
      name
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($email: String!) {
    forgotPassword(email: $email) {
      error {
        message
      }
    }
  }
`;

export const PASSWORD_RESET_ALLOWED = gql`
  query passwordResetAllowed($resetToken: String!) {
    passwordResetAllowed(resetToken: $resetToken) {
      user {
        _id
      }
      error {
        message
      }
    }
  }
`;

export const ARCHIVE_USER = gql`
  mutation ($userId: String!) {
    archiveUser(userId: $userId) {
      _id
      first_name
      last_name
      archived
    }
  }
`;

export const UNARCHIVE_USER = gql`
  mutation ($userId: String!) {
    unArchiveUser(userId: $userId) {
      _id
      first_name
      last_name
      archived
    }
  }
`;

export const UPDATE_USER = gql`
  mutation (
    $userId: String!
    $firstName: String!
    $lastName: String!
    $roleId: Int
    $email: String
    $stores: String
    $canCancel: Boolean!
  ) {
    updateUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      roleId: $roleId
      email: $email
      stores: $stores
      canCancel: $canCancel
    ) {
      _id
      first_name
      last_name
      email
      updated_date
      archived
      can_cancel
    }
  }
`;

export const NEW_USER = gql`
  mutation (
    $email: String!
    $firstName: String!
    $lastName: String!
    $roleId: Int!
    $stores: String!
    $canCancel: Boolean!
  ) {
    newUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      roleId: $roleId
      stores: $stores
      canCancel: $canCancel
    ) {
      _id
      first_name
      last_name
    }
  }
`;

export const GET_STORE = gql`
  query store($name: String!) {
    store(name: $name) {
      _id
      name
    }
  }
`;

export const ADMIN_PASSWORD_RESET = gql`
  mutation ($email: String!, $admin: Boolean) {
    adminPasswordResetRequest(email: $email, admin: $admin) {
      _id
    }
  }
`;
