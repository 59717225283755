import React from "react";
import { Form, Field } from "react-final-form";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import { TextField, Grid, Typography, Divider } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import {
  Loading,
  ErrorMessage,
  Button,
} from "@web-applications/daffodil-component-library";

import { openSnackbar } from "../../../components/reusable/Notifier";
import ActivityTable from "../../../components/reusable/ActivityTable";
import Spacer from "../../../newComponents/Spacer";

export const GET_ACTIVITIES = gql`
  query getActivities($orderId: ID) {
    getActivities(orderId: $orderId) {
      _id
      time
      content
      user {
        _id
        first_name
        last_name
      }
    }
    getEmails(orderId: $orderId) {
      _id
      email_template
      order {
        _id
      }
      sent_time
      created_date
      subject
      status
      target_email
    }
    getTexts(orderId: $orderId) {
      _id
      mobile_number
      message
      status
      created_date
      sent_time
    }
    getNotes(orderId: $orderId) {
      _id
      created_date
      created_by {
        first_name
        last_name
      }
      content
    }
  }
`;

const CREATE_NOTE = gql`
  mutation createNote($orderId: ID, $content: String!) {
    createNote(orderId: $orderId, content: $content) {
      _id
      created_date
      created_by {
        first_name
        last_name
      }
      content
    }
  }
`;

const GET_ORDER = gql`
  query order($invoice_number: Int!) {
    order(invoice_number: $invoice_number) {
      _id
      invoice_number
    }
  }
`;

export default function OrderActivity() {
  const { invoiceNumber } = useParams();

  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(GET_ORDER, {
    variables: { invoice_number: parseInt(invoiceNumber) },
  });

  const {
    loading: activitiesLoading,
    error: activitiesError,
    data: activitiesData,
  } = useQuery(GET_ACTIVITIES, {
    variables: { orderId: orderData?.order?._id },
    skip: !orderData?.order?._id,
    fetchPolicy: "network-only",
  });

  const [createNote, { loading: createNoteLoading }] = useMutation(
    CREATE_NOTE,
    {
      onError: () => {
        openSnackbar({
          message: "Failed to add note, please try again",
          type: "error",
        });
      },
      update(cache, { data: { createNote: newNote } }) {
        const { getNotes } = cache.readQuery({
          query: gql`
            query getNotes($orderId: ID) {
              getNotes(orderId: $orderId) {
                _id
                created_date
                created_by {
                  first_name
                  last_name
                }
                content
              }
            }
          `,
          variables: {
            orderId: orderData?.order?._id,
          },
        });

        cache.writeQuery({
          query: gql`
            query getNotes($orderId: ID) {
              getNotes(orderId: $orderId) {
                _id
                created_date
                created_by {
                  first_name
                  last_name
                }
                content
              }
            }
          `,
          variables: {
            orderId: orderData?.order?._id,
          },
          data: {
            getNotes: [...getNotes, newNote],
          },
        });
      },
    }
  );

  if (activitiesLoading || orderLoading) return <Loading />;

  if (activitiesError || orderError)
    return <ErrorMessage error={activitiesError || orderError} />;

  const texts = activitiesData.getTexts || [];
  const emails = activitiesData.getEmails || [];
  const activities = activitiesData.getActivities || [];
  const notes = activitiesData.getNotes || [];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" gutterBottom>
            Order Activity
          </Typography>
          <Divider />
        </Grid>
      </Grid>
      <ActivityTable
        title=""
        notes={notes}
        activities={activities}
        texts={texts}
        emails={emails}
        orderId={orderData?.order?._id}
      />
      <Spacer height="32px" />
      <Form
        onSubmit={({ content }, form) => {
          createNote({
            variables: {
              orderId: orderData?.order?._id,
              content,
            },
          }).then(() => {
            openSnackbar({
              message: "Order note added",
              type: "success",
            });
            form.reset();
          });
        }}
        render={({ handleSubmit, pristine, submitting }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={10}>
                <Field name="content">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="Add Note"
                      multiline
                      rows="4"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={pristine || submitting || createNoteLoading}
                  icon={<Save />}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </>
  );
}
