import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import Icon from '@mdi/react'
import { mdiMenuDown } from '@mdi/js'

import Typography from './Typography'
import FlexBox from './FlexBox'
import Dropdown from './Dropdown'
import MenuItem from './MenuItem'
import Spacer from './Spacer'

export const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ fullwidth }) => {
    if (!fullwidth) return '320px'
  }};
  .optional-text {
    margin-left: 4px;
  }
  .helper-text {
    margin-top: -24px;
  }
  .select-components-wrapper {
    margin-bottom: 24px;
  }
  .secondary-label {
    margin-left: 16px;
  }
`

export const StyledSelect = styled.button`
  width: ${({ small }) => {
    if (small) return '88px'

    return '100%'
  }};
  max-width: ${({ fullwidth }) => {
    if (!fullwidth) return '320px'
  }};
  cursor: ${({ readOnly, disabled }) => {
    if (readOnly || disabled) return 'not-allowed'

    return 'pointer'
  }};
  height: 56px;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all ease-in-out 150ms;
  border-color: ${({ error }) => {
    if (error) return 'red'

    return '#E0E0E0'
  }};
  border-radius: 4px;
  background-color: ${({ readOnly, disabled }) => {
    if (readOnly || disabled) return '#E0E0E0'

    return 'white'
  }};
  color: ${({ readOnly, disabled }) => {
    if (readOnly || disabled) return '#7b7b7b'

    return '#000000'
  }};
  padding: 7px 8px 7px 16px;
  &:focus,
  &:active {
    border-color: ${({ readOnly, disabled }) => {
      if (!readOnly && !disabled) return '#f7941d'
    }};
    outline: none;
  }
`

export default function Select({
  options,
  name,
  required,
  label,
  helperText,
  placeholder,
  small,
  secondaryLabel,
  ...rest
}) {
  //validation function, returns error message if theres a problem

  const validate = (value) => {
    //required validator
    if (required && (!JSON.stringify(value) || !JSON.stringify(value).trim())) {
      return 'Required'
    }
  }

  if (rest.readOnly)
    return (
      <StyledSelectWrapper {...rest}>
        <div className="select-components-wrapper">
          <FlexBox>
            <Typography variant="label">{label}</Typography>

            {required && (
              <Typography variant="label" className="optional-text">
                *
              </Typography>
            )}
          </FlexBox>
          <Spacer height="8px" />
          <FlexBox>
            <StyledSelect {...rest} small={small} dropdownTrigger type="button">
              <Typography variant="bodySmall">{rest.value}</Typography>
              <Icon
                path={mdiMenuDown}
                title="Open Select"
                size="24px"
                color="#7b7b7b"
              />
            </StyledSelect>
            {secondaryLabel && (
              <Typography variant="bodySmall" className="secondary-label">
                {secondaryLabel}
              </Typography>
            )}
          </FlexBox>
        </div>
        {!!helperText ? (
          <Typography
            variant="caption"
            colour="#7b7b7b"
            className="helper-text"
          >
            {helperText}
          </Typography>
        ) : null}
      </StyledSelectWrapper>
    )

  return (
    <Field
      name={name}
      validate={validate}
      format={(value) => {
        const selectedOption = options.find((option) => option.value === value)
        return selectedOption?.label
      }}
    >
      {({ input: { onChange, value, ...inputRest }, meta }) => {
        //get error

        let errorMessage

        if (meta.error && meta.touched) {
          errorMessage = meta.error
        } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
          errorMessage = meta.submitError
        }

        return (
          <StyledSelectWrapper {...rest} error={!!errorMessage} {...inputRest}>
            <div className="select-components-wrapper">
              <FlexBox>
                <Typography variant="label">{label}</Typography>
                {required && (
                  <Typography variant="label" className="optional-text">
                    *
                  </Typography>
                )}
              </FlexBox>
              <Spacer height="8px" />
              <Dropdown fullwidth={!small} disabled={rest.disabled}>
                <FlexBox dropdownTrigger>
                  <StyledSelect
                    {...rest}
                    small={small}
                    error={!!errorMessage}
                    type="button"
                  >
                    <Typography variant="bodySmall">
                      {JSON.stringify(value)
                        ? value
                        : !!placeholder
                        ? placeholder
                        : small
                        ? '?'
                        : 'Select'}
                    </Typography>
                    <Icon
                      path={mdiMenuDown}
                      title="Dropdown"
                      size="24px"
                      color="black"
                    />
                  </StyledSelect>
                  {secondaryLabel && (
                    <Typography
                      variant="bodySmall"
                      className="secondary-label"
                      colour={errorMessage ? 'red' : undefined}
                    >
                      {secondaryLabel}
                    </Typography>
                  )}
                </FlexBox>

                {options.map((option) => (
                  <MenuItem
                    key={option.label}
                    onClick={() => {
                      onChange(option.value)
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
            {!!errorMessage ? (
              <Typography
                variant="caption"
                colour="red"
                className="helper-text"
              >
                {errorMessage}
              </Typography>
            ) : !!helperText ? (
              <Typography
                variant="caption"
                colour="#7b7b7b"
                className="helper-text"
              >
                {helperText}
              </Typography>
            ) : null}
          </StyledSelectWrapper>
        )
      }}
    </Field>
  )
}
