import React from 'react'
import { Switch } from 'react-router-dom'

import AuthenticatedRoute from '../components/util/AuthenticatedRoute'
import Home from '../components/pages/Dashboard/Home'

// Actual routes
import LoginRoutes from './LoginRoutes'
import AdminRoutes from './AdminRoutes'
import CustomerRoutes from './CustomerRoutes'
import OrderRoutes from './OrderRoutes'
import CancellationRoutes from './CancellationRoutes'
import NewOrderRoutes from './NewOrderRoutes'
import UserRoutes from './UserRoutes'
import FailedPaymentLinkRoutes from './FailedPaymentLinkRoutes'
import ReportRoutes from './ReportRoutes'
import FitterRoutes from './FitterRoutes'

const indexRoute = [
  <AuthenticatedRoute path="/" exact component={Home} title="Dashboard" />,
]

const allRoutes = indexRoute.concat(
  AdminRoutes,
  LoginRoutes,
  CustomerRoutes,
  OrderRoutes,
  CancellationRoutes,
  NewOrderRoutes,
  UserRoutes,
  FailedPaymentLinkRoutes,
  ReportRoutes,
  FitterRoutes
)

const routes = <Switch>{allRoutes}</Switch>

export default routes
