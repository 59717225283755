import React, { Fragment } from 'react'
import styled, { keyframes } from 'styled-components'
import { darken, rgba } from 'polished'
import Typography from './Typography'

const StyledButton = styled.button`
  background-color: ${({
    colour = '#f7941d',
    disabled,
    loading,
    variant = 'contained',
  }) => {
    if (variant === 'text') return 'transparent'

    if (loading) return darken(0.08, colour)

    if (disabled && variant !== 'outline') return '#e0e0e0'

    if (disabled && variant === 'outline') return rgba('black', 0.12)

    if (variant === 'outline') return rgba('black', 0)

    return colour
  }};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 150ms;
  position: relative;
  width: ${({ fullwidth }) => {
    if (fullwidth) return '100%'
  }};
  padding: 10px 16px;
  cursor: ${({ disabled, loading }) => {
    if (disabled || loading) return 'not-allowed'

    return 'pointer'
  }};
  border: ${({ variant }) => {
    if (variant === 'outline') return 'solid 1px #E0E0E0'

    return 'none'
  }};
  border-radius: 4px;
  opacity: ${({ disabled, variant }) => {
    if (disabled && variant === 'text') return 0.5

    return 1
  }};
  &:focus,
  &:active {
    outline: none;
    background-color: ${({
      colour = '#f7941d',
      disabled,
      loading,
      variant = 'contained',
    }) => {
      if (!disabled && !loading && variant !== 'contained')
        return rgba('black', 0.12)

      if (!disabled && !loading) return darken(0.16, colour)
    }};
  }
  &:hover {
    background-color: ${({
      colour = '#f7941d',
      disabled,
      loading,
      variant = 'contained',
    }) => {
      if (!disabled && !loading && variant !== 'contained')
        return rgba('black', 0.04)

      if (!disabled && !loading) return darken(0.08, colour)
    }};
  }
  svg {
    margin-right: 8px;
  }
`

const HiddenButtonContent = styled.div`
  opacity: 0;
  display: flex;
`

const stretch = (color) => keyframes`
  0% {
      transform: scale(.5);
      background-color: ${color};
  }
  50% {
      background-color: ${color};
  }
  100% {
      transform: scale(1);
      background-color:  ${color};
  }
`

const ButtonLoadingWrapper = styled.div`
  position: absolute;
  width: 40px;
  display: flex;
  align-items: center;
  span {
    height: 8px;
    width: 8px;
    background-color: white;
    display: block;
    border-radius: 100%;
    animation-delay: 0.1s;
    margin-right: 5px;
    animation-direction: alternate;
    animation-duration: 0.5s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-name: ${({ variant }) => {
      if (variant === 'text') return stretch(rgba('black', 0.8))

      return stretch('white')
    }};
    animation-play-state: running;
    animation-timing-function: ease-out;

    &:first-child {
      animation-delay: 0s;
    }

    &:last-child {
      animation-delay: 0.2s;
      margin-right: 0;
    }
  }
`

export default function Button({
  children,
  component,
  icon,
  loading,
  fullwidth,
  ...rest
}) {
  //calculate button text colour
  const { variant, colour } = rest

  let buttonTextColour = 'black'

  if (variant === 'outline') {
    buttonTextColour = colour
  }

  return (
    <StyledButton
      as={component}
      loading={loading ? 1 : 0}
      fullwidth={fullwidth ? 1 : 0}
      {...rest}
    >
      {loading ? (
        <Fragment>
          <HiddenButtonContent>
            {icon && icon}
            <Typography variant="button" colour={buttonTextColour}>
              {children}
            </Typography>
          </HiddenButtonContent>
          <ButtonLoadingWrapper {...rest}>
            <span />
            <span />
            <span />
          </ButtonLoadingWrapper>
        </Fragment>
      ) : (
        <Fragment>
          {icon && icon}
          <Typography variant="button" colour={buttonTextColour}>
            {children}
          </Typography>
        </Fragment>
      )}
    </StyledButton>
  )
}
