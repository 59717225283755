import React from 'react'

import AuthenticatedRoute from '../components/util/AuthenticatedRoute'
import EditFitter from '../pages/Fitters/EditFitter'
import NewFitter from '../pages/Fitters/NewFitter'

const FitterRoutes = [
  <AuthenticatedRoute
    path="/fitters/new-fitter"
    exact
    component={NewFitter}
    title="New Fitter"
  />,
  <AuthenticatedRoute
    path="/fitters/edit/:fitterId"
    exact
    component={EditFitter}
    title="Edit Fitter"
  />,
]

export default FitterRoutes
