import React, { Fragment } from 'react'
import { Query, Mutation } from '@apollo/react-components'
import gql from 'graphql-tag'
import { Form, Field } from 'react-final-form'
import NumberFormat from 'react-number-format'

import {
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  IconButton,
  Tooltip,
  CardHeader,
  InputAdornment,
} from '@material-ui/core'

import { Save, Clear, Add } from '@material-ui/icons'

import { GET_ROOMS } from './../../../helpers/queries/Product'
import { openSnackbar } from './../../reusable/Notifier'
import { openModal, closeModal } from './../../reusable/Popup'

export default function RoomSettings() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography component="h1" variant="h4" gutterBottom>
          Room Settings
        </Typography>
        <Button
          variant="contained"
          style={{
            marginLeft: 'auto',
          }}
          color="secondary"
          onClick={() => {
            openModal({
              content: (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="Close Pop-up"
                        onClick={closeModal}
                      >
                        <Clear />
                      </IconButton>
                    }
                    title="Add Room"
                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Mutation
                        mutation={gql`
                          mutation($name: String!, $stair_charge: String!) {
                            newRoom(name: $name, stair_charge: $stair_charge) {
                              error {
                                message
                              }
                              room {
                                _id
                                name
                                stair_charge
                              }
                            }
                          }
                        `}
                        refetchQueries={[
                          {
                            query: GET_ROOMS,
                          },
                        ]}
                      >
                        {(newRoom) => (
                          <Form
                            onSubmit={(values) => {
                              newRoom({
                                variables: {
                                  name: values.name,
                                  stair_charge: values.stairCharge,
                                },
                              }).then(({ data }) => {
                                const { newRoom } = data

                                if (newRoom.error) {
                                  openSnackbar({
                                    message: newRoom.error.message,
                                    type: 'error',
                                  })
                                } else {
                                  openSnackbar({
                                    message: 'Room updated successfully',
                                    type: 'success',
                                  })
                                  closeModal()
                                }
                              })
                            }}
                            render={({
                              handleSubmit,
                              pristine,
                              submitting,
                            }) => (
                              <Grid item xs={12}>
                                <form
                                  onSubmit={handleSubmit}
                                  autoComplete="off"
                                >
                                  <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} md={4}>
                                      <Field name="name">
                                        {({ input }) => (
                                          <TextField
                                            {...input}
                                            label="Room Name"
                                            fullWidth
                                            variant="outlined"
                                            required
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Field name="stairCharge">
                                        {({ input }) => (
                                          <NumberFormat
                                            customInput={TextField}
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  £
                                                </InputAdornment>
                                              ),
                                            }}
                                            {...input}
                                            label="Stair Charge"
                                            displayType="tel"
                                            decimalScale={2}
                                            fixedDecimalScale
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                        disabled={pristine || submitting}
                                      >
                                        <Save style={{ marginRight: '10px' }} />
                                        Save
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </form>
                              </Grid>
                            )}
                          />
                        )}
                      </Mutation>
                    </Grid>
                  </CardContent>
                </Card>
              ),
            })
          }}
        >
          <Add style={{ marginRight: '10px' }} />
          Add Room
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3} alignItems="center">
              <Query query={GET_ROOMS}>
                {({ loading, error, data }) => {
                  if (loading || error) {
                    return null
                  }

                  let { rooms } = data

                  //remove 'other' option from edit fields
                  rooms = rooms.filter((room) => room.name !== 'Other')

                  return (
                    <Fragment>
                      {rooms.map((room) => (
                        <Mutation
                          key={room._id}
                          mutation={gql`
                            mutation(
                              $_id: String!
                              $name: String!
                              $stair_charge: String!
                            ) {
                              updateRoom(
                                _id: $_id
                                name: $name
                                stair_charge: $stair_charge
                              ) {
                                error {
                                  message
                                }
                                room {
                                  _id
                                  name
                                  stair_charge
                                }
                              }
                            }
                          `}
                          refetchQueries={[
                            {
                              query: GET_ROOMS,
                            },
                          ]}
                        >
                          {(updateRoom) => (
                            <Form
                              initialValues={{
                                name: room.name,
                                stairCharge: room.stair_charge,
                              }}
                              onSubmit={(values) => {
                                updateRoom({
                                  variables: {
                                    _id: room._id,
                                    name: values.name,
                                    stair_charge: values.stairCharge,
                                  },
                                }).then(({ data }) => {
                                  const { updateRoom } = data

                                  if (updateRoom.error) {
                                    openSnackbar({
                                      message: updateRoom.error.message,
                                      type: 'error',
                                    })
                                  } else {
                                    openSnackbar({
                                      message: 'Room updated successfully',
                                      type: 'success',
                                    })
                                  }
                                })
                              }}
                              render={({
                                handleSubmit,
                                pristine,
                                submitting,
                              }) => (
                                <Grid item xs={12}>
                                  <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                  >
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems="center"
                                    >
                                      <Grid item xs={12} md={1}>
                                        <Mutation
                                          mutation={gql`
                                            mutation($_id: String!) {
                                              deleteRoom(_id: $_id) {
                                                error {
                                                  message
                                                }
                                                room {
                                                  _id
                                                  name
                                                  stair_charge
                                                }
                                              }
                                            }
                                          `}
                                          refetchQueries={[
                                            {
                                              query: GET_ROOMS,
                                            },
                                          ]}
                                        >
                                          {(deleteRoom) => (
                                            <Tooltip
                                              title="Delete Room"
                                              placement="top"
                                            >
                                              <IconButton
                                                aria-label="Delete Room"
                                                onClick={() => {
                                                  deleteRoom({
                                                    variables: {
                                                      _id: room._id,
                                                    },
                                                  }).then(({ data }) => {
                                                    const { deleteRoom } = data

                                                    if (deleteRoom.error) {
                                                      openSnackbar({
                                                        message:
                                                          deleteRoom.error
                                                            .message,
                                                        type: 'error',
                                                      })
                                                    } else {
                                                      openSnackbar({
                                                        message:
                                                          'Room deleted successfully',
                                                        type: 'success',
                                                      })
                                                    }
                                                  })
                                                }}
                                              >
                                                <Clear />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </Mutation>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="name">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Room Name"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="stairCharge">
                                          {({ input }) => (
                                            <NumberFormat
                                              customInput={TextField}
                                              variant="outlined"
                                              fullWidth
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    £
                                                  </InputAdornment>
                                                ),
                                              }}
                                              {...input}
                                              label="Stair Charge"
                                              displayType="tel"
                                              decimalScale={2}
                                              fixedDecimalScale
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={3}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          type="submit"
                                          fullWidth
                                          disabled={pristine || submitting}
                                        >
                                          <Save
                                            style={{ marginRight: '10px' }}
                                          />
                                          Save
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </form>
                                </Grid>
                              )}
                            />
                          )}
                        </Mutation>
                      ))}
                    </Fragment>
                  )
                }}
              </Query>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
