import React, { Fragment } from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import createDecorator from "final-form-calculate";
import moment from "moment";
import NumberFormat from "react-number-format";

import NewOrderWrapper from "./NewOrderWrapper";

import { openSnackbar } from "./../../reusable/Notifier";

import { DatePicker } from "@material-ui/pickers";
import { GET_UI_LIST_ENTRIES } from "../../../helpers/queries/CustomerVisitOutcome";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  InputAdornment,
  IconButton,
  Hidden,
} from "@material-ui/core";

import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Clear,
  Add,
} from "@material-ui/icons";

const GET_PARKED_ORDER = gql`
  query parkedOrder($parkedOrderId: String!) {
    parkedOrder(parkedOrderId: $parkedOrderId) {
      _id
      items
      discounts
      increases
      payments_before_release
      weekly_payment
      order_type
      finance_agreement_number
      finance_agreement_term_months
      deposit_method
      first_payment_date
      deposit_date
      total_retail
      deposit
      fitting_delivery_total
      to_pay
      fitting_charge
      complete_payments
      delivery_charge
      grand_total
    }
  }
`;

const GET_SETTINGS = gql`
  query settings {
    settings {
      _id
      monthly_min_payments
      monthly_max_payments

      weekly_min_payments
      weekly_max_payments

      fortnightly_min_payments
      fortnightly_max_payments

      fourweekly_min_payments
      fourweekly_max_payments

      weekly_payment_amounts
      monthly_payment_amounts
      fortnightly_payment_amounts
      fourweekly_payment_amounts
    }
  }
`;

export const UPDATE_PARKED_ORDER_PAYMENT_DETAILS = gql`
  mutation updateParkedOrderPricing(
    $parkedOrderId: ID!
    $completePayments: Int!
    $deliveryCharge: Float!
    $deposit: Float!
    $depositDate: DateTime!
    $depositMethod: String!
    $discounts: [JSON]!
    $increases: [JSON]!
    $firstPaymentDate: DateTime!
    $fittingCharge: Float!
    $orderType: String!
    $financeAgreementNumber: String
    $financeAgreementTerm: Int
    $paymentsBeforeRelease: Int!
    $releaseDate: DateTime!
    $weeklyPayment: Int!
    $toPay: Float!
  ) {
    updateParkedOrderPricing(
      parkedOrderId: $parkedOrderId
      completePayments: $completePayments
      deliveryCharge: $deliveryCharge
      deposit: $deposit
      depositDate: $depositDate
      depositMethod: $depositMethod
      discounts: $discounts
      increases: $increases
      firstPaymentDate: $firstPaymentDate
      fittingCharge: $fittingCharge
      orderType: $orderType
      financeAgreementNumber: $financeAgreementNumber
      financeAgreementTerm: $financeAgreementTerm
      paymentsBeforeRelease: $paymentsBeforeRelease
      releaseDate: $releaseDate
      weeklyPayment: $weeklyPayment
      toPay: $toPay
    ) {
      _id
      items
      discounts
      increases
      payments_before_release
      weekly_payment
      order_type
      finance_agreement_number
      finance_agreement_term_months
      deposit_method
      first_payment_date
      deposit_date
      total_retail
      deposit
      fitting_delivery_total
      to_pay
      fitting_charge
      complete_payments
      delivery_charge
      grand_total
    }
  }
`;

export default function PaymentDetails({ match: { params }, history }) {
  const { data: cvoData } = useQuery(GET_UI_LIST_ENTRIES, {
    variables: { type: "discountsincreases" },
  });
  const {
    loading: parkedOrderLoading,
    error: parkedOrderError,
    data: parkedOrderData,
  } = useQuery(GET_PARKED_ORDER, {
    variables: { parkedOrderId: params.parkedOrderId },
  });

  const {
    loading: settingsLoading,
    error: settingsError,
    data: settingsData,
  } = useQuery(GET_SETTINGS);

  const [updateParkedOrderPricing, { loading: updateLoading }] = useMutation(
    UPDATE_PARKED_ORDER_PAYMENT_DETAILS,
    {
      onError: () => {
        openSnackbar({
          message: "Failed to update order items, please try again",
          type: "error",
        });
      },
      onCompleted: () => {
        history.push(`/new-order/billing-details/${params.parkedOrderId}`);
      },
    }
  );

  //get current parked order details
  let initialValues = {
    items: [],
    toPay: 0,
    grandTotal: 0,
  };

  let settings = {};

  if (settingsData) {
    settings = settingsData.settings;
  }

  if (parkedOrderData && settingsData) {
    const { parkedOrder } = parkedOrderData;
    // console.log("parkedOrder", parkedOrder);

    initialValues = {
      items: JSON.parse(parkedOrder.items) || [{}],
      discounts: parkedOrder.discounts.length ? parkedOrder.discounts : [],
      increases: parkedOrder.increases.length ? parkedOrder.increases : [],
      paymentsBeforeRelease: parseInt(parkedOrder.payments_before_release) || 2,
      weeklyPayment: parseFloat(parkedOrder.weekly_payment) || "",
      orderType: parkedOrder.order_type || "weekly",
      financeAgreementNumber: parkedOrder.finance_agreement_number,
      financeAgreementTerm: parseInt(parkedOrder.finance_agreement_term_months),
      depositMethod: parkedOrder.deposit_method || "cardNow",
      firstPaymentDate: parkedOrder.first_payment_date
        ? moment(parkedOrder.first_payment_date, "x")
        : "",
      depositDate: parkedOrder.deposit_date
        ? moment(parkedOrder.deposit_date, "x")
        : null,
      totalRetail: parseFloat(parkedOrder.total_retail),
      deposit: parseFloat(parkedOrder.deposit),
      fittingDeliveryTotal: parseFloat(parkedOrder.fitting_delivery_total),
      toPay: parseFloat(parkedOrder.to_pay),
      fittingCharge: parseFloat(parkedOrder.fitting_charge),
      completePayments: parkedOrder.complete_payments
        ? parseInt(parkedOrder.complete_payments)
        : null,
      deliveryCharge: parseFloat(parkedOrder.delivery_charge),
      grandTotal: parseFloat(parkedOrder.grand_total),
    };
  }

  if (initialValues && initialValues.increases) {
    for (var i = 0; i < initialValues.increases.length; i++) {
      if (initialValues.increases[i].reasonCodePlusText == null) {
        initialValues.increases[i].reasonCodePlusText =
          initialValues.increases[i].reasonCode +
          "," +
          initialValues.increases[i].reasonText;
      }
    }
  }
  if (initialValues && initialValues.discounts) {
    for (i = 0; i < initialValues.discounts.length; i++) {
      if (initialValues.discounts[i].reasonCodePlusText == null) {
        initialValues.discounts[i].reasonCodePlusText =
          initialValues.discounts[i].reasonCode +
          "," +
          initialValues.discounts[i].reasonText;
      }
    }
  }

  return (
    <Form
      onSubmit={(values) => {
        updateParkedOrderPricing({
          variables: {
            parkedOrderId: params.parkedOrderId,
            completePayments: parseInt(values.completePayments),
            deliveryCharge: parseFloat(values.deliveryCharge),
            deposit: parseFloat(values.deposit),
            depositDate: values.depositDate,
            depositMethod: values.depositMethod,
            firstPaymentDate: values.firstPaymentDate,
            fittingCharge: values.fittingCharge
              ? parseFloat(values.fittingCharge)
              : 0.0,
            orderType: values.orderType,
            financeAgreementNumber: values.financeAgreementNumber,
            financeAgreementTerm: parseInt(values.financeAgreementTerm),
            paymentsBeforeRelease: parseInt(values.paymentsBeforeRelease),
            releaseDate: moment(values.releaseDate, "DD/MM/YYYY"),
            weeklyPayment: parseInt(values.weeklyPayment),
            discounts: values.discounts.map(
              ({ amount, reason, reasonCodePlusText }) => ({
                amount: parseFloat(amount),
                reason,
                reasonCode: reasonCodePlusText.split(",")[0],
                reasonText: reasonCodePlusText.split(",")[1],
              })
            ),
            increases: values.increases.map(
              ({ amount, reason, reasonCodePlusText }) => ({
                amount: parseFloat(amount),
                reason,
                reasonCode: reasonCodePlusText.split(",")[0],
                reasonText: reasonCodePlusText.split(",")[1],
              })
            ),
            toPay: parseFloat(values.toPay),
          },
        });
      }}
      validate={(values) => {
        const errors = { discounts: [], increases: [] };

        const requiredFields = [
          "depositDate",
          "weeklyPayment",
          "paymentsBeforeRelease",
          "firstPaymentDate",
          "depositMethod",
          "orderType",
        ];

        if (
          values.orderType !== "financeAgreement" &&
          values.orderType !== "cashOnly"
        ) {
          requiredFields.push("deposit");
        } else if (values.orderType !== "cashOnly") {
          requiredFields.push("financeAgreementNumber", "financeAgreementTerm");
        }

        requiredFields.forEach((requiredField) => {
          if (!values[requiredField]) {
            errors[requiredField] = "Required";
          }
        });

        //validate fitting charge if this bed contains a floor

        let fittingRequired = false;

        values.items.forEach((item) => {
          if (item.itemType !== "Bed") {
            fittingRequired = true;
          }
        });

        if (fittingRequired && values["fittingCharge"] === undefined) {
          errors["fittingCharge"] = "Required";
        }

        //set min/max

        const totalRetailPrice = values.totalRetail;
        const minFittingCharge = 0;

        const retailPrices = values.items.map((item) => parseFloat(item.price));

        const totalDiscount = values?.discounts?.length
          ? values.discounts
              .map(({ amount }) => (amount ? parseFloat(amount) : 0))
              .reduce((a, b) => a + b, 0)
          : 0;

        const totalIncrease = values?.increases?.length
          ? values.increases
              .map(({ amount }) => (amount ? parseFloat(amount) : 0))
              .reduce((a, b) => a + b, 0)
          : 0;

        switch (values.orderType) {
          case "financeAgreement":
          case "cashOnly":
            break;

          default:
            //deposit min/max
            const maxDeposit = totalRetailPrice - totalDiscount + totalIncrease;

            if (values.deposit < 0.01) {
              errors.deposit = "Deposit can't be less than £0.01";
            } else if (values.deposit >= maxDeposit) {
              errors.deposit = `Deposit can't be more than £${maxDeposit - 1}`;
            }
        }

        //deposit date validation

        if (
          values.depositMethod === "cardLater" &&
          values.depositDate &&
          values.depositDate.endOf("day") <= moment().endOf("day")
        ) {
          errors.depositDate = "Card later deposits must be in the future";
        }

        if (
          (values.depositMethod === "cash" ||
            values.depositMethod === "cardNow") &&
          values.depositDate &&
          values.depositDate.endOf("day") > moment().endOf("day")
        ) {
          errors.depositDate = "Deposit date must be today";
        }

        //discount min/max

        const maxDiscount =
          retailPrices.reduce((a, b) => a + b, 0) -
          values.deposit +
          totalIncrease;

        if (totalDiscount > maxDiscount) {
          // set all amounts of discounts to have an error
          values.discounts.forEach((_, index) => {
            errors.discounts.push({});

            errors.discounts[
              index
            ].amount = `Total discount can't be more than £${maxDiscount}`;
          });
        }

        // add required to discount fields

        if (values?.discounts?.length) {
          values.discounts.forEach(
            ({ amount, reason, reasonCodePlusText }, index) => {
              errors.discounts.push({});

              // check each if their empty

              if (!amount) {
                errors.discounts[index].amount = `Required`;
              }

              if (!reason) {
                errors.discounts[index].reason = `Required`;
              }

              if (!reasonCodePlusText) {
                errors.discounts[index].reasonCodePlusText = "Required";
              }
            }
          );
        }

        if (values?.increases?.length) {
          values.increases.forEach(
            ({ amount, reason, reasonCodePlusText }, index) => {
              errors.increases.push({});
              if (!amount) {
                errors.increases[index].amount = `Required`;
              }

              if (!reason) {
                errors.increases[index].reason = `Required`;
              }

              if (!reasonCodePlusText) {
                errors.increases[index].reasonCodePlusText = "Required";
              }
            }
          );
        }

        //fitting min/max
        if (values.fittingCharge < minFittingCharge) {
          errors.fittingCharge =
            "Fitting charge must be more than £" + minFittingCharge;
        } else if (!values.fittingCharge && !!minFittingCharge) {
          errors.fittingCharge = "Required";
        }

        //don't allow minus payments before release
        if (values.paymentsBeforeRelease && values.paymentsBeforeRelease < 1) {
          errors.paymentsBeforeRelease = "Must be at least 1";
        }

        //prevent number of payments going above define max
        if (
          values.completePayments > settings.weekly_max_payments &&
          values.orderType === "weekly"
        ) {
          errors.completePayments = `Payments must be completed within ${settings.weekly_max_payments} weeks, try increasing the deposit or weekly amount.`;
        }

        //prevent number of payments going below minimum
        if (
          values.completePayments < settings.weekly_min_payments &&
          values.orderType === "weekly"
        ) {
          errors.completePayments = `Payments must be completed in more than ${settings.weekly_min_payments} weeks, try decreasing the deposit or weekly amount.`;
        }

        //prevent number of payments going above define max
        if (
          values.completePayments > settings.monthly_max_payments &&
          values.orderType === "monthly"
        ) {
          errors.completePayments = `Payments must be completed within ${settings.monthly_max_payments} months, try increasing the deposit or monthly amount.`;
        }

        //prevent number of payments going below minimum
        if (values.completePayments < settings.monthly_min_payments) {
          errors.completePayments = `Payments must be completed in more than ${settings.monthly_min_payments} months, try decreasing the deposit or monthly amount.`;
        }

        //prevent number of payments going above define max
        if (
          values.completePayments > settings.fortnightly_max_payments &&
          values.orderType === "fortnightly"
        ) {
          errors.completePayments = `Payments must be completed within ${settings.fortnightly_max_payments} months, try increasing the deposit or fortnightly payment amount.`;
        }

        //prevent number of payments going below minimum
        if (values.completePayments < settings.fortnightly_min_payments) {
          errors.completePayments = `Payments must be completed in more than ${settings.fortnightly_min_payments} months, try decreasing the deposit or fortnightly payment amount.`;
        }
        //prevent number of payments going above define max
        if (
          values.completePayments > settings.fourweekly_max_payments &&
          values.orderType === "4weekly"
        ) {
          errors.completePayments = `Payments must be completed within ${settings.fourweekly_max_payments} months, try increasing the deposit or weekly amount.`;
        }

        //prevent number of payments going below minimum
        if (values.completePayments < settings.fourweekly_min_payments) {
          errors.completePayments = `Payments must be completed in more than ${settings.fourweekly_min_payments} months, try decreasing the deposit or weekly amount.`;
        }

        if (values.paymentsBeforeRelease > values.completePayments) {
          errors.paymentsBeforeRelease = `Payments before release must be less than or equal to ${values.completePayments}`;
        }

        // console.log("err", errors);

        return errors;
      }}
      initialValues={initialValues}
      decorators={[
        createDecorator(
          {
            // reset weekly payment value when swapping between weekly/monthly order
            field: "orderType",
            updates: async (value, name, allValues) => {
              return {
                weeklyPayment: "",
                releaseDate: "",
                firstPaymentDate: "",
              };
            },
          },
          {
            field: "orderType",
            updates: async (value, name, allValues) => {
              let predictedDate;

              switch (allValues.orderType) {
                case "financeAgreement":
                  return {
                    weeklyPayment: allValues.grandTotal,
                    deposit: 0,
                    firstPaymentDate: moment(),
                    paymentsBeforeRelease: 1,
                    releaseDate: moment().format("DD/MM/YYYY"),
                    completePayments: 2,
                    depositMethod: "cash",
                    toPay: allValues.totalRetail,
                  };
                case "cashOnly":
                  return {
                    weeklyPayment: allValues.grandTotal,
                    deposit: 0,
                    firstPaymentDate: moment(),
                    paymentsBeforeRelease: 1,
                    releaseDate: moment().format("DD/MM/YYYY"),
                    completePayments: 2,
                    depositMethod: "cash",
                    toPay: allValues.totalRetail,
                  };

                case "fortnightly" || "4weekly":
                  if (allValues.firstPaymentDate) {
                    if (parseInt(allValues.paymentsBeforeRelease) === 0) {
                      //release product day after deposit
                      predictedDate = allValues.depositDate
                        .clone()
                        .add(1, "days")
                        .format("DD/MM/YYYY");
                    } else {
                      //release product after first payment date
                      predictedDate = allValues.firstPaymentDate
                        .clone()
                        .add(
                          (parseInt(allValues.paymentsBeforeRelease) - 1) *
                            (allValues.orderType === "fortnightly" ? 2 : 4),
                          "weeks"
                        )
                        .add(1, "days")
                        .format("DD/MM/YYYY");
                    }
                  }

                  return {
                    releaseDate: predictedDate,
                  };

                default:
                  if (allValues.firstPaymentDate) {
                    if (parseInt(allValues.paymentsBeforeRelease) === 0) {
                      //release product day after deposit
                      predictedDate = allValues.depositDate
                        .clone()
                        .add(1, "days")
                        .format("DD/MM/YYYY");
                    } else {
                      //release product after first payment date
                      predictedDate = allValues.firstPaymentDate
                        .clone()
                        .add(
                          parseInt(allValues.paymentsBeforeRelease) - 1,
                          value === "weekly" ? "weeks" : "months"
                        )
                        .add(1, "days")
                        .format("DD/MM/YYYY");
                    }
                  }

                  return {
                    releaseDate: predictedDate,
                  };
              }
            },
          },
          {
            //changed fitting/delivery total when fitting charge changes
            field: "fittingCharge",
            updates: async (value, name, allValues) => {
              const fittingCharge = value || 0;

              const deliveryPrices = allValues.items
                .map((item) => {
                  let deliveryPrice = parseFloat(item.delivery);

                  if (isNaN(deliveryPrice)) {
                    deliveryPrice = 0;
                  }

                  return deliveryPrice;
                })
                .reduce((a, b) => a + b, 0);

              return {
                fittingDeliveryTotal:
                  parseFloat(fittingCharge) + deliveryPrices,
              };
            },
          },
          {
            //set deposit date for immediate payments
            field: "depositMethod",
            updates: async (value, name, allValues, oldValues) => {
              if (value === "cardNow" || value === "cash") {
                return {
                  depositDate: moment(),
                };
              }

              const firstLoad = Object.entries(oldValues).length === 0;

              if (firstLoad) {
                return {
                  depositDate: allValues.depositDate,
                };
              }

              return {
                depositDate: null,
              };
            },
          },
          {
            //change to pay when deposit changes
            field: "deposit",
            updates: async (value, name, allValues) => {
              const deposit = value || 0;

              const totalRetailPrice = parseFloat(allValues.totalRetail);

              const totalDiscount = allValues?.discounts?.length
                ? allValues.discounts
                    .map(({ amount }) => (amount ? parseFloat(amount) : 0))
                    .reduce((a, b) => a + b, 0)
                : 0;

              const totalIncrease = allValues?.increases?.length
                ? allValues.increases
                    .map(({ amount }) => (amount ? parseFloat(amount) : 0))
                    .reduce((a, b) => a + b, 0)
                : 0;

              switch (allValues.orderType) {
                case "financeAgreement":
                case "cashOnly":
                  return {
                    toPay:
                      totalRetailPrice +
                      totalIncrease -
                      totalDiscount -
                      deposit,
                  };

                default:
                  const numOfPayments =
                    (totalRetailPrice -
                      deposit +
                      totalIncrease -
                      totalDiscount) /
                    parseInt(allValues.weeklyPayment);

                  return {
                    toPay:
                      totalRetailPrice +
                      totalIncrease -
                      totalDiscount -
                      deposit,
                    completePayments: numOfPayments
                      ? Math.ceil(numOfPayments)
                      : "",
                  };
              }
            },
          },
          {
            //set first payment date to as long as possible
            field: "depositDate",
            updates: async (value, name, allValues) => {
              if (value && !allValues.firstPaymentDate) {
                return {
                  firstPaymentDate: value
                    .clone()
                    .add(
                      allValues.orderType === "fortnightly"
                        ? 14
                        : allValues.orderType === "4weekly"
                        ? 28
                        : 7,
                      "days"
                    ),
                };
              }

              return {};
            },
          },
          {
            //change total retail when discount changes
            field: "discounts",
            updates: async (value, name, allValues) => {
              const totalRetailPrice = allValues.totalRetail;

              const totalDiscount = value.length
                ? value
                    .map(({ amount }) => (amount ? parseFloat(amount) : 0))
                    .reduce((a, b) => a + b, 0)
                : 0;

              const totalIncrease = allValues?.increases?.length
                ? allValues.increases
                    .map(({ amount }) => (amount ? parseFloat(amount) : 0))
                    .reduce((a, b) => a + b, 0)
                : 0;

              const numOfPayments =
                (totalRetailPrice -
                  allValues.deposit +
                  totalIncrease -
                  totalDiscount) /
                parseInt(allValues.weeklyPayment);

              return {
                toPay:
                  totalRetailPrice -
                  allValues.deposit +
                  totalIncrease -
                  totalDiscount,
                grandTotal:
                  totalRetailPrice +
                  totalIncrease -
                  totalDiscount +
                  parseFloat(allValues.fittingDeliveryTotal),
                completePayments: numOfPayments ? Math.ceil(numOfPayments) : "",
              };
            },
          },
          {
            //change total retail when discount changes
            field: "increases",
            updates: async (value, name, allValues) => {
              const totalRetailPrice = allValues.totalRetail;

              const totalDiscount = allValues?.discounts?.length
                ? allValues.discounts
                    .map(({ amount }) => (amount ? parseFloat(amount) : 0))
                    .reduce((a, b) => a + b, 0)
                : 0;

              const totalIncrease = value.length
                ? value
                    .map(({ amount }) => (amount ? parseFloat(amount) : 0))
                    .reduce((a, b) => a + b, 0)
                : 0;

              const numOfPayments =
                (totalRetailPrice -
                  allValues.deposit +
                  totalIncrease -
                  totalDiscount) /
                parseInt(allValues.weeklyPayment);

              return {
                toPay:
                  totalRetailPrice -
                  allValues.deposit +
                  totalIncrease -
                  totalDiscount,
                grandTotal:
                  totalRetailPrice +
                  totalIncrease -
                  totalDiscount +
                  parseFloat(allValues.fittingDeliveryTotal),
                completePayments: numOfPayments ? Math.ceil(numOfPayments) : "",
              };
            },
          },
          {
            //change predicted release date when first payment date changes
            field: "firstPaymentDate",
            updates: async (value, name, allValues) => {
              switch (allValues.orderType) {
                case "financeAgreement":
                case "cashOnly":
                  break;
                default:
                  let predictedDate;
                  if (value) {
                    if (
                      parseInt(allValues.paymentsBeforeRelease) === 0 &&
                      allValues.depositDate
                    ) {
                      //release product day after deposit
                      predictedDate = allValues.depositDate
                        .clone()
                        .add(1, "days")
                        .format("DD/MM/YYYY");
                    } else {
                      //release product after first payment date
                      predictedDate = value
                        .clone()
                        .add(
                          parseInt(allValues.paymentsBeforeRelease) - 1,
                          allValues.orderType === "weekly" ? "weeks" : "months"
                        )
                        .add(1, "days")
                        .format("DD/MM/YYYY");
                    }
                  }
                  return {
                    releaseDate: predictedDate,
                  };
              }
            },
          },
          {
            //change predicted release date when weeks till release changes
            field: "paymentsBeforeRelease",

            updates: async (value, name, allValues) => {
              switch (allValues.orderType) {
                case "financeAgreement":
                case "cashOnly":
                  break;
                default:
                  let predictedDate;

                  if (allValues.firstPaymentDate) {
                    if (parseInt(value) === 0 && allValues.depositDate) {
                      //release product day after deposit
                      predictedDate = allValues.depositDate
                        .clone()
                        .add(1, "days")
                        .format("DD/MM/YYYY");
                    } else {
                      //release product after first payment date
                      predictedDate = allValues.firstPaymentDate
                        .clone()
                        .add(
                          parseInt(value) - 1,
                          allValues.orderType === "weekly" ? "weeks" : "months"
                        )
                        .add(1, "days")
                        .format("DD/MM/YYYY");
                    }
                  }

                  return {
                    releaseDate: predictedDate,
                  };
              }
            },
          },
          {
            //change grand total when fitting/delivery total changes
            field: "fittingDeliveryTotal",
            updates: async (value, name, allValues) => {
              const totalDiscount = allValues?.discounts?.length
                ? allValues.discounts
                    .map(({ amount }) => (amount ? parseFloat(amount) : 0))
                    .reduce((a, b) => a + b, 0)
                : 0;

              const totalIncrease = allValues?.increases?.length
                ? allValues.increases
                    .map(({ amount }) => (amount ? parseFloat(amount) : 0))
                    .reduce((a, b) => a + b, 0)
                : 0;

              return {
                grandTotal:
                  value +
                  parseFloat(allValues.totalRetail) +
                  totalIncrease -
                  totalDiscount,
              };
            },
          },
          {
            //recalculate total payments
            field: "weeklyPayment",
            updates: async (value, name, allValues) => {
              switch (allValues.orderType) {
                case "financeAgreement":
                case "cashOnly":
                  break;
                default:
                  const totalRetailPrice = parseFloat(allValues.totalRetail);

                  const totalDiscount = allValues?.discounts?.length
                    ? allValues.discounts
                        .map(({ amount }) => (amount ? parseFloat(amount) : 0))
                        .reduce((a, b) => a + b, 0)
                    : 0;

                  const totalIncrease = allValues?.increases?.length
                    ? allValues.increases
                        .map(({ amount }) => (amount ? parseFloat(amount) : 0))
                        .reduce((a, b) => a + b, 0)
                    : 0;

                  const numOfPayments =
                    (totalRetailPrice -
                      allValues.deposit +
                      totalIncrease -
                      totalDiscount) /
                    parseInt(value);
                  return {
                    completePayments: numOfPayments
                      ? Math.ceil(numOfPayments)
                      : "",
                  };
              }
            },
          }
        ),
      ]}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, values }) => {
        //calculate the minimum fitting fee
        let minFittingCharge = 0;

        minFittingCharge = values.items
          .map((item) => {
            let fittingPrice = parseFloat(item.fitting);

            if (isNaN(fittingPrice)) {
              fittingPrice = 0;
            }

            return fittingPrice;
          })
          .reduce((a, b) => a + b, 0);

        // calculate to pay values
        let { toPay } = values;

        toPay = toPay.toFixed(2);

        const weeklyPayment = values.weeklyPayment || 0;

        let numOfCompletePayments = 0;
        let remainingToPay = 0;

        const numOfPayments = toPay / weeklyPayment;

        if (toPay && weeklyPayment) {
          if (numOfPayments % 1 === 0) {
            numOfCompletePayments = numOfPayments;
          } else {
            numOfCompletePayments = Math.floor(numOfPayments);

            //remaining
            remainingToPay = toPay - weeklyPayment * numOfCompletePayments;
            remainingToPay = remainingToPay.toFixed(2);
          }
        }

        const totalDiscount = values?.discounts?.length
          ? values.discounts
              .map(({ amount }) => (amount ? parseFloat(amount) : 0))
              .reduce((a, b) => a + b, 0)
          : 0;

        const totalIncrease = values?.increases?.length
          ? values.increases
              .map(({ amount }) => (amount ? parseFloat(amount) : 0))
              .reduce((a, b) => a + b, 0)
          : 0;

        return (
          <NewOrderWrapper
            pageNumber={3}
            nextButtonLabel="Next"
            nextPageFunction={() => {
              handleSubmit();
            }}
            loading={parkedOrderLoading || settingsLoading}
            nextButtonLoading={updateLoading}
            error={
              parkedOrderError || settingsError
                ? "Failed to get payment details, please refresh the page."
                : null
            }
          >
            <form autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography component="h2" variant="h5" gutterBottom>
                    Pricing
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    Order Type *
                  </Typography>
                  <RadioGroup
                    aria-label="Order Type"
                    name="orderType"
                    style={{
                      flexDirection: "column",
                      justifyContent: "space-around",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Field name="orderType" type="radio" value="weekly">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="weekly"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              Weekly
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                    <Field name="orderType" type="radio" value="fortnightly">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="fortnightly"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              2 Weekly (Fortnightly)
                            </Typography>
                          }
                        />
                      )}
                    </Field>

                    <Field name="orderType" type="radio" value="4weekly">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="4 weekly"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              4 Weekly
                            </Typography>
                          }
                        />
                      )}
                    </Field>

                    <Field name="orderType" type="radio" value="monthly">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="monthly"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              Monthly
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                    <Field
                      name="orderType"
                      type="radio"
                      value="financeAgreement"
                    >
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="financeAgreement"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              Finance Agreement
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                    <Field name="orderType" type="radio" value="cashOnly">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="financeAgreement"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              Cash Only
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    Deposit Payment Type
                  </Typography>
                  <RadioGroup
                    aria-label="Deposit Method"
                    name="depositMethod"
                    style={{
                      flexDirection: "column",
                      justifyContent: "space-around",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Field name="depositMethod" type="radio" value="cardNow">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="cardNow"
                          control={<Radio {...input} />}
                          disabled={values.orderType === "cashOnly"}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              Card Now
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                    <Field name="depositMethod" type="radio" value="cardLater">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="cardLater"
                          control={<Radio {...input} />}
                          disabled={values.orderType === "cashOnly"}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              Card Later
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                    <Field name="depositMethod" type="radio" value="cash">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="cash"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              Cash
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                  </RadioGroup>
                </Grid>

                {values.orderType === "financeAgreement" && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Field name="financeAgreementNumber">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          name="financeAgreementNumber"
                          label="Finance Agreement Number"
                          fullWidth
                          required
                          variant="outlined"
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : ""
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                )}

                {values.orderType === "financeAgreement" && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Field name="financeAgreementTerm">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          select
                          label="Finance Agreement Term"
                          fullWidth
                          required
                          variant="outlined"
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : ""
                          }
                        >
                          {[4, 6, 10, 12, 18].map((option) => (
                            <MenuItem
                              value={option}
                            >{`${option} Months`}</MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Field>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={3}>
                  <Field name="depositDate">
                    {({ input, meta }) => (
                      <DatePicker
                        format="DD/MM/YYYY"
                        name={input.name}
                        onChange={input.onChange}
                        value={input.value ? input.value : ""}
                        onBlur={input.onBlur}
                        fullWidth
                        required
                        disablePast
                        autoOk
                        clearable
                        disabled={
                          !values.depositMethod
                            ? true
                            : values.depositMethod !== "cardLater"
                            ? true
                            : false
                        }
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        minDate={
                          values.depositMethod === "cardLater"
                            ? moment().add(1, "days")
                            : ""
                        }
                        maxDate={moment().add(1, "weeks")}
                        label="Deposit Date"
                        leftArrowIcon={<KeyboardArrowLeft />}
                        inputVariant="outlined"
                        rightArrowIcon={<KeyboardArrowRight />}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="deposit">
                    {({ input, meta }) => (
                      <NumberFormat
                        customInput={TextField}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        disabled={values.orderType === "cashOnly"}
                        required={
                          values.orderType === "financeAgreement" ||
                          values.orderType === "cashOnly"
                            ? false
                            : true
                        }
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        {...input}
                        label="Deposit"
                        displayType="tel"
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    )}
                  </Field>
                </Grid>

                {values.orderType !== "financeAgreement" && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Field name="weeklyPayment">
                      {({ input, meta }) => (
                        <Fragment>
                          {(values.orderType === "weekly" ||
                            values.orderType === "financeAgreement" ||
                            values.orderType === "cashOnly") && (
                            <TextField
                              {...input}
                              select
                              label="Weekly Payment"
                              fullWidth
                              required
                              variant="outlined"
                              disabled={
                                values.orderType === "financeAgreement" ||
                                values.orderType === "cashOnly"
                                  ? true
                                  : false
                              }
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : ""
                              }
                            >
                              {settings.weekly_payment_amounts.map((amount) => (
                                <MenuItem
                                  key={amount}
                                  value={amount}
                                >{`£${amount}`}</MenuItem>
                              ))}
                              {(values.orderType === "financeAgreement" ||
                                values.orderType === "cashOnly") && (
                                <MenuItem value={values.grandTotal}>
                                  {values.grandTotal}
                                </MenuItem>
                              )}
                            </TextField>
                          )}

                          {values.orderType === "monthly" && (
                            <TextField
                              {...input}
                              select
                              label="Monthly Payment"
                              fullWidth
                              required
                              variant="outlined"
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : ""
                              }
                            >
                              {settings.monthly_payment_amounts.map(
                                (amount) => (
                                  <MenuItem
                                    key={amount}
                                    value={amount}
                                  >{`£${amount}`}</MenuItem>
                                )
                              )}
                            </TextField>
                          )}

                          {values.orderType === "fortnightly" && (
                            <TextField
                              {...input}
                              select
                              label="Fortnightly Payment"
                              fullWidth
                              required
                              variant="outlined"
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : ""
                              }
                            >
                              {settings.fortnightly_payment_amounts.map(
                                (amount) => (
                                  <MenuItem
                                    key={amount}
                                    value={amount}
                                  >{`£${amount}`}</MenuItem>
                                )
                              )}
                            </TextField>
                          )}

                          {values.orderType === "4weekly" && (
                            <TextField
                              {...input}
                              select
                              label="4 weekly Payment"
                              fullWidth
                              required
                              variant="outlined"
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : ""
                              }
                            >
                              {settings.fourweekly_payment_amounts.map(
                                (amount) => (
                                  <MenuItem
                                    key={amount}
                                    value={amount}
                                  >{`£${amount}`}</MenuItem>
                                )
                              )}
                            </TextField>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={3}>
                  <Field name="fittingCharge">
                    {({ input, meta }) => (
                      <NumberFormat
                        customInput={TextField}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        disabled={!!!minFittingCharge}
                        required={!!minFittingCharge}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        {...input}
                        label="Floor Fitting Charge"
                        displayType="tel"
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="toPay">
                    {({ input }) => (
                      <NumberFormat
                        customInput={TextField}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        disabled
                        {...input}
                        label="To Pay"
                        displayType="tel"
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    )}
                  </Field>
                </Grid>

                {(values.orderType !== "financeAgreement" ||
                  values.orderType !== "cashOnly") && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Field name="paymentsBeforeRelease">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Payments Before Release"
                          fullWidth
                          required
                          variant="outlined"
                          type="number"
                          disabled={values.orderType === "cashOnly"}
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : ""
                          }
                          InputProps={{
                            inputProps: { min: 1 },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={3}>
                  <Field name="firstPaymentDate">
                    {({ input, meta }) => (
                      <DatePicker
                        format="DD/MM/YYYY"
                        name={input.name}
                        onChange={input.onChange}
                        value={input.value ? input.value : ""}
                        onBlur={input.onBlur}
                        fullWidth
                        required
                        disablePast
                        autoOk
                        disabled={!!!values.depositDate}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        maxDate={moment(values.depositDate).add(
                          values.orderType === "fortnightly"
                            ? 2
                            : values.orderType === "4weekly"
                            ? 4
                            : 1,
                          values.orderType === "weekly" ||
                            values.orderType === "fortnightly" ||
                            values.orderType === "4weekly"
                            ? "weeks"
                            : "months"
                        )}
                        minDate={moment(values.depositDate).add(1, "days")}
                        label="First Payment Date"
                        leftArrowIcon={<KeyboardArrowLeft />}
                        inputVariant="outlined"
                        rightArrowIcon={<KeyboardArrowRight />}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Field name="deliveryCharge">
                    {({ input }) => (
                      <NumberFormat
                        customInput={TextField}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        disabled
                        {...input}
                        label="Bed Delivery Charge"
                        displayType="tel"
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="totalRetail">
                    {({ input }) => (
                      <NumberFormat
                        customInput={TextField}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        disabled
                        {...input}
                        label="Total Retail"
                        displayType="tel"
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="releaseDate">
                    {({ input }) => (
                      <TextField
                        {...input}
                        label="Expected Release Date"
                        fullWidth
                        disabled
                        variant="outlined"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="fittingDeliveryTotal">
                    {({ input }) => (
                      <NumberFormat
                        customInput={TextField}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        disabled
                        {...input}
                        label="Fitting/Delivery Total"
                        displayType="tel"
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="grandTotal">
                    {({ input }) => (
                      <NumberFormat
                        customInput={TextField}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        disabled
                        {...input}
                        label="Grand Total"
                        displayType="tel"
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    )}
                  </Field>
                </Grid>

                {(values.orderType !== "financeAgreement" ||
                  values.orderType !== "cashOnly") && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Field name="completePayments">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="No. of Payments"
                          fullWidth
                          variant="outlined"
                          type="number"
                          disabled
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : ""
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                )}

                <FieldArray name="discounts">
                  {({ fields }) => (
                    <>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography component="h3" variant="h5" gutterBottom>
                            Discounts
                          </Typography>
                          <IconButton
                            aria-label="Delete Row"
                            onClick={() =>
                              fields.push({
                                amount: null,
                                reason: "",
                                reasonCode: "",
                                reasonText: "",
                              })
                            }
                          >
                            <Add />
                          </IconButton>
                        </div>
                        <Divider />
                      </Grid>
                      {fields.length ? (
                        <>
                          {fields.map((name, index) => (
                            <Fragment key={name}>
                              <Grid item xs={12} sm={5} md={3}>
                                <Field name={`${name}.amount`}>
                                  {({ input, meta }) => (
                                    <NumberFormat
                                      customInput={TextField}
                                      variant="outlined"
                                      fullWidth
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            £
                                          </InputAdornment>
                                        ),
                                      }}
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                      {...input}
                                      label="Amount"
                                      required
                                      displayType="tel"
                                      decimalScale={2}
                                      fixedDecimalScale
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} sm={5} md={3}>
                                <Field name={name + ".reasonCodePlusText"}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      select
                                      fullWidth
                                      variant="outlined"
                                      label="Reason Code"
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                      //size="small"
                                      //style={{ marginTop: '7.5px' }}
                                    >
                                      {cvoData &&
                                        cvoData.entries.map((outcome) => {
                                          return (
                                            <MenuItem
                                              value={
                                                outcome.value +
                                                "," +
                                                outcome.name
                                              }
                                              key={outcome._id}
                                            >
                                              {outcome.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </TextField>
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} sm={5} md={3}>
                                <Field name={`${name}.reason`}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      label="Reason"
                                      fullWidth
                                      required
                                      variant="outlined"
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid
                                item
                                md={1}
                                sm={2}
                                xs={12}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  aria-label="Delete Row"
                                  onClick={() => {
                                    fields.remove(index);
                                  }}
                                >
                                  <Clear />
                                </IconButton>
                              </Grid>
                              <Hidden smDown>
                                <Grid item md={2} />
                              </Hidden>
                            </Fragment>
                          ))}
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              Total Discount: £
                              {parseFloat(totalDiscount.toFixed(2))}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            There are no discounts on this order. Click the '+'
                            to add one!
                          </Typography>
                        </Grid>
                      )}
                    </>
                  )}
                </FieldArray>
                <FieldArray name="increases">
                  {({ fields }) => (
                    <>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography component="h3" variant="h5" gutterBottom>
                            Increases
                          </Typography>
                          <IconButton
                            aria-label="Delete Row"
                            onClick={() =>
                              fields.push({ amount: null, reason: "" })
                            }
                          >
                            <Add />
                          </IconButton>
                        </div>
                        <Divider />
                      </Grid>
                      {fields.length ? (
                        <>
                          {fields.map((name, index) => (
                            <Fragment key={name}>
                              <Grid item xs={12} sm={5} md={3}>
                                <Field name={`${name}.amount`}>
                                  {({ input, meta }) => (
                                    <NumberFormat
                                      customInput={TextField}
                                      variant="outlined"
                                      fullWidth
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            £
                                          </InputAdornment>
                                        ),
                                      }}
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                      {...input}
                                      label="Amount"
                                      required
                                      displayType="tel"
                                      decimalScale={2}
                                      fixedDecimalScale
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} sm={5} md={3}>
                                <Field name={name + ".reasonCodePlusText"}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      select
                                      fullWidth
                                      variant="outlined"
                                      label="Reason Code"
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                      //size="small"
                                      //style={{ marginTop: '7.5px' }}
                                    >
                                      {cvoData &&
                                        cvoData.entries.map((outcome) => {
                                          return (
                                            <MenuItem
                                              value={
                                                outcome.value +
                                                "," +
                                                outcome.name
                                              }
                                              key={outcome._id}
                                            >
                                              {outcome.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </TextField>
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} sm={5} md={3}>
                                <Field name={`${name}.reason`}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      label="Reason"
                                      fullWidth
                                      required
                                      variant="outlined"
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid
                                item
                                md={1}
                                sm={2}
                                xs={12}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  aria-label="Delete Row"
                                  onClick={() => {
                                    fields.remove(index);
                                  }}
                                >
                                  <Clear />
                                </IconButton>
                              </Grid>
                              <Hidden smDown>
                                <Grid item md={2} />
                              </Hidden>
                            </Fragment>
                          ))}
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              Total Increase: £
                              {parseFloat(totalIncrease.toFixed(2))}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            There are no increases on this order. Click the '+'
                            to add one!
                          </Typography>
                        </Grid>
                      )}
                    </>
                  )}
                </FieldArray>
                <Grid item xs={12}>
                  <Typography component="h2" variant="h5" gutterBottom>
                    Pricing Breakdown
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  {!!values.deposit &&
                    !!values.depositMethod &&
                    !!values.depositDate && (
                      <Typography variant="body1">
                        A deposit of £{parseFloat(values.deposit).toFixed(2)} to
                        be paid{" "}
                        {values.depositMethod === "cardNow"
                          ? "now by card"
                          : values.depositMethod === "cash"
                          ? "now by cash"
                          : "by card on " +
                            moment(values.depositDate).format("DD/MM/YYYY")}
                      </Typography>
                    )}
                  {!!values.fittingCharge && (
                    <Typography variant="body1">
                      A floor fitting charge of £{values.fittingCharge} to be
                      paid in cash to the fitters on the day of installation.
                    </Typography>
                  )}
                  {!!values.deliveryCharge && (
                    <Typography variant="body1">
                      A delivery charge of £{values.deliveryCharge} to be paid
                      over the phone when arranging a delivery date.
                    </Typography>
                  )}
                  {!!numOfCompletePayments &&
                    !!values.firstPaymentDate &&
                    !!values.weeklyPayment &&
                    values.orderType === "weekly" && (
                      <Typography variant="body1">
                        {numOfCompletePayments} payments of £
                        {values.weeklyPayment} taken on{" "}
                        {moment(values.firstPaymentDate).format("dddd")},
                        starting{" "}
                        {moment(values.firstPaymentDate).format("DD/MM/YYYY")}
                      </Typography>
                    )}
                  {!!numOfCompletePayments &&
                    !!values.firstPaymentDate &&
                    !!values.weeklyPayment &&
                    values.orderType === "monthly" && (
                      <Typography variant="body1">
                        {numOfCompletePayments} payments of £
                        {values.weeklyPayment} taken on the{" "}
                        {moment(values.firstPaymentDate).format("Do")} of each
                        month, starting{" "}
                        {moment(values.firstPaymentDate).format("DD/MM/YYYY")}
                      </Typography>
                    )}
                  {!!remainingToPay &&
                    !!values.firstPaymentDate &&
                    values.orderType !== "financeAgreement" &&
                    values.orderType !== "cashOnly" && (
                      <Typography variant="body1">
                        A final payment of £{remainingToPay} to be paid{" "}
                        {moment(values.firstPaymentDate)
                          .add(
                            values.completePayments,
                            values.orderType === "weekly" ? "weeks" : "months"
                          )
                          .format("DD/MM/YYYY")}
                      </Typography>
                    )}
                  {!!values.completePayments &&
                    values.orderType !== "financeAgreement" &&
                    values.orderType !== "cashOnly" && (
                      <Typography variant="body1">
                        There will be {values.completePayments} payments in
                        total, excluding your deposit.
                      </Typography>
                    )}
                  {values.orderType === "financeAgreement" &&
                    (values.financeAgreementNumber ? (
                      <Typography variant="body1">
                        Your items will be released today.
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        Your items will be released when finance is agreed.
                      </Typography>
                    ))}

                  {values.orderType === "cashOnly" && (
                    <Typography variant="body1">
                      {`There will be 1 payment ${moment(
                        values.firstPaymentDate
                      ).format("DD/MM/YYYY")} for £${values.toPay.toFixed(2)}`}
                    </Typography>
                  )}
                  {values.orderType !== "cashOnly" && (
                    <Typography variant="body1">
                      After {values.paymentsBeforeRelease} payments, your items
                      will be released.
                    </Typography>
                  )}

                  <Typography variant="body1">
                    The grand total of your order including fitting/delivery
                    costs is £{values.grandTotal.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </form>
          </NewOrderWrapper>
        );
      }}
    />
  );
}
