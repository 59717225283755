import { createMuiTheme } from "@material-ui/core";
import { orange, lightGreen, red } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: { main: orange[500] },
    secondary: { main: lightGreen[500] },
    custom: { main: red[500] },
  },
  overrides: {
    MuiCard: {
      root: {
        padding: "15px",
        borderRadius: "10px",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "10px",
      },
    },
    MuiInputBase: {
      root: {
        "&$disabled": {
          backgroundColor: "rgba(35,44,152, 0.05)",
          borderRadius: "4px",
        },
      },
    },
    MuiMobileStepper: {
      root: {
        backgroundColor: "#fff",
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiButton: {
      sizeSmall: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: "4px",
      },
    },
    MuiTable: {
      root: {
        outline: "none",
      },
    },
    MuiDrawer: {
      paper: {
        overflowX: "hidden",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "13px",
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: "auto",
      },
      responsiveScrollFullHeight: {
        zIndex: 1,
      },
    },
    MUIDataTablePagination: {
      root: {
        borderBottom: "0",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "white",
      },
      toolbarBtnSelected: {
        color: "white",
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: "white",
      },
    },
    MuiGridListTile: {
      root: {
        width: "auto !important",
      },
    },
  },
});

export default theme;
