import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import OrdersTable from '../Orders/OrdersTable'

import { Typography, Grid } from '@material-ui/core'

import Loading from '../../reusable/Loading'
import Error from '../../../newComponents/Error'

const GET_CUSTOMER = gql`
  query getCustomer($customerId: ID) {
    getCustomer(customerId: $customerId) {
      _id
      first_name
      last_name
    }
  }
`

const GET_ORDERS = gql`
  query getOrders($customerId: ID) {
    getOrders(customerId: $customerId) {
      _id
      invoice_number
      agent {
        _id
        first_name
        last_name
      }
      store {
        _id
        name
      }
      order_status
      created_date
      customer {
        _id
        first_name
        last_name
        title
        customer_type
      }
      release_date
      total_retail
      to_pay
      grand_total
      deposit_date
      weekly_payment
    }
  }
`

export default function CustomerOrders({
  match: {
    params: { customerId },
  },
}) {
  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
  } = useQuery(GET_CUSTOMER, {
    variables: { customerId: customerId },
  })

  const {
    loading: ordersLoading,
    error: ordersError,
    data: ordersData,
  } = useQuery(GET_ORDERS, {
    variables: { customerId: customerId },
  })

  if (customerLoading || ordersLoading) return <Loading />

  if (customerError || ordersError) return <Error />

  const customer = customerData.getCustomer
  const orders = ordersData.getOrders

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h4" gutterBottom>
          {`Orders - ${customer.first_name} ${customer.last_name}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OrdersTable orders={orders} />
      </Grid>
    </Grid>
  )
}
