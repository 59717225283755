import React, { Fragment, useState } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Divider,
} from '@material-ui/core'

import { Search } from '@material-ui/icons'

import ParkedOrdersTable from './ParkedOrdersTable'

import Dropdown from '../../reusable/Dropdown'
import Loading from '../../reusable/Loading'
import { openSnackbar } from '../../reusable/Notifier'

const GET_PARKED_ORDER_AGENTS = gql`
  query getParkedOrderAgents {
    getParkedOrderAgents {
      _id
      first_name
      last_name
    }
  }
`

const GET_PARKED_ORDERS = gql`
  query parkedOrders($agentId: String) {
    parkedOrders(agentId: $agentId) {
      _id
      time_started
      agent {
        _id
        first_name
        last_name
      }
      status
      first_name
      last_name
      payment_start_time
      locked
    }
  }
`

export default function ParkedOrdersSearch() {
  const [selectedAgent, setSelectedAgent] = useState(null)
  const [selectedAgentId, setSelectedAgentId] = useState(null)
  const [agentInputError, setAgentInputError] = useState(null)

  const {
    loading: parkedOrderAgentsLoading,
    error: parkedOrderAgentsError,
    data: parkedOrderAgentsData,
  } = useQuery(GET_PARKED_ORDER_AGENTS)

  const [
    getParkedOrders,
    {
      loading: parkedOrdersLoading,
      error: parkedOrdersError,
      data: parkedOrdersData,
    },
  ] = useLazyQuery(GET_PARKED_ORDERS, {
    onError: () => {
      openSnackbar({
        message: 'Failed to find parked orders, please try again.',
        type: 'error',
      })
    },
  })

  let searchView

  if (parkedOrderAgentsData) {
    const formattedParkedOrderAgents = parkedOrderAgentsData.getParkedOrderAgents.map(
      (agent) => ({
        value: `${agent._id} ~ ${agent.first_name} ${agent.last_name}`,
        label: `${agent.first_name} ${agent.last_name}`,
      })
    )

    searchView = (
      <Fragment>
        <Grid item xs={12} md={6} lg={3}>
          <Dropdown
            input={{
              onChange: (value) => {
                setAgentInputError(null)

                if (value && value.includes('~')) {
                  const valueSplit = value.split('~')
                  const selectedId = valueSplit[0].trim()
                  const agentName = valueSplit[1].trim()

                  setSelectedAgent(agentName)
                  setSelectedAgentId(selectedId)
                } else {
                  setSelectedAgent(value)
                  setSelectedAgentId(null)
                }
              },
              value: selectedAgent,
              name: 'Agent',
            }}
            name="Agent"
            margin="dense"
            items={formattedParkedOrderAgents}
            placeholder="Search for an agent"
            label="Agent"
            required
            error={!!agentInputError}
            helperText={!!agentInputError ? agentInputError : ''}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              if (selectedAgentId) {
                getParkedOrders({ variables: { agentId: selectedAgentId } })
              } else {
                setAgentInputError('Please enter an agent')
              }
            }}
          >
            <Search style={{ marginRight: '10px' }} />
            Search
          </Button>
        </Grid>
      </Fragment>
    )
  } else if (parkedOrderAgentsError) {
    searchView = (
      <Typography variant="body1">
        Failed to load agents, please refresh the page.
      </Typography>
    )
  }

  let view = null

  if (parkedOrdersError) {
    view = (
      <Grid item xs={12}>
        <Typography variant="body1">
          Failed to load parked orders, please try again
        </Typography>
      </Grid>
    )
  } else if (parkedOrdersLoading) {
    view = (
      <Grid item xs={12}>
        <Loading />
      </Grid>
    )
  } else if (parkedOrdersData) {
    view = (
      <Fragment>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ParkedOrdersTable parkedOrders={parkedOrdersData.parkedOrders} />
        </Grid>
      </Fragment>
    )
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Parked Orders
            </Typography>
            <Typography variant="body2" style={{ color: 'grey' }}>
              Search to view an agent's parked orders
            </Typography>
          </Grid>

          {parkedOrderAgentsLoading ? (
            <Grid item xs={12}>
              <Loading />
            </Grid>
          ) : (
            <Fragment>
              {searchView}
              {view}
            </Fragment>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
