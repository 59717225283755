import React from 'react'
import Downshift from 'downshift'
import matchSorter from 'match-sorter'

import { withStyles } from '@material-ui/core/styles'
import { TextField, Paper, MenuItem } from '@material-ui/core'

const itemToString = (item) => (item ? item : '')

const Dropdown = ({
  input,
  placeholder,
  classes,
  items,
  onChange,
  disabled,
  label,
  required,
  error,
  helperText,
  margin,
}) => (
  <div className={classes.root}>
    <Downshift
      {...input}
      onInputValueChange={(inputValue) => {
        input.onChange(inputValue)

        if (onChange) {
          onChange(inputValue)
        }
      }}
      itemToString={itemToString}
      selectedItem={input.value}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        selectedItem,
        getMenuProps,
        openMenu,
      }) => {
        const filteredItems = matchSorter(items, inputValue, {
          keys: ['label'],
          maxRanking: matchSorter.rankings.STARTS_WITH,
        })
        return (
          <div className={classes.container}>
            <TextField
              required={required}
              disabled={disabled}
              onClick={() => {
                openMenu()
              }}
              InputProps={getInputProps({
                name: input.name,
                placeholder: placeholder,
                classes: {
                  root: classes.inputRoot,
                  input: classes.inputInput,
                },
              })}
              fullWidth
              label={label}
              variant="outlined"
              margin={margin}
              error={error}
              helperText={helperText}
            />
            <div {...getMenuProps()}>
              {isOpen && !!filteredItems.length && (
                <Paper className={classes.paper} square>
                  {filteredItems.map(({ value, label }, index) => (
                    <MenuItem
                      {...getItemProps({
                        key: value,
                        index,
                        item: value,
                        style: {
                          fontWeight:
                            (selectedItem || '').indexOf(label) > -1
                              ? 500
                              : 400,
                        },
                      })}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Paper>
              )}
            </div>
          </div>
        )
      }}
    </Downshift>
  </div>
)

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
  },
  paper: {
    position: 'absolute',
    zIndex: 4,
    marginTop: theme.spacing(1),
    maxHeight: '300px',
    overflowY: 'auto',
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
})

export default withStyles(styles)(Dropdown)
