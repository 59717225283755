import gql from 'graphql-tag'

export const GET_PRODUCTS = gql`
  query products {
    products {
      _id
      name
      type
      archived
    }
  }
`

export const GET_PRODUCT = gql`
  query product($name: String!) {
    product(name: $name) {
      _id
      name
      type
      archived
      colours {
        code
        name
      }
      sizes {
        dimension
        price
        code
        delivery_charge
        fitting_charge
      }
    }
  }
`

export const GET_ROOMS = gql`
  query rooms {
    rooms {
      _id
      name
      stair_charge
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation(
    $colours: String!
    $_id: String!
    $name: String!
    $sizes: String!
    $type: String!
  ) {
    updateProduct(
      colours: $colours
      _id: $_id
      name: $name
      sizes: $sizes
      type: $type
    ) {
      error {
        message
      }
      product {
        _id
        name
        type
        archived
        colours {
          code
          name
        }
        sizes {
          dimension
          price
          code
          delivery_charge
          fitting_charge
        }
      }
    }
  }
`

export const ARCHIVE_PRODUCT = gql`
  mutation($_id: String!) {
    archiveProduct(_id: $_id) {
      error {
        message
      }
      product {
        _id
        name
        type
        archived
        colours {
          code
          name
        }
        sizes {
          dimension
          price
          code
          delivery_charge
          fitting_charge
        }
      }
    }
  }
`

export const ADD_PRODUCT = gql`
  mutation($colours: String!, $name: String!, $sizes: String!, $type: String!) {
    addProduct(colours: $colours, name: $name, sizes: $sizes, type: $type) {
      error {
        message
      }
      product {
        _id
        name
        type
        archived
        colours {
          code
          name
        }
        sizes {
          dimension
          price
          code
          delivery_charge
          fitting_charge
        }
      }
    }
  }
`
