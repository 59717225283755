import React from 'react'

import UnsecureRoute from '../components/util/UnsecureRoute'

import Login from '../components/pages/Login/Login'
import ResetPassword from '../components/pages/Login/ResetPassword'
import ForgottenPassword from '../components/pages/Login/ForgottenPassword'

const LoginRoutes = [
  <UnsecureRoute path="/login" component={Login} title="Login" />,
  <UnsecureRoute
    path="/forgotten-password"
    component={ForgottenPassword}
    title="Forgotten Password"
  />,
  <UnsecureRoute
    path="/reset-password/:reset_token"
    component={ResetPassword}
    title="Reset Password"
  />,
]

export default LoginRoutes
