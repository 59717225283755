import React, { useState, Fragment, useContext, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Mutation } from "@apollo/react-components";
import { Helmet } from "react-helmet-async";

import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  Tooltip,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";
import { Save } from "@material-ui/icons";
import { Field, Form } from "react-final-form";

import OrderSummary from "./Tabs/OrderSummary";
import OrderActivity from "./Tabs/OrderActivity";
import Items from "./Tabs/Items";
import CustomerDetails from "./Tabs/CustomerDetails";
import NotesFiles from "./Tabs/NotesFiles";
import PaymentsTable from "./Tabs/PaymentsTable";
import FittingDelivery from "./Tabs/FittingDelivery";
import IncreasesDiscounts from "./Tabs/IncreasesDiscounts";
import PaymentPlan from "./Tabs/PaymentPlan";

import RemoveCancellationPopup from "../../components/pages/CancelOrder/RemoveCancellationPopup";
import { openModal } from "./../../components/reusable/Popup";
import { UserContext } from "../../components/util/PageWrapper";
import { openSnackbar } from "../../components/reusable/Notifier";

const StyledTabs = styled.div`
  display: flex;
  align-items: flex-start;
  .tabs-wrapper {
    background-color: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    .tab {
      padding: 8px 12px 8px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      border-right: 4px solid transparent;
      cursor: pointer;
      transition: all ease-in-out 150ms;
      &:last-of-type {
        border-bottom: none;
      }
      &.active,
      &:hover {
        border-right: 4px solid rgb(243, 146, 0);
      }
    }
  }
  .content-area {
    background-color: white;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    padding: 24px;
  }
`;

const tabs = [
  "Order Summary",
  "Increases/Discounts",
  "Fitting/Delivery",
  "Customer Details",
  "Items",
  "Payment Plan",
  "Order Activity",
  "Payments",
  "Notes/Files",
];

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      order_status
      invoice_number
      total_paid
      to_pay
      order_status
      deposit
      cancellation {
        _id
        requested_by {
          _id
        }
      }
    }
  }
`;

const GET_PAYMENTS = gql`
  query getPayments($orderId: ID!) {
    getPayments(orderId: $orderId) {
      _id
      payment_number
      time
      timezone
      approval_code
      status
      invoice_number
      amount
      transaction_id
      reference_number
      payment_type
      card_number
      refunded
      cancelled_due_to_restructure
      customer_paid
    }
  }
`;

const UPDATE_TOTAL_OUTSTANDING = gql`
  mutation updateTotalOutstanding($orderId: ID!, $to_pay: String!) {
    updateTotalOutstanding(orderId: $orderId, to_pay: $to_pay) {
      _id
    }
  }
`;

const UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatus($orderId: ID!, $order_status: String!) {
    updateOrderStatus(orderId: $orderId, order_status: $order_status) {
      _id
    }
  }
`;

const COMPLETE_ORDER = gql`
  mutation completeOrder($orderId: ID!) {
    completeOrder(orderId: $orderId) {
      _id
    }
  }
`;

const MARK_DEPOSIT_PAID = gql`
  mutation markDepositPaid($orderId: ID!) {
    markDepositPaid(orderId: $orderId) {
      _id
    }
  }
`;

export default function SingleOrder({
  match: {
    params: { invoiceNumber },
  },
  history,
}) {
  const [currentTab, setCurrentTab] = useState("Order Summary");

  const user = useContext(UserContext);

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
    fetchPolicy: "network-only",
  });

  const {
    data: paymentsData,
    loading: paymentsLoading,
    error: paymentsError,
  } = useQuery(GET_PAYMENTS, {
    variables: {
      orderId: data?.getOrder?._id,
    },
    fetchPolicy: "network-only",
    skip: !data?.getOrder?._id,
  });

  // get payments

  if (loading || paymentsLoading) return <Loading />;

  if (error || paymentsError)
    return <ErrorMessage error={error || paymentsError} />;

  const { getOrder: order } = data;
  let { getPayments: payments } = paymentsData;

  //calculate to pay value

  // console.log("order", order);

  let toPay = order.to_pay;

  if (order.order_status === "Awaiting Deposit") {
    toPay = parseFloat(toPay) + parseFloat(order.deposit);
  }

  let tabView;

  switch (currentTab) {
    case "Order Summary":
    default:
      tabView = <OrderSummary />;
      break;
    case "Order Activity":
      tabView = <OrderActivity />;
      break;
    case "Items":
      tabView = <Items />;
      break;
    case "Customer Details":
      tabView = <CustomerDetails />;
      break;
    case "Notes/Files":
      tabView = <NotesFiles />;
      break;
    case "Payments":
      tabView = <PaymentsTable />;
      break;
    case "Fitting/Delivery":
      tabView = <FittingDelivery />;
      break;
    case "Increases/Discounts":
      tabView = <IncreasesDiscounts />;
      break;
    case "Payment Plan":
      tabView = <PaymentPlan />;
      break;
  }

  //payment information

  payments = payments
    .filter((payment) => payment.invoice_number !== null)
    .sort((a, b) => new Date(a.time) - new Date(b.time));

  //calculate total paid

  const approvedPayments = payments.filter(
    (payment) =>
      payment.payment_type !== "Refund" &&
      (payment.status === "APPROVED" || payment.status === "COLLECTED") &&
      !payment.refunded
  );

  let approvedPaymentNumbers = approvedPayments.filter(
    (payment) => !!payment.payment_number
  );

  approvedPaymentNumbers = approvedPaymentNumbers.map((payment) => {
    return payment.payment_number;
  });

  //calculate failed scheduled payments

  const failedPayments = payments.filter(
    (payment) =>
      payment.payment_type === "Scheduled Payment" &&
      payment.status === "FAILED" &&
      !payment.cancelled_due_to_restructure
  );

  //we now need to remove any manual retries
  for (let i = 0; i < payments.length; i++) {
    let payment = payments[i];
    if (
      payment.payment_type === "Manual Retry" &&
      payment.status === "APPROVED"
    ) {
      //remove this from the set of failed payments
      for (let j = 0; j < failedPayments.length; j++) {
        let failedPayment = failedPayments[j];
        if (failedPayment.payment_number === payment.payment_number) {
          failedPayments.splice(j, 1);
          break;
        }
      }
    }
  }

  const futurePayments = payments.filter(
    (payment) =>
      payment.payment_type === "Scheduled Payment" &&
      (payment.status === "PENDING" || payment.status === "ON HOLD")
  );

  const futurePaymentsNumbers = futurePayments.map(
    (payment) => payment.payment_number
  );

  const arrearsPayments = failedPayments.filter(
    (payment) =>
      !approvedPaymentNumbers.includes(payment.payment_number) &&
      !futurePaymentsNumbers.includes(payment.payment_number)
  );

  const arrearsPaymentsAmounts = arrearsPayments.map((payment) =>
    parseFloat(payment.amount)
  );

  let totalArrears = arrearsPaymentsAmounts.reduce((a, b) => a + b, 0);

  //remove one off payments from this number

  const successfulOneOffPayments = payments.filter(
    (payment) =>
      payment.payment_type === "One-Off Payment" &&
      payment.status === "APPROVED"
  );

  const oneOffAmounts = successfulOneOffPayments.map((payment) =>
    parseFloat(payment.amount)
  );

  if (successfulOneOffPayments.length) {
    const totalOneOff = oneOffAmounts.reduce((total, num) => {
      return total + num;
    });

    totalArrears -= totalOneOff;

    if (totalArrears < 0) {
      totalArrears = 0;
    }
  }

  const outstandingPayments = payments.filter(
    (payment) =>
      (payment.status === "PENDING" || payment.status === "ON HOLD") &&
      payment.payment_type !== "One-Off Payment" &&
      payment.payment_type !== "Manual Retry"
  );

  return (
    <>
      <Helmet>
        <title>Order - {invoiceNumber}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Typography component="h1" variant="h4">
            Order - {invoiceNumber}
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {user.role_id > 4 && order.order_status !== "Complete" && (
            <Mutation
              mutation={COMPLETE_ORDER}
              refetchQueries={[
                {
                  query: GET_ORDER,
                  variables: { invoiceNumber: parseInt(invoiceNumber) },
                },
              ]}
            >
              {(completeOrder) => (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "24px",
                  }}
                  onClick={() =>
                    completeOrder({
                      variables: {
                        orderId: order._id,
                      },
                    }).then(({ data }) => {
                      const { completeOrder } = data;

                      if (completeOrder.error) {
                        openSnackbar({
                          message: completeOrder.error.message,
                          type: "error",
                        });
                      } else {
                        openSnackbar({
                          message: "Order completed successfully",
                          type: "success",
                        });
                      }
                    })
                  }
                >
                  Complete Order
                </Button>
              )}
            </Mutation>
          )}

          {user.role_id > 4 && order.order_status === "Awaiting Deposit" && (
            <Mutation
              mutation={MARK_DEPOSIT_PAID}
              refetchQueries={[
                {
                  query: GET_ORDER,
                  variables: { invoiceNumber: parseInt(invoiceNumber) },
                },
              ]}
            >
              {(markDepositPaid) => (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "24px",
                  }}
                  onClick={() =>
                    markDepositPaid({
                      variables: {
                        orderId: order._id,
                      },
                    }).then(({ data }) => {
                      const { markDepositPaid } = data;

                      if (markDepositPaid.error) {
                        openSnackbar({
                          message: markDepositPaid.error.message,
                          type: "error",
                        });
                      } else {
                        openSnackbar({
                          message: "Order deposit marked as paid",
                          type: "success",
                        });
                      }
                    })
                  }
                >
                  Mark Deposit As Paid
                </Button>
              )}
            </Mutation>
          )}

          {user.role_id > 4 && order.order_status === "Cancelled" && (
            <Mutation
              mutation={UPDATE_ORDER_STATUS}
              refetchQueries={[
                {
                  query: GET_ORDER,
                  variables: { invoiceNumber: parseInt(invoiceNumber) },
                },
              ]}
            >
              {(updateOrderStatus) => (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "24px",
                  }}
                  onClick={() =>
                    updateOrderStatus({
                      variables: {
                        orderId: order._id,
                        order_status: "Active",
                      },
                    }).then(({ data }) => {
                      const { updateOrderStatus } = data;

                      if (updateOrderStatus.error) {
                        openSnackbar({
                          message: updateOrderStatus.error.message,
                          type: "error",
                        });
                      } else {
                        openSnackbar({
                          message: "Order uncancelled successfully",
                          type: "success",
                        });
                      }
                    })
                  }
                >
                  Uncancel Order
                </Button>
              )}
            </Mutation>
          )}

          {order.order_status !== "Cancelled" &&
            order.order_status !== "Complete" && (
              <Fragment>
                {((order.cancellation &&
                  order.cancellation.requested_by._id === user._id) ||
                  (order.cancellation && user.can_cancel)) && (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      marginRight: "24px",
                    }}
                    onClick={() => {
                      openModal({
                        content: (
                          <RemoveCancellationPopup
                            cancellationId={order.cancellation._id}
                            history={history}
                          />
                        ),
                      });
                    }}
                  >
                    Abort Cancellation
                  </Button>
                )}

                {!!order.cancellation ? (
                  <Tooltip
                    title="A cancellation has already been requested"
                    placement="top"
                  >
                    <div>
                      <Button
                        variant="contained"
                        component={Link}
                        to={"/cancel-order/" + order.invoice_number}
                        disabled
                        style={{
                          marginLeft: "24px",
                        }}
                      >
                        Cancel Order
                      </Button>
                    </div>
                  </Tooltip>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "24px",
                    }}
                    component={Link}
                    to={"/cancel-order/" + order.invoice_number}
                  >
                    Cancel Order
                  </Button>
                )}
              </Fragment>
            )}
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            disabled
            value={order.total_paid.toFixed(2)}
            label="Total Paid"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          {user.role_id > 4 ? (
            <Mutation mutation={UPDATE_TOTAL_OUTSTANDING}>
              {(updateTotalOutstanding) => (
                <Form
                  initialValues={{ to_pay: toPay.toFixed(2) }}
                  onSubmit={(values) => {
                    updateTotalOutstanding({
                      variables: {
                        orderId: order._id,
                        to_pay: values.to_pay,
                      },
                    }).then(({ data }) => {
                      const { updateTotalOutstanding } = data;

                      if (updateTotalOutstanding.error) {
                        openSnackbar({
                          message: updateTotalOutstanding.error.message,
                          type: "error",
                        });
                      } else {
                        openSnackbar({
                          message: "Total Outstanding updated successfully",
                          type: "success",
                        });
                      }
                    });
                  }}
                  render={({
                    handleSubmit,
                    pristine,
                    submitting,
                    dirtySinceLastSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <Field name="to_pay">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            name="to_pay"
                            label="Total Outstanding"
                            fullWidth
                            required
                            variant="outlined"
                            error={
                              (meta.error && meta.touched) ||
                              (!!meta.submitError &&
                                !dirtySinceLastSubmit &&
                                !submitting)
                            }
                            helperText={
                              meta.error && meta.touched
                                ? meta.error
                                : !!meta.submitError &&
                                  !dirtySinceLastSubmit &&
                                  !submitting
                                ? meta.submitError
                                : ""
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Field>
                      {!pristine && !submitting && (
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          icon={<Save />}
                          style={{ marginTop: "15px" }}
                        >
                          Save
                        </Button>
                      )}
                    </form>
                  )}
                />
              )}
            </Mutation>
          ) : (
            <TextField
              disabled
              value={toPay.toFixed(2)}
              label="Total Outstanding"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            disabled
            value={totalArrears.toFixed(2)}
            label="Total Arrears"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            disabled
            value={outstandingPayments.length}
            label="Payments till Completion"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTabs>
            <div className="tabs-wrapper">
              {tabs.map((tabName) => (
                <div
                  key={tabName}
                  onClick={() => {
                    setCurrentTab(tabName);
                  }}
                  className={currentTab === tabName ? "tab active" : "tab"}
                >
                  <Typography component="h3" variant="subtitle1">
                    {tabName}
                  </Typography>
                </div>
              ))}
            </div>
            <div className="content-area">{tabView}</div>
          </StyledTabs>
        </Grid>
      </Grid>
    </>
  );
}
