import React, { Fragment, useContext } from "react";
import { Query, Mutation } from "@apollo/react-components";
import gql from "graphql-tag";
import { Form, Field } from "react-final-form";

import {
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  IconButton,
  Tooltip,
  CardHeader,
} from "@material-ui/core";

import { Save, Clear, Add } from "@material-ui/icons";

import { GET_CUSTOMER_VISIT_OUTCOMES } from "./../../../helpers/queries/CustomerVisitOutcome";
import { openSnackbar } from "./../../reusable/Notifier";
import { openModal, closeModal } from "./../../reusable/Popup";
import UIListEntryManager from "./UIListEntryManager";
import { UserContext } from "./../../util/PageWrapper";

export default function UIListSettings() {
  const user = useContext(UserContext);
  return (
    <div>
      <Grid container spacing={3} style={{ marginBottom: "1em" }}>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Typography component="h1" variant="h4" gutterBottom>
            Customer Visit Outcomes
          </Typography>
          <Button
            variant="contained"
            style={{
              marginLeft: "auto",
            }}
            color="secondary"
            onClick={() => {
              openModal({
                content: (
                  <Card>
                    <CardHeader
                      action={
                        <IconButton
                          aria-label="Close Pop-up"
                          onClick={closeModal}
                        >
                          <Clear />
                        </IconButton>
                      }
                      title="Add Outcome"
                    />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Mutation
                          mutation={gql`
                            mutation ($name: String!, $value: String!) {
                              newCustomerVisitOutcome(
                                name: $name
                                value: $value
                              ) {
                                error {
                                  message
                                }
                                outcome {
                                  _id
                                  name
                                  value
                                }
                              }
                            }
                          `}
                          refetchQueries={[
                            {
                              query: GET_CUSTOMER_VISIT_OUTCOMES,
                            },
                          ]}
                        >
                          {(newCustomerVisitOutcome) => (
                            <Form
                              onSubmit={(values) => {
                                newCustomerVisitOutcome({
                                  variables: {
                                    name: values.name,
                                    value: values.value,
                                  },
                                }).then(({ data }) => {
                                  const { newCustomerVisitOutcome } = data;

                                  if (newCustomerVisitOutcome.error) {
                                    openSnackbar({
                                      message:
                                        newCustomerVisitOutcome.error.message,
                                      type: "error",
                                    });
                                  } else {
                                    openSnackbar({
                                      message: "Outcome updated successfully",
                                      type: "success",
                                    });
                                    closeModal();
                                  }
                                });
                              }}
                              render={({
                                handleSubmit,
                                pristine,
                                submitting,
                              }) => (
                                <Grid item xs={12}>
                                  <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                  >
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems="center"
                                    >
                                      <Grid item xs={12} md={4}>
                                        <Field name="name">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Outcome Name"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="value">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Outcome Value"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={3}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          type="submit"
                                          fullWidth
                                          disabled={pristine || submitting}
                                        >
                                          <Save
                                            style={{ marginRight: "10px" }}
                                          />
                                          Save
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </form>
                                </Grid>
                              )}
                            />
                          )}
                        </Mutation>
                      </Grid>
                    </CardContent>
                  </Card>
                ),
              });
            }}
          >
            <Add style={{ marginRight: "10px" }} />
            Add Outcome
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={3} alignItems="center">
                <Query query={GET_CUSTOMER_VISIT_OUTCOMES}>
                  {({ loading, error, data }) => {
                    if (loading || error) {
                      return null;
                    }

                    let { customerVisitOutcomes } = data;

                    return (
                      <Fragment>
                        {customerVisitOutcomes.map((outcome) => (
                          <Mutation
                            key={outcome._id}
                            mutation={gql`
                              mutation (
                                $_id: String!
                                $name: String!
                                $value: String!
                              ) {
                                updateCustomerVisitOutcome(
                                  _id: $_id
                                  name: $name
                                  value: $value
                                ) {
                                  error {
                                    message
                                  }
                                  outcome {
                                    _id
                                    name
                                    value
                                  }
                                }
                              }
                            `}
                            refetchQueries={[
                              {
                                query: GET_CUSTOMER_VISIT_OUTCOMES,
                              },
                            ]}
                          >
                            {(updateOutcome) => (
                              <Form
                                initialValues={{
                                  name: outcome.name,
                                  value: outcome.value,
                                }}
                                onSubmit={(values) => {
                                  updateOutcome({
                                    variables: {
                                      _id: outcome._id,
                                      name: values.name,
                                      value: values.value,
                                    },
                                  }).then(({ data }) => {
                                    const { updateCustomerVisitOutcome } = data;

                                    if (updateCustomerVisitOutcome.error) {
                                      openSnackbar({
                                        message:
                                          updateCustomerVisitOutcome.error
                                            .message,
                                        type: "error",
                                      });
                                    } else {
                                      openSnackbar({
                                        message: "Outcome updated successfully",
                                        type: "success",
                                      });
                                    }
                                  });
                                }}
                                render={({
                                  handleSubmit,
                                  pristine,
                                  submitting,
                                }) => (
                                  <Grid item xs={12}>
                                    <form
                                      onSubmit={handleSubmit}
                                      autoComplete="off"
                                    >
                                      <Grid
                                        container
                                        spacing={3}
                                        alignItems="center"
                                      >
                                        <Grid item xs={12} md={1}>
                                          <Mutation
                                            mutation={gql`
                                              mutation ($_id: String!) {
                                                deleteCustomerVisitOutcome(
                                                  _id: $_id
                                                ) {
                                                  error {
                                                    message
                                                  }
                                                  outcome {
                                                    _id
                                                    name
                                                    value
                                                  }
                                                }
                                              }
                                            `}
                                            refetchQueries={[
                                              {
                                                query:
                                                  GET_CUSTOMER_VISIT_OUTCOMES,
                                              },
                                            ]}
                                          >
                                            {(deleteOutcome) => (
                                              <Tooltip
                                                title="Delete Outcome"
                                                placement="top"
                                              >
                                                <IconButton
                                                  aria-label="Delete Outcome"
                                                  onClick={() => {
                                                    deleteOutcome({
                                                      variables: {
                                                        _id: outcome._id,
                                                      },
                                                    }).then(({ data }) => {
                                                      const {
                                                        deleteCustomerVisitOutcome,
                                                      } = data;

                                                      if (
                                                        deleteCustomerVisitOutcome.error
                                                      ) {
                                                        openSnackbar({
                                                          message:
                                                            deleteCustomerVisitOutcome
                                                              .error.message,
                                                          type: "error",
                                                        });
                                                      } else {
                                                        openSnackbar({
                                                          message:
                                                            "Outcome deleted successfully",
                                                          type: "success",
                                                        });
                                                      }
                                                    });
                                                  }}
                                                >
                                                  <Clear />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          </Mutation>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="name">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Outcome Name"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="value">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Outcome Value"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            fullWidth
                                            disabled={pristine || submitting}
                                          >
                                            <Save
                                              style={{ marginRight: "10px" }}
                                            />
                                            Save
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </form>
                                  </Grid>
                                )}
                              />
                            )}
                          </Mutation>
                        ))}
                      </Fragment>
                    );
                  }}
                </Query>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <UIListEntryManager
          title="Discount/Increase Reason Codes"
          type="discountsincreases"
        />

        {user.role_id > 4 && (
          <UIListEntryManager
            title="Payments Download Allowed"
            type="paymentsdownloadallowed"
          />
        )}

        {user.role_id > 4 && (
          <UIListEntryManager title="Product Types" type="producttypes" />
        )}
      </Grid>
    </div>
  );
}
