import React from 'react'

import AuthenticatedRoute from '../components/util/AuthenticatedRoute'

import Customers from '../components/pages/Customers/Customers'
import EditCustomer from '../components/pages/Customers/EditCustomer'
import NewCustomer from '../components/pages/Customers/NewCustomer'
import CustomerOrders from '../components/pages/Customers/CustomerOrders'

const CustomerRoutes = [
  <AuthenticatedRoute
    path="/customers"
    exact
    component={Customers}
    title="Customers"
  />,
  <AuthenticatedRoute
    path="/customers/edit/:customerId"
    exact
    component={EditCustomer}
    title="Edit Customer"
  />,
  <AuthenticatedRoute
    path="/customers/add"
    exact
    component={NewCustomer}
    title="New Customer"
  />,
  <AuthenticatedRoute
    path="/customers/orders/:customerId"
    exact
    component={CustomerOrders}
    title="Customer Orders"
  />,
]

export default CustomerRoutes
