import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import gql from 'graphql-tag'
import { closeModal } from '../../reusable/Popup'
import { openSnackbar } from '../../reusable/Notifier'

import {
  Typography,
  Card,
  CardContent,
  Button,
  CardHeader,
  CardActions,
} from '@material-ui/core'

const DELETE_PARKED_ORDER = gql`
  mutation($parkedOrderId: String!) {
    deleteParkedOrder(parkedOrderId: $parkedOrderId)
  }
`

export default function DeleteParkedOrderPopup({ parkedOrderId }) {
  const [deleteParkedOrder] = useMutation(DELETE_PARKED_ORDER, {
    onCompleted: () => {
      openSnackbar({
        message: 'Parked order deleted successfully',
        type: 'success',
      })
      closeModal()
    },
    onError: () => {
      openSnackbar({
        message: 'Failed to delete parked order, please try again.',
        type: 'error',
      })
      closeModal()
    },
  })

  return (
    <Card>
      <CardHeader title="Are You Sure?" />
      <CardContent>
        <Typography variant="body1" gutterBottom>
          This cannot be undone, if you wish to return to this order in the
          future you will have to start from the begining.
        </Typography>
      </CardContent>
      <CardActions
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            closeModal()
          }}
        >
          No
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            deleteParkedOrder({
              variables: {
                parkedOrderId: parkedOrderId,
              },
            })
          }}
        >
          Yes
        </Button>
      </CardActions>
    </Card>
  )
}
