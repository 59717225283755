import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Mutation } from '@apollo/react-components'
import Dropzone from 'react-dropzone'
import {
  Loading,
  ErrorMessage,
} from '@web-applications/daffodil-component-library'
import {
  Typography,
  Grid,
  Divider,
  IconButton,
  Button,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'

import { UserContext } from '../../../components/util/PageWrapper'
import { openSnackbar } from '../../../components/reusable/Notifier'

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      fitting_delivery_notes
      files {
        _id
        name
      }
    }
  }
`

export default function NotesFiles() {
  const { invoiceNumber } = useParams()

  const user = useContext(UserContext)

  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  })

  if (orderLoading) return <Loading />

  if (orderError) return <ErrorMessage error={orderError} />

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" gutterBottom>
            Notes/Files
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {!!orderData.getOrder.fitting_delivery_notes ? (
            <Typography variant="body1" gutterBottom>
              {orderData.getOrder.fitting_delivery_notes}
            </Typography>
          ) : (
            <Typography variant="body1" gutterBottom>
              There are no additional notes for this order
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Files</Typography>
          <Mutation
            mutation={gql`
              mutation (
                $file: Upload!
                $order_id: String!
                $agent_id: String!
              ) {
                uploadOrderFile(
                  file: $file
                  order_id: $order_id
                  agent_id: $agent_id
                ) {
                  file {
                    _id
                    name
                  }
                  error {
                    message
                  }
                }
              }
            `}
            refetchQueries={[
              {
                query: GET_ORDER,
                variables: {
                  invoice_number: parseInt(invoiceNumber),
                },
              },
            ]}
          >
            {(uploadOrderFile) => (
              <Dropzone
                onDrop={(files) => {
                  files.forEach((file) => {
                    let newFile = true

                    if (orderData?.getOrder?.files?.length) {
                      orderData.getOrder.files.forEach((existingFile) => {
                        if (existingFile.name === file.name) {
                          newFile = false
                        }
                      })
                    }

                    if (newFile) {
                      uploadOrderFile({
                        variables: {
                          file: file,
                          order_id: orderData.getOrder._id,
                          agent_id: user._id,
                        },
                      }).then(({ data }) => {
                        if (data.uploadOrderFile.error) {
                          openSnackbar({
                            message: 'Failed to upload file, please try again',
                            type: 'error',
                          })
                        } else {
                          openSnackbar({
                            message: 'File uploaded successfully',
                            type: 'success',
                          })
                        }
                      })
                    }
                  })
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <IconButton color="primary" aria-label="Add File">
                      <Add />
                    </IconButton>
                  </div>
                )}
              </Dropzone>
            )}
          </Mutation>
        </Grid>
        {!!orderData?.getOrder?.files?.length && (
          <>
            {orderData.getOrder.files.map((file) => (
              <Grid item xs={12} md={6} key={file._id}>
                <Button
                  href={`${process.env.REACT_APP_API_URL}/file/${file._id}`}
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                  variant="ghost"
                >
                  {file.name}
                </Button>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  )
}
