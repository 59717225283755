import React from 'react'
import styled from 'styled-components'

const StyledCircle = styled.div`
  border-radius: 50%;
  border: solid #d8d8d8 1px;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    object-position: center;
  }
`
export default function CircleIcon({ src, alt, ...rest }) {
  return (
    <StyledCircle {...rest}>
      <img src={src} alt={alt} />
    </StyledCircle>
  )
}
