import React, { Fragment } from "react";
import { Form, Field } from "react-final-form";
import moment from "moment";
import createDecorator from "final-form-calculate";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { DatePicker } from "@material-ui/pickers";
import { useParams } from "react-router-dom";

import { openSnackbar } from "../../../../components/reusable/Notifier";

import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  MenuItem,
  Button,
  TextField,
  IconButton,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import {
  Save,
  Clear,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import Loading from "../../../../components/reusable/Loading";
import Error from "../../../../newComponents/Error";

const GET_SETTINGS = gql`
  query settings {
    settings {
      _id
      monthly_min_payments
      monthly_max_payments

      weekly_min_payments
      weekly_max_payments

      fortnightly_min_payments
      fortnightly_max_payments

      fourweekly_min_payments
      fourweekly_max_payments

      weekly_payment_amounts
      monthly_payment_amounts
      fortnightly_payment_amounts
      fourweekly_payment_amounts
    }
  }
`;

const RESTRUCTURE_PAYMENT_SCHEDULE = gql`
  mutation (
    $orderId: String!
    $weeklyPayment: Int!
    $nextPaymentDate: String!
    $orderType: String!
  ) {
    restructurePaymentSchedule(
      orderId: $orderId
      weeklyPayment: $weeklyPayment
      nextPaymentDate: $nextPaymentDate
      orderType: $orderType
    ) {
      _id
      weekly_payment
    }
  }
`;

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      to_pay
    }
  }
`;

const GET_PAYMENTS = gql`
  query getPayments($orderId: ID!) {
    getPayments(orderId: $orderId) {
      _id
      status
      payment_type
    }
  }
`;

export default function RestructurePaymentSchedule({ hideModal }) {
  const { invoiceNumber } = useParams();

  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  });

  const {
    data: paymentsData,
    loading: paymentsLoading,
    error: paymentsError,
  } = useQuery(GET_PAYMENTS, {
    variables: {
      orderId: orderData?.getOrder?._id,
    },
    skip: !orderData?.getOrder?._id,
  });

  const {
    data: settingsData,
    loading: settingsLoading,
    error: settingsError,
  } = useQuery(GET_SETTINGS);

  const [restructurePaymentSchedule, { loading: restructureLoading }] =
    useMutation(RESTRUCTURE_PAYMENT_SCHEDULE, {
      onError: () => {
        hideModal();
        openSnackbar({
          message: "Failed to update payment schedule",
          type: "error",
        });
      },
      onCompleted: () => {
        hideModal();
        openSnackbar({
          message: "Payment schedule updated successfully",
          type: "success",
        });
      },
    });

  if (orderLoading || settingsLoading || paymentsLoading) return <Loading />;

  if (orderError || settingsError || paymentsError) return <Error />;

  const { settings } = settingsData;
  const order = orderData.getOrder;
  const payments = paymentsData.getPayments;

  const depositDatePayments = payments.filter((payment) => {
    return (
      (payment.payment_type === "Card Deposit" ||
        payment.payment_type === "Cash Deposit") &&
      (payment.status === "APPROVED" ||
        payment.status === "COLLECTED" ||
        payment.status === "PENDING")
    );
  });

  let depositDate=moment()
  if(depositDatePayments.length>0){
    depositDate = moment(
      depositDatePayments[depositDatePayments.length - 1].time,
      "x"
    );
  }
  

  let minNextPaymentDate = depositDate.add(1, "days");

  if (minNextPaymentDate < moment()) {
    minNextPaymentDate = moment().add(1, "days");
  }

  const toPay = parseFloat(order.to_pay);

  return (
    <Form
      onSubmit={(values) => {
        restructurePaymentSchedule({
          variables: {
            orderId: order._id,
            weeklyPayment: parseInt(values.weeklyPayment),
            nextPaymentDate: values.nextPaymentDate,
            orderType: values.orderType,
          },
        });
      }}
      validate={(values) => {
        let errors = {};

        if (!values.orderType) {
          errors.orderType = "Required";
        }

        if (!values.nextPaymentDate) {
          errors.nextPaymentDate = "Required";
        }

        if (!values.weeklyPayment) {
          errors.weeklyPayment = "Required";
        }

        return errors;
      }}
      decorators={[
        createDecorator(
          {
            field: "orderType", // when orderType changes...
            updates: (value, name, allValues) => {
              return {
                nextPaymentDate: null,
                paymentsTillDone: "",
                completeDate: null,
                weeklyPayment: "",
              };
            },
          },
          {
            field: "weeklyPayment", // when weeklyPayment changes...
            updates: (value, name, allValues) => {
              let paymentsTillDone;

              if (value) {
                paymentsTillDone = Math.ceil(toPay / value);
              }
              //calculate number of payments till completion

              //calculate expected completion date

              let completeDate = null;

              if (allValues.nextPaymentDate) {
                completeDate = allValues.nextPaymentDate
                  .clone()
                  .add(
                    allValues.orderType === "fortnightly"
                      ? paymentsTillDone * 2
                      : allValues.orderType === "4weekly"
                      ? paymentsTillDone * 4
                      : paymentsTillDone,

                    allValues.orderType === "weekly" ||
                      allValues.orderType === "fortnightly" ||
                      allValues.orderType === "4weekly"
                      ? "weeks"
                      : "months"
                  )
                  .format("DD/MM/YYYY");
              }

              return {
                paymentsTillDone: paymentsTillDone,
                completeDate: completeDate,
              };
            },
          },
          {
            field: "nextPaymentDate", // when weeklyPayment changes...
            updates: (value, name, allValues) => {
              let paymentsTillDone;

              if (allValues.weeklyPayment) {
                paymentsTillDone = Math.ceil(toPay / allValues.weeklyPayment);
              }

              //calculate expected completion date

              let completeDate = null;

              if (allValues.weeklyPayment && value) {
                completeDate = value
                  .clone()
                  .add(
                    allValues.paymentsTillDone,
                    allValues.orderType === "weekly" ? "weeks" : "months"
                  )
                  .format("DD/MM/YYYY");
              }

              return {
                completeDate: completeDate,
                paymentsTillDone: paymentsTillDone,
              };
            },
          }
        ),
      ]}
      render={({ handleSubmit, pristine, submitting, values, invalid }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Card>
            <CardHeader
              action={
                <IconButton aria-label="Close Pop-up" onClick={hideModal}>
                  <Clear />
                </IconButton>
              }
              title="Restructure Payment Schedule"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    Order Type *
                  </Typography>
                  <RadioGroup
                    aria-label="Order Type"
                    name="orderType"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <Field name="orderType" type="radio" value="weekly">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="weekly"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              Weekly
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                    <Field name="orderType" type="radio" value="monthly">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="monthly"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              Monthly
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                    <Field name="orderType" type="radio" value="fortnightly">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="fortnightly"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              Fortnightly
                            </Typography>
                          }
                        />
                      )}
                    </Field>

                    <Field name="orderType" type="radio" value="4weekly">
                      {({ input, meta }) => (
                        <FormControlLabel
                          value="4weekly"
                          control={<Radio {...input} />}
                          label={
                            <Typography
                              variant="body2"
                              style={{
                                color:
                                  meta.touched && meta.error
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.87)",
                              }}
                            >
                              4 Weekly
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                  </RadioGroup>
                </Grid>
                {!!values.orderType && (
                  <Fragment>
                    <Grid item xs={12} sm={6} md={3}>
                      <Field name="nextPaymentDate">
                        {({ input, meta }) => (
                          <DatePicker
                            format="DD/MM/YYYY"
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value ? input.value : null}
                            onBlur={input.onBlur}
                            fullWidth
                            required
                            disablePast
                            autoOk
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                            minDate={minNextPaymentDate}
                            label="Next Payment Day"
                            leftArrowIcon={<KeyboardArrowLeft />}
                            inputVariant="outlined"
                            rightArrowIcon={<KeyboardArrowRight />}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Field name="weeklyPayment">
                        {({ input, meta }) => (
                          <Fragment>
                            {values.orderType === "weekly" && (
                              <TextField
                                {...input}
                                select
                                label="Payment"
                                fullWidth
                                required
                                variant="outlined"
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : ""
                                }
                              >
                                {settings.weekly_payment_amounts.map(
                                  (amount) => (
                                    <MenuItem
                                      key={amount}
                                      value={amount}
                                    >{`£${amount}`}</MenuItem>
                                  )
                                )}
                              </TextField>
                            )}

                            {values.orderType === "monthly" && (
                              <TextField
                                {...input}
                                select
                                label="Monthly Payment"
                                fullWidth
                                required
                                variant="outlined"
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : ""
                                }
                              >
                                {settings.monthly_payment_amounts.map(
                                  (amount) => (
                                    <MenuItem
                                      key={amount}
                                      value={amount}
                                    >{`£${amount}`}</MenuItem>
                                  )
                                )}
                              </TextField>
                            )}

                            {values.orderType === "fortnightly" && (
                              <TextField
                                {...input}
                                select
                                label="Fortnightly Payment"
                                fullWidth
                                required
                                variant="outlined"
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : ""
                                }
                              >
                                {settings.fortnightly_payment_amounts.map(
                                  (amount) => (
                                    <MenuItem
                                      key={amount}
                                      value={amount}
                                    >{`£${amount}`}</MenuItem>
                                  )
                                )}
                              </TextField>
                            )}

                            {values.orderType === "4weekly" && (
                              <TextField
                                {...input}
                                select
                                label="4 weekly Payment"
                                fullWidth
                                required
                                variant="outlined"
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : ""
                                }
                              >
                                {settings.fourweekly_payment_amounts.map(
                                  (amount) => (
                                    <MenuItem
                                      key={amount}
                                      value={amount}
                                    >{`£${amount}`}</MenuItem>
                                  )
                                )}
                              </TextField>
                            )}
                          </Fragment>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Field name="paymentsTillDone">
                        {({ input }) => (
                          <TextField
                            {...input}
                            label="Payments Remaining"
                            fullWidth
                            disabled
                            variant="outlined"
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Field name="completeDate">
                        {({ input }) => (
                          <TextField
                            {...input}
                            label="Completion Date"
                            fullWidth
                            disabled
                            variant="outlined"
                          />
                        )}
                      </Field>
                    </Grid>
                  </Fragment>
                )}

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      pristine || submitting || invalid || restructureLoading
                    }
                  >
                    <Save style={{ marginRight: "10px" }} />
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    />
  );
}
