import React from 'react'

import AuthenticatedRoute from '../components/util/AuthenticatedRoute'

import ChooseCustomer from '../components/pages/NewOrder/ChooseCustomer'
import CustomerDetails from '../components/pages/NewOrder/CustomerDetails'
import OrderDetails from '../components/pages/NewOrder/OrderDetails'
import PaymentDetails from '../components/pages/NewOrder/PaymentDetails'
import BillingDetails from '../components/pages/NewOrder/BillingDetails'
import OrderReview from '../components/pages/NewOrder/OrderReview'
import OrderConfirmation from '../components/pages/NewOrder/OrderConfirmation'
import SuccessfulPayment from '../components/pages/NewOrder/SuccessfulPayment'
import FailedPayment from '../components/pages/NewOrder/FailedPayment'

const NewOrderRoutes = [
  <AuthenticatedRoute
    path="/new-order/choose-customer/:parkedOrderId"
    exact
    component={ChooseCustomer}
    title="New Order - Choose Customer"
  />,
  <AuthenticatedRoute
    path="/new-order/customer-details/:parkedOrderId"
    exact
    component={CustomerDetails}
    title="New Order - Customer Details"
  />,
  <AuthenticatedRoute
    path="/new-order/order-details/:parkedOrderId"
    exact
    component={OrderDetails}
    title="New Order - Order Details"
  />,
  <AuthenticatedRoute
    path="/new-order/payment-details/:parkedOrderId"
    exact
    component={PaymentDetails}
    title="New Order - Payment Details"
  />,
  <AuthenticatedRoute
    path="/new-order/billing-details/:parkedOrderId"
    exact
    component={BillingDetails}
    title="New Order - Billing Details"
  />,
  <AuthenticatedRoute
    path="/new-order/order-review/:parkedOrderId"
    exact
    component={OrderReview}
    title="New Order - Order Review"
  />,
  <AuthenticatedRoute
    path="/new-order/order-confirmation/:parkedOrderId"
    exact
    component={OrderConfirmation}
    title="New Order - Order Confirmation"
  />,
  <AuthenticatedRoute
    path="/new-order-success"
    exact
    component={SuccessfulPayment}
    title="New Order - Success"
  />,
  <AuthenticatedRoute
    path="/new-order-error/:paymentId/:parkedOrderId"
    exact
    component={FailedPayment}
    title="New Order - Failure"
  />,
]

export default NewOrderRoutes
