import React from 'react'
import { Link } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import Loading from '../../reusable/Loading'
import { openSnackbar } from '../../reusable/Notifier'

import { Typography, Button, Grid, TextField } from '@material-ui/core'

const PASSWORD_RESET_ALLOWED = gql`
  query passwordResetAllowed($resetToken: String!) {
    passwordResetAllowed(resetToken: $resetToken) {
      _id
    }
  }
`

const RESET_PASSWORD = gql`
  mutation updatePassword($userId: String!, $password: String!) {
    updatePassword(userId: $userId, password: $password) {
      _id
    }
  }
`

export default function ResetPassword({ match, history }) {
  const {
    loading: resetAllowedLoading,
    error: resetAllowedError,
    data: passwordResetAllowedData,
  } = useQuery(PASSWORD_RESET_ALLOWED, {
    variables: { resetToken: match.params.reset_token },
  })
  const [updatePassword, { loading: updatePasswordLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted: () => {
        openSnackbar({
          message: 'Password updated successfully',
          type: 'success',
        })
        history.push('/login')
      },
      onError: () => {
        openSnackbar({
          message: 'Failed to reset password, please try again.',
          type: 'error',
        })
      },
    }
  )

  if (resetAllowedLoading) return <Loading />

  if (resetAllowedError) {
    return (
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography component="h1" variant="h2" gutterBottom align="center">
            Reset Password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom align="center">
            Expired reset token, please send another reset request and follow
            the steps within 30 minsutes.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/login"
            fullWidth
          >
            Back to Login
          </Button>
        </Grid>
      </Grid>
    )
  }

  const { passwordResetAllowed } = passwordResetAllowedData

  return (
    <Form
      onSubmit={({ password }) => {
        updatePassword({
          variables: {
            password: password,
            userId: passwordResetAllowed._id,
          },
        })
      }}
      validate={(values) => {
        const errors = {}

        const requiredFields = ['password', 'password2']

        requiredFields.forEach((requiredField) => {
          if (!values[requiredField]) {
            errors[requiredField] = 'Required'
          }
        })

        if (values.password !== values.password2) {
          errors.password2 = 'Passwords Must Match'
        }

        return errors
      }}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: '600px', margin: 'auto' }}
        >
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h2"
                gutterBottom
                align="center"
              >
                Reset Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field name="password">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="password"
                    label="Password"
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ''}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="password2">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="password2"
                    label="Repeat Password"
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ''}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={updatePasswordLoading}
                type="submit"
              >
                Reset Password
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Button component={Link} to="/login" fullWidth>
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}
