import React, { useContext } from 'react'
import { Typography, Grid, Divider, IconButton } from '@material-ui/core'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { Add,Create } from '@material-ui/icons'
import {
  Loading,
  ErrorMessage,
} from '@web-applications/daffodil-component-library'
import AddDiscount from './Popups/AddDiscount'
import AddIncrease from './Popups/AddIncrease'
import EditDecrease from './Popups/EditDecrease'
import EditIncrease from './Popups/EditIncrease'
import Modal from '../../../newComponents/Modal'
import { UserContext } from '../../../components/util/PageWrapper'

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      increases
      total_increase
      discounts
      total_discount
    }
  }
`

function MyItemComponent({_id,reasonCode,reasonText}) {
  //const initialReasonCodePlusText=reasonCode+','+reasonText
  const [showModal, hideModal] = useModal(() => {
    return (<Modal>
      <EditIncrease hideModal={hideModal} _id={_id} initialReasonCodePlusText={reasonCode+','+reasonText} />
    </Modal>)
  });

  return <IconButton onClick={()=>{showModal()}}><Create /></IconButton>;
}

function MyItemComponent2({_id,reasonCode,reasonText}) {
  //const initialReasonCodePlusText=reasonCode+','+reasonText
  const [showModal, hideModal] = useModal(() => {
    return (<Modal>
      <EditDecrease hideModal={hideModal} _id={_id} initialReasonCodePlusText={reasonCode+','+reasonText} />
    </Modal>)
  });

  return <IconButton onClick={()=>{showModal()}}><Create /></IconButton>;
}


export default function IncreasesDiscounts() {
  const { invoiceNumber } = useParams()

  const { role_id: userRole } = useContext(UserContext)

  const [showAddDiscountModal, hideAddDiscountModal] = useModal(() => (
    <Modal>
      <AddDiscount hideModal={hideAddDiscountModal} />
    </Modal>
  ))

  const [showAddIncreaseModal, hideAddIncreaseModal] = useModal(() => (
    <Modal>
      <AddIncrease hideModal={hideAddIncreaseModal} />
    </Modal>
  ))

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  })

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  const { getOrder: order } = data

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" gutterBottom>
          Increases/Discounts
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: '8px',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Increases
          </Typography>
          <IconButton
            onClick={() => {
              showAddIncreaseModal()
            }}
            disabled={userRole === 1}
          >
            <Add />
          </IconButton>
        </div>

        {order?.increases?.length ? (
          <>
            {order.increases.map(({ _id, amount, reason,reasonCode, reasonText }, index) => (
              <Typography variant="body1" key={index}>
                {`${new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                }).format(amount)}${reasonText ? ' - '+reasonText : ''} - ${reason}`}
                <MyItemComponent _id={_id} reasonCode={reasonCode} reasonText={reasonText} />
              </Typography>
            ))}
            <Divider style={{ margin: '16px 0' }} />
            <Typography variant="body1">
              {`Total Increase: ${new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
              }).format(order.total_increase)}`}
            </Typography>
            <Divider style={{ margin: '16px 0' }} />
          </>
        ) : (
          <Typography variant="body1">
            There are no increases on this order
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: '8px',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Discounts
          </Typography>
          <IconButton
            onClick={() => {
              showAddDiscountModal()
            }}
            disabled={userRole === 1}
          >
            <Add />
          </IconButton>
        </div>

        {order?.discounts?.length ? (
          <>
            {order.discounts.map(({_id, amount, reason,reasonCode, reasonText }, index) => (
              <Typography variant="body1" key={index}>
                {`${new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                }).format(amount)}${reasonText ? ' - '+reasonText : ''} - ${reason}`}
                <MyItemComponent2 _id={_id} reasonCode={reasonCode} reasonText={reasonText} />
              </Typography>
            ))}
            <Divider style={{ margin: '16px 0' }} />
            <Typography variant="body1">
              {`Total Discount: ${new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
              }).format(order.total_discount)}`}
            </Typography>
            <Divider style={{ margin: '16px 0' }} />
          </>
        ) : (
          <Typography variant="body1">
            There are no discounts on this order
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}
