import React, { Fragment } from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import createDecorator from 'final-form-calculate'

import NewOrderWrapper from './NewOrderWrapper'

import Dropdown from '../../reusable/Dropdown'
import { openSnackbar } from './../../reusable/Notifier'
import arrayMutators from 'final-form-arrays'

import {
  Grid,
  Typography,
  Divider,
  TextField,
  IconButton,
  MenuItem,
  Button,
  Hidden,
} from '@material-ui/core'

import { Add, Clear, FilterNone, Delete } from '@material-ui/icons'
import FileUpload from '../../util/FileUpload'

const DELETE_FILE = gql`
  mutation deleteFile($id: String!) {
    deleteFile(id: $id)
  }
`

const GET_PRODUCTS = gql`
  query products {
    products {
      _id
      name
      type
      archived
      colours {
        code
        name
      }
      sizes {
        dimension
        price
        code
        delivery_charge
        fitting_charge
      }
    }
  }
`

const GET_PARKED_ORDER = gql`
  query parkedOrder($parkedOrderId: String!) {
    parkedOrder(parkedOrderId: $parkedOrderId) {
      _id
      items
      files
      delivery_charge
      fitting_charge
      deposit
      total_retail
      to_pay
      grand_total
      complete_payments
      weekly_payment
      fitting_delivery_notes
    }
  }
`

const GET_ROOMS = gql`
  query rooms {
    rooms {
      _id
      name
      stair_charge
    }
  }
`

export const UPDATE_PARKED_ORDER_ITEMS = gql`
  mutation updateParkedOrderItems(
    $parkedOrderId: ID!
    $items: [JSON!]!
    $files: [JSON!]
    $fittingDeliveryNotes: String
  ) {
    updateParkedOrderItems(
      parkedOrderId: $parkedOrderId
      items: $items
      files: $files
      fittingDeliveryNotes: $fittingDeliveryNotes
    ) {
      _id
      items
      files
      delivery_charge
      fitting_charge
      deposit
      total_retail
      to_pay
      grand_total
      complete_payments
      fitting_delivery_notes
    }
  }
`

export default function OrderDetails({ match: { params }, history }) {
  const {
    loading: productsLoading,
    error: productsError,
    data: productsData,
  } = useQuery(GET_PRODUCTS)

  const {
    loading: parkedOrderLoading,
    error: parkedOrderError,
    data: parkedOrderData,
  } = useQuery(GET_PARKED_ORDER, {
    variables: { parkedOrderId: params.parkedOrderId },
  })

  const {
    loading: roomsLoading,
    error: roomsError,
    data: roomsData,
  } = useQuery(GET_ROOMS)

  const [updateParkedOrderItems, { loading: updateLoading }] = useMutation(
    UPDATE_PARKED_ORDER_ITEMS,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to update order items, please try again',
          type: 'error',
        })
      },
      onCompleted: () => {
        history.push(`/new-order/payment-details/${params.parkedOrderId}`)
      },
    }
  )

  const [deleteFile, { loading: fileDeleteLoading }] = useMutation(
    DELETE_FILE,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to remove file, please try again',
          type: 'error',
        })
      },
      ignoreResults: true,
    }
  )

  let sanitisedProducts = []

  if (productsData) {
    const { products } = productsData

    sanitisedProducts = products
      .filter((product) => !!!product.archived)
      .map((product) => ({
        value: product.name,
        label: `${product.type} - ${product.name}`,
      }))
  }

  let rooms

  if (roomsData) {
    rooms = roomsData.rooms
  }

  //get current parked order details
  let initialValues = {
    items: [],
  }

  if (parkedOrderData) {
    const { parkedOrder } = parkedOrderData

    initialValues = {
      items: JSON.parse(parkedOrder.items) || [{}],
      files: JSON.parse(parkedOrder.files) || [],
      fittingDeliveryNotes: parkedOrder.fitting_delivery_notes,
    }
  }

  return (
    <Form
      onSubmit={(values) => {
        updateParkedOrderItems({
          variables: {
            parkedOrderId: params.parkedOrderId,
            items: values.items,
            files: values.files,
            fittingDeliveryNotes: values.fittingDeliveryNotes,
          },
        })
      }}
      validate={(values) => {
        let errors = { items: [] }

        values.items.forEach(() => {
          errors.items.push({ doorbars: [] })
        })

        values.items.forEach((item, index) => {
          const requiredFields = [
            'itemRoom',
            'itemName',
            'itemColour',
            'itemSize',
          ]

          requiredFields.forEach((requiredField) => {
            if (!item[requiredField]) {
              errors.items[index][requiredField] = 'Required'
            }
          })

          if (item.colourOptions) {
            const alllowedColours = item.colourOptions.map(
              (colour) => colour.value
            )

            if (!alllowedColours.includes(item.itemColour)) {
              errors.items[index].itemColour = 'Please enter a valid colour'
            }
          }

          if (item.sizeOptions) {
            const alllowedSizes = item.sizeOptions.map((size) => size.value)

            if (!alllowedSizes.includes(item.itemSize)) {
              errors.items[index].itemSize = 'Please enter a valid size'
            }
          }

          if (
            (item.itemRoom === 'Other' && !!!item.otherRoom) ||
            (item.itemRoom === 'Other' &&
              item.otherRoom &&
              !item.otherRoom.trim())
          ) {
            errors.items[index].otherRoom = 'Please enter a room'
          }

          if (item.itemType === 'Carpet' && !!!item.underlay) {
            errors.items[index].underlay = 'Required'
          }

          if (item.itemType === 'Carpet' && !!!item.grippers) {
            errors.items[index].grippers = 'Required'
          }

          if (
            (item.itemType === 'Carpet' || item.itemType === 'Vinyl') &&
            !!!item.subfloor
          ) {
            errors.items[index].subfloor = 'Required'
          }

          if (item.doorbars && item.doorbars.length) {
            item.doorbars.forEach((doorbar, doorbarIndex) => {
              errors.items[index].doorbars.push({})

              if (!doorbar) {
                errors.items[index].doorbars[doorbarIndex].material = 'Required'
                errors.items[index].doorbars[doorbarIndex].size = 'Required'
                errors.items[index].doorbars[doorbarIndex].threshold =
                  'Required'
              }

              if (doorbar && !doorbar.material) {
                errors.items[index].doorbars[doorbarIndex].material = 'Required'
              }

              if (doorbar && !doorbar.size) {
                errors.items[index].doorbars[doorbarIndex].size = 'Required'
              }

              if (doorbar && !doorbar.threshold) {
                errors.items[index].doorbars[doorbarIndex].threshold =
                  'Required'
              }
            })
          }
        })

        return errors
      }}
      initialValues={initialValues}
      decorators={[
        createDecorator(
          {
            //get colours for selected products
            field: /.itemName/,
            updates: async (value, name, allValues, oldValues) => {
              let index = name.split('[')
              index = index[1].split(']')
              index = index[0]

              const currentItem = allValues.items[index]

              let sanitisedSizes = []
              let sanitisedColours = []

              let itemId
              let itemType

              const firstLoad = Object.entries(oldValues).length === 0

              if (currentItem && currentItem.itemName && !firstLoad) {
                //find the correct product from the list of products

                const product = productsData.products.find((product) => {
                  if (product.name === currentItem.itemName) return product

                  return null
                })

                if (!product) return {}

                const { colours, _id, sizes, type } = product

                itemId = _id
                itemType = type

                sanitisedColours = colours.map((colour) => ({
                  value: colour.name,
                  label: colour.name,
                }))

                sanitisedSizes = sizes.map((size) => ({
                  value: size.dimension,
                  label: size.dimension,
                }))

                let fieldName = name.split('.')
                fieldName = fieldName[0] + '.'

                return {
                  [fieldName + 'colourOptions']: sanitisedColours,
                  [fieldName + 'itemColour']: '',
                  [fieldName + 'itemSize']: '',
                  [fieldName + 'itemId']: itemId,
                  [fieldName + 'itemType']: itemType,
                  [fieldName + 'price']: '',
                  [fieldName + 'sizeOptions']: sanitisedSizes,
                }
              }

              return {}
            },
          },
          {
            //get price, delivery, fitting, deposit and code
            field: /.itemSize/,
            updates: async (value, name, allValues) => {
              let index = name.split('[')
              index = index[1].split(']')
              index = index[0]

              const currentItem = allValues.items[index]

              let price = 0
              let fittingCharge = 0
              let deliveryCharge = 0

              if (currentItem && currentItem.itemSize) {
                const product = productsData.products.find((product) => {
                  if (product.name === currentItem.itemName) return product

                  return null
                })

                if (!product) return {}

                const { sizes } = product

                const selectedSize = sizes.find((size) => {
                  if (size.dimension === value) return size

                  return null
                })

                if (selectedSize) {
                  price = parseFloat(selectedSize.price)

                  if (selectedSize.fitting_charge) {
                    fittingCharge = parseFloat(selectedSize.fitting_charge)
                  }

                  if (selectedSize.delivery_charge) {
                    deliveryCharge = parseFloat(selectedSize.delivery_charge)
                  }
                }
              }

              let fieldName = name.split('.')
              fieldName = fieldName[0] + '.'

              return {
                [fieldName + 'price']: price,
                [fieldName + 'delivery']: deliveryCharge,
                [fieldName + 'fitting']: fittingCharge,
              }
            },
          }
        ),
      ]}
      mutators={{
        ...arrayMutators,
        clearItem: (args, state) => {
          const index = args[0]

          let items = state.lastFormState.values.items

          items[index] = {}
        },
        duplicateRow: (args, state) => {
          let itemIndex = args[0].split('[')
          itemIndex = itemIndex[1].split(']')
          itemIndex = itemIndex[0]

          const existingDoorbars =
            state.lastFormState.values.items[itemIndex].doorbars

          const newDoorbar = existingDoorbars[args[1]]

          existingDoorbars.push(newDoorbar)
        },
        uploadOrderFile: (args, state) => {
          const existingFiles = state.lastFormState.values.files
          const newFile = args[0]

          existingFiles.push(newFile)
        },
        deleteOrderFile: (args, state) => {
          const index = args[0]

          const existingFiles = state.lastFormState.values.files

          existingFiles.splice(index, 1)
        },
      }}
      render={({
        handleSubmit,
        values,
        form: {
          mutators: {
            uploadOrderFile,
            deleteOrderFile,
            duplicateRow,
            clearItem,
          },
        },
      }) => (
        <NewOrderWrapper
          pageNumber={2}
          nextButtonLabel="Next"
          nextPageFunction={() => {
            handleSubmit()
          }}
          loading={productsLoading || parkedOrderLoading || roomsLoading}
          nextButtonLoading={updateLoading}
          error={
            productsError || parkedOrderError || roomsError
              ? 'Failed to get order details, please refresh the page.'
              : null
          }
        >
          <form autoComplete="off">
            <FieldArray name="items">
              {({ fields }) => (
                <Grid container spacing={3} justify="center">
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography component="h2" variant="h5">
                        Items
                      </Typography>

                      <IconButton
                        color="primary"
                        aria-label="Add Item"
                        onClick={() => fields.push({})}
                      >
                        <Add fontSize="large" />
                      </IconButton>
                    </div>

                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {fields.map((name, index) => (
                        <Fragment key={name}>
                          <Grid item xs={12} md={2}>
                            <Field name={`${name}.itemRoom`}>
                              {({ input, meta }) => (
                                <TextField
                                  {...input}
                                  select
                                  fullWidth
                                  required
                                  variant="outlined"
                                  name={`${name}.itemRoom`}
                                  margin="dense"
                                  label="Room"
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.error && meta.touched ? meta.error : ''
                                  }
                                >
                                  {rooms.map((room) => {
                                    return (
                                      <MenuItem
                                        value={room.name}
                                        key={room._id}
                                      >
                                        {room.name}
                                      </MenuItem>
                                    )
                                  })}
                                </TextField>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Field name={`${name}.itemName`}>
                              {({ input, meta }) => (
                                <Dropdown
                                  margin="dense"
                                  input={input}
                                  name={`${name}.itemName`}
                                  items={sanitisedProducts}
                                  placeholder="Type a Product"
                                  label="Product"
                                  required
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.error && meta.touched ? meta.error : ''
                                  }
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Field name={`${name}.itemColour`}>
                              {({ input, meta }) => (
                                <Dropdown
                                  input={input}
                                  margin="dense"
                                  name={`${name}.itemColour`}
                                  items={
                                    values.items[index] &&
                                    values.items[index].itemName
                                      ? values.items[index].colourOptions
                                      : []
                                  }
                                  component={Dropdown}
                                  placeholder="Type a Colour"
                                  label="Colour"
                                  required
                                  disabled={
                                    !!values.items[index]
                                      ? !!!values.items[index].itemName
                                      : true
                                  }
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.error && meta.touched ? meta.error : ''
                                  }
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Field name={`${name}.itemSize`}>
                              {({ input, meta }) => (
                                <Dropdown
                                  input={input}
                                  margin="dense"
                                  name={`${name}.itemSize`}
                                  items={
                                    values.items[index] &&
                                    values.items[index].itemName &&
                                    values.items[index].itemColour
                                      ? values.items[index].sizeOptions
                                      : []
                                  }
                                  component={Dropdown}
                                  placeholder="Type a Size"
                                  label="Size"
                                  required
                                  disabled={
                                    !!values.items[index]
                                      ? !!!values.items[index].itemColour
                                      : true
                                  }
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.error && meta.touched ? meta.error : ''
                                  }
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={1}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <IconButton
                              aria-label="Delete Row"
                              onClick={() => {
                                if (fields.length > 1) {
                                  fields.remove(index)
                                } else {
                                  clearItem(index)
                                }
                              }}
                            >
                              <Clear />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            {!!values.items[index] &&
                              values.items[index].itemRoom === 'Other' && (
                                <Field name={`${name}.otherRoom`}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      name={`${name}.otherRoom`}
                                      label="Room Name"
                                      fullWidth
                                      required
                                      margin="dense"
                                      variant="outlined"
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ''
                                      }
                                    />
                                  )}
                                </Field>
                              )}
                          </Grid>
                          {!!values.items[index] &&
                            !!values.items[index].itemType &&
                            values.items[index].itemType === 'Carpet' && (
                              <Grid item xs={12} md={3}>
                                <Field name={`${name}.underlay`}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      select
                                      fullWidth
                                      required
                                      variant="outlined"
                                      name={`${name}.underlay`}
                                      margin="dense"
                                      label="Underlay Required"
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ''
                                      }
                                    >
                                      <MenuItem value="yes">Yes</MenuItem>
                                      <MenuItem value="no">No</MenuItem>
                                    </TextField>
                                  )}
                                </Field>
                              </Grid>
                            )}
                          {!!values.items[index] &&
                            !!values.items[index].itemType &&
                            values.items[index].itemType === 'Carpet' && (
                              <Grid item xs={12} md={3}>
                                <Field name={`${name}.grippers`}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      select
                                      fullWidth
                                      required
                                      variant="outlined"
                                      name={`${name}.grippers`}
                                      margin="dense"
                                      label="Grippers Required"
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ''
                                      }
                                    >
                                      <MenuItem value="yes">Yes</MenuItem>
                                      <MenuItem value="no">No</MenuItem>
                                    </TextField>
                                  )}
                                </Field>
                              </Grid>
                            )}
                          {!!values.items[index] &&
                            !!values.items[index].itemType &&
                            values.items[index].itemType !== 'Bed' && (
                              <Grid item xs={12} md={3}>
                                <Field name={`${name}.subfloor`}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      select
                                      required
                                      fullWidth
                                      variant="outlined"
                                      name={`${name}.subfloor`}
                                      margin="dense"
                                      label="Subfloor"
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ''
                                      }
                                    >
                                      <MenuItem value="Concrete">
                                        Concrete
                                      </MenuItem>
                                      <MenuItem value="Wooden">Wooden</MenuItem>
                                    </TextField>
                                  )}
                                </Field>
                              </Grid>
                            )}

                          <Grid item xs={12} />
                          {!!values.items[index] &&
                            !!values.items[index].itemType &&
                            values.items[index].itemType !== 'Bed' && (
                              <FieldArray name={`${name}.doorbars`}>
                                {({ fields }) => (
                                  <Fragment>
                                    <Grid item xs={12} md={2}>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        required
                                        onClick={() => fields.push(undefined)}
                                      >
                                        Add Door Bar
                                      </Button>
                                    </Grid>
                                    {fields.map((name, index) => (
                                      <Fragment key={name}>
                                        {index !== 0 && (
                                          <Hidden smDown>
                                            <Grid item xs={12} md={2} />
                                            <Grid item xs={12} md={2} />
                                          </Hidden>
                                        )}
                                        <Grid item xs={12} md={2}>
                                          <Field name={`${name}.threshold`}>
                                            {({ input, meta }) => (
                                              <TextField
                                                {...input}
                                                select
                                                fullWidth
                                                required
                                                variant="outlined"
                                                name={`${name}.threshold`}
                                                margin="dense"
                                                label="Threshold"
                                                error={
                                                  meta.error && meta.touched
                                                }
                                                helperText={
                                                  meta.error && meta.touched
                                                    ? meta.error
                                                    : ''
                                                }
                                              >
                                                <MenuItem value="carpet2carpet">
                                                  Double Door Bar
                                                </MenuItem>
                                                <MenuItem value="carpet2hardfloor">
                                                  Z Bar
                                                </MenuItem>
                                                <MenuItem value="carpet2vinyl">
                                                  Single Door Bar
                                                </MenuItem>
                                                <MenuItem value="vinyl2vinyl">
                                                  Cover Plate
                                                </MenuItem>
                                                <MenuItem value="Stick Down Flat">
                                                  Stick Down Flat
                                                </MenuItem>
                                                <MenuItem value="vinyl2laminate">
                                                  Stick Down Angle Edge
                                                </MenuItem>
                                              </TextField>
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                          <Field name={`${name}.material`}>
                                            {({ input, meta }) => (
                                              <TextField
                                                {...input}
                                                select
                                                fullWidth
                                                required
                                                variant="outlined"
                                                name={`${name}.material`}
                                                margin="dense"
                                                label="Material"
                                                error={
                                                  meta.error && meta.touched
                                                }
                                                helperText={
                                                  meta.error && meta.touched
                                                    ? meta.error
                                                    : ''
                                                }
                                              >
                                                <MenuItem value="aluminium">
                                                  Aluminium
                                                </MenuItem>
                                                <MenuItem value="brass">
                                                  Brass
                                                </MenuItem>
                                              </TextField>
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                          <Field name={`${name}.size`}>
                                            {({ input, meta }) => (
                                              <TextField
                                                {...input}
                                                select
                                                fullWidth
                                                required
                                                variant="outlined"
                                                name={`${name}.size`}
                                                margin="dense"
                                                label="Size"
                                                error={
                                                  meta.error && meta.touched
                                                }
                                                helperText={
                                                  meta.error && meta.touched
                                                    ? meta.error
                                                    : ''
                                                }
                                              >
                                                <MenuItem value="3ft">
                                                  3ft
                                                </MenuItem>
                                                <MenuItem value="9ft">
                                                  9ft
                                                </MenuItem>
                                              </TextField>
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid
                                          item
                                          md={1}
                                          xs={12}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <IconButton
                                            aria-label="Duplicate Row"
                                            onClick={() =>
                                              duplicateRow(name, index)
                                            }
                                          >
                                            <FilterNone />
                                          </IconButton>
                                          <IconButton
                                            aria-label="Delete Row"
                                            onClick={() => fields.remove(index)}
                                          >
                                            <Clear />
                                          </IconButton>
                                        </Grid>
                                      </Fragment>
                                    ))}
                                  </Fragment>
                                )}
                              </FieldArray>
                            )}

                          <Grid item xs={12}>
                            <Divider style={{ marginTop: '10px' }} />
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Field name="fittingDeliveryNotes">
                      {({ input }) => (
                        <TextField
                          {...input}
                          label="Fitting/Delivery Notes"
                          fullWidth
                          rows="4"
                          multiline
                          margin="dense"
                          variant="outlined"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <input
                      name="files"
                      type="file"
                      multiple
                      style={{ display: 'none' }}
                      id="order-files"
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography component="h3" variant="h6">
                        Uploads
                      </Typography>
                      <FileUpload uploadFileFunction={uploadOrderFile} />
                    </div>
                    <Divider />
                    {!!values.files && (
                      <Fragment>
                        {values.files.map((file, index) => (
                          <div
                            key={file.id}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="subtitle2">
                              {file.name}
                            </Typography>
                            <IconButton
                              aria-label="Delete File"
                              style={{ marginLeft: 'auto' }}
                              disabled={fileDeleteLoading}
                              onClick={() => {
                                deleteFile({
                                  variables: {
                                    id: file.id,
                                  },
                                }).then(() => {
                                  deleteOrderFile(index)
                                })
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </Grid>
                </Grid>
              )}
            </FieldArray>
          </form>
        </NewOrderWrapper>
      )}
    />
  )
}
