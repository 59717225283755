import React from 'react'

import AuthenticatedRoute from '../components/util/AuthenticatedRoute'

import Orders from '../components/pages/Orders/Orders'
import SingleOrder from '../pages/Order/SingleOrder'
import FailedUpdateCard from '../components/pages/Orders/FailedUpdateCard'
import SuccessUpdateCard from '../components/pages/Orders/SuccessUpdateCard'
import SuccessfulOneOffPayment from '../components/pages/Orders/SuccessfulOneOffPayment'
import FailedOneOffPayment from '../components/pages/Orders/FailedOneOffPayment'

const OrderRoutes = [
  <AuthenticatedRoute
    path="/update-card/success/:invoice_number"
    exact
    component={SuccessUpdateCard}
    title="Card Update - Success"
  />,
  <AuthenticatedRoute
    path="/update-card/error/:invoice_number"
    exact
    component={FailedUpdateCard}
    title="Card Update - Failure"
  />,
  <AuthenticatedRoute
    path="/one-off-payment/success/:invoice_number"
    exact
    component={SuccessfulOneOffPayment}
    title="One-Off Payment - Success"
  />,
  <AuthenticatedRoute
    path="/one-off-payment/error/:invoice_number"
    exact
    component={FailedOneOffPayment}
    title="One-Off Payment - Failure"
  />,
  <AuthenticatedRoute path="/orders" exact component={Orders} title="Orders" />,
  <AuthenticatedRoute
    path="/orders/single/:invoiceNumber"
    exact
    component={SingleOrder}
    title="Order"
  />,
]

export default OrderRoutes
