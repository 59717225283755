import React from 'react'
import { Field } from 'react-final-form'

import {
  TextField,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core'

export default function CancelOrderReason() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={7} md={4}>
        <Field name="reasonForCancelling">
          {({ input, meta }) => (
            <TextField
              {...input}
              select
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : ''}
              label="Reason for Cancelling"
              fullWidth
              required
              variant="outlined"
            >
              <MenuItem value="1 - Changed Mind">1 - Changed Mind</MenuItem>
              <MenuItem value="2 - Can’t afford it/too expensive/found cheaper elsewhere">
                2 - Can’t afford it/too expensive/found cheaper elsewhere
              </MenuItem>
              <MenuItem value="3 - Waiting too long for fitting">
                3 - Waiting too long for fitting
              </MenuItem>
              <MenuItem value="4 - Waiting too long for delivery">
                4 - Waiting too long for delivery
              </MenuItem>
              <MenuItem value="5 - Fitting or delivery issues/product out of stock">
                5 - Fitting or delivery issues/product out of stock
              </MenuItem>
              <MenuItem value="6 - Misinformation from appt">
                6 - Misinformation from appt
              </MenuItem>
              <MenuItem value="7 - Change of circumstance/Moving house">
                7 - Change of circumstance/Moving house
              </MenuItem>
              <MenuItem value="8 - Family or landlord providing product">
                8 - Family or landlord providing product
              </MenuItem>
              <MenuItem value="9 - Arrears">9 - Arrears</MenuItem>
              <MenuItem value="10 - Chargeback">10 - Chargeback</MenuItem>
              <MenuItem value="11 - Schedule set up incorrectly">
                11 - Schedule set up incorrectly
              </MenuItem>
              <MenuItem value="12 - Balance Transfer (2nd order)">
                12 - Balance Transfer (2nd order)
              </MenuItem>
              <MenuItem value="13 - Change of Product">
                13 - Change of Product
              </MenuItem>
              <MenuItem value="14 - Other">14 - Other</MenuItem>
            </TextField>
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field name="newOrderPlaced" type="checkbox">
          {({ input }) => (
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ name: input.name }}
                  {...input}
                  value={input.name}
                />
              }
              label={
                <Typography variant="body2">
                  Will a new order be placed?
                </Typography>
              }
            />
          )}
        </Field>
        <Typography variant="subtitle2">
          Check this box if you will be creating a new order that payments for
          this order will be used for
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field name="cancellationNotes">
          {({ input, meta }) => (
            <TextField
              {...input}
              label="Notes"
              required
              multiline
              rows="4"
              fullWidth
              variant="outlined"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : ''}
            />
          )}
        </Field>
      </Grid>
    </Grid>
  )
}
