import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Form, Field } from 'react-final-form'

import { Card, CardContent, TextField, Button, Grid } from '@material-ui/core'

import { Save } from '@material-ui/icons'

import { openSnackbar } from './../../reusable/Notifier'
import ActivityTable from './../../reusable/ActivityTable'

import Error from './../../../newComponents/Error'
import Loading from './../../reusable/Loading'

const GET_TEXTS = gql`
  query getTexts($customerId: ID) {
    getTexts(customerId: $customerId) {
      _id
      mobile_number
      message
      status
      created_date
      sent_time
    }
  }
`

const GET_EMAILS = gql`
  query getEmails($customerId: ID) {
    getEmails(customerId: $customerId) {
      _id
      email_template
      order {
        _id
      }
      sent_time
      created_date
      subject
      status
      target_email
    }
  }
`

const GET_ACTIVITIES = gql`
  query getActivities($customerId: ID) {
    getActivities(customerId: $customerId) {
      _id
      time
      content
      user {
        _id
        first_name
        last_name
      }
    }
  }
`

const GET_CUSTOMER_NOTES = gql`
  query getNotes($customerId: ID) {
    getNotes(customerId: $customerId) {
      _id
      created_date
      created_by {
        first_name
        last_name
      }
      content
    }
  }
`

const CREATE_NOTE = gql`
  mutation createNote($customerId: ID, $content: String!) {
    createNote(customerId: $customerId, content: $content) {
      _id
      created_date
      created_by {
        first_name
        last_name
      }
      content
    }
  }
`

export default function CustomerActivity({ customerId }) {
  const { loading: texsLoading, error: textsError, data: textsData } = useQuery(
    GET_TEXTS,
    {
      variables: {
        customerId: customerId,
      },
    }
  )

  const {
    loading: emailsLoading,
    error: emailsError,
    data: emailsData,
  } = useQuery(GET_EMAILS, {
    variables: {
      customerId: customerId,
    },
  })

  const {
    loading: activitiesLoading,
    error: activitiesError,
    data: activitiesData,
  } = useQuery(GET_ACTIVITIES, {
    variables: {
      customerId: customerId,
    },
  })

  const {
    loading: customerNotesLoading,
    error: customerNotesError,
    data: customerNotesData,
  } = useQuery(GET_CUSTOMER_NOTES, {
    variables: {
      customerId: customerId,
    },
  })

  const [createNote, { loading: createNoteLoading }] = useMutation(
    CREATE_NOTE,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to add note, please try again',
          type: 'error',
        })
      },
    }
  )

  if (texsLoading || emailsLoading || activitiesLoading || customerNotesLoading)
    return <Loading />

  if (textsError || emailsError || activitiesError || customerNotesError)
    return <Error />

  const texts = textsData.getTexts || []
  const emails = emailsData.getEmails || []
  const activities = activitiesData.getActivities || []
  const notes = customerNotesData.getNotes || []

  return (
    <Card>
      <ActivityTable
        title="Customer Activity"
        notes={notes}
        activities={activities}
        texts={texts}
        emails={emails}
      />

      <CardContent>
        <Form
          onSubmit={({ content }, form) => {
            createNote({
              variables: {
                customerId: customerId,
                content: content,
              },
            }).then(() => {
              openSnackbar({
                message: 'Customer note added',
                type: 'success',
              })
              form.reset()
            })
          }}
          render={({ handleSubmit, pristine, submitting }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={10}>
                  <Field name="content">
                    {({ input }) => (
                      <TextField
                        {...input}
                        label="Add Note"
                        multiline
                        rows="4"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={pristine || submitting || createNoteLoading}
                  >
                    <Save
                      style={{
                        marginRight: '10px',
                      }}
                    />
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </CardContent>
    </Card>
  )
}
