import React, { Fragment, useContext } from "react";
import { Query, Mutation, useQuery } from "@apollo/react-components";
import gql from "graphql-tag";

import { Form, Field } from "react-final-form";

import {
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  IconButton,
  Tooltip,
  CardHeader,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CardActions,
} from "@material-ui/core";

import { Save, Clear, Add } from "@material-ui/icons";

import { openSnackbar } from "../../reusable/Notifier";
import { openModal, closeModal } from "../../reusable/Popup";

import { UserContext } from "../../util/PageWrapper";

import { GET_USERS } from "../../../helpers/queries/User";
import { GET_FRANCHISES } from "../../../helpers/queries/Franchises";

export const GET_STORES = gql`
  query stores {
    stores {
      _id
      name
      email
      bed_phone
      fitting_phone
      type
      franchise {
        _id
      }
      users {
        _id
        first_name
        last_name
      }
    }
  }
`;

export default function Stores() {
  const user = useContext(UserContext);

  const { data: usersData, loading: usersLoading } = useQuery(GET_USERS);
  const { data: franchisesData, loading: franchisesLoading } =
    useQuery(GET_FRANCHISES);

  const users = usersData?.users?.map((user) => ({
    value: user._id,
    label: user.first_name + user.last_name,
  }));
  const franchises = franchisesData?.franchises?.map((franchise) => ({
    value: franchise._id,
    label: franchise.name,
  }));

  if (franchisesLoading || usersLoading) {
    return null;
  }

  return (
    user.role_id > 4 && (
      <Grid container spacing={3} style={{ marginBottom: "1em" }}>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Typography component="h1" variant="h4" gutterBottom>
            Stores
          </Typography>
          <Button
            variant="contained"
            style={{
              marginLeft: "auto",
            }}
            color="secondary"
            onClick={() => {
              openModal({
                content: (
                  <Card>
                    <CardHeader
                      action={
                        <IconButton
                          aria-label="Close Pop-up"
                          onClick={closeModal}
                        >
                          <Clear />
                        </IconButton>
                      }
                      title="Add Store"
                    />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Mutation
                          mutation={gql`
                            mutation (
                              $name: String!
                              $email: String!
                              $bed_phone: String!
                              $fitting_phone: String!
                              $type: String!
                              $franchise: String!
                              $users: [String]
                            ) {
                              newStore(
                                name: $name
                                email: $email
                                bed_phone: $bed_phone
                                fitting_phone: $fitting_phone
                                type: $type
                                franchise: $franchise
                                users: $users
                              ) {
                                error {
                                  message
                                }
                                store {
                                  _id
                                }
                              }
                            }
                          `}
                          refetchQueries={[
                            {
                              query: GET_STORES,
                            },
                          ]}
                        >
                          {(newStore) => (
                            <Form
                              initialValues={{ franchise: "", users: [] }}
                              onSubmit={(values) => {
                                newStore({
                                  variables: values,
                                }).then(({ data }) => {
                                  const { newStore } = data;

                                  if (newStore.error) {
                                    openSnackbar({
                                      message: newStore.error.message,
                                      type: "error",
                                    });
                                  } else {
                                    openSnackbar({
                                      message: "Store added successfully",
                                      type: "success",
                                    });
                                    closeModal();
                                  }
                                });
                              }}
                              render={({
                                handleSubmit,
                                values,
                                pristine,
                                submitting,
                              }) => (
                                <Grid item xs={12}>
                                  <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                  >
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems="center"
                                    >
                                      <Grid item xs={12} md={4}>
                                        <Field name="name">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Store Name"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>

                                      <Grid item xs={12} md={4}>
                                        <Field name="bed_phone">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Bed phone"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>

                                      <Grid item xs={12} md={4}>
                                        <Field name="email">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Email"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>

                                      <Grid item xs={12} md={4}>
                                        <Field name="type">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Type"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>

                                      <Grid item xs={12} md={4}>
                                        <Field name="fitting_phone">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Fitting phone"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>

                                      <Grid item xs={12} md={4}></Grid>

                                      <Grid item xs={12} md={4}>
                                        <Field name="franchise">
                                          {({
                                            input: {
                                              name,
                                              onChange,
                                              value,
                                              ...restInput
                                            },
                                            meta,
                                            ...rest
                                          }) => (
                                            <FormControl fullWidth>
                                              <InputLabel id="multiple-select-label">
                                                Franchise
                                              </InputLabel>
                                              <Select
                                                {...rest}
                                                name={name}
                                                helperText={
                                                  meta.touched
                                                    ? meta.error
                                                    : undefined
                                                }
                                                error={
                                                  meta.error && meta.touched
                                                }
                                                inputProps={restInput}
                                                onChange={onChange}
                                                value={value}
                                                labelId="multiple-name-label"
                                                id="multiple-select"
                                                required
                                              >
                                                {franchises.map((franchise) => (
                                                  <MenuItem
                                                    key={franchise.value}
                                                    value={franchise.value}
                                                  >
                                                    {franchise.label}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={8}>
                                        <Field name="users">
                                          {({
                                            input: {
                                              name,
                                              onChange,
                                              value,
                                              ...restInput
                                            },
                                            meta,
                                            ...rest
                                          }) => (
                                            <FormControl fullWidth>
                                              <InputLabel id="multiple-select-label">
                                                Users
                                              </InputLabel>
                                              <Select
                                                {...rest}
                                                name={name}
                                                helperText={
                                                  meta.touched
                                                    ? meta.error
                                                    : undefined
                                                }
                                                error={
                                                  meta.error && meta.touched
                                                }
                                                inputProps={restInput}
                                                onChange={onChange}
                                                value={value}
                                                labelId="multiple-name-label"
                                                id="multiple-select"
                                                multiple
                                              >
                                                {users.map((user) => (
                                                  <MenuItem
                                                    key={user.value}
                                                    value={user.value}
                                                  >
                                                    {user.label}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          )}
                                        </Field>
                                      </Grid>

                                      <Grid item xs={12} md={3}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          type="submit"
                                          fullWidth
                                          disabled={pristine || submitting}
                                        >
                                          <Save
                                            style={{ marginRight: "10px" }}
                                          />
                                          Save
                                        </Button>
                                      </Grid>
                                    </Grid>
                                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                  </form>
                                </Grid>
                              )}
                            />
                          )}
                        </Mutation>
                      </Grid>
                    </CardContent>
                  </Card>
                ),
              });
            }}
          >
            <Add style={{ marginRight: "10px" }} />
            Add Store
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center">
            <Query query={GET_STORES}>
              {({ loading, error, data }) => {
                if (loading || error) {
                  return null;
                }

                let { stores } = data;

                return (
                  <Fragment>
                    {stores.map((store) => (
                      <Mutation
                        key={store._id}
                        mutation={gql`
                          mutation (
                            $_id: String!
                            $name: String!
                            $email: String!
                            $bed_phone: String!
                            $fitting_phone: String!
                            $type: String!
                            $franchise: String!
                            $users: [String]
                          ) {
                            updateStore(
                              _id: $_id
                              name: $name
                              email: $email
                              bed_phone: $bed_phone
                              fitting_phone: $fitting_phone
                              type: $type
                              franchise: $franchise
                              users: $users
                            ) {
                              error {
                                message
                              }
                              store {
                                _id
                              }
                            }
                          }
                        `}
                        refetchQueries={[
                          {
                            query: GET_STORES,
                          },
                        ]}
                      >
                        {(updateStore) => (
                          <Form
                            initialValues={{
                              ...store,
                              users: store.users.map((store) => store._id),
                              franchise: store.franchise._id,
                            }}
                            onSubmit={(values) => {
                              updateStore({
                                variables: {
                                  _id: store._id,
                                  ...values,
                                },
                              }).then(({ data }) => {
                                const { updateStore } = data;

                                if (updateStore.error) {
                                  openSnackbar({
                                    message: updateStore.error.message,
                                    type: "error",
                                  });
                                } else {
                                  openSnackbar({
                                    message: "Store updated successfully",
                                    type: "success",
                                  });
                                }
                              });
                            }}
                            render={({
                              handleSubmit,
                              pristine,
                              submitting,
                              values,
                            }) => (
                              <Card style={{ marginBottom: "2rem" }}>
                                <CardContent>
                                  <Grid item xs={12}>
                                    <form
                                      onSubmit={handleSubmit}
                                      autoComplete="off"
                                    >
                                      <Grid
                                        container
                                        spacing={3}
                                        alignItems="center"
                                      >
                                        <Grid item xs={12} md={12}>
                                          <Mutation
                                            mutation={gql`
                                              mutation ($_id: String!) {
                                                deleteStore(_id: $_id) {
                                                  error {
                                                    message
                                                  }
                                                  store {
                                                    _id
                                                  }
                                                }
                                              }
                                            `}
                                            refetchQueries={[
                                              {
                                                query: GET_STORES,
                                              },
                                            ]}
                                          >
                                            {(deleteStore) => (
                                              <Tooltip
                                                title="Delete Store"
                                                placement="top"
                                              >
                                                <IconButton
                                                  aria-label="Delete"
                                                  onClick={() => {
                                                    openModal({
                                                      content: (
                                                        <Card>
                                                          <CardHeader
                                                            action={
                                                              <IconButton
                                                                aria-label="Close Pop-up"
                                                                onClick={
                                                                  closeModal
                                                                }
                                                              >
                                                                <Clear />
                                                              </IconButton>
                                                            }
                                                            title="Delete Store"
                                                          />
                                                          <CardContent>
                                                            <Typography
                                                              variant="body1"
                                                              gutterBottom
                                                            >
                                                              Please confirm
                                                              that you would
                                                              like to continue
                                                              with this.
                                                            </Typography>
                                                          </CardContent>
                                                          <CardActions
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "space-between",
                                                            }}
                                                          >
                                                            <Button
                                                              variant="contained"
                                                              color="primary"
                                                              onClick={
                                                                closeModal
                                                              }
                                                            >
                                                              Cancel
                                                            </Button>

                                                            <Button
                                                              variant="contained"
                                                              color="secondary"
                                                              onClick={() => {
                                                                deleteStore({
                                                                  variables: {
                                                                    _id: store._id,
                                                                  },
                                                                }).then(
                                                                  ({
                                                                    data,
                                                                  }) => {
                                                                    const {
                                                                      deleteStore,
                                                                    } = data;

                                                                    if (
                                                                      deleteStore.error
                                                                    ) {
                                                                      openSnackbar(
                                                                        {
                                                                          message:
                                                                            deleteStore
                                                                              .error
                                                                              .message,
                                                                          type: "error",
                                                                        }
                                                                      );
                                                                    } else {
                                                                      closeModal();
                                                                      openSnackbar(
                                                                        {
                                                                          message:
                                                                            "Store deleted successfully",
                                                                          type: "success",
                                                                        }
                                                                      );
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              Continue
                                                            </Button>
                                                          </CardActions>
                                                        </Card>
                                                      ),
                                                    });
                                                  }}
                                                >
                                                  <Clear />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          </Mutation>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                          <Field name="name">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Store Name"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                          <Field name="bed_phone">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Bed phone"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                          <Field name="email">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Email"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                          <Field name="type">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Type"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                          <Field name="fitting_phone">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Fitting phone"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>

                                        <Grid item xs={12} md={4}></Grid>

                                        <Grid item xs={12} md={4}>
                                          <Field name="franchise">
                                            {({
                                              input: {
                                                name,
                                                onChange,
                                                value,
                                                ...restInput
                                              },
                                              meta,
                                              ...rest
                                            }) => (
                                              <FormControl fullWidth>
                                                <InputLabel id="multiple-select-label">
                                                  Franchise
                                                </InputLabel>
                                                <Select
                                                  {...rest}
                                                  name={name}
                                                  helperText={
                                                    meta.touched
                                                      ? meta.error
                                                      : undefined
                                                  }
                                                  error={
                                                    meta.error && meta.touched
                                                  }
                                                  inputProps={restInput}
                                                  onChange={onChange}
                                                  value={value}
                                                  labelId="multiple-name-label"
                                                  id="multiple-select"
                                                  required
                                                >
                                                  {franchises.map(
                                                    (franchise) => (
                                                      <MenuItem
                                                        key={franchise.value}
                                                        value={franchise.value}
                                                      >
                                                        {franchise.label}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>
                                              </FormControl>
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                          <Field name="users">
                                            {({
                                              input: {
                                                name,
                                                onChange,
                                                value,
                                                ...restInput
                                              },
                                              meta,
                                              ...rest
                                            }) => (
                                              <FormControl fullWidth>
                                                <InputLabel id="multiple-select-label">
                                                  Users
                                                </InputLabel>
                                                <Select
                                                  {...rest}
                                                  name={name}
                                                  helperText={
                                                    meta.touched
                                                      ? meta.error
                                                      : undefined
                                                  }
                                                  error={
                                                    meta.error && meta.touched
                                                  }
                                                  inputProps={restInput}
                                                  onChange={onChange}
                                                  value={value}
                                                  labelId="multiple-name-label"
                                                  id="multiple-select"
                                                  multiple
                                                >
                                                  {users.map((user) => (
                                                    <MenuItem
                                                      key={user.value}
                                                      value={user.value}
                                                    >
                                                      {user.label}
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            fullWidth
                                            disabled={pristine || submitting}
                                          >
                                            <Save
                                              style={{ marginRight: "10px" }}
                                            />
                                            Save
                                          </Button>
                                          {/* <pre>
                                            {JSON.stringify(values, null, 2)}
                                          </pre> */}
                                        </Grid>
                                      </Grid>
                                    </form>
                                  </Grid>
                                </CardContent>
                              </Card>
                            )}
                          />
                        )}
                      </Mutation>
                    ))}
                  </Fragment>
                );
              }}
            </Query>
          </Grid>
        </Grid>
      </Grid>
    )
  );
}
