import React, { createContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet-async'

import Loading from '../reusable/Loading'

import PageWrapper from './PageWrapper'

const AUTHENTICATE_USER = gql`
  query user {
    user {
      _id
      first_name
      last_name
      role_id
      email
      can_cancel
      stores {
        _id
        name
      }
    }
  }
`

export const UserContext = createContext()

export default function AuthenticatedRoute({
  component: Component,
  title,
  ...props
}) {
  const { loading, error, data } = useQuery(AUTHENTICATE_USER, {
    fetchPolicy: 'network-only',
  })

  if (error || !Cookies.get('ppw')) return <Redirect to="/login" />

  return (
    <Route
      {...props}
      render={(innerProps) => (
        <PageWrapper user={data ? data.user : undefined}>
          <Helmet>
            <title>{title || 'Pay Per Week'}</title>
          </Helmet>
          {loading ? <Loading /> : <Component {...innerProps} />}
        </PageWrapper>
      )}
    />
  )
}
