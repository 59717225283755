import { useMutation } from "@apollo/react-hooks";
import { NEW_PARKED_ORDER } from "../../helpers/queries/ParkedOrder";
import { closeModal } from "./Popup";
import { openSnackbar } from "./Notifier";

export const useNewParkedOrderHook = (history) => {
  return useMutation(NEW_PARKED_ORDER, {
    onCompleted: ({ newParkedOrder }) => {
      closeModal();
      history.push(`/new-order/choose-customer/${newParkedOrder._id}`);
    },
    onError: (error) => {
      openSnackbar({
        message: `Something went wrong ${error.message}.`,
        type: "error",
      });
    },
  });
};
