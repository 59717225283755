import React, { Fragment } from 'react'
import { Form } from 'react-final-form'
import { Mutation, ApolloConsumer } from '@apollo/react-components'
import validator from 'validator'
import createDecorator from 'final-form-calculate'

import { Typography, Card, CardContent, Grid, Button } from '@material-ui/core'

import { Save } from '@material-ui/icons'

import { openSnackbar } from './../../reusable/Notifier'

import { GET_USERS, NEW_USER, GET_STORE } from './../../../helpers/queries/User'
import UserEdit from './UserEdit'

export default function NewUser({ history }) {
  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12}>
        <Typography component="h2" variant="h5">
          New User
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Mutation
              mutation={NEW_USER}
              refetchQueries={[
                {
                  query: GET_USERS,
                },
              ]}
            >
              {(newUser) => (
                <Fragment>
                  <ApolloConsumer>
                    {(client) => (
                      <Form
                        onSubmit={(values) => {
                          newUser({
                            variables: {
                              email: values.email,
                              firstName: values.firstName,
                              lastName: values.lastName,
                              roleId: values.role,
                              stores: JSON.stringify(values.chosenStores),
                              canCancel: values.canCancel
                                ? values.canCancel
                                : false,
                            },
                          })
                            .then(({ data }) => {
                              const { newUser } = data

                              openSnackbar({
                                message:
                                  newUser.first_name +
                                  ' ' +
                                  newUser.last_name +
                                  ' has been successfully created',
                                type: 'success',
                              })

                              history.push('/admin-settings/users')
                            })
                            .catch(({ message }) => {
                              openSnackbar({
                                message: message.replace('GraphQL error: ', ''),
                                type: 'error',
                              })
                            })
                        }}
                        decorators={[
                          createDecorator({
                            //match billing address to customer address
                            field: 'store',
                            updates: async (value, name, allValues) => {
                              if (!value)
                                return {
                                  [name]: '',
                                }

                              //get value and use it to find a store id
                              const { data } = await client.query({
                                query: GET_STORE,
                                variables: {
                                  name: value,
                                },
                              })

                              const { store } = data

                              if (!store) return {}

                              const newStore = {
                                _id: store._id,
                                name: store.name,
                              }

                              const chosenStores = allValues.chosenStores || []

                              const chosenStoreNames = chosenStores.map(
                                (store) => {
                                  return store.name
                                }
                              )

                              if (!chosenStoreNames.includes(store.name)) {
                                chosenStores.push(newStore)
                              }

                              return {
                                [name]: '',
                                chosenStores: chosenStores,
                              }
                            },
                          }),
                        ]}
                        mutators={{
                          removeStore: (args, state, utils) => {
                            const chosenStores =
                              state.formState.values.chosenStores

                            chosenStores.splice(args[0], 1)

                            utils.changeValue(
                              state,
                              'chosenStores',
                              () => chosenStores
                            )
                          },
                        }}
                        initialValues={{
                          chosenStores: [],
                        }}
                        validate={(values) => {
                          const errors = {}

                          const requiredFields = [
                            'firstName',
                            'lastName',
                            'email',
                            'role',
                          ]

                          requiredFields.forEach((requiredField) => {
                            if (!values[requiredField]) {
                              errors[requiredField] = 'Required'
                            }
                          })

                          if (
                            values.email &&
                            !validator.isEmail(values.email) &&
                            !values.email.includes('.con') &&
                            !values.email.includes('.coma')
                          ) {
                            errors.email = 'Invalid Email'
                          }

                          if (!values.chosenStores.length) {
                            errors.store = 'At least 1 store is required'
                          }

                          return errors
                        }}
                        render={({
                          handleSubmit,
                          pristine,
                          invalid,
                          submitting,
                        }) => (
                          <form onSubmit={handleSubmit} noValidate>
                            <UserEdit />
                            <Grid container spacing={3} justify="center">
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={pristine || invalid || submitting}
                                  type="submit"
                                >
                                  <Save style={{ marginRight: '10px' }} />
                                  Save
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      />
                    )}
                  </ApolloConsumer>
                </Fragment>
              )}
            </Mutation>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
