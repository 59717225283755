import React, { useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { useParams, useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

import Loading from '../../reusable/Loading'
import { openSnackbar } from '../../reusable/Notifier'

import { Grid, TextField, Typography, Button } from '@material-ui/core'

import { Search } from '@material-ui/icons'

const GET_CUSTOMERS = gql`
  query customers(
    $firstName: String
    $lastName: String
    $email: String
    $postcode: String
  ) {
    customers(
      firstName: $firstName
      lastName: $lastName
      email: $email
      postcode: $postcode
    ) {
      _id
      first_name
      last_name
      address_line_1
      address_postcode
    }
  }
`

const ADD_PARKED_ORDER_EXISTING_CUSTOMER = gql`
  mutation addParkedOrderExistingCustomer(
    $parkedOrderId: ID!
    $customerId: ID!
  ) {
    addParkedOrderExistingCustomer(
      parkedOrderId: $parkedOrderId
      customerId: $customerId
    ) {
      _id
      customer_type
      existing_customer
      address_city
      address_county
      address_line_1
      address_line_2
      address_postcode
      email
      first_name
      last_name
      mobile
      phone
      preferred_contact
      title
      marketing_news
      marketing_offers
    }
  }
`

export default function ExistingCustomers() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [postcode, setPostcode] = useState('')
  const [email, setEmail] = useState('')

  const params = useParams()
  const history = useHistory()

  const [
    getCustomers,
    { loading: getCustomersLoading, error: getCustomersError, data },
  ] = useLazyQuery(GET_CUSTOMERS)

  const [addParkedOrderExistingCustomer, { loading }] = useMutation(
    ADD_PARKED_ORDER_EXISTING_CUSTOMER,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to select customer, please try again',
          type: 'error',
        })
      },
      onCompleted: () => {
        history.push(`/new-order/customer-details/${params.parkedOrderId}`)
      },
    }
  )

  let variables = {
    email: email,
    firstName: firstName,
    lastName: lastName,
    postcode: postcode,
  }

  let view = (
    <Typography variant="body1" style={{ color: 'grey' }}>
      Search for a customer or create a new one
    </Typography>
  )

  if (getCustomersLoading) {
    view = <Loading></Loading>
  }

  if (getCustomersError) {
    view = (
      <Typography variant="body1">
        Failed to get customers, please try again.
      </Typography>
    )
  }

  if (data) {
    const { customers } = data

    //format data
    const formattedCustomersArray = customers.map((customer) => {
      //format postcode
      let postcode = customer.address_postcode.match(
        /^([a-zA-Z]{1,2}\d{1,2}[a-zA-Z]?)\s*(\d[a-zA-Z]{2})$/
      )
      postcode.shift()
      postcode = postcode.join(' ')

      //create select
      const select = (
        <Button
          color="primary"
          variant="outlined"
          disabled={loading}
          onClick={() => {
            addParkedOrderExistingCustomer({
              variables: {
                parkedOrderId: params.parkedOrderId,
                customerId: customer._id,
              },
            })
          }}
        >
          Select Customer
        </Button>
      )

      //format customers for table
      return [
        customer.first_name,
        customer.last_name,
        customer.address_line_1,
        postcode,
        select,
      ]
    })

    const columns = [
      'First Name',
      'Last Name',
      'Address Line 1',
      'Postcode',
      {
        name: 'Select',
        options: {
          sort: false,
        },
      },
    ]

    const options = {
      selectableRows: 'none',
      print: false,
      download: false,
      responsive: 'standard',
      search: false,
      filter: false,
      elevation: 0,
      viewColumns: false,
    }

    view = (
      <div style={{ width: '100%', height: '100%' }}>
        <MUIDataTable
          title="Existing Customers"
          data={formattedCustomersArray}
          columns={columns}
          options={options}
        />
      </div>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ paddingBottom: '0px' }}>
        <Typography variant="subtitle2" style={{ color: 'grey' }}>
          Search for customer
        </Typography>
      </Grid>

      <Grid item xs={12} md={2}>
        <TextField
          label="First Name"
          value={firstName}
          fullWidth
          onChange={(e) => {
            setFirstName(e.target.value)

            variables.firstName = e.target.value
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          label="Last Name"
          value={lastName}
          fullWidth
          onChange={(e) => {
            setLastName(e.target.value)

            variables.lastName = e.target.value
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          label="Email Address"
          value={email}
          fullWidth
          onChange={(e) => {
            setEmail(e.target.value)

            variables.email = e.target.value
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          label="Postcode"
          value={postcode}
          fullWidth
          onChange={(e) => {
            setPostcode(e.target.value)

            variables.postcode = e.target.value
          }}
          variant="outlined"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          type="submit"
          disabled={
            !email.trim() &&
            !firstName.trim() &&
            !lastName.trim() &&
            !postcode.trim()
          }
          onClick={async (e) => {
            e.preventDefault()

            getCustomers({ variables: variables })
          }}
        >
          <Search style={{ marginRight: '10px' }} />
          Search
        </Button>
      </Grid>
      <Grid item xs={12} style={{ padding: '0' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: data ? '1px' : '350px',
          }}
        >
          {view}
        </div>
      </Grid>
    </Grid>
  )
}
