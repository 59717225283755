import React from 'react'

import { CreditCard, Redo, Undo, Event, Check } from '@material-ui/icons'

export const Dashboard = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61.66 35.91"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        style={{
          fill: 'none',
          stroke: !!props.color ? props.color : '#6C6C6C',
          strokeLinecap: 'round',
          strokeMiterlimit: '10',
          strokeWidth: '3px',
        }}
        d="M1.5 30.83a29.33 29.33 0 1 1 58.66 0"
      />
      <path
        style={{ fill: !!props.color ? props.color : '#6C6C6C' }}
        d="M42.13 8.29L25.57 23.22a6.59 6.59 0 0 0-2.08 6.16 6.59 6.59 0 0 0 12.65 1.12L44 9.61a1.17 1.17 0 0 0-1.87-1.32z"
      />
      <circle cx="29.91" cy="27.91" r="3" style={{ fill: '#fff' }} />
    </svg>
  )
}

export const Orders = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 57.5 39"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        d="M8.63 1h21.81a5.06 5.06 0 0 1 5.06 5.06v13.88A5.06 5.06 0 0 1 30.44 25H8.81a2.31 2.31 0 0 1-2.31-2.31V3.13A2.13 2.13 0 0 1 8.63 1zM4 29h51.5"
        style={{
          fill: 'none',
          stroke: !!props.color ? props.color : '#6C6C6C',
          strokeLinecap: 'round',
          strokeMiterlimit: '10',
          strokeWidth: '2px',
        }}
      />
      <path
        d="M8.5 33.5a5.5 5.5 0 1 0 9.74-3.5H9.76a5.48 5.48 0 0 0-1.26 3.5zM37.5 33.5a5.5 5.5 0 1 0 9.74-3.5h-8.48a5.48 5.48 0 0 0-1.26 3.5z"
        style={{
          fill: 'none',
          stroke: !!props.color ? props.color : '#6C6C6C',
          strokeLinecap: 'round',
          strokeMiterlimit: '10',
          strokeWidth: '2px',
        }}
      />
      <circle cx="14" cy="33.5" r="2" style={{ fill: '#fff' }} />
      <circle cx="43" cy="33.5" r="2" style={{ fill: '#fff' }} />
      <path
        d="M49.31 4h-5.43a2.38 2.38 0 0 0-2.38 2.38v21.25A2.38 2.38 0 0 0 43.88 30h11.25a2.38 2.38 0 0 0 2.38-2.37V12.19A8.21 8.21 0 0 0 49.31 4zM54 17.75A1.26 1.26 0 0 1 52.75 19h-7a1.26 1.26 0 0 1-1.25-1.25V8.07a1.26 1.26 0 0 1 1.25-1.25h3.93A4.33 4.33 0 0 1 54 11.13z"
        style={{ fill: !!props.color ? props.color : '#6C6C6C' }}
      />
      <path
        d="M1 9.5h18M6 15.5h13"
        style={{
          fill: 'none',
          stroke: !!props.color ? props.color : '#6C6C6C',
          strokeLinecap: 'round',
          strokeMiterlimit: '10',
          strokeWidth: '2px',
        }}
      />
    </svg>
  )
}

export const Customers = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.16 46"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <circle
        cx="43"
        cy="16"
        r="5"
        style={{ fill: !!props.color ? props.color : '#6C6C6C' }}
      />
      <path
        d="M51 37h-9v-9c0-3-5-5-2-5h6a5 5 0 0 1 5 5z"
        style={{ fill: !!props.color ? props.color : '#6C6C6C' }}
      />
      <circle
        cx="11"
        cy="14"
        r="7"
        style={{ fill: !!props.color ? props.color : '#6C6C6C' }}
      />
      <path
        d="M14 43H0V31a7 7 0 0 1 7-7h8c4 0-1 2-1 6z"
        style={{ fill: !!props.color ? props.color : '#6C6C6C' }}
      />
      <circle
        cx="28"
        cy="10"
        r="9"
        style={{
          fill: 'none',
          stroke: !!props.color ? props.color : '#6C6C6C',
          strokeMiterlimit: '10',
          strokeWidth: '2px',
        }}
      />
      <path
        d="M23 21h10a9 9 0 0 1 9 9v15H14V30a9 9 0 0 1 9-9z"
        style={{
          fill: 'none',
          stroke: !!props.color ? props.color : '#6C6C6C',
          strokeMiterlimit: '10',
          strokeWidth: '2px',
        }}
      />
    </svg>
  )
}

export const Reports = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 55.52 35.06"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <rect
        style={{
          fill: 'none',
          stroke: !!props.color ? props.color : '#6C6C6C',
          strokeMiterlimit: '10',
          strokeWidth: '2.58px',
        }}
        x="1.29"
        y="1.29"
        width="43"
        height="32.49"
        rx="7.09"
        ry="7.09"
      />
      <path
        d="M7 10h6M7 17h6"
        style={{
          fill: 'none',
          stroke: !!props.color ? props.color : '#6C6C6C',
          strokeMiterlimit: '10',
          strokeWidth: '2.58px',
        }}
      />
      <path style={{ fill: '#fff' }} d="M37 8h19v15H37z" />
      <path
        style={{
          fill: 'none',
          stroke: !!props.color ? props.color : '#6C6C6C',
          strokeMiterlimit: '10',
          strokeLinecap: 'round',
          strokeWidth: '2.58px',
        }}
        d="M53 7L37 23a1 1 0 0 1-2 0l-3-4"
      />
    </svg>
  )
}

export const UserSettings = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        style={{
          fill: !!props.color ? props.color : '#6C6C6C',
        }}
        d="M256,181c-33.091,0-60,26.909-60,60s26.909,60,60,60s60-26.909,60-60S289.091,181,256,181z"
      />
      <path
        style={{
          fill: !!props.color ? props.color : '#6C6C6C',
        }}
        d="M499.461,211.205l-39.848-6.475c-4.995-19.893-12.847-38.789-23.408-56.411l22.91-32.095
			c4.263-5.962,3.589-14.136-1.597-19.321l-42.422-42.422c-5.215-5.2-13.374-5.889-19.321-1.597l-32.095,22.91
			c-17.622-10.562-36.519-18.413-56.411-23.408l-6.475-39.848C299.594,5.303,293.339,0,286,0h-60
			c-7.339,0-13.594,5.303-14.795,12.539l-6.475,39.848c-19.893,4.995-38.789,12.847-56.411,23.408l-32.095-22.91
			c-5.962-4.292-14.15-3.604-19.321,1.597L54.481,96.903c-5.186,5.186-5.859,13.359-1.597,19.321l22.91,32.095
			c-10.562,17.622-18.413,36.519-23.408,56.411l-39.848,6.475C5.303,212.406,0,218.661,0,226v60c0,7.339,5.303,13.594,12.539,14.795
			l39.848,6.475c4.995,19.893,12.847,38.789,23.408,56.411l-22.91,32.095c-4.263,5.962-3.589,14.136,1.597,19.321l42.422,42.422
			c5.171,5.171,13.359,5.83,19.321,1.597l32.095-22.91c17.622,10.562,36.519,18.413,56.411,23.408l6.475,39.848
			C212.406,506.697,218.661,512,226,512h60c7.339,0,13.594-5.303,14.795-12.539l6.475-39.848
			c19.893-4.995,38.789-12.847,56.411-23.408l32.095,22.91c5.947,4.233,14.106,3.574,19.321-1.597l42.422-42.422
			c5.186-5.186,5.859-13.359,1.597-19.321l-22.91-32.095c10.562-17.622,18.413-36.519,23.408-56.411l39.848-6.475
			C506.697,299.594,512,293.339,512,286v-60C512,218.661,506.697,212.406,499.461,211.205z M256,421c-90.981,0-165-74.019-165-165
			S165.019,91,256,91s165,74.019,165,165S346.981,421,256,421z"
      />
      <path
        style={{
          fill: !!props.color ? props.color : '#6C6C6C',
        }}
        d="M325.29,297.732C308.773,317.866,284.017,331,256,331c-28.017,0-52.773-13.134-69.291-33.268
			c-14.379,12.744-24.868,29.451-30.654,48.197C180.784,373.387,216.224,391,256,391s75.216-17.613,99.944-45.071
			C350.158,327.183,339.67,310.476,325.29,297.732z"
      />
    </svg>
  )
}

export const Daffodil = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 55.04 55.04"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <defs>
        <path id="a" d="M0 .1h104.2V85H0z" />
        <path id="c" d="M0 .4h104.2v84.9H0z" />
        <path id="e" d="M0 .4h47.1V170H0z" />
        <path id="g" d="M.6.4h46.7v99.2H.6z" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(101 130.6)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill="#5F5F5F"
            d="M104.2.4c0 23.8-7.8 44.2-23.4 61A80.6 80.6 0 0124 85L.1 54.8l24-29.4h6.3c6.5 0 12.3-2.5 16.8-6.5 3.3-3 22.3-9.3 57-18.8v.3z"
            mask="url(#b)"
          />
        </g>
        <g transform="translate(101 45.6)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            fill="#6C6C6C"
            d="M104.2 85.3c-34.9-9.7-54.1-16-57.8-19-4.3-3.7-9.9-6-16-6h-6.3L.1 30.7 24 .4C46.2.5 66.2 9.5 80.8 24a86.7 86.7 0 0123.4 61v.3z"
            mask="url(#d)"
          />
        </g>
        <path
          fill="#4E4E4E"
          d="M147.4 150.1a25 25 0 00-.5-38.7c28.2-3.9 58.3 0 58.3 19.3 0 19.6-29.8 23.2-57.8 19.4"
        />
        <g transform="translate(78 45.6)">
          <mask id="f" fill="#fff">
            <use xlinkHref="#e" />
          </mask>
          <path
            fill="#5E5E5E"
            d="M10.1.4h37l-20 84.8L46.5 170H10C4.5 170 0 165.5 0 159.9V10.5C0 5 4.5.5 10.1.5"
            mask="url(#f)"
          />
        </g>
        <g transform="translate(78 45.6)">
          <mask id="h" fill="#fff">
            <use xlinkHref="#g" />
          </mask>
          <path
            fill="#505050"
            d="M47.3.4V71l-20 28.6L10 85.4a34 34 0 01-5.4-4.2C-14.4 62.2 40.6.4 47 .4h.3"
            mask="url(#h)"
          />
        </g>
        <path
          fill="#444"
          d="M125 215.6c-6.5 0-61.5-61.7-42.4-80.8 2-2 4.7-4 8-5.9l18.6 2 16.1 14.3v70.4h-.3z"
        />
        <path
          fill="#3D3D3D"
          d="M87.7 130.8c8.5-5.7 22.4-10.9 37.6-14.4v28.8c-15.2-3.5-29-8.7-37.6-14.4"
        />
      </g>
    </svg>
  )
}

export const AwaitingDeposit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.81 16.81"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        style={{ fill: '#ffe000' }}
        d="M8.4 0a8.4 8.4 0 1 0 8.4 8.4A8.4 8.4 0 0 0 8.4 0zm2.9 12.8l-2.9-1.5-2.9 1.5.6-3.2-2.3-2.2L7 6.9 8.4 4l1.4 2.9 3.2.5-2.3 2.2z"
      />
    </svg>
  )
}

export const CancelledInsideCooling = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.81 16.81"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        style={{ fill: '#20409a' }}
        d="M8.4 0a8.4 8.4 0 1 0 8.4 8.4A8.4 8.4 0 0 0 8.4 0zm3.3 12.4a.7.7 0 0 1-.3.3.8.8 0 0 1-.4 0 .8.8 0 0 1-.4 0 .9.9 0 0 1-.3-.3L10 12 8.4 9.5l-1.6 2.4-.2.4a1.5 1.5 0 0 1-.2.2.8.8 0 0 1-.3.2.9.9 0 0 1-.3 0 .7.7 0 0 1-.5-.1.7.7 0 0 1-.3-.6 2 2 0 0 1 .5-1l1.9-2.8-1.6-2.4a4.9 4.9 0 0 1-.4-.7 1.2 1.2 0 0 1 0-.4.6.6 0 0 1 .1-.5.8.8 0 0 1 .6-.2.7.7 0 0 1 .5.2 5.4 5.4 0 0 1 .6.8l1.2 2 1.4-2 .3-.5a2.4 2.4 0 0 1 .2-.3.7.7 0 0 1 .3-.1.8.8 0 0 1 .3-.1.7.7 0 0 1 .5.2.6.6 0 0 1 .2.5 2.1 2.1 0 0 1-.4 1L9.5 8.2l1.8 2.8a4.6 4.6 0 0 1 .3.6 1 1 0 0 1 .2.5.7.7 0 0 1-.1.3z"
      />
    </svg>
  )
}

export const CancelledOutsideCooling = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.81 16.81"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        style={{ fill: 'red' }}
        d="M8.4 0a8.4 8.4 0 1 0 8.4 8.4A8.4 8.4 0 0 0 8.4 0zm3.3 12.4a.7.7 0 0 1-.3.3.8.8 0 0 1-.4 0 .8.8 0 0 1-.4 0 .9.9 0 0 1-.3-.3L10 12 8.4 9.5l-1.6 2.4-.2.4a1.5 1.5 0 0 1-.2.2.8.8 0 0 1-.3.2.9.9 0 0 1-.3 0 .7.7 0 0 1-.5-.1.7.7 0 0 1-.3-.6 2 2 0 0 1 .5-1l1.9-2.8-1.6-2.4a4.9 4.9 0 0 1-.4-.7 1.2 1.2 0 0 1 0-.4.6.6 0 0 1 .1-.5.8.8 0 0 1 .6-.2.7.7 0 0 1 .5.2 5.4 5.4 0 0 1 .6.8l1.2 2 1.4-2 .3-.5a2.4 2.4 0 0 1 .2-.3.7.7 0 0 1 .3-.1.8.8 0 0 1 .3-.1.7.7 0 0 1 .5.2.6.6 0 0 1 .2.5 2.1 2.1 0 0 1-.4 1L9.5 8.2l1.8 2.8a4.6 4.6 0 0 1 .3.6 1 1 0 0 1 .2.5.7.7 0 0 1-.1.3z"
      />
    </svg>
  )
}

export const Complete = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.81 16.81"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        style={{ fill: '#85b80c' }}
        d="M8.4 0a8.4 8.4 0 1 0 8.4 8.4A8.4 8.4 0 0 0 8.4 0zM14 6l-6.4 7a.5.5 0 0 1-.7 0l-4-4a.5.5 0 0 1 0-.8l1.4-1.4a.5.5 0 0 1 .7 0l2.1 2 4.6-5a.5.5 0 0 1 .7 0L14 5.3a.5.5 0 0 1 0 .7z"
      />
    </svg>
  )
}

export const DeliveryThresholdReached = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.81 16.81"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        d="M8.4 0a8.4 8.4 0 1 0 8.4 8.4A8.4 8.4 0 0 0 8.4 0z"
        style={{ fill: '#85b80c' }}
      />
      <path
        d="M3.6 4.5h4.9a1.1 1.1 0 0 1 1.1 1.1v3.1a1.1 1.1 0 0 1-1.1 1.1H3.7a.5.5 0 0 1-.6-.5V5a.5.5 0 0 1 .5-.5zM2.6 10.7h11.5"
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeLinecap: 'round',
          strokeMiterlimit: '10',
          strokeWidth: '0.45px',
        }}
      />
      <path
        d="M11.5 5.2h1.2A1.8 1.8 0 0 1 14.5 7v3.4a.5.5 0 0 1-.5.6h-2.5a.5.5 0 0 1-.6-.6V5.7a.5.5 0 0 1 .6-.6zM3.6 11.7a1.2 1.2 0 1 0 2.1-.7H4a1.2 1.2 0 0 0-.3.7z"
        style={{ fill: '#fff' }}
      />
      <path
        d="M10 11.7a1.2 1.2 0 1 0 2.2-.7h-1.9a1.2 1.2 0 0 0-.3.7z"
        style={{ fill: '#fff' }}
      />
      <circle cx="4.8" cy="11.7" r=".5" style={{ fill: '#85b80c' }} />
      <circle cx="11.3" cy="11.7" r=".5" style={{ fill: '#85b80c' }} />
      <path
        d="M11.9 5.8h.9a1 1 0 0 1 1 1v1.4a.3.3 0 0 1-.3.3h-1.6a.3.3 0 0 1-.3-.3V6.1a.3.3 0 0 1 .3-.3z"
        style={{ fill: '#85b80c' }}
      />
      <path
        d="M1.9 6.4h4M3 7.7h2.9"
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeLinecap: 'round',
          strokeMiterlimit: '10',
          strokeWidth: '0.45px',
        }}
      />
    </svg>
  )
}

export const PaymentFailure = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.81 16.81"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        style={{ fill: '#f7941d' }}
        d="M8.4 0a8.4 8.4 0 1 0 8.4 8.4A8.4 8.4 0 0 0 8.4 0zm3.2 12.5H5.2v-1.1a2.5 2.5 0 0 0 1.6-2 3.9 3.9 0 0 0 0-.6H5.4V7.3h1.1a6 6 0 0 1-.1-1.1 2.9 2.9 0 0 1 3.2-3 3.6 3.6 0 0 1 1.6.4L11 5.2A2.4 2.4 0 0 0 9.7 5a1.2 1.2 0 0 0-1.3 1.3 4.4 4.4 0 0 0 .1 1h2v1.5H8.6a2.6 2.6 0 0 1-.1 1 2.4 2.4 0 0 1-.7 1h3.7z"
      />
    </svg>
  )
}

export const MultiplePaymentFailure = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.81 16.81"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        style={{ fill: 'red' }}
        d="M8.4 0a8.4 8.4 0 1 0 8.4 8.4A8.4 8.4 0 0 0 8.4 0zm3.2 12.5H5.2v-1.1a2.5 2.5 0 0 0 1.6-2 3.9 3.9 0 0 0 0-.6H5.4V7.3h1.1a6 6 0 0 1-.1-1.1 2.9 2.9 0 0 1 3.2-3 3.6 3.6 0 0 1 1.6.4L11 5.2A2.4 2.4 0 0 0 9.7 5a1.2 1.2 0 0 0-1.3 1.3 4.4 4.4 0 0 0 .1 1h2v1.5H8.6a2.6 2.6 0 0 1-.1 1 2.4 2.4 0 0 1-.7 1h3.7z"
      />
    </svg>
  )
}

export const Active = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.81 16.81"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        style={{ fill: '#85b80c' }}
        d="M8.4 0a8.4 8.4 0 1 0 8.4 8.4A8.4 8.4 0 0 0 8.4 0zm3.2 12.5H5.2v-1.1a2.5 2.5 0 0 0 1.6-2 3.9 3.9 0 0 0 0-.6H5.4V7.3h1.1a6 6 0 0 1-.1-1.1 2.9 2.9 0 0 1 3.2-3 3.6 3.6 0 0 1 1.6.4L11 5.2A2.4 2.4 0 0 0 9.7 5a1.2 1.2 0 0 0-1.3 1.3 4.4 4.4 0 0 0 .1 1h2v1.5H8.6a2.6 2.6 0 0 1-.1 1 2.4 2.4 0 0 1-.7 1h3.7z"
        data-name="Agent Dashboard"
      />
    </svg>
  )
}

export const CashDeposit = ({ colour, style }) => {
  return (
    <span
      style={{
        backgroundColor: colour,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px',
        borderRadius: '50%',
        ...style,
      }}
    >
      <svg style={{ width: '20px', height: '20px' }} viewBox="0 0 24 24">
        <path
          fill="#fff"
          d="M3,6H21V18H3V6M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M7,8A2,2 0 0,1 5,10V14A2,2 0 0,1 7,16H17A2,2 0 0,1 19,14V10A2,2 0 0,1 17,8H7Z"
        />
      </svg>
    </span>
  )
}

export const OneOffPayment = ({ colour, style }) => {
  return (
    <span
      style={{
        backgroundColor: colour,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px',
        borderRadius: '50%',
        ...style,
      }}
    >
      <svg style={{ width: '20px', height: '20px' }} viewBox="0 0 24 24">
        <path
          fill="#fff"
          d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
        />
      </svg>
    </span>
  )
}

export const ScheduledPayment = ({ colour, style }) => {
  return (
    <span
      style={{
        backgroundColor: colour,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px',
        borderRadius: '50%',
        ...style,
      }}
    >
      <Event style={{ color: 'white' }} fontSize="small" />
    </span>
  )
}

export const CardValidation = ({ colour, style }) => {
  return (
    <span
      style={{
        backgroundColor: colour,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px',
        borderRadius: '50%',
        ...style,
      }}
    >
      <Check style={{ color: 'white' }} fontSize="small" />
    </span>
  )
}

export const CardDeposit = ({ colour, style }) => {
  return (
    <span
      style={{
        backgroundColor: colour,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px',
        borderRadius: '50%',
        ...style,
      }}
    >
      <CreditCard style={{ color: 'white' }} fontSize="small" />
    </span>
  )
}

export const Retry = ({ colour, style }) => {
  return (
    <span
      style={{
        backgroundColor: colour,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px',
        borderRadius: '50%',
        ...style,
      }}
    >
      <Redo style={{ color: 'white' }} fontSize="small" />
    </span>
  )
}

export const Refund = ({ colour, style }) => {
  return (
    <span
      style={{
        backgroundColor: colour,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px',
        borderRadius: '50%',
        ...style,
      }}
    >
      <Undo style={{ color: 'white' }} fontSize="small" />
    </span>
  )
}

export const Cancelled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.81 16.81"
      style={{ width: '25px', height: '100%', maxHeight: '30px' }}
    >
      <path
        style={{ fill: 'red' }}
        d="M8.4 0a8.4 8.4 0 1 0 8.4 8.4A8.4 8.4 0 0 0 8.4 0zm3.3 12.4a.7.7 0 0 1-.3.3.8.8 0 0 1-.4 0 .8.8 0 0 1-.4 0 .9.9 0 0 1-.3-.3L10 12 8.4 9.5l-1.6 2.4-.2.4a1.5 1.5 0 0 1-.2.2.8.8 0 0 1-.3.2.9.9 0 0 1-.3 0 .7.7 0 0 1-.5-.1.7.7 0 0 1-.3-.6 2 2 0 0 1 .5-1l1.9-2.8-1.6-2.4a4.9 4.9 0 0 1-.4-.7 1.2 1.2 0 0 1 0-.4.6.6 0 0 1 .1-.5.8.8 0 0 1 .6-.2.7.7 0 0 1 .5.2 5.4 5.4 0 0 1 .6.8l1.2 2 1.4-2 .3-.5a2.4 2.4 0 0 1 .2-.3.7.7 0 0 1 .3-.1.8.8 0 0 1 .3-.1.7.7 0 0 1 .5.2.6.6 0 0 1 .2.5 2.1 2.1 0 0 1-.4 1L9.5 8.2l1.8 2.8a4.6 4.6 0 0 1 .3.6 1 1 0 0 1 .2.5.7.7 0 0 1-.1.3z"
      />
    </svg>
  )
}
