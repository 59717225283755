import React, { Fragment } from "react";
import { Query, Mutation } from "@apollo/react-components";
import gql from "graphql-tag";
import { Form, Field } from "react-final-form";

import {
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  IconButton,
  Tooltip,
  CardHeader,
} from "@material-ui/core";

import { Save, Clear, Add } from "@material-ui/icons";

import { GET_UI_LIST_ENTRIES } from "./../../../helpers/queries/CustomerVisitOutcome";
import { openSnackbar } from "./../../reusable/Notifier";
import { openModal, closeModal } from "./../../reusable/Popup";

export default function UIListEntryManager(props) {
  return (
    <>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Typography component="h1" variant="h4" gutterBottom>
          {props.title}
        </Typography>
        <Button
          variant="contained"
          style={{
            marginLeft: "auto",
          }}
          color="secondary"
          onClick={() => {
            openModal({
              content: (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="Close Pop-up"
                        onClick={closeModal}
                      >
                        <Clear />
                      </IconButton>
                    }
                    title="Add Entry"
                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Mutation
                        mutation={gql`
                          mutation (
                            $name: String!
                            $value: String!
                            $type: String!
                          ) {
                            newUIListEntry(
                              name: $name
                              value: $value
                              type: $type
                            ) {
                              error {
                                message
                              }
                              entry {
                                _id
                                name
                                value
                                type
                              }
                            }
                          }
                        `}
                        refetchQueries={[
                          {
                            query: GET_UI_LIST_ENTRIES,
                            variables: { type: props.type },
                          },
                        ]}
                      >
                        {(newUIListEntry) => (
                          <Form
                            onSubmit={(values) => {
                              newUIListEntry({
                                variables: {
                                  name: values.name,
                                  value: values.value,
                                  type: props.type,
                                },
                              }).then(({ data }) => {
                                const { newUIListEntry } = data;

                                if (newUIListEntry.error) {
                                  openSnackbar({
                                    message: newUIListEntry.error.message,
                                    type: "error",
                                  });
                                } else {
                                  openSnackbar({
                                    message: "Entry updated successfully",
                                    type: "success",
                                  });
                                  closeModal();
                                }
                              });
                            }}
                            render={({
                              handleSubmit,
                              pristine,
                              submitting,
                            }) => (
                              <Grid item xs={12}>
                                <form
                                  onSubmit={handleSubmit}
                                  autoComplete="off"
                                >
                                  <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} md={4}>
                                      <Field name="name">
                                        {({ input }) => (
                                          <TextField
                                            {...input}
                                            label="Entry Name"
                                            fullWidth
                                            variant="outlined"
                                            required
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Field name="value">
                                        {({ input }) => (
                                          <TextField
                                            {...input}
                                            label="Entry Value"
                                            fullWidth
                                            variant="outlined"
                                            required
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                        disabled={pristine || submitting}
                                      >
                                        <Save style={{ marginRight: "10px" }} />
                                        Save
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </form>
                              </Grid>
                            )}
                          />
                        )}
                      </Mutation>
                    </Grid>
                  </CardContent>
                </Card>
              ),
            });
          }}
        >
          <Add style={{ marginRight: "10px" }} />
          Add Entry
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3} alignItems="center">
              <Query
                query={GET_UI_LIST_ENTRIES}
                variables={{ type: props.type }}
              >
                {({ loading, error, data }) => {
                  if (loading || error) {
                    return null;
                  }

                  let { entries } = data;

                  return (
                    <Fragment>
                      {entries.map((entry) => (
                        <Mutation
                          key={entry._id}
                          mutation={gql`
                            mutation (
                              $_id: String!
                              $name: String!
                              $value: String!
                              $type: String!
                            ) {
                              updateUIListEntry(
                                _id: $_id
                                name: $name
                                value: $value
                                type: $type
                              ) {
                                error {
                                  message
                                }
                                entry {
                                  _id
                                  name
                                  value
                                  type
                                }
                              }
                            }
                          `}
                          refetchQueries={[
                            {
                              query: GET_UI_LIST_ENTRIES,
                              variables: { type: props.type },
                            },
                          ]}
                        >
                          {(updateEntry) => (
                            <Form
                              initialValues={{
                                name: entry.name,
                                value: entry.value,
                              }}
                              onSubmit={(values) => {
                                updateEntry({
                                  variables: {
                                    _id: entry._id,
                                    name: values.name,
                                    value: values.value,
                                    type: props.type,
                                  },
                                }).then(({ data }) => {
                                  const { updateUIListEntry } = data;

                                  if (updateUIListEntry.error) {
                                    openSnackbar({
                                      message: updateUIListEntry.error.message,
                                      type: "error",
                                    });
                                  } else {
                                    openSnackbar({
                                      message: "Entry updated successfully",
                                      type: "success",
                                    });
                                  }
                                });
                              }}
                              render={({
                                handleSubmit,
                                pristine,
                                submitting,
                              }) => (
                                <Grid item xs={12}>
                                  <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                  >
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems="center"
                                    >
                                      <Grid item xs={12} md={1}>
                                        <Mutation
                                          mutation={gql`
                                            mutation ($_id: String!) {
                                              deleteUIListEntry(_id: $_id) {
                                                error {
                                                  message
                                                }
                                                entry {
                                                  _id
                                                  name
                                                  value
                                                  type
                                                }
                                              }
                                            }
                                          `}
                                          refetchQueries={[
                                            {
                                              query: GET_UI_LIST_ENTRIES,
                                              variables: { type: props.type },
                                            },
                                          ]}
                                        >
                                          {(deleteEntry) => (
                                            <Tooltip
                                              title="Delete Entry"
                                              placement="top"
                                            >
                                              <IconButton
                                                aria-label="Delete Entry"
                                                onClick={() => {
                                                  deleteEntry({
                                                    variables: {
                                                      _id: entry._id,
                                                    },
                                                  }).then(({ data }) => {
                                                    const {
                                                      deleteUIListEntry,
                                                    } = data;

                                                    if (
                                                      deleteUIListEntry.error
                                                    ) {
                                                      openSnackbar({
                                                        message:
                                                          deleteUIListEntry
                                                            .error.message,
                                                        type: "error",
                                                      });
                                                    } else {
                                                      openSnackbar({
                                                        message:
                                                          "Entry deleted successfully",
                                                        type: "success",
                                                      });
                                                    }
                                                  });
                                                }}
                                              >
                                                <Clear />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </Mutation>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="name">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Entry Name"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="value">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Entry Value"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={3}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          type="submit"
                                          fullWidth
                                          disabled={pristine || submitting}
                                        >
                                          <Save
                                            style={{ marginRight: "10px" }}
                                          />
                                          Save
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </form>
                                </Grid>
                              )}
                            />
                          )}
                        </Mutation>
                      ))}
                    </Fragment>
                  );
                }}
              </Query>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
