import React, { Fragment, useState, useContext } from 'react'
import gql from 'graphql-tag'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { ApolloConsumer, Mutation } from '@apollo/react-components'
import { Form, Field } from 'react-final-form'
import {
  Loading,
  ErrorMessage,
  Button,
} from '@web-applications/daffodil-component-library'
import {
  Typography,
  Grid,
  Divider,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { Save, Edit, Clear } from '@material-ui/icons'

import { openSnackbar } from '../../../components/reusable/Notifier'
import Dropdown from '../../../components/reusable/Dropdown'
import { UserContext } from '../../../components/util/PageWrapper'

const GET_ITEMS = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      items {
        room
        name
        colour
        size
        type
        code
        subfloor
        underlay
        grippers
        product {
          _id
        }
        doorbars {
          code
          description
        }
      }
    }
  }
`

const GET_PRODUCT = gql`
  query product($name: String!) {
    product(name: $name) {
      _id
      name
      type
      archived
      colours {
        code
        name
      }
      sizes {
        dimension
        price
        code
        delivery_charge
        fitting_charge
      }
    }
  }
`

const UPDATE_ITEM_COLOUR = gql`
  mutation updateItemColour(
    $order_id: String!
    $item_index: Int!
    $colour: String!
    $user_id: String!
  ) {
    updateItemColour(
      order_id: $order_id
      item_index: $item_index
      colour: $colour
      user_id: $user_id
    ) {
      error {
        message
      }
      order {
        _id
        items {
          room
          name
          colour
          size
          type
          code
          subfloor
          underlay
          grippers
          doorbars {
            code
            description
          }
        }
      }
    }
  }
`

export default function Items() {
  const { invoiceNumber } = useParams()

  const [editItem, setEditItem] = useState('')
  const [colourList, setColourList] = useState([])

  const user = useContext(UserContext)

  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(GET_ITEMS, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  })

  if (orderLoading) return <Loading />

  if (orderError) return <ErrorMessage error={orderError} />

  //check if product colour can be changed

  let changeFloorColourAllowed = false
  let changeBedColourAllowed = false

  //Delivery
  if (
    (orderData.getOrder.delivery_date &&
      moment(orderData.getOrder.delivery_date, 'x') > moment()) ||
    !orderData.getOrder.delivery_date
  ) {
    changeBedColourAllowed = true
  }

  //Fitting
  if (
    (orderData.getOrder.fitting_date &&
      moment(orderData.getOrder.fitting_date, 'x') > moment()) ||
    !orderData.getOrder.fitting_date
  ) {
    changeFloorColourAllowed = true
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" gutterBottom>
          Items
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {orderData.getOrder.items.map((item, index) => (
          <Fragment key={index}>
            <Typography
              variant="body1"
              style={{
                marginTop: index === 0 ? '0' : '15px',
              }}
            >
              {`${item.code} - ${item.name}`}
            </Typography>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div>
                {(item.type === 'Bed' && changeBedColourAllowed
                  ? true
                  : (item.type === 'Vinyl' || item.type === 'Carpet') &&
                    changeFloorColourAllowed
                  ? true
                  : false) && (
                  <ApolloConsumer>
                    {(client) => (
                      <Tooltip title="Edit Colour" placement="top">
                        <IconButton
                          aria-label="Edit"
                          style={{
                            marginLeft: 'auto',
                            padding: '0',
                          }}
                          onClick={async (e) => {
                            if (editItem === index) {
                              setEditItem('')
                            } else {
                              const sanitisedColours = []

                              const { data } = await client.query({
                                query: GET_PRODUCT,
                                variables: {
                                  name: item.name,
                                },
                              })

                              if (data.product) {
                                const { colours } = data.product

                                colours.forEach((colour) => {
                                  sanitisedColours.push({
                                    value: colour.name,
                                    label: colour.name,
                                  })
                                })
                              }
                              setEditItem(index)
                              setColourList(sanitisedColours)
                            }
                          }}
                        >
                          {editItem === index ? (
                            <Clear fontSize="small" />
                          ) : (
                            <Edit fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </ApolloConsumer>
                )}
              </div>

              <Typography
                variant="body1"
                style={{
                  paddingLeft: '10px',
                  paddingRight: '16px',
                }}
              >
                {`Colour: ${item.colour}`}
                <br />
                {`Size: ${item.size}`}
                <br />
                {`Room: ${item.room}`}
                <br />
                {item.subfloor && (
                  <Fragment>
                    {`Subfloor: ${item.subfloor}`}
                    <br />
                  </Fragment>
                )}
                {item.underlay && (
                  <Fragment>
                    {`Underlay: ${item.underlay.replace(/^\w/, (c) =>
                      c.toUpperCase()
                    )}`}
                    <br />
                  </Fragment>
                )}
                {item.grippers && (
                  <Fragment>
                    {`Grippers: ${item.grippers.replace(/^\w/, (c) =>
                      c.toUpperCase()
                    )}`}
                    <br />
                  </Fragment>
                )}
              </Typography>
              {editItem === index && (
                <Mutation mutation={UPDATE_ITEM_COLOUR}>
                  {(updateItemColour) => (
                    <Form
                      initialValues={{}}
                      onSubmit={(values) => {
                        updateItemColour({
                          variables: {
                            order_id: orderData.getOrder._id,
                            item_index: index,
                            colour: values.colour,
                            user_id: user._id,
                          },
                        }).then(({ data }) => {
                          const { updateItemColour } = data

                          if (updateItemColour.error) {
                            openSnackbar({
                              message: updateItemColour.error.message,
                              type: 'error',
                            })
                          } else {
                            openSnackbar({
                              message: 'Colour updated successfully',
                              type: 'success',
                            })

                            setEditItem('')
                            setColourList([])
                          }
                        })
                      }}
                      render={({ handleSubmit, pristine, submitting }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                          <Field name={`colour`}>
                            {({ input }) => {
                              return (
                                <Dropdown
                                  input={input}
                                  margin="dense"
                                  name={`colour`}
                                  items={colourList}
                                  component={Dropdown}
                                  placeholder="Type a Colour"
                                  label="Colour"
                                  required
                                />
                              )
                            }}
                          </Field>
                          {!pristine && !submitting && (
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              icon={<Save />}
                            >
                              Save
                            </Button>
                          )}
                        </form>
                      )}
                    />
                  )}
                </Mutation>
              )}
            </div>

            {!!item?.doorbars?.length && (
              <Fragment>
                <Typography
                  variant="body1"
                  style={{
                    paddingLeft: '5%',
                  }}
                >
                  Doorbars:
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    paddingLeft: '10%',
                  }}
                >
                  {item.doorbars.map((doorbar, index) => (
                    <Fragment key={index}>
                      {`${doorbar.code} - ${doorbar.description}`}
                      <br />
                    </Fragment>
                  ))}
                </Typography>
              </Fragment>
            )}
            <Divider
              style={{
                marginTop: '20px',
              }}
            />
          </Fragment>
        ))}
      </Grid>
    </Grid>
  )
}
