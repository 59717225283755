import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import {
  Loading,
  ErrorMessage,
  Button,
} from '@web-applications/daffodil-component-library'
import {
  Grid,
  Card,
  CardContent,
  TextField,
  IconButton,
  CardHeader,
  CardActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import NumberFormat from 'react-number-format'

import { openSnackbar } from '../../../../components/reusable/Notifier'

const TAKE_ONE_OFF_PAYMENT = gql`
  mutation ($orderId: ID!, $amount: String!, $paymentMethod: String!) {
    takeOneOffPayment(
      orderId: $orderId
      amount: $amount
      paymentMethod: $paymentMethod
    )
  }
`

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
    }
  }
`

const ONE_OFF_CARD_PAYMENT_PAGE = gql`
  mutation oneOffCardPaymentPage($invoice_number: Int!, $amount: String!) {
    oneOffCardPaymentPage(invoice_number: $invoice_number, amount: $amount) {
      payment_page
    }
  }
`
var takingPayment = false

export default function TakePaymentModal({ hideModal }) {
  const { invoiceNumber } = useParams()

  const [takeOneOffPayment, { loading: takeOneOffPaymentLoading }] =
    useMutation(TAKE_ONE_OFF_PAYMENT, {
      onError: () => {
        hideModal()
        openSnackbar({
          message: 'One-off payment failed',
          type: 'error',
        })
        takingPayment=false
      },
      onCompleted: () => {
        hideModal()
        openSnackbar({
          message: 'One-off payment taken successfully',
          type: 'success',
        })
        takingPayment=false
      },
    })

  const [oneOffCardPaymentPageUrl, setOneOffCardPaynentPageUrl] = useState('')

  
  const [oneOffCardPaymentPage, { loading: oneOffCardPaymentPageLoading }] =
    useMutation(ONE_OFF_CARD_PAYMENT_PAGE, {
      onError: () => {
        openSnackbar({
          message: 'Failed to take payment, please try again',
          type: 'error',
        })
        takingPayment=false
      },
      onCompleted: ({ oneOffCardPaymentPage }) => {
        setOneOffCardPaynentPageUrl(oneOffCardPaymentPage.payment_page)
        document.getElementById('oneOffPaymentForm').submit()
        takingPayment=false
      },
    })

  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  })

  if (orderLoading) return <Loading />

  if (orderError) return <ErrorMessage error={orderError} />

  const { getOrder: order } = orderData

  return (
    <Form
      initialValues={{
        paymentMethod: 'existing',
      }}
      validate={(values) => {
        const errors = {}

        if (!values.amount || !parseFloat(values.amount)) {
          errors.amount = 'Required'
        }

        if (
          values.amount &&
          parseFloat(values.amount) > parseFloat(order.to_pay)
        ) {
          errors.amount = 'Amount cannot be more than the remaining cost'
        }

        return errors
      }}
      onSubmit={(values) => {
        if(takingPayment){
          return
        }
        takingPayment=true
        takeOneOffPayment({
          variables: {
            amount: values.amount || 0.0,
            orderId: order._id,
            paymentMethod: values.paymentMethod,
          },
        })
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Card>
            <CardHeader
              action={
                <IconButton aria-label="Close Pop-up" onClick={hideModal}>
                  <Clear />
                </IconButton>
              }
              title="Take One Off Payment"
            />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <RadioGroup aria-label="Deposit Method" name="paymentMethod">
                    <Field name="paymentMethod" type="radio" value="existing">
                      {({ input }) => (
                        <FormControlLabel
                          value="existing"
                          control={<Radio {...input} />}
                          label="Existing Card"
                        />
                      )}
                    </Field>
                    <Field name="paymentMethod" type="radio" value="new">
                      {({ input }) => (
                        <FormControlLabel
                          value="new"
                          control={<Radio {...input} />}
                          label="New Card"
                        />
                      )}
                    </Field>
                    <Field name="paymentMethod" type="radio" value="manual">
                      {({ input }) => (
                        <FormControlLabel
                          value="manual"
                          control={<Radio {...input} />}
                          label="Manual Cash/BACS"
                        />
                      )}
                    </Field>
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="amount">
                    {({ input, meta }) => (
                      <NumberFormat
                        customInput={TextField}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        {...input}
                        label="Amount"
                        displayType="tel"
                        decimalScale={2}
                        fixedDecimalScale
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ''
                        }
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button variant="contained" color="secondary" onClick={hideModal}>
                Cancel
              </Button>

              {values.paymentMethod === 'new' ? (
                <>
                  <Button
                    loading={oneOffCardPaymentPageLoading}
                    onClick={() => {
                      if(takingPayment){
                        return
                      }
                      takingPayment=true
                      oneOffCardPaymentPage({
                        variables: {
                          invoice_number: parseInt(invoiceNumber),
                          amount: values.amount || '0.00',
                        },
                      })
                    }}
                  >
                    Continue
                  </Button>
                  {!!oneOffCardPaymentPageUrl && (
                    <form
                      action={oneOffCardPaymentPageUrl}
                      method="post"
                      id="oneOffPaymentForm"
                      style={{ display: 'none' }}
                    >
                      <input type="submit" value="Do Stuff!" />
                    </form>
                  )}
                </>
              ) : (
                <Button type="submit" loading={takeOneOffPaymentLoading}>
                  Continue
                </Button>
              )}
            </CardActions>
          </Card>
        </form>
      )}
    />
  )
}
