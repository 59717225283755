import gql from "graphql-tag";

export const GET_FRANCHISES = gql`
  query franchises {
    franchises {
      _id
      name
      merchant_id
      secret_key
      api_user
      api_user_password
      cert_password
      stores {
        _id
        name
        #   email: String!
        #   bed_phone: String!
        #   fitting_phone: String!
        #   type: String!
        #   franchise: Franchise!
        #   users: [User]
      }
      active
      payment_provider
      elavon_vendor_id
      max_failed_payment_attempts
      max_failed_payment_attempts_in_one_month
    }
  }
`;
