import React from 'react'

import UnsecureRoute from '../components/util/UnsecureRoute'

import FailedPaymentLanding from '../components/pages/FailedPaymentLink/FailedPaymentLanding'
import FailedPaymentSuccess from '../components/pages/FailedPaymentLink/FailedPaymentSuccess'
import FailedPaymentFailure from '../components/pages/FailedPaymentLink/FailedPaymentFailure'

const FailedPaymentLinkRoutes = [
  <UnsecureRoute
    path="/failed-payment/:linkReference"
    exact
    component={FailedPaymentLanding}
    title="Failed Payment"
  />,
  <UnsecureRoute
    path="/failed-payment/:linkReference/success"
    exact
    component={FailedPaymentSuccess}
    title="Failed Payment -  Success"
  />,
  <UnsecureRoute
    path="/failed-payment/:linkReference/failure"
    exact
    component={FailedPaymentFailure}
    title="Failed Payment -  Failure"
  />,
]

export default FailedPaymentLinkRoutes
