import React, { Fragment } from 'react'
import { Form, Field } from 'react-final-form'
import gql from 'graphql-tag'
import moment from 'moment'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { DatePicker } from '@material-ui/pickers'
import {
  Card,
  CardContent,
  IconButton,
  CardHeader,
  Grid,
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
} from '@material-ui/core'
import {
  Clear,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons'

import { closeModal } from './../../reusable/Popup'
import { openSnackbar } from './../../reusable/Notifier'
import Loading from '../../reusable/Loading'
import Error from '../../../newComponents/Error'

const GET_CANCELLATION = gql`
  query cancellation($cancellationId: String!) {
    cancellation(cancellationId: $cancellationId) {
      _id
      order {
        _id
        deposit_date
        order_type
        weekly_payment
      }
    }
  }
`

const GET_PAYMENTS = gql`
  query getPayments($orderId: ID!) {
    getPayments(orderId: $orderId) {
      _id
      status
      time
      payment_type
    }
  }
`

const ABORT_CANCELLATION = gql`
  mutation abortCancellation(
    $cancellationId: String!
    $reason: String!
    $depositDate: String
    $orderType: String
    $nextPaymentDate: String
    $weeklyPayment: Int
  ) {
    abortCancellation(
      cancellationId: $cancellationId
      reason: $reason
      depositDate: $depositDate
      orderType: $orderType
      nextPaymentDate: $nextPaymentDate
      weeklyPayment: $weeklyPayment
    ) {
      _id
      order {
        _id
        order_status
        invoice_number
        cancellation {
          _id
        }
      }
    }
  }
`

export default function RemoveCancellationPopup({ cancellationId, history }) {
  const [abortCancellation, { loading: abortCancellationLoading }] =
    useMutation(ABORT_CANCELLATION, {
      onError: () => {
        openSnackbar({
          message: 'Failed to abort cancellation, please try again.',
          type: 'error',
        })
      },
      onCompleted: ({ abortCancellation }) => {
        closeModal()
        openSnackbar({
          message: `Cancellation for Order ${abortCancellation.order.invoice_number} has been aborted.`,
          type: 'success',
        })
        history.push(`/orders/single/${abortCancellation.order.invoice_number}`)
      },
    })

  const {
    loading: cancellationDetailsLoading,
    data: cancellationDetails,
    error: cancellationDetailsError,
  } = useQuery(GET_CANCELLATION, {
    variables: { cancellationId: cancellationId },
  })

  const {
    data: paymentsData,
    loading: paymentsLoading,
    error: paymentsError,
  } = useQuery(GET_PAYMENTS, {
    variables: {
      orderId: cancellationDetails?.cancellation?.order?._id,
    },
    skip: cancellationDetails?.cancellation?.order?._id === undefined,
  })

  if (cancellationDetailsLoading || paymentsLoading) {
    return (
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="Close Pop-up" onClick={closeModal}>
              <Clear />
            </IconButton>
          }
          title="Abort Cancellation"
        />
        <CardContent>
          <Loading />
        </CardContent>
      </Card>
    )
  }

  if (cancellationDetailsError || paymentsError) {
    return (
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="Close Pop-up" onClick={closeModal}>
              <Clear />
            </IconButton>
          }
          title="Abort Cancellation"
        />
        <CardContent>
          <Error />
        </CardContent>
      </Card>
    )
  }

  const payments = paymentsData.getPayments
  const { cancellation } = cancellationDetails

  const orderType = cancellation.order.order_type
  //calculate if any on hold payment dates have passed

  let missedPayments = payments.filter(
    (payment) =>
      payment.status === 'ON HOLD' &&
      moment(payment.time) <= moment() &&
      payment.payment_type !== 'Card Deposit'
  )

  //calculate what the next payment date should be

  let nextPaymentDate

  if (missedPayments.length) {
    missedPayments.sort((a, b) => parseInt(b.time) - parseInt(a.time))

    //set next payment date to be the latest payment

    nextPaymentDate = moment(
      missedPayments[missedPayments.length - 1].time,
      'x'
    )

    //check next payment date isn't in the past, if it is, add another payment time on!

    while (nextPaymentDate <= moment()) {
      nextPaymentDate = nextPaymentDate.add(
        1,
        orderType === 'weekly' ? 'weeks' : 'months'
      )
    }
  }

  missedPayments = !!missedPayments.length

  let missedDeposit = payments.filter(
    (payment) =>
      payment.status === 'ON HOLD' &&
      moment(payment.time) <= moment() &&
      payment.payment_type === 'Card Deposit'
  )

  missedDeposit = !!missedDeposit.length

  let initialValues = {}

  if (missedPayments) {
    // since there's missed payments, we should suggest new dates and amounts that match the original schedule.
    initialValues = {
      orderType: orderType,
      nextPaymentDate: nextPaymentDate,
      weeklyPayment: cancellationDetails.cancellation.order.weekly_payment,
    }
  }

  return (
    <Card>
      <CardHeader
        action={
          <IconButton aria-label="Close Pop-up" onClick={closeModal}>
            <Clear />
          </IconButton>
        }
        title="Abort Cancellation"
      />
      <CardContent>
        {' '}
        <Form
          initialValues={initialValues}
          validate={(values) => {
            let errors = {}

            if (!values.reasonForAborting) {
              errors.reasonForAborting = 'Required'
            }

            if (missedDeposit && !values.depositDate) {
              errors.depositDate = 'Required'
            }

            if (missedPayments && !values.orderType) {
              errors.orderType = 'Required'
            }

            if (missedPayments && !values.nextPaymentDate) {
              errors.nextPaymentDate = 'Required'
            }

            if (missedPayments && !values.weeklyPayment) {
              errors.weeklyPayment = 'Required'
            }

            //don't let deposit date be later than the next payment date

            if (values.depositDate > values.nextPaymentDate) {
              errors.depositDate =
                'Deposit cannot be later than the next payment date'
            }

            return errors
          }}
          onSubmit={(values) => {
            abortCancellation({
              variables: {
                cancellationId: cancellationId,
                reason: values.reasonForAborting,
                depositDate: values.depositDate,
                orderType: values.orderType,
                nextPaymentDate: values.nextPaymentDate,
                weeklyPayment: parseInt(values.weeklyPayment),
              },
            })
          }}
        >
          {({ handleSubmit, invalid, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field name="reasonForAborting">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        label="Reason For Aborting"
                        required
                        multiline
                        rows="4"
                        fullWidth
                        variant="outlined"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ''
                        }
                      />
                    )}
                  </Field>
                </Grid>
                {missedDeposit && (
                  <Fragment>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {`The original deposit date (${moment(
                          cancellationDetails.cancellation.order.deposit_date,
                          'x'
                        ).format('DD/MM/YYYY')}) has passed whilst the order was
                      on hold, please enter a new one.`}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Field name="depositDate">
                        {({ input, meta }) => (
                          <DatePicker
                            format="DD/MM/YYYY"
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value ? input.value : null}
                            onBlur={input.onBlur}
                            fullWidth
                            required
                            disablePast
                            autoOk
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ''
                            }
                            minDate={moment().add(1, 'days')}
                            label="Deposit Date"
                            leftArrowIcon={<KeyboardArrowLeft />}
                            inputVariant="outlined"
                            rightArrowIcon={<KeyboardArrowRight />}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Fragment>
                )}
                {missedPayments && (
                  <Fragment>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Payment dates have passed since the order was on hold,
                        please restructure the order. We've suggested new dates
                        and amounts that match the original schedule.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" gutterBottom>
                        Order Type *
                      </Typography>
                      <RadioGroup
                        aria-label="Order Type"
                        name="orderType"
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          flexWrap: 'nowrap',
                          maxWidth: '35%',
                        }}
                      >
                        <Field name="orderType" type="radio" value="weekly">
                          {({ input, meta }) => (
                            <FormControlLabel
                              value="weekly"
                              control={<Radio {...input} />}
                              label={
                                <Typography
                                  variant="body2"
                                  style={{
                                    color:
                                      meta.touched && meta.error
                                        ? 'red'
                                        : 'rgba(0, 0, 0, 0.87)',
                                  }}
                                >
                                  Weekly
                                </Typography>
                              }
                            />
                          )}
                        </Field>
                        <Field name="orderType" type="radio" value="monthly">
                          {({ input, meta }) => (
                            <FormControlLabel
                              value="monthly"
                              control={<Radio {...input} />}
                              label={
                                <Typography
                                  variant="body2"
                                  style={{
                                    color:
                                      meta.touched && meta.error
                                        ? 'red'
                                        : 'rgba(0, 0, 0, 0.87)',
                                  }}
                                >
                                  Monthly
                                </Typography>
                              }
                            />
                          )}
                        </Field>
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field name="nextPaymentDate">
                        {({ input, meta }) => (
                          <DatePicker
                            format="DD/MM/YYYY"
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value ? input.value : null}
                            onBlur={input.onBlur}
                            fullWidth
                            required
                            disablePast
                            autoOk
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ''
                            }
                            minDate={moment().add(1, 'days')}
                            label="Next Payment Day"
                            leftArrowIcon={<KeyboardArrowLeft />}
                            inputVariant="outlined"
                            rightArrowIcon={<KeyboardArrowRight />}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="weeklyPayment">
                        {({ input, meta }) => (
                          <Fragment>
                            {values.orderType === 'weekly' ? (
                              <TextField
                                {...input}
                                select
                                label="Weekly Payment"
                                fullWidth
                                required
                                variant="outlined"
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : ''
                                }
                              >
                                <MenuItem value="10">£10</MenuItem>
                                <MenuItem value="15">£15</MenuItem>
                                <MenuItem value="18">£18</MenuItem>
                                <MenuItem value="20">£20</MenuItem>
                                <MenuItem value="25">£25</MenuItem>
                                <MenuItem value="30">£30</MenuItem>
                                <MenuItem value="50">£50</MenuItem>
                              </TextField>
                            ) : (
                              <TextField
                                {...input}
                                select
                                label="Monthly Payment"
                                fullWidth
                                required
                                variant="outlined"
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : ''
                                }
                              >
                                <MenuItem value="40">£40</MenuItem>
                                <MenuItem value="50">£50</MenuItem>
                                <MenuItem value="60">£60</MenuItem>
                                <MenuItem value="75">£75</MenuItem>
                                <MenuItem value="80">£80</MenuItem>
                                <MenuItem value="100">£100</MenuItem>
                              </TextField>
                            )}
                          </Fragment>
                        )}
                      </Field>
                    </Grid>
                  </Fragment>
                )}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={abortCancellationLoading || invalid}
                  >
                    {abortCancellationLoading
                      ? 'Loading'
                      : 'Abort Cancellation'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </CardContent>
    </Card>
  )
}
