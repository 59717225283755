import React from 'react'
import { Form } from 'react-final-form'
import validator from 'validator'
import createDecorator from 'final-form-calculate'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
} from '@material-ui/core'

import { Save } from '@material-ui/icons'

import Customer from './../../forms/Customer'

import { openSnackbar } from './../../reusable/Notifier'

const NEW_CUSTOMER = gql`
  mutation newCustomer(
    $title: String!
    $first_name: String!
    $last_name: String!
    $address_line_1: String!
    $address_line_2: String
    $address_city: String!
    $address_county: String!
    $address_postcode: String!
    $email: String!
    $phone: String
    $mobile: String
    $preferred_contact: String!
    $customer_type: String!
    $marketing_news: Boolean!
    $marketing_offers: Boolean!
  ) {
    newCustomer(
      title: $title
      first_name: $first_name
      last_name: $last_name
      address_line_1: $address_line_1
      address_line_2: $address_line_2
      address_city: $address_city
      address_county: $address_county
      address_postcode: $address_postcode
      email: $email
      phone: $phone
      mobile: $mobile
      preferred_contact: $preferred_contact
      customer_type: $customer_type
      marketing_news: $marketing_news
      marketing_offers: $marketing_offers
    ) {
      _id
      first_name
      last_name
    }
  }
`

export default function NewCustomer({ history }) {
  const [newCustomer, { loading }] = useMutation(NEW_CUSTOMER, {
    onCompleted: ({ newCustomer }) => {
      openSnackbar({
        message: `Customer ${newCustomer.first_name} ${newCustomer.last_name} created.`,
        type: 'success',
      })
      history.push('/customers')
    },
  })

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12}>
            <Typography component="h2" variant="h5">
              New Customer
            </Typography>
            <Divider />
          </Grid>
        </Grid>

        <Form
          onSubmit={(values) => {
            const response = newCustomer({
              variables: {
                title: values.title,
                first_name: values.firstName,
                last_name: values.lastName,
                address_line_1: values.addressLine1,
                address_line_2: values.addressLine2,
                address_city: values.addressCity,
                address_county: values.addressCounty,
                address_postcode: values.addressPostcode,
                email: values.email,
                phone: values.phone,
                mobile: values.mobile,
                customer_notes: values.customerNotes,
                preferred_contact: values.preferredContact,
                customer_type: values.customerType,
                marketing_news: values.marketingNews || false,
                marketing_offers: values.marketingOffers || false,
              },
            }).catch((errors) => {
              if (errors.message.includes('Email Already Used')) {
                openSnackbar({
                  message: 'Email already in use',
                  type: 'error',
                })

                return {
                  email: 'Email already in use',
                }
              }
              openSnackbar({
                message: 'Failed create customer, please try again',
                type: 'error',
              })
            })

            if (!response.data) return response
          }}
          validate={(values) => {
            const errors = {}

            const requiredFields = [
              'firstName',
              'lastName',
              'addressLine1',
              'addressCity',
              'email',
              'addressCounty',
              'addressPostcode',
              'title',
              'preferredContact',
              'customerType',
            ]

            requiredFields.forEach((requiredField) => {
              if (!values[requiredField]) {
                errors[requiredField] = 'Required'
              }
            })

            if (
              values.email &&
              !validator.isEmail(values.email) &&
              !values.email.includes('.con') &&
              !values.email.includes('.coma')
            ) {
              errors.email = 'Invalid Email'
            }

            if (
              values.addressPostcode &&
              (!validator.isPostalCode(values.addressPostcode, ['GB']) ||
                values.addressPostcode.trim().length <= 4)
            ) {
              errors.addressPostcode = 'Invalid Postcode'
            }

            if (
              values.phone &&
              !validator.matches(
                values.phone,
                /^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{5}\)?[\s-]?\d{4,5}|8(?:00[\s-]?11[\s-]?11|45[\s-]?46[\s-]?4\d))(?:(?:[\s-]?(?:x|ext\.?\s?|#)\d+)?)$/gm
              )
            ) {
              errors.phone = 'Invalid Phonenumber'
            }

            if (
              (values.mobile && values.mobile.length !== 11) ||
              (values.mobile && values.mobile.substring(0, 2) !== '07')
            ) {
              errors.mobile = 'Invalid Mobile Number'
            }

            if (values.phone && values.phone.substring(0, 2) === '07') {
              errors.phone = 'Landline Only'
            }

            return errors
          }}
          decorators={[
            createDecorator({
              //match billing address to customer address
              field: 'chosenAddress',
              updates: async (value) => {
                if (value) {
                  const address = value.split(',')

                  return {
                    addressLine1: address[0].trim(),
                    addressLine2: address[1].trim(),
                    addressCity: address[2].trim(),
                    addressCounty: address[3].trim(),
                    addressPostcode: address[4].trim(),
                  }
                }

                return {}
              },
            }),
          ]}
          render={({
            handleSubmit,
            pristine,
            invalid,
            dirtySinceLastSubmit,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Customer />
              <Grid container spacing={3} justify="center">
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      pristine || (invalid && !dirtySinceLastSubmit) || loading
                    }
                    type="submit"
                  >
                    <Save style={{ marginRight: '10px' }} />
                    {loading ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </CardContent>
    </Card>
  )
}
