import React from 'react'
import { gql, Query } from '@apollo/react-components'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { parsePhoneNumberFromString } from 'libphonenumber-js/min'

import Loading from './../../reusable/Loading'

import { GET_USERS } from './../../../helpers/queries/User'

import {
  Typography,
  Grid,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { Add, RemoveRedEye } from '@material-ui/icons'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { orange, lightGreen } from '@material-ui/core/colors'

const GET_FITTERS = gql`
  query getFitters {
    getFitters {
      id
      name
      contactNumber
      email
      deleted
    }
  }
`

const secondary = lightGreen[500]
const primary = orange[500]

const columnWidthTheme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        '&:last-child': {
          width: 100,
          padding: '4px 24px',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '13px',
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'auto',
      },
      responsiveScrollFullHeight: {
        zIndex: 1,
      },
      responsiveBase: {
        zIndex: 1,
      },
    },
    MUIDataTablePagination: {
      root: {
        borderBottom: '0',
      },
    },
  },
})

export default function UserList() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography component="h1" variant="h4" gutterBottom>
          Users
        </Typography>
        <Button
          variant="contained"
          style={{
            marginLeft: 'auto',
          }}
          color="secondary"
          component={Link}
          to="users/add"
        >
          <Add style={{ marginRight: '10px' }} />
          New User
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Query query={GET_USERS}>
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />
            }

            if (error) {
              return null
            }

            let { users } = data

            const formattedUsersArray = []

            users.forEach((user) => {
              let userRole = ''

              switch (user.role_id) {
                case 1:
                  userRole = 'Sales Agent'

                  break
                case 2:
                  userRole = 'Manager'

                  break
                case 3:
                  userRole = 'Office Member'

                  break
                case 4:
                  userRole = 'Admin'

                  break

                default:
                  break
              }

              const storesArray = []

              user.stores.forEach((store) => {
                storesArray.push(store.name)
              })

              const data = [
                {
                  columnDetails: {
                    name: 'Name',
                    options: {
                      filter: false,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: `${user.first_name} ${user.last_name}`,
                },
                {
                  columnDetails: {
                    name: 'Role',
                    options: {
                      filter: true,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: userRole,
                },
                {
                  columnDetails: {
                    name: 'Email',
                    options: {
                      filter: false,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: user.email,
                },
                {
                  columnDetails: {
                    name: 'Created Date',
                    options: {
                      filter: false,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: moment(user.created_date, 'x').format(
                    'DD/MM/YYYY'
                  ),
                },
                {
                  columnDetails: {
                    name: 'Updated Date',
                    options: {
                      filter: false,
                      sort: true,
                      download: true,
                      display: 'false',
                    },
                  },
                  columnData: moment(user.updated_date, 'x').format(
                    'DD/MM/YYYY'
                  ),
                },
                {
                  columnDetails: {
                    name: 'Stores',
                    options: {
                      filter: false,
                      sort: false,
                      download: true,
                      display: 'false',
                    },
                  },
                  columnData: storesArray.join(', '),
                },
                {
                  columnDetails: {
                    name: 'Archived',
                    options: {
                      filter: true,
                      sort: true,
                      download: true,
                      display: 'false',
                    },
                  },
                  columnData: user.archived ? 'Yes' : 'No',
                },
                {
                  columnDetails: {
                    name: 'Actions',
                    options: {
                      filter: false,
                      sort: false,
                      download: false,
                      display: 'true',
                    },
                  },
                  columnData: (
                    <Tooltip title="View User Details" placement="top">
                      <IconButton
                        aria-label="View"
                        component={Link}
                        to={'users/edit/' + user._id}
                      >
                        <RemoveRedEye />
                      </IconButton>
                    </Tooltip>
                  ),
                },
              ]

              formattedUsersArray.push(data)
            })

            const columnsArray = []
            const userData = []

            formattedUsersArray.forEach((columns, index) => {
              const columnData = []

              columns.forEach((column) => {
                columnData.push(column.columnData)
              })

              userData.push(columnData)

              //only create column titles on first pass
              if (index === 0) {
                columns.forEach((column) => {
                  columnsArray.push(column.columnDetails)
                })
              }
            })

            const options = {
              filterType: 'checkbox',
              selectableRows: 'none',
              print: false,
              responsive: 'standard',
              downloadOptions: {
                filename: 'Users.csv',
              },
            }

            return (
              <MuiThemeProvider theme={columnWidthTheme}>
                <MUIDataTable
                  title=""
                  data={userData}
                  columns={columnsArray}
                  options={options}
                />
              </MuiThemeProvider>
            )
          }}
        </Query>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography component="h1" variant="h4" gutterBottom>
          Fitters
        </Typography>
        <Button
          variant="contained"
          style={{
            marginLeft: 'auto',
          }}
          color="secondary"
          component={Link}
          to="/fitters/new-fitter"
        >
          <Add style={{ marginRight: '10px' }} />
          New Fitter
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Query query={GET_FITTERS} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />
            }

            if (error) {
              return null
            }

            let { getFitters: fitters } = data

            fitters = fitters
              .filter(({ deleted }) => !deleted)
              .map((fitter) => [
                {
                  columnDetails: {
                    name: 'Name',
                    options: {
                      filter: false,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: fitter.name,
                },
                {
                  columnDetails: {
                    name: 'Email',
                    options: {
                      filter: false,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: fitter.email || '-',
                },
                {
                  columnDetails: {
                    name: 'Contact Number',
                    options: {
                      filter: false,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: fitter.contactNumber
                    ? parsePhoneNumberFromString(fitter.contactNumber)
                        .format('IDD', {
                          fromCountry: 'GB',
                          humanReadable: true,
                        })
                        .replace(' ', '')
                    : '-',
                },
                {
                  columnDetails: {
                    name: 'Actions',
                    options: {
                      filter: false,
                      sort: false,
                      download: false,
                      display: 'true',
                    },
                  },
                  columnData: (
                    <Tooltip title="View Fitter Details" placement="top">
                      <IconButton
                        aria-label="View"
                        component={Link}
                        to={'/fitters/edit/' + fitter.id}
                      >
                        <RemoveRedEye />
                      </IconButton>
                    </Tooltip>
                  ),
                },
              ])

            const columnsArray = []
            const fittersData = []

            fitters.forEach((columns, index) => {
              const columnData = []

              columns.forEach((column) => {
                columnData.push(column.columnData)
              })

              fittersData.push(columnData)

              //only create column titles on first pass
              if (index === 0) {
                columns.forEach((column) => {
                  columnsArray.push(column.columnDetails)
                })
              }
            })

            const options = {
              filterType: 'checkbox',
              selectableRows: 'none',
              print: false,
              responsive: 'standard',
              filter: false,
              viewColumns: false,
              downloadOptions: {
                filename: 'Fitters.csv',
              },
            }

            return (
              <MuiThemeProvider theme={columnWidthTheme}>
                <MUIDataTable
                  title=""
                  data={fittersData}
                  columns={columnsArray}
                  options={options}
                />
              </MuiThemeProvider>
            )
          }}
        </Query>
      </Grid>
    </Grid>
  )
}
