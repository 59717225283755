import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../util/PageWrapper";

import { Typography, Card, CardContent, Grid, Button } from "@material-ui/core";

import { Settings } from "@material-ui/icons";

import ProductManagement from "./../../../assets/images/ProductManagement.svg";
import UserManagement from "./../../../assets/images/UserManagement.svg";
import RoomSettings from "./../../../assets/images/RoomSettings.svg";
import PaymentSettings from "./../../../assets/images/PaymentSettings.svg";
import FailedPayments from "./../../../assets/images/FailedPayments.svg";
import StoreManagement from "./../../../assets/images/StoreManagement.svg";

export default function AdminSettings() {
  const user = useContext(UserContext);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Typography component="h1" variant="h4" gutterBottom>
          Admin Settings
        </Typography>
        <Button
          variant="contained"
          style={{
            marginLeft: "auto",
          }}
          color="secondary"
          component={Link}
          to="/admin-settings/general"
        >
          <Settings style={{ marginRight: "15px" }} />
          General Settings
        </Button>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card style={{ height: "100%" }}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${ProductManagement})`,
                border: "solid #D8D8D8 1px",
                height: "120px",
                width: "120px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                marginBottom: "30px",
              }}
            />
            <Typography variant="h6" gutterBottom align="center">
              Product Management
            </Typography>
            <Typography variant="body1" paragraph align="center">
              Add new products and edit existing products
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              component={Link}
              to="/admin-settings/products"
            >
              Manage Products
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card style={{ height: "100%" }}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${UserManagement})`,
                border: "solid #D8D8D8 1px",
                height: "120px",
                width: "120px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                marginBottom: "30px",
              }}
            />
            <Typography variant="h6" gutterBottom align="center">
              User Management
            </Typography>
            <Typography variant="body1" paragraph align="center">
              See all users, add new users, and edit existing users
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              component={Link}
              to="/admin-settings/users"
            >
              Manage Users
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card style={{ height: "100%" }}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${RoomSettings})`,
                border: "solid #D8D8D8 1px",
                height: "120px",
                width: "120px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                marginBottom: "30px",
              }}
            />
            <Typography variant="h6" gutterBottom align="center">
              Room Management
            </Typography>
            <Typography variant="body1" paragraph align="center">
              See all rooms, add new rooms, and edit existing rooms
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              component={Link}
              to="/admin-settings/rooms"
            >
              Manage Rooms
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card style={{ height: "100%" }}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${PaymentSettings})`,
                border: "solid #D8D8D8 1px",
                height: "120px",
                width: "120px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                marginBottom: "30px",
              }}
            />
            <Typography variant="h6" gutterBottom align="center">
              Payment Settings
            </Typography>
            <Typography variant="body1" paragraph align="center">
              Manage payment denominations and min/max payments.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              component={Link}
              to="/admin-settings/payments"
            >
              Manage Payments
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card style={{ height: "100%" }}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${FailedPayments})`,
                border: "solid #D8D8D8 1px",
                height: "120px",
                width: "120px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                marginBottom: "30px",
              }}
            />
            <Typography variant="h6" gutterBottom align="center">
              Failed Payments
            </Typography>
            <Typography variant="body1" paragraph align="center">
              Manage settings for failed payments texts and emails
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              component={Link}
              to="/admin-settings/failed-payments"
            >
              Manage
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card style={{ height: "100%" }}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${ProductManagement})`,
                border: "solid #D8D8D8 1px",
                height: "120px",
                width: "120px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                marginBottom: "30px",
              }}
            />
            <Typography variant="h6" gutterBottom align="center">
              UI List Management
            </Typography>
            <Typography variant="body1" paragraph align="center">
              See all lists used in the UI, edit the entries in those lists.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              component={Link}
              to="/admin-settings/uilists"
            >
              Manage UI Lists
            </Button>
          </CardContent>
        </Card>
      </Grid>
      {user.role_id > 4 && (
        <Grid item xs={12} sm={6} md={3}>
          <Card style={{ height: "100%" }}>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${StoreManagement})`,
                  border: "solid #D8D8D8 1px",
                  height: "120px",
                  width: "120px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "50%",
                  marginBottom: "30px",
                }}
              />
              <Typography variant="h6" gutterBottom align="center">
                Manage Franchises
              </Typography>
              <Typography variant="body1" paragraph align="center">
                Add, edit or remove franchises
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                component={Link}
                to="/admin-settings/franchises"
              >
                Manage Franchises
              </Button>
            </CardContent>
          </Card>
        </Grid>
      )}
      {user.role_id > 4 && (
        <Grid item xs={12} sm={6} md={3}>
          <Card style={{ height: "100%" }}>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${StoreManagement})`,
                  border: "solid #D8D8D8 1px",
                  height: "120px",
                  width: "120px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "50%",
                  marginBottom: "30px",
                }}
              />
              <Typography variant="h6" gutterBottom align="center">
                Manage Stores
              </Typography>
              <Typography variant="body1" paragraph align="center">
                Add, edit or remove stores
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                component={Link}
                to="/admin-settings/stores"
              >
                Manage Stores
              </Button>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
