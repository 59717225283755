import React from 'react'
import validator from 'validator'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-calculate'

import { Grid, Divider, Typography } from '@material-ui/core'

import { openSnackbar } from './../../reusable/Notifier'
import { openModal } from './../../reusable/Popup'

import Customer from '../../forms/Customer'
import NewOrderWrapper from './NewOrderWrapper'
import EmailAlreadyInUsePopup from './EmailAlreadyInUsePopup'

const GET_PARKED_ORDER = gql`
  query parkedOrder($parkedOrderId: String!) {
    parkedOrder(parkedOrderId: $parkedOrderId) {
      _id
      customer_type
      existing_customer
      address_city
      address_county
      address_line_1
      address_line_2
      address_postcode
      email
      first_name
      last_name
      mobile
      phone
      preferred_contact
      title
      lead_generation
      customer_notes
    }
  }
`

const UPDATE_PARKED_ORDER_CUSTOMER = gql`
  mutation updateParkedOrderCustomer(
    $parkedOrderId: ID!
    $customerType: String!
    $addressCity: String!
    $addressCounty: String!
    $addressLine1: String!
    $addressLine2: String
    $addressPostcode: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $mobile: String
    $phone: String
    $preferredContact: String!
    $title: String!
    $leadGeneration: String!
    $customerNotes: String
  ) {
    updateParkedOrderCustomer(
      parkedOrderId: $parkedOrderId
      customerType: $customerType
      addressCity: $addressCity
      addressCounty: $addressCounty
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressPostcode: $addressPostcode
      email: $email
      firstName: $firstName
      lastName: $lastName
      mobile: $mobile
      phone: $phone
      preferredContact: $preferredContact
      title: $title
      leadGeneration: $leadGeneration
      customerNotes: $customerNotes
    ) {
      _id
      customer_type
      existing_customer
      address_city
      address_county
      address_line_1
      address_line_2
      address_postcode
      email
      first_name
      last_name
      mobile
      phone
      preferred_contact
      title
      lead_generation
      customer_notes
    }
  }
`

export default function CustomerDetails({ match: { params }, history }) {
  const [updateParkedOrderCustomer, { loading: updateLoading }] = useMutation(
    UPDATE_PARKED_ORDER_CUSTOMER,
    {
      onCompleted: () => {
        history.push(`/new-order/order-details/${params.parkedOrderId}`)
      },
    }
  )

  const { loading, error, data } = useQuery(GET_PARKED_ORDER, {
    variables: { parkedOrderId: params.parkedOrderId },
  })

  let initialValues = {}

  if (data) {
    const { parkedOrder } = data

    //set initial values to ones received in for the parked order

    initialValues = {
      firstName: parkedOrder.first_name,
      lastName: parkedOrder.last_name,
      customerType: parkedOrder.customer_type || 'Platinum',
      addressCity: parkedOrder.address_city,
      addressCounty: parkedOrder.address_county,
      addressLine1: parkedOrder.address_line_1,
      addressLine2: parkedOrder.address_line_2,
      addressPostcode: parkedOrder.address_postcode,
      email: parkedOrder.email,
      mobile: parkedOrder.mobile,
      phone: parkedOrder.phone,
      preferredContact: parkedOrder.preferred_contact,
      title: parkedOrder.title,
      leadGeneration: parkedOrder.lead_generation,
      customerNotes: parkedOrder.customer_notes,
    }
  }

  return (
    <Form
      onSubmit={(values) => {
        updateParkedOrderCustomer({
          variables: {
            parkedOrderId: params.parkedOrderId,
            customerType: values.customerType,
            addressCity: values.addressCity,
            addressCounty: values.addressCounty,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            addressPostcode: values.addressPostcode,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            mobile: values.mobile,
            phone: values.phone,
            preferredContact: values.preferredContact,
            title: values.title,
            leadGeneration: values.leadGeneration,
            customerNotes: values.customerNotes,
          },
        }).catch((errors) => {
          if (errors.message.includes('Email Already Used')) {
            openModal({
              content: (
                <EmailAlreadyInUsePopup
                  email={errors.graphQLErrors[0].extensions.email}
                  parkedOrderId={params.parkedOrderId}
                />
              ),
            })
          } else {
            openSnackbar({
              message: 'Failed to update customer, please try again',
              type: 'error',
            })
          }
        })
      }}
      decorators={[
        createDecorator({
          //match billing address to customer address
          field: 'chosenAddress',
          updates: async (value, name, allValues) => {
            if (value) {
              const address = value.split(',')

              return {
                addressLine1: address[0].trim(),
                addressLine2: address[1].trim(),
                addressCity: address[2].trim(),
                addressCounty: address[3].trim(),
                addressPostcode: address[4].trim(),
              }
            }

            return {}
          },
        }),
      ]}
      initialValues={initialValues}
      validate={(values) => {
        const errors = {}

        const requiredFields = [
          'firstName',
          'lastName',
          'addressLine1',
          'addressCity',
          'email',
          'addressCounty',
          'addressPostcode',
          'title',
          'preferredContact',
          'leadGeneration',
        ]

        requiredFields.forEach((requiredField) => {
          if (!values[requiredField]) {
            errors[requiredField] = 'Required'
          }
        })

        if (
          values.email &&
          !validator.isEmail(values.email) &&
          !values.email.includes('.con') &&
          !values.email.includes('.coma')
        ) {
          errors.email = 'Invalid Email'
        }

        if (values.preferredContact === 'phone' && !!!values.phone) {
          errors.phone = 'Required'
        }

        if (values.preferredContact === 'mobile' && !!!values.mobile) {
          errors.mobile = 'Required'
        }

        if (!!!values.phone && !!!values.mobile) {
          errors.mobile = 'At least one phone number is required'
          errors.phone = 'At least one phone number is required'
        }

        if (
          values.addressPostcode &&
          (!validator.isPostalCode(values.addressPostcode, ['GB']) ||
            values.addressPostcode.trim().length <= 4)
        ) {
          errors.addressPostcode = 'Invalid Postcode'
        }

        if (
          values.phone &&
          !validator.matches(
            values.phone,
            /^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{5}\)?[\s-]?\d{4,5}|8(?:00[\s-]?11[\s-]?11|45[\s-]?46[\s-]?4\d))(?:(?:[\s-]?(?:x|ext\.?\s?|#)\d+)?)$/gm
          )
        ) {
          errors.phone = 'Invalid Phonenumber'
        }

        if (
          (values.mobile && values.mobile.length !== 11) ||
          (values.mobile && values.mobile.substring(0, 2) !== '07')
        ) {
          errors.mobile = 'Invalid Mobile Number'
        }

        if (values.phone && values.phone.substring(0, 2) === '07') {
          errors.phone = 'Landline Only'
        }

        return errors
      }}
      render={({ handleSubmit }) => (
        <NewOrderWrapper
          pageNumber={1}
          nextButtonLabel="Next"
          nextPageFunction={() => {
            handleSubmit()
          }}
          loading={loading}
          nextButtonLoading={updateLoading}
          error={
            error
              ? 'Failed to get customer details, please refresh the page.'
              : null
          }
          previousPage="choose-customer"
        >
          <form autoComplete="off">
            <Grid container spacing={3} justify="center">
              <Grid item xs={12}>
                <Typography component="h2" variant="h5" gutterBottom>
                  {initialValues.firstName
                    ? `Update Customer - ${initialValues.firstName} ${initialValues.lastName}`
                    : 'New Customer'}
                </Typography>
                <Divider />
              </Grid>
            </Grid>

            <Customer />
          </form>
        </NewOrderWrapper>
      )}
    />
  )
}
