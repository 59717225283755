import React, { useEffect } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

export const ModalBackground = styled.div`
  position: fixed;
  background-color: ${() => rgba('black', 0.4)};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
`

const ModalContainer = styled.div`
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

export default function Modal({ lite, children }) {
  // lock html to prevent scrolling the background
  useEffect(() => {
    // run on mount
    document.getElementsByTagName('html')[0].classList.add('lock')

    //run on unmount
    return () => {
      document.getElementsByTagName('html')[0].classList.remove('lock')
    }
  }, [])

  return (
    <ModalBackground>
      <ModalContainer maxWidth={lite ? '360px' : '100%'}>
        {children}
      </ModalContainer>
    </ModalBackground>
  )
}
