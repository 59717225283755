import React, { Fragment } from 'react'
import { Query, ApolloConsumer, Mutation } from '@apollo/react-components'
import { Field, Form } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import arrayMutators from 'final-form-arrays'
import { Link } from 'react-router-dom'

import {
  TextField,
  Typography,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
  Divider,
  Button,
  IconButton,
  CardHeader,
  CardActions,
} from '@material-ui/core'

import { Search, Save, Clear, Add } from '@material-ui/icons'

import {
  GET_PRODUCTS,
  GET_PRODUCT,
  UPDATE_PRODUCT,
  ARCHIVE_PRODUCT,
} from './../../../helpers/queries/Product'

import ProductForm from './ProductForm'

import { openSnackbar } from './../../reusable/Notifier'
import { openModal, closeModal } from './../../reusable/Popup'

export default function ManageProducts() {
  return (
    <ApolloConsumer>
      {(client) => (
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography component="h1" variant="h4">
              Products
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="products/add"
              style={{ marginLeft: 'auto' }}
            >
              <Add style={{ marginRight: '15px' }} />
              New Product
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Mutation
              mutation={UPDATE_PRODUCT}
              refetchQueries={[
                {
                  query: GET_PRODUCTS,
                },
              ]}
            >
              {(updateProduct) => (
                <Form
                  onSubmit={async (values) => {
                    // validate
                    let errorsExist = false
                    const errors = { productSizes: [] }

                    //   const requiredFields = ['productName', 'productType']

                    //   requiredFields.forEach((requiredField) => {
                    //     if (!values[requiredField]) {
                    //       errors[requiredField] = 'Required'
                    //     }
                    //   })

                    //   //check if each colour has required
                    //   values.productColours.forEach((colour, index) => {
                    //     errors.productColours.push({})
                    //     if (!colour.colourName) {
                    //       errors.productColours[index].colourName = 'Required'
                    //     }
                    //   })

                    //check size
                    values.productSizes.forEach((size, index) => {
                      errors.productSizes.push({})
                      // if (!size.sizeDimension) {
                      //   errors.productSizes[index].sizeDimension = 'Required'
                      // }
                      if (!size.sizePrice) {
                        errors.productSizes[index].sizePrice = 'Required'
                        errorsExist = true
                      }
                    })

                    if (errorsExist) {
                      openSnackbar({
                        message: 'There is an error in the form',
                        type: 'error',
                      })
                      return errors
                    }

                    updateProduct({
                      variables: {
                        colours: JSON.stringify(values.productColours),
                        _id: values.productId,
                        name: values.productName,
                        sizes: JSON.stringify(values.productSizes),
                        type: values.productType,
                      },
                    }).then(({ data }) => {
                      if (data.updateProduct.error) {
                        openSnackbar({
                          message: data.updateProduct.error.message,
                          type: 'error',
                        })
                      } else {
                        openSnackbar({
                          message: 'Product updated successfully',
                          type: 'success',
                        })
                      }
                    })
                  }}
                  mutators={{
                    editProduct: async (args, state, utils) => {
                      const productName = args[0]

                      utils.changeValue(
                        state,
                        'selectedProduct',
                        () => productName
                      )
                    },
                    ...arrayMutators,
                  }}
                  decorators={[
                    createDecorator({
                      //match billing address to customer address
                      field: 'selectedProduct',
                      updates: async (value, name, allValues) => {
                        if (!value) return {}

                        //get product details using id
                        const { data } = await client.query({
                          query: GET_PRODUCT,
                          variables: {
                            name: value,
                          },
                        })

                        const { product } = data

                        //format colours
                        const formattedColours = product.colours.map(
                          (colour) => {
                            return {
                              colourName: colour.name,
                              colourCode: colour.code,
                            }
                          }
                        )

                        //format sizes

                        const formattedSizes = product.sizes.map((size) => {
                          return {
                            sizeDimension: size.dimension,
                            sizeCode: size.code,
                            sizePrice: size.price,
                            sizeDelivery: size.delivery_charge,
                            sizeFitting: size.fitting_charge,
                          }
                        })

                        return {
                          productName: product.name,
                          productType: product.type,
                          productId: product._id,
                          productColours: formattedColours,
                          productSizes: formattedSizes,
                        }
                      },
                    }),
                  ]}
                  initialValues={{
                    selectedProduct: '',
                    productSearch: '',
                    productName: '',
                    productColours: [{ colourName: '', colourCode: '' }],
                    productSizes: [
                      {
                        sizeDimension: '',
                        sizeCode: '',
                        sizePrice: '',
                        sizeDelivery: '',
                        sizeFitting: '',
                      },
                    ],
                  }}
                  render={({
                    handleSubmit,
                    pristine,
                    submitting,
                    values,
                    form: {
                      mutators: { editProduct },
                    },
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <Card>
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                              <Field name="productSearch">
                                {({ input }) => (
                                  <TextField
                                    {...input}
                                    label="Search Products"
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Search />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              </Field>
                              <List
                                style={{
                                  maxHeight: '60vh',
                                  minHeight: '60vh',
                                  overflowY: 'scroll',
                                  border: 'solid 1px rgba(0, 0, 0, 0.23)',
                                  borderTop: 'none',
                                }}
                              >
                                <Query query={GET_PRODUCTS}>
                                  {({ loading, error, data }) => {
                                    if (loading) {
                                      return (
                                        <ListItem>
                                          <ListItemText primary="Loading..." />
                                        </ListItem>
                                      )
                                    }

                                    if (error) {
                                      return (
                                        <ListItem>
                                          <ListItemText primary="No products found" />
                                        </ListItem>
                                      )
                                    }

                                    const { products } = data

                                    let filteredProducts = products.filter(
                                      (product) => {
                                        return !product.archived
                                      }
                                    )

                                    if (values.productSearch) {
                                      filteredProducts = filteredProducts.filter(
                                        (product) => {
                                          return product.name
                                            .toLowerCase()
                                            .includes(
                                              values.productSearch.toLowerCase()
                                            )
                                        }
                                      )
                                    }

                                    return (
                                      <Fragment>
                                        {!filteredProducts.length && (
                                          <ListItem>
                                            <ListItemText primary="No products found" />
                                          </ListItem>
                                        )}
                                        {filteredProducts.map((product) => (
                                          <ListItem
                                            button
                                            key={product._id}
                                            selected={
                                              values.selectedProduct ===
                                              product.name
                                            }
                                            onClick={() =>
                                              editProduct(product.name)
                                            }
                                          >
                                            <ListItemText
                                              primary={product.name}
                                              secondary={product.type}
                                            />
                                          </ListItem>
                                        ))}
                                      </Fragment>
                                    )
                                  }}
                                </Query>
                              </List>
                            </Grid>
                            <Grid item xs={12} md={9}>
                              <Fragment>
                                {values.selectedProduct && values.productId ? (
                                  <Grid container spacing={3}>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography variant="h6">
                                        Edit Product
                                      </Typography>
                                      <Mutation
                                        mutation={ARCHIVE_PRODUCT}
                                        refetchQueries={[
                                          {
                                            query: GET_PRODUCTS,
                                          },
                                        ]}
                                      >
                                        {(archiveProduct) => (
                                          <Button
                                            variant="contained"
                                            style={{ marginLeft: 'auto' }}
                                            onClick={() => {
                                              openModal({
                                                content: (
                                                  <Card>
                                                    <CardHeader
                                                      action={
                                                        <IconButton
                                                          aria-label="Close Pop-up"
                                                          onClick={closeModal}
                                                        >
                                                          <Clear />
                                                        </IconButton>
                                                      }
                                                      title="Are You Sure?"
                                                    />
                                                    <CardContent>
                                                      <Typography
                                                        variant="body1"
                                                        gutterBottom
                                                      >
                                                        Archiving a product
                                                        cannot be undone.
                                                      </Typography>
                                                    </CardContent>
                                                    <CardActions
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                          'space-between',
                                                      }}
                                                    >
                                                      <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={closeModal}
                                                      >
                                                        Cancel
                                                      </Button>

                                                      <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                          archiveProduct({
                                                            variables: {
                                                              _id:
                                                                values.productId,
                                                            },
                                                          }).then(
                                                            ({ data }) => {
                                                              const {
                                                                archiveProduct,
                                                              } = data

                                                              if (
                                                                !!archiveProduct.error
                                                              ) {
                                                                openSnackbar({
                                                                  message:
                                                                    archiveProduct
                                                                      .error
                                                                      .message,
                                                                  type: 'error',
                                                                })
                                                                closeModal()
                                                              } else {
                                                                openSnackbar({
                                                                  message:
                                                                    'Product has been successfully archived',
                                                                  type:
                                                                    'success',
                                                                })
                                                                closeModal()
                                                              }
                                                            }
                                                          )
                                                        }}
                                                      >
                                                        Continue
                                                      </Button>
                                                    </CardActions>
                                                  </Card>
                                                ),
                                              })
                                            }}
                                          >
                                            Archive Product
                                          </Button>
                                        )}
                                      </Mutation>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <ProductForm />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={pristine || submitting}
                                        type="submit"
                                      >
                                        <Save style={{ marginRight: '10px' }} />
                                        Save
                                      </Button>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <div
                                    style={{
                                      height: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Typography variant="body1">
                                      Select a product
                                    </Typography>
                                  </div>
                                )}
                              </Fragment>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </form>
                  )}
                />
              )}
            </Mutation>
          </Grid>
        </Grid>
      )}
    </ApolloConsumer>
  )
}
