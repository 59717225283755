import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
} from '@material-ui/core'

export default function FailedOneOffPayment({ match }) {
  return (
    <Fragment>
      <Typography component="h1" variant="h4" gutterBottom>
        Error
      </Typography>
      <Card>
        <CardContent>
          <Typography component="h2" variant="h5" gutterBottom>
            The one-off payment was unsuccesful
          </Typography>
          <Divider />
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to={`/orders/single/${match.params.invoice_number}`}
          >
            Return To Order
          </Button>
        </CardContent>
      </Card>
    </Fragment>
  )
}
