import React, { Fragment, useContext } from "react";
import { Query, Mutation } from "@apollo/react-components";
import gql from "graphql-tag";
import { GET_FRANCHISES } from "../../../helpers/queries/Franchises";

import { Form, Field } from "react-final-form";

import {
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  IconButton,
  Tooltip,
  CardHeader,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CardActions,
} from "@material-ui/core";

import { Save, Clear, Add } from "@material-ui/icons";

import { openSnackbar } from "../../reusable/Notifier";
import { openModal, closeModal } from "../../reusable/Popup";

import { UserContext } from "../../util/PageWrapper";
import Checkbox from "../../../newComponents/Checkbox";

export default function Franchises() {
  const user = useContext(UserContext);

  const stores = user.stores.map((store) => ({
    value: store._id,
    label: store.name,
  }));

  return (
    user.role_id > 4 && (
      <Grid container spacing={3} style={{ marginBottom: "1em" }}>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Typography component="h1" variant="h4" gutterBottom>
            Franchises
          </Typography>
          <Button
            variant="contained"
            style={{
              marginLeft: "auto",
            }}
            color="secondary"
            onClick={() => {
              openModal({
                content: (
                  <Card>
                    <CardHeader
                      action={
                        <IconButton
                          aria-label="Close Pop-up"
                          onClick={closeModal}
                        >
                          <Clear />
                        </IconButton>
                      }
                      title="Add Franchise"
                    />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Mutation
                          mutation={gql`
                            mutation (
                              $name: String!
                              $merchant_id: String!
                              $secret_key: String!
                              $api_user: String!
                              $api_user_password: String!
                              $cert_password: String!
                              $stores: [String]
                              $active: Boolean!
                              $payment_provider: String!
                              $elavon_vendor_id: String!
                              $max_failed_payment_attempts: Int!
                              $max_failed_payment_attempts_in_one_month: Int!
                            ) {
                              newFranchise(
                                name: $name
                                merchant_id: $merchant_id
                                secret_key: $secret_key
                                api_user: $api_user
                                api_user_password: $api_user_password
                                cert_password: $cert_password
                                stores: $stores
                                active: $active
                                payment_provider: $payment_provider
                                elavon_vendor_id: $elavon_vendor_id
                                max_failed_payment_attempts: $max_failed_payment_attempts
                                max_failed_payment_attempts_in_one_month: $max_failed_payment_attempts_in_one_month
                              ) {
                                error {
                                  message
                                }
                                franchise {
                                  _id
                                  name
                                  merchant_id
                                  secret_key
                                  api_user
                                  api_user_password
                                  cert_password
                                  stores {
                                    _id
                                    name
                                  }
                                  active
                                  payment_provider
                                  elavon_vendor_id
                                  max_failed_payment_attempts
                                  max_failed_payment_attempts_in_one_month
                                }
                              }
                            }
                          `}
                          refetchQueries={[
                            {
                              query: GET_FRANCHISES,
                            },
                          ]}
                        >
                          {(newFranchise) => (
                            <Form
                              initialValues={{ stores: [], active: false }}
                              onSubmit={(values) => {
                                newFranchise({
                                  variables: {
                                    ...values,
                                    max_failed_payment_attempts:parseInt(values.max_failed_payment_attempts),
                                    max_failed_payment_attempts_in_one_month:parseInt(values.max_failed_payment_attempts_in_one_month)
                                  },
                                }).then(({ data }) => {
                                  const { newFranchise } = data;

                                  if (newFranchise.error) {
                                    openSnackbar({
                                      message: newFranchise.error.message,
                                      type: "error",
                                    });
                                  } else {
                                    openSnackbar({
                                      message: "Franchise added successfully",
                                      type: "success",
                                    });
                                    closeModal();
                                  }
                                });
                              }}
                              render={({
                                handleSubmit,
                                values,
                                pristine,
                                submitting,
                              }) => (
                                <Grid item xs={12}>
                                  <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                  >
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems="center"
                                    >
                                      <Grid item xs={12} md={4}>
                                        <Field name="name">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Franchise Name"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="merchant_id">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="merchant_id"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="secret_key">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="secret_key"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="api_user">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="api_user"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="api_user_password">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="api_user_password"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="cert_password">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="cert_password"
                                              fullWidth
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="elavon_vendor_id">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="elavon_vendor_id"
                                              fullWidth
                                              variant="outlined"
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={6}>
                                        <Field name="stores">
                                          {({
                                            input: {
                                              name,
                                              onChange,
                                              value,
                                              ...restInput
                                            },
                                            meta,
                                            ...rest
                                          }) => (
                                            <FormControl fullWidth>
                                              <InputLabel id="multiple-select-label">
                                                Stores
                                              </InputLabel>
                                              <Select
                                                {...rest}
                                                name={name}
                                                helperText={
                                                  meta.touched
                                                    ? meta.error
                                                    : undefined
                                                }
                                                error={
                                                  meta.error && meta.touched
                                                }
                                                inputProps={restInput}
                                                onChange={onChange}
                                                value={value}
                                                labelId="multiple-name-label"
                                                id="multiple-select"
                                                multiple
                                              >
                                                {stores.map((store) => (
                                                  <MenuItem
                                                    key={store.value}
                                                    value={store.value}
                                                  >
                                                    {store.label}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="payment_provider">
                                          {({ input, meta }) => (
                                            <TextField
                                              {...input}
                                              select
                                              label="Payment Provider"
                                              fullWidth
                                              required
                                              variant="outlined"
                                              error={meta.error && meta.touched}
                                              helperText={
                                                meta.error && meta.touched
                                                  ? meta.error
                                                  : ""
                                              }
                                            >
                                              <MenuItem value="Fiserv">
                                                Fiserv
                                              </MenuItem>
                                              <MenuItem value="Elavon">
                                                Elavon
                                              </MenuItem>
                                            </TextField>
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="max_failed_payment_attempts">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Max failed payment attempts. Leave at 0 to use global default."
                                              fullWidth
                                              variant="outlined"
                                              type="number"
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <Field name="max_failed_payment_attempts_in_one_month">
                                          {({ input }) => (
                                            <TextField
                                              {...input}
                                              label="Max failed payment attempts in 30 days. Set to 3 for Fiserv or 99 for Opayo."
                                              fullWidth
                                              variant="outlined"
                                              type="number"
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <Field name="active" type="checkbox">
                                          {({ input }) => (
                                            <Checkbox
                                              inputProps={{
                                                name: "active",
                                              }}
                                              {...input}
                                              value="Active"
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={12} md={3}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          type="submit"
                                          fullWidth
                                          disabled={pristine || submitting}
                                        >
                                          <Save
                                            style={{ marginRight: "10px" }}
                                          />
                                          Save
                                        </Button>
                                      </Grid>
                                    </Grid>
                                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                  </form>
                                </Grid>
                              )}
                            />
                          )}
                        </Mutation>
                      </Grid>
                    </CardContent>
                  </Card>
                ),
              });
            }}
          >
            <Add style={{ marginRight: "10px" }} />
            Add Franchise
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center">
            <Query query={GET_FRANCHISES}>
              {({ loading, error, data }) => {
                if (loading || error) {
                  return null;
                }

                let { franchises } = data;

                return (
                  <Fragment>
                    {franchises.map((franchise) => (
                      <Mutation
                        key={franchise._id}
                        mutation={gql`
                          mutation (
                            $_id: String!
                            $name: String!
                            $merchant_id: String!
                            $secret_key: String!
                            $api_user: String!
                            $api_user_password: String!
                            $cert_password: String!
                            $stores: [String]
                            $active: Boolean!
                            $payment_provider: String
                            $elavon_vendor_id: String!
                            $max_failed_payment_attempts: Int!
                            $max_failed_payment_attempts_in_one_month: Int!
                          ) {
                            updateFranchise(
                              _id: $_id
                              name: $name
                              merchant_id: $merchant_id
                              secret_key: $secret_key
                              api_user: $api_user
                              api_user_password: $api_user_password
                              cert_password: $cert_password
                              stores: $stores
                              active: $active
                              payment_provider: $payment_provider
                              elavon_vendor_id: $elavon_vendor_id
                              max_failed_payment_attempts: $max_failed_payment_attempts
                              max_failed_payment_attempts_in_one_month: $max_failed_payment_attempts_in_one_month
                            ) {
                              error {
                                message
                              }
                              franchise {
                                _id
                              }
                            }
                          }
                        `}
                        refetchQueries={[
                          {
                            query: GET_FRANCHISES,
                          },
                        ]}
                      >
                        {(updateFranchise) => (
                          <Form
                            initialValues={{
                              ...franchise,
                              stores: franchise.stores.map(
                                (store) => store._id
                              ),
                            }}
                            onSubmit={(values) => {
                              updateFranchise({
                                variables: {
                                  _id: franchise._id,
                                  ...values,
                                  max_failed_payment_attempts:parseInt(values.max_failed_payment_attempts),
                                  max_failed_payment_attempts_in_one_month:parseInt(values.max_failed_payment_attempts_in_one_month)
                                },
                              }).then(({ data }) => {
                                const { updateFranchise } = data;

                                if (updateFranchise.error) {
                                  openSnackbar({
                                    message: updateFranchise.error.message,
                                    type: "error",
                                  });
                                } else {
                                  openSnackbar({
                                    message: "Franchise updated successfully",
                                    type: "success",
                                  });
                                }
                              });
                            }}
                            render={({
                              handleSubmit,
                              pristine,
                              submitting,
                              values,
                            }) => (
                              <Card style={{ marginBottom: "2rem" }}>
                                <CardContent>
                                  <Grid item xs={12}>
                                    <form
                                      onSubmit={handleSubmit}
                                      autoComplete="off"
                                    >
                                      <Grid
                                        container
                                        spacing={3}
                                        alignItems="center"
                                      >
                                        <Grid item xs={12} md={12}>
                                          <Mutation
                                            mutation={gql`
                                              mutation ($_id: String!) {
                                                deleteFranchise(_id: $_id) {
                                                  error {
                                                    message
                                                  }
                                                  franchise {
                                                    _id
                                                    name
                                                    merchant_id
                                                    secret_key
                                                    api_user
                                                    api_user_password
                                                    cert_password
                                                    stores {
                                                      _id
                                                      name
                                                    }
                                                    active
                                                    payment_provider
                                                    elavon_vendor_id
                                                    max_failed_payment_attempts
                                                    max_failed_payment_attempts_in_one_month
                                                  }
                                                }
                                              }
                                            `}
                                            refetchQueries={[
                                              {
                                                query: GET_FRANCHISES,
                                              },
                                            ]}
                                          >
                                            {(deleteFranchise) => (
                                              <Tooltip
                                                title="Delete Franchise"
                                                placement="top"
                                              >
                                                <IconButton
                                                  aria-label="Delete"
                                                  onClick={() => {
                                                    openModal({
                                                      content: (
                                                        <Card>
                                                          <CardHeader
                                                            action={
                                                              <IconButton
                                                                aria-label="Close Pop-up"
                                                                onClick={
                                                                  closeModal
                                                                }
                                                              >
                                                                <Clear />
                                                              </IconButton>
                                                            }
                                                            title="Delete Franchise"
                                                          />
                                                          <CardContent>
                                                            <Typography
                                                              variant="body1"
                                                              gutterBottom
                                                            >
                                                              Please confirm
                                                              that you would
                                                              like to continue
                                                              with this.
                                                            </Typography>
                                                          </CardContent>
                                                          <CardActions
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "space-between",
                                                            }}
                                                          >
                                                            <Button
                                                              variant="contained"
                                                              color="primary"
                                                              onClick={
                                                                closeModal
                                                              }
                                                            >
                                                              Cancel
                                                            </Button>

                                                            <Button
                                                              variant="contained"
                                                              color="secondary"
                                                              onClick={() => {
                                                                deleteFranchise(
                                                                  {
                                                                    variables: {
                                                                      _id: franchise._id,
                                                                    },
                                                                  }
                                                                ).then(
                                                                  ({
                                                                    data,
                                                                  }) => {
                                                                    const {
                                                                      deleteFranchise,
                                                                    } = data;

                                                                    if (
                                                                      deleteFranchise.error
                                                                    ) {
                                                                      openSnackbar(
                                                                        {
                                                                          message:
                                                                            deleteFranchise
                                                                              .error
                                                                              .message,
                                                                          type: "error",
                                                                        }
                                                                      );
                                                                    } else {
                                                                      closeModal();
                                                                      openSnackbar(
                                                                        {
                                                                          message:
                                                                            "Franchise deleted successfully",
                                                                          type: "success",
                                                                        }
                                                                      );
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              Continue
                                                            </Button>
                                                          </CardActions>
                                                        </Card>
                                                      ),
                                                    });
                                                  }}
                                                >
                                                  <Clear />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          </Mutation>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="name">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Franchise Name"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="merchant_id">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="merchant_id"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="secret_key">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="secret_key"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="api_user">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="api_user"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="api_user_password">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="api_user_password"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="cert_password">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="cert_password"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="elavon_vendor_id">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Opayo Vendor ID"
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="max_failed_payment_attempts">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Maximum failed payment attempts. Leave at 0 to use the global default."
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="max_failed_payment_attempts_in_one_month">
                                            {({ input }) => (
                                              <TextField
                                                {...input}
                                                label="Max failed payment attempts in 30 days. Set to 3 for Fiserv or 99 for Opayo."
                                                fullWidth
                                                variant="outlined"
                                                required
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <Field name="stores">
                                            {({
                                              input: {
                                                name,
                                                onChange,
                                                value,
                                                ...restInput
                                              },
                                              meta,
                                              ...rest
                                            }) => (
                                              <FormControl fullWidth>
                                                <InputLabel id="multiple-select-label">
                                                  Stores
                                                </InputLabel>
                                                <Select
                                                  {...rest}
                                                  name={name}
                                                  helperText={
                                                    meta.touched
                                                      ? meta.error
                                                      : undefined
                                                  }
                                                  error={
                                                    meta.error && meta.touched
                                                  }
                                                  inputProps={restInput}
                                                  onChange={onChange}
                                                  value={value}
                                                  labelId="multiple-name-label"
                                                  id="multiple-select"
                                                  multiple
                                                >
                                                  {stores.map((store) => (
                                                    <MenuItem
                                                      key={store.value}
                                                      value={store.value}
                                                    >
                                                      {store.label}
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <Field name="payment_provider">
                                            {({ input, meta }) => (
                                              <TextField
                                                {...input}
                                                select
                                                label="Payment Provider"
                                                fullWidth
                                                required
                                                variant="outlined"
                                                error={
                                                  meta.error && meta.touched
                                                }
                                                helperText={
                                                  meta.error && meta.touched
                                                    ? meta.error
                                                    : ""
                                                }
                                              >
                                                <MenuItem value="Fiserv">
                                                  Fiserv
                                                </MenuItem>
                                                <MenuItem value="Elavon">
                                                  Elavon
                                                </MenuItem>
                                              </TextField>
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Field name="active" type="checkbox">
                                            {({ input }) => (
                                              <Checkbox
                                                inputProps={{
                                                  name: "active",
                                                }}
                                                {...input}
                                                value="Active"
                                              />
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            fullWidth
                                            disabled={pristine || submitting}
                                          >
                                            <Save
                                              style={{ marginRight: "10px" }}
                                            />
                                            Save
                                          </Button>
                                          {/* <pre>
                                            {JSON.stringify(values, null, 2)}
                                          </pre> */}
                                        </Grid>
                                      </Grid>
                                    </form>
                                  </Grid>
                                </CardContent>
                              </Card>
                            )}
                          />
                        )}
                      </Mutation>
                    ))}
                  </Fragment>
                );
              }}
            </Query>
          </Grid>
        </Grid>
      </Grid>
    )
  );
}
