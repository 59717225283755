import React from "react";

import AuthenticatedRoute from "../components/util/AuthenticatedRoute";

import UserList from "../components/pages/User/UserList";
import NewUser from "../components/pages/User/NewUser";
import UserView from "../components/pages/User/UserView";
import ManageProducts from "../components/pages/Products/ManageProducts";
import AdminSettings from "../components/pages/Settings/AdminSettings";
import AddProduct from "../components/pages/Products/AddProduct";
import GeneralSettings from "../components/pages/Settings/GeneralSettings";
import RoomSettings from "../components/pages/Settings/RoomSettings";
import UIListSettings from "../components/pages/Settings/UIListSettings";
import PaymentSettings from "../components/pages/Settings/PaymentSettings";
import FailedPayments from "../components/pages/Settings/FailedPayments";
import Franchises from "../components/pages/Settings/Franchises";
import Stores from "../components/pages/Settings/Stores";

const AdminRoutes = [
  <AuthenticatedRoute
    path="/admin-settings/users"
    exact
    component={UserList}
    title="User Management"
  />,
  <AuthenticatedRoute
    path="/admin-settings/rooms"
    exact
    component={RoomSettings}
    title="Room Management"
  />,
  <AuthenticatedRoute
    path="/admin-settings/uilists"
    exact
    component={UIListSettings}
    title="UI List Management"
  />,
  <AuthenticatedRoute
    path="/admin-settings/franchises"
    exact
    component={Franchises}
    title="Franchises Management"
  />,
  <AuthenticatedRoute
    path="/admin-settings/stores"
    exact
    component={Stores}
    title="Stores Management"
  />,
  <AuthenticatedRoute
    path="/admin-settings/payments"
    exact
    component={PaymentSettings}
    title="Payment Settings"
  />,
  <AuthenticatedRoute
    path="/admin-settings/users/add"
    exact
    component={NewUser}
    title="Add New User"
  />,
  <AuthenticatedRoute
    path="/admin-settings/users/edit/:userId"
    exact
    component={UserView}
    title="Edit User"
  />,
  <AuthenticatedRoute
    path="/admin-settings/products"
    exact
    component={ManageProducts}
    title="Product Management"
  />,
  <AuthenticatedRoute
    path="/admin-settings"
    exact
    component={AdminSettings}
    title="Admin Settings"
  />,
  <AuthenticatedRoute
    path="/admin-settings/failed-payments"
    exact
    component={FailedPayments}
    title="Failed Payment Settings"
  />,
  <AuthenticatedRoute
    path="/admin-settings/products/add"
    exact
    component={AddProduct}
    title="Add Product"
  />,
  <AuthenticatedRoute
    path="/admin-settings/general"
    exact
    component={GeneralSettings}
    title="General Settings"
  />,
];

export default AdminRoutes;
