import React, { useContext } from 'react'
import NumberFormat from 'react-number-format'
import { DatePicker } from '@material-ui/pickers'
import {
  Loading,
  ErrorMessage,
  Button,
  Input,
  DropdownLookup,
} from '@web-applications/daffodil-component-library'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

import {
  Typography,
  Grid,
  Divider,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { Form, Field } from 'react-final-form'
import Icon from '@mdi/react'
import { mdiContentSave } from '@mdi/js'
import moment from 'moment'

import { openSnackbar } from '../../../components/reusable/Notifier'
import { UserContext } from '../../../components/util/PageWrapper'

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      payments_before_release
      release_date
      estimated_release_date
      delivery_charge
      delivery_date
      goods_delivered_date
      fitting_charge
      fitting_date
      goods_fitted_date
      items {
        type
      }
      fittingCost
      fitter {
        _id
        name
      }
    }
    getFitters {
      id
      name
      deleted
    }
  }
`

const UPDATE_ORDER_FITTING_DELIVERY = gql`
  mutation updateOrderFittingDelivery(
    $invoiceNumber: Int!
    $paymentsBeforeRelease: Int
    $deliveryCharge: Float!
    $deliveryChargeChangeReason: String
    $deliveryDate: DateTime
    $goodsDeliveredDate: DateTime
    $fittingCharge: Float!
    $fittingChargeChangeReason: String
    $fittingDate: DateTime
    $goodsFittedDate: DateTime
    $fittingCost: Float
    $fitter: ID
  ) {
    updateOrderFittingDelivery(
      invoiceNumber: $invoiceNumber
      paymentsBeforeRelease: $paymentsBeforeRelease
      deliveryCharge: $deliveryCharge
      deliveryChargeChangeReason: $deliveryChargeChangeReason
      deliveryDate: $deliveryDate
      goodsDeliveredDate: $goodsDeliveredDate
      fittingCharge: $fittingCharge
      fittingChargeChangeReason: $fittingChargeChangeReason
      fittingDate: $fittingDate
      goodsFittedDate: $goodsFittedDate
      fittingCost: $fittingCost
      fitter: $fitter
    ) {
      _id
      payments_before_release
      release_date
      delivery_charge
      delivery_date
      goods_delivered_date
      fitting_charge
      fitting_date
      goods_fitted_date
      fittingCost
      fitter {
        id
        name
      }
    }
  }
`

const RELEASE_ORDER_NOW = gql`
  mutation releaseOrderNow($invoiceNumber: Int!) {
    releaseOrderNow(invoiceNumber: $invoiceNumber) {
      _id
      release_date
    }
  }
`

export default function FittingDelivery() {
  const { invoiceNumber } = useParams()

  const { role_id: userRole } = useContext(UserContext)

  const [
    updateOrderFittingDelivery,
    { loading: updateOrderFittingDeliveryLoading },
  ] = useMutation(UPDATE_ORDER_FITTING_DELIVERY, {
    onError: () => {
      openSnackbar({
        message: 'Failed to fitting/delivery details, please try again.',
        type: 'error',
      })
    },
    onCompleted: () => {
      openSnackbar({
        message: 'Fitting/delivery details updated successfully',
        type: 'success',
      })
    },
  })

  const [releaseOrderNow, { loading: releaseOrderNowLoading }] = useMutation(
    RELEASE_ORDER_NOW,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to release order.',
          type: 'error',
        })
      },
      onCompleted: () => {
        openSnackbar({
          message: 'Order released.',
          type: 'success',
        })
      },
    }
  )

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  })

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  const { getOrder: order } = data

  const fitters = data.getFitters.map(({ id, name, deleted }) => ({
    value: id,
    label: name,
    visible: !deleted,
  }))

  //calcualte if we should show delivery fields

  const deliveryRequired = order.items.some(({ type }) => type === 'Bed')
  const fittingRequired = order.items.some(({ type }) => (type === 'Carpet') | (type === 'Vinyl'))
  let paymentsBeforeReleaseRequired = true
  if(order?.release_date){
    paymentsBeforeReleaseRequired = false
  }
  return (
    <Form
      initialValues={{
        paymentsBeforeRelease: order?.payments_before_release || undefined,
        deliveryCharge: order?.delivery_charge || undefined,
        deliveryDate: !!order.delivery_date
          ? moment(order.delivery_date, 'x')
          : null,
        goodsDeliveredDate: !!order.goods_delivered_date
          ? moment(order.goods_delivered_date, 'x')
          : null,
        fittingCharge: order?.fitting_charge || undefined,
        fittingDate: !!order.fitting_date
          ? moment(order.fitting_date, 'x')
          : null,
        goodsFittedDate: !!order.goods_fitted_date
          ? moment(order.goods_fitted_date, 'x')
          : null,
        fittingCost: order.fittingCost
          ? Number.parseFloat(order.fittingCost).toFixed(2)
          : '',
        fitter: order?.fitter?._id || undefined,
      }}
      onSubmit={({
        paymentsBeforeRelease,
        deliveryCharge,
        deliveryChargeChangeReason,
        deliveryDate,
        goodsDeliveredDate,
        fittingCharge,
        fittingChargeChangeReason,
        fittingDate,
        goodsFittedDate,
        fittingCost,
        fitter,
      }) => {
        updateOrderFittingDelivery({
          variables: {
            invoiceNumber: parseInt(invoiceNumber),
            paymentsBeforeRelease: parseInt(paymentsBeforeRelease),
            deliveryCharge: parseFloat(deliveryCharge),
            deliveryChargeChangeReason,
            deliveryDate,
            goodsDeliveredDate,
            fittingCharge: parseFloat(fittingCharge),
            fittingChargeChangeReason,
            fittingDate,
            goodsFittedDate,
            fittingCost: parseFloat(fittingCost),
            fitter: fitter,
          },
        })
      }}
      validate={({
        paymentsBeforeRelease,
        deliveryCharge,
        deliveryChargeChangeReason,
        fittingCharge,
        fittingChargeChangeReason,
      }) => {
        let errors = {}

        if (!paymentsBeforeRelease && paymentsBeforeReleaseRequired) {
          errors.paymentsBeforeRelease = 'Required'
        }

        if (deliveryRequired) {
          if (deliveryCharge === undefined || deliveryCharge == null) {
            errors.deliveryCharge = 'Required'
          }

          if (
            parseFloat(deliveryCharge) !== parseFloat(order.delivery_charge) &&
            !deliveryChargeChangeReason
          ) {
            errors.deliveryChargeChangeReason = 'Required'
          }
        }

        if (fittingRequired) {
          if (fittingCharge === undefined || fittingCharge == null) {
            errors.fittingCharge = 'Required'
          }

          if (
            parseFloat(fittingCharge) !== parseFloat(order.fitting_charge) &&
            !fittingChargeChangeReason
          ) {
            errors.fittingChargeChangeReason = 'Required'
          }
        }

        return errors
      }}
      render={({
        handleSubmit,
        pristine,
        submitting,
        values: { deliveryCharge, fittingCharge },
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h5" gutterBottom>
                Fitting/Delivery
              </Typography>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Release
              </Typography>
              <Typography variant="body1" gutterBottom>
                {order.release_date
                  ? `Released: ${moment(order.release_date, 'x').format(
                      'DD/MM/YYYY'
                    )}`
                  : `Estimated: ${moment(order.estimated_release_date).format(
                      'DD/MM/YYYY'
                    )}`}
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Field
                  component={Input}
                  name="paymentsBeforeRelease"
                  label="Consecutive Payments Before Release"
                  disabled={userRole === 1 || !!order.release_date}
                  type="number"
                  required={paymentsBeforeReleaseRequired}
                />

                <Button
                  style={{ marginLeft: '16px' }}
                  loading={releaseOrderNowLoading}
                  disabled={userRole === 1 || !!order.release_date}
                  onClick={() => {
                    releaseOrderNow({
                      variables: { invoiceNumber: parseInt(invoiceNumber) },
                    })
                  }}
                >
                  Release Order Now
                </Button>
              </div>
            </Grid>
            {deliveryRequired && (
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Delivery
                </Typography>
                <Field name="deliveryCharge">
                  {({ input, meta }) => (
                    <NumberFormat
                      customInput={TextField}
                      variant="outlined"
                      disabled={userRole === 1}
                      fullWidth
                      margin="dense"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ''}
                      {...input}
                      label="Delivery Charge"
                      required
                      displayType="tel"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  )}
                </Field>
                {parseFloat(deliveryCharge) !==
                  parseFloat(order.delivery_charge) && (
                  <Field
                    component={Input}
                    name="deliveryChargeChangeReason"
                    label="Reason For Change"
                    disabled={userRole === 1}
                    required
                    fullwidth
                  />
                )}
                <Field name="deliveryDate">
                  {({ input }) => (
                    <DatePicker
                      style={{
                        margin: '10px 0',
                      }}
                      format="DD/MM/YYYY"
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value ? input.value : null}
                      onBlur={input.onBlur}
                      fullWidth
                      margin="dense"
                      disablePast
                      autoOk
                      clearable
                      error={false}
                      disabled={userRole === 1 || !!order.goods_delivered_date}
                      helperText=""
                      label="Delivery Date"
                      leftArrowIcon={<KeyboardArrowLeft />}
                      inputVariant="outlined"
                      rightArrowIcon={<KeyboardArrowRight />}
                    />
                  )}
                </Field>
                <Field name={`goodsDeliveredDate`}>
                  {({ input }) => (
                    <DatePicker
                      style={{
                        margin: '10px 0',
                      }}
                      format="DD/MM/YYYY"
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value ? input.value : null}
                      onBlur={input.onBlur}
                      fullWidth
                      margin="dense"
                      autoOk
                      disabled={userRole === 1}
                      clearable
                      error={false}
                      helperText=""
                      disableFuture
                      label="Goods Delivered Date"
                      leftArrowIcon={<KeyboardArrowLeft />}
                      inputVariant="outlined"
                      rightArrowIcon={<KeyboardArrowRight />}
                    />
                  )}
                </Field>
              </Grid>
            )}
            {/* PBB 20210727 David asked for fitting information to always be shown as this is used to fudge things on orders */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Fitting
              </Typography>
              <Field name="fittingCharge">
                {({ input, meta }) => (
                  <NumberFormat
                    customInput={TextField}
                    margin="dense"
                    variant="outlined"
                    disabled={userRole === 1}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                    }}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ''}
                    {...input}
                    label="Fitting Charge"
                    required
                    displayType="tel"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                )}
              </Field>
              {parseFloat(fittingCharge) !==
                parseFloat(order.fitting_charge) && (
                <Field
                  component={Input}
                  name="fittingChargeChangeReason"
                  label="Reason For Change"
                  disabled={userRole === 1}
                  fullwidth
                  required
                />
              )}
              <Field name="fittingDate">
                {({ input }) => (
                  <DatePicker
                    style={{
                      margin: '10px 0',
                    }}
                    margin="dense"
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    disablePast
                    autoOk
                    clearable
                    error={false}
                    disabled={userRole === 1 || !!order.goods_fitted_date}
                    helperText=""
                    label="Fitting Date"
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
              <Field name={`goodsFittedDate`}>
                {({ input }) => (
                  <DatePicker
                    style={{
                      margin: '10px 0',
                    }}
                    margin="dense"
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    autoOk
                    disabled={userRole === 1}
                    clearable
                    error={false}
                    helperText=""
                    disableFuture
                    label="Goods Fitted Date"
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
              <Field
                component={DropdownLookup}
                name="fitter"
                label="Fitter"
                options={fitters}
                fullwidth
              />
              <Field
                component={Input}
                name="fittingCost"
                type="currency"
                fullwidth
                label="Fitting Cost"
                formatOnBlur
                format={(value) =>
                  !value ? '' : Number.parseFloat(value).toFixed(2)
                }
                disabled={userRole === 1}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                icon={<Icon path={mdiContentSave} size="24px" />}
                loading={updateOrderFittingDeliveryLoading}
                disabled={pristine || submitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}
