import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { Form, Field } from "react-final-form";
import Icon from "@mdi/react";
import { mdiContentSave } from "@mdi/js";
import { Typography, Grid, Divider } from "@material-ui/core";
import {
  Loading,
  ErrorMessage,
  DropdownLookup,
  Button,
  Input,
} from "@web-applications/daffodil-component-library";

import {
  AwaitingDeposit,
  Active,
  Complete,
  Cancelled,
} from "../../../assets/images/Icons";

import { openSnackbar } from "../../../components/reusable/Notifier";
import { UserContext } from "../../../components/util/PageWrapper";

const GET_ORDER_DETAILS = gql`
  query getOrderDetails($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      invoice_number
      order_status
      created_date
      store_transaction_number
      supplier_invoice_number
      deposit
      customer {
        _id
        first_name
        last_name
      }
      agent {
        _id
        first_name
        last_name
      }
      account_manager {
        _id
        first_name
        last_name
      }
      store {
        _id
      }
      total_paid
      to_pay
      bad_debt
    }
    getUsers {
      _id
      first_name
      last_name
    }
  }
`;

const GET_STORES = gql`
  query getStoresInSameFranchise($storeId: ID!) {
    getStoresInSameFranchise(storeId: $storeId) {
      _id
      name
    }
  }
`;

const UPDATE_ORDER_SUMMARY = gql`
  mutation updateOrderSummary(
    $invoiceNumber: Int!
    $agentId: ID!
    $accountManagerId: ID
    $storeId: ID!
    $storeTransactionNumber: String
    $supplierInvoiceNumber: String
    $badDebt: String
  ) {
    updateOrderSummary(
      invoiceNumber: $invoiceNumber
      agentId: $agentId
      accountManagerId: $accountManagerId
      storeId: $storeId
      storeTransactionNumber: $storeTransactionNumber
      supplierInvoiceNumber: $supplierInvoiceNumber
      badDebt: $badDebt
    ) {
      _id
      store_transaction_number
      supplier_invoice_number
      agent {
        _id
        first_name
        last_name
      }
      account_manager {
        _id
        first_name
        last_name
      }
      store {
        _id
      }
      bad_debt
    }
  }
`;

export default function OrderSummary() {
  const { invoiceNumber } = useParams();

  const { role_id: userRole } = useContext(UserContext);

  const [updateOrderSummary, { loading: updateOrderSummaryLoading }] =
    useMutation(UPDATE_ORDER_SUMMARY, {
      onError: () => {
        openSnackbar({
          message: "Failed to update details, please try again.",
          type: "error",
        });
      },
      onCompleted: () => {
        openSnackbar({
          message: "Details updated successfully",
          type: "success",
        });
      },
    });

  const { loading, error, data } = useQuery(GET_ORDER_DETAILS, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  });

  const {
    loading: storeLoading,
    error: storeError,
    data: storeData,
  } = useQuery(GET_STORES, {
    variables: { storeId: data?.getOrder?.store?._id },
    skip: !data?.getOrder?.store?._id,
  });

  if (loading || storeLoading) return <Loading />;

  if (error || storeError) return <ErrorMessage error={error || storeError} />;

  const { getOrder: order, getUsers: users } = data;

  let orderStatusIcon;

  switch (order.order_status) {
    case "Awaiting Deposit":
      orderStatusIcon = <AwaitingDeposit />;
      break;
    case "Active":
      orderStatusIcon = <Active />;
      break;
    case "Complete":
      orderStatusIcon = <Complete />;
      break;
    case "Cancelled":
      orderStatusIcon = <Cancelled />;
      break;
    default:
      break;
  }

  // to_pay needs to include deposit amount if deposit hasnt been taken

  let toPay = order.to_pay;

  if (order.order_status === "Awaiting Deposit") {
    toPay = parseFloat(toPay) + parseFloat(order.deposit);
  }

  const formattedUsers = users.map(({ _id, first_name, last_name }) => ({
    value: _id,
    label: `${first_name} ${last_name}`,
    visible: true,
  }));
  const agentUsers = formattedUsers;
  if (order?.agent) {
    let found = false;
    for (var i = 0; i < agentUsers.length; i++) {
      if (agentUsers[i].value === order.agent._id) {
        found = true;
        break;
      }
    }
    if (found) {
    } else {
      agentUsers.push({
        value: order.agent._id,
        label: `${order.agent.first_name} ${order.agent.last_name} (Archived)`,
        visible: true,
      });
    }
  }

  const formattedStores = storeData.getStoresInSameFranchise.map(
    ({ _id, name }) => ({
      value: _id,
      label: name,
      visible: true,
    })
  );

  return (
    <Form
      initialValues={{
        agent: order?.agent?._id || undefined,
        accountManager: order?.account_manager?._id || undefined,
        store: order?.store?._id || undefined,
        storeTransactionNumber: order?.store_transaction_number || undefined,
        supplierInvoiceNumber: order?.supplier_invoice_number || undefined,
        badDebt: order?.bad_debt || "",
      }}
      onSubmit={({
        agent,
        accountManager,
        store,
        storeTransactionNumber,
        supplierInvoiceNumber,
        badDebt,
      }) => {
        updateOrderSummary({
          variables: {
            invoiceNumber: parseInt(invoiceNumber),
            agentId: agent,
            accountManagerId: accountManager,
            storeId: store,
            storeTransactionNumber,
            supplierInvoiceNumber,
            badDebt,
          },
        });
      }}
      validate={({ agent, store }) => {
        let errors = {};

        // check that the selected agent is an actual user in the system
        const isValidAgent = formattedUsers.some(
          ({ value: userId }) => userId === agent
        );

        if (!isValidAgent) {
          errors.agent = "Please select a valid agent";
        }

        //Check store is a valid store
        const isValidStore = formattedStores.some(
          ({ value: storeId }) => storeId === store
        );

        if (!isValidStore) {
          errors.store = "Please select a valid store";
        }

        return errors;
      }}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h5" gutterBottom>
                Order Summary
              </Typography>

              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <Typography variant="h6" gutterBottom>
                  Status
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {orderStatusIcon}
                  <Typography
                    variant="body1"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {order.order_status}
                  </Typography>
                </div>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Order Date
                </Typography>
                <Typography variant="body1">
                  {moment(order.created_date, "x").format("DD/MM/YYYY")}
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Customer
                </Typography>
                <Typography variant="body1">
                  {`${order.customer?.first_name} ${order.customer?.last_name}`}
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Total Paid
                </Typography>
                <Typography variant="body1">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  }).format(order.total_paid)}
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  To Pay
                </Typography>
                <Typography variant="body1">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  }).format(toPay)}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                component={DropdownLookup}
                name="agent"
                label="Agent"
                options={agentUsers}
                required
                fullwidth
                disabled={userRole === 1}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                component={DropdownLookup}
                name="store"
                label="Store"
                options={formattedStores}
                required
                fullwidth
                disabled={userRole === 1}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                component={DropdownLookup}
                name="accountManager"
                label="Account Manager"
                options={formattedUsers}
                disabled={userRole === 1}
                fullwidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                component={Input}
                name="storeTransactionNumber"
                fullwidth
                label="Store Transaction Number"
                disabled={userRole === 1}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                component={Input}
                name="supplierInvoiceNumber"
                fullwidth
                label="Supplier Invoice Number"
                disabled={userRole === 1}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                component={Input}
                name="badDebt"
                fullwidth
                label="Bad Debt"
                disabled={userRole === 1}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                icon={<Icon path={mdiContentSave} size="24px" />}
                loading={updateOrderSummaryLoading}
                disabled={pristine || submitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
}
