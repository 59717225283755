import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'

import CancelOrderForm from './CancelOrderForm'

import Loading from './../../reusable/Loading'

import {
  CashDeposit,
  OneOffPayment,
  ScheduledPayment,
  CardDeposit,
  Retry,
} from '../../../assets/images/Icons'

const GET_CANCELLATION = gql`
  query cancellation($cancellationId: String!) {
    cancellation(cancellationId: $cancellationId) {
      _id
      reason
      new_order_placed
      cancellation_notes
      payments_to_refund {
        _id
        status
        time
        payment_type
        amount
        refunded
      }
      order {
        _id
        invoice_number
      }
    }
  }
`

const GET_PAYMENTS = gql`
  query getPayments($orderId: ID!) {
    getPayments(orderId: $orderId) {
      _id
      status
      time
      payment_type
      amount
      refunded
    }
  }
`

export default function CancelOrderRequest({
  match: {
    params: { cancellationId },
  },
  history,
}) {
  // get cancellation request details
  const { loading, error, data } = useQuery(GET_CANCELLATION, {
    variables: { cancellationId: cancellationId },
  })
  const {
    data: paymentsData,
    loading: paymentsLoading,
    error: paymentsError,
  } = useQuery(GET_PAYMENTS, {
    variables: {
      orderId: data?.cancellation?.order?._id,
    },
    skip: data?.cancellation?.order?._id === undefined,
  })

  if (loading || paymentsLoading) return <Loading />

  if (error || paymentsError) return null

  const { cancellation } = data
  const payments = paymentsData.getPayments

  //format cancellation payments for initial values
  const paymentsToRefund = cancellation.payments_to_refund.map((payment) => {
    //Figure out icon

    let icon

    switch (payment.payment_type) {
      case 'Cash Deposit':
        icon = <CashDeposit colour="#8BC34A" style={{ marginRight: '10px' }} />

        break

      case 'Card Deposit':
        icon = <CardDeposit colour="#8BC34A" style={{ marginRight: '10px' }} />

        break

      case 'One-Off Payment':
        icon = (
          <OneOffPayment colour="#8BC34A" style={{ marginRight: '10px' }} />
        )

        break

      case 'Scheduled Payment':
        icon = (
          <ScheduledPayment colour="#8BC34A" style={{ marginRight: '10px' }} />
        )

        break

      case 'Automatic Retry':
        icon = <Retry colour="#8BC34A" style={{ marginRight: '10px' }} />

        break

      case 'Manual Retry':
        icon = <Retry colour="#8BC34A" style={{ marginRight: '10px' }} />

        break

      default:
        break
    }
    return [
      payment._id,
      moment(payment.time).format('DD/MM/YY, HH:mm:ss'),
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
        {icon}
        {payment.payment_type}
      </div>,
      payment.status,
      new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(payment.amount),
    ]
  })

  //calculate which indexes to select
  const approvedPayments = payments.filter(
    (payment) =>
      (payment.status === 'APPROVED' &&
        payment.payment_type !== 'Refund' &&
        payment.payment_type !== 'Card Validation' &&
        !payment.refunded) ||
      (payment.status === 'COLLECTED' && !payment.refunded)
  )

  //sort payments by time

  approvedPayments.sort((a, b) => parseInt(a.time) - parseInt(b.time))

  const allPaymentIds = approvedPayments.map((payment) => payment._id)

  const selectedIndexes = cancellation.payments_to_refund.map((payment) => {
    const paymentId = payment._id

    //get the index of the payment in the payments array
    return allPaymentIds.indexOf(paymentId)
  })

  return (
    <CancelOrderForm
      invoiceNumber={cancellation.order.invoice_number}
      orderId={cancellation.order._id}
      history={history}
      initialValues={{
        reasonForCancelling: cancellation.reason,
        newOrderPlaced: cancellation.new_order_placed,
        cancellationNotes: cancellation.cancellation_notes,
        paymentsToRefund: paymentsToRefund,
        selectedIndexes: selectedIndexes,
        confirmCancellation: false,
      }}
      cancellationId={cancellation._id}
    />
  )
}
