import React, { createContext, Fragment, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import Icon from '@mdi/react'
import { ModalProvider } from 'react-modal-hook'

import CustomTypography from './../../newComponents/Typography'

import { mdiChartBar } from '@mdi/js'

import {
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'

import {
  Menu,
  ChevronLeft,
  Person,
  Input,
  Settings,
  CancelOutlined,
} from '@material-ui/icons'

import logo from './../../assets/images/logo.png'
import { Dashboard, Orders, Customers } from '../../assets/images/Icons'
import Daffodil from './../../assets/images/dlogo-greyscale.svg'
import DaffodilSoftwareIcon from './../../assets/images/daffodil-software-logo.svg'

export const UserContext = createContext()

export default function PageWrapper({ children, user }) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const location = useLocation()

  let userRole = ''
  if (user) {
    switch (user.role_id) {
      case 1:
        userRole = 'Sales Agent'

        break
      case 2:
        userRole = 'Manager'

        break
      case 3:
        userRole = 'Office Member'

        break
      case 4:
        userRole = 'Admin'

        break

      default:
        break
    }
  }

  return (
    <ModalProvider>
      <UserContext.Provider value={user ? { ...user } : user}>
        <div style={{ display: 'flex' }}>
          <AppBar
            position="fixed"
            style={{
              zIndex: 2,
              backgroundColor: 'white',
            }}
          >
            <Toolbar
              disableGutters
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px',
              }}
            >
              {user && (
                <div>
                  {!location.pathname.includes('/new-order') && (
                    <Fragment>
                      {drawerOpen ? (
                        <IconButton
                          onClick={() => {
                            setDrawerOpen(false)
                          }}
                        >
                          <ChevronLeft />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="inherit"
                          aria-label="Open drawer"
                          onClick={() => {
                            setDrawerOpen(true)
                          }}
                        >
                          <Menu />
                        </IconButton>
                      )}
                    </Fragment>
                  )}
                </div>
              )}
              {user ? (
                <Link
                  to="/"
                  style={{
                    backgroundImage: 'url(' + logo + ')',
                    height: '40px',
                    width: '70px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: '10px',
                  }}
                />
              ) : (
                <div
                  style={{
                    backgroundImage: 'url(' + logo + ')',
                    height: '40px',
                    width: '70px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: '10px',
                  }}
                />
              )}

              {window.location.host !== 'ppw.daffodil-apps.co.uk' && (
                <CustomTypography variant="h2" colour="red">
                  DEVELOPMENT BUILD.
                </CustomTypography>
              )}
            </Toolbar>
          </AppBar>

          {!location.pathname.includes('/new-order') && user && (
            <div
              style={{
                overflowX: 'hidden',
                width: drawerOpen ? '240px' : '64px',
                transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                flexShrink: '0',
                whiteSpace: 'nowrap',
                flex: '0 0 auto',
              }}
            >
              <div
                style={{
                  width: drawerOpen ? '240px' : '64px',
                  paddingTop: '64px',
                  whiteSpace: 'nowrap',
                  transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                  left: '0',
                  right: 'auto',
                  top: '0',
                  flex: ' 1 0 auto',
                  height: '100%',
                  display: 'flex',
                  outline: '0',
                  zIndex: '1',
                  position: 'fixed',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  flexDirection: 'column',
                  backgroundColor: '#fff',
                }}
              >
                <List>
                  <ListItem
                    button
                    component={Link}
                    to={'/user/' + user._id}
                    style={{
                      borderLeft: 'solid 4px transparent',
                      paddingLeft: '13px',
                      borderColor: location.pathname.includes('/user/')
                        ? '#20409A'
                        : 'transparent',
                    }}
                  >
                    <ListItemIcon>
                      <Person
                        style={{
                          color: location.pathname.includes('/user/')
                            ? '#20409A'
                            : '#6C6C6C',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: '#6C6C6C',
                          }}
                        >
                          {userRole}
                        </Typography>
                      }
                      disableTypography
                      primary={
                        <Typography
                          variant="body1"
                          style={{
                            color: location.pathname.includes('/user/')
                              ? '#20409A'
                              : '',
                          }}
                        >
                          {user.first_name + ' ' + user.last_name}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem
                    button
                    component={Link}
                    to="/"
                    style={{
                      borderLeft: 'solid 4px transparent',
                      paddingLeft: '13px',
                      borderColor:
                        location.pathname === '/' ? '#dc633e' : 'transparent',
                    }}
                  >
                    <ListItemIcon>
                      <Dashboard
                        color={location.pathname === '/' ? '#dc633e' : ''}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body1"
                          style={{
                            color: location.pathname === '/' ? '#dc633e' : '',
                          }}
                        >
                          Dashboard
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/orders"
                    style={{
                      borderLeft: 'solid 4px transparent',
                      paddingLeft: '13px',
                      borderColor:
                        location.pathname.includes('/orders') &&
                        !location.pathname.includes('customers/orders')
                          ? '#85b80c'
                          : 'transparent',
                    }}
                  >
                    <ListItemIcon>
                      <Orders
                        color={
                          location.pathname.includes('/orders') &&
                          !location.pathname.includes('customers/orders')
                            ? '#85b80c'
                            : ''
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body1"
                          style={{
                            color:
                              location.pathname.includes('/orders') &&
                              !location.pathname.includes('customers/orders')
                                ? '#85b80c'
                                : '',
                          }}
                        >
                          Orders
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/customers"
                    style={{
                      borderLeft: 'solid 4px transparent',
                      paddingLeft: '13px',
                      borderColor: location.pathname.includes('/customers')
                        ? '#20409a'
                        : 'transparent',
                    }}
                  >
                    <ListItemIcon>
                      <Customers
                        color={
                          location.pathname.includes('/customers')
                            ? '#20409a'
                            : ''
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body1"
                          style={{
                            color: location.pathname.includes('/customers')
                              ? '#20409a'
                              : '',
                          }}
                        >
                          Customers
                        </Typography>
                      }
                    />
                  </ListItem>
                  {user.role_id !== 1 && (
                    <ListItem
                      button
                      component={Link}
                      to="/cancellations"
                      style={{
                        borderLeft: 'solid 4px transparent',
                        paddingLeft: '13px',
                        borderColor: location.pathname.includes(
                          '/cancellations'
                        )
                          ? 'red'
                          : 'transparent',
                      }}
                    >
                      <ListItemIcon>
                        <CancelOutlined
                          style={{
                            color: location.pathname.includes('/cancellations')
                              ? 'red'
                              : '',
                          }}
                        ></CancelOutlined>
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            variant="body1"
                            style={{
                              color: location.pathname.includes(
                                '/cancellations'
                              )
                                ? 'red'
                                : '',
                            }}
                          >
                            Cancellations
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                  {(user.role_id === 4 ||
                    [
                      'sophiemarriott@payperweekcarpetsandbeds.co.uk',
                      'darrenmessam@unitedcarpets.net',
                      'karlmarkell@payperweekcarpetsandbeds.co.uk',
                    ].includes(user.email)) && (
                    <ListItem
                      button
                      component={Link}
                      to="/reports"
                      style={{
                        borderLeft: 'solid 4px transparent',
                        paddingLeft: '13px',
                        borderColor: location.pathname.includes('/reports')
                          ? '#F39200'
                          : 'transparent',
                      }}
                    >
                      <ListItemIcon>
                        <Icon
                          path={mdiChartBar}
                          title="Reports"
                          size="24px"
                          color={
                            location.pathname.includes('/reports')
                              ? '#F39200'
                              : '#6C6C6C'
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            variant="body1"
                            style={{
                              color: location.pathname.includes('/reports')
                                ? '#F39200'
                                : '',
                            }}
                          >
                            Reports
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}

                  {user.role_id >= 4 && (
                    <ListItem
                      button
                      component={Link}
                      to="/admin-settings"
                      style={{
                        borderLeft: 'solid 4px transparent',
                        paddingLeft: '13px',
                        borderColor: location.pathname.includes(
                          '/admin-settings'
                        )
                          ? '#85b80c'
                          : 'transparent',
                      }}
                    >
                      <ListItemIcon>
                        <Settings
                          style={{
                            color: location.pathname.includes('/admin-settings')
                              ? '#85b80c'
                              : '#6C6C6C',
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            variant="body1"
                            style={{
                              color: location.pathname.includes(
                                '/admin-settings'
                              )
                                ? '#85b80c'
                                : '',
                            }}
                          >
                            Admin Settings
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </List>
                <List style={{ marginTop: 'auto' }}>
                  <ListItem
                    button
                    onClick={() => {
                      Cookies.remove('ppw')
                    }}
                    component={Link}
                    to="/login"
                  >
                    <ListItemIcon>
                      <Input style={{ transform: 'rotate(180deg)' }} />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItem>
                  <ListItem style={{ paddingBottom: 0 }}>
                    <ListItemIcon>
                      <img
                        src={DaffodilSoftwareIcon}
                        alt=""
                        style={{
                          height: '25px',
                          width: '25px',
                          objectFit: 'contain',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2">Developed By</Typography>
                      }
                      secondary={
                        <Typography variant="body1">
                          Daffodil Software
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem style={{ paddingTop: 0 }}>
                    <ListItemIcon>
                      <div
                        style={{
                          backgroundImage: `url(${Daffodil})`,
                          backgroundSize: '150%',
                          height: '25px',
                          width: '25px',
                          backgroundPosition: 'center',
                          visibility: 'hidden',
                        }}
                      ></div>
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                        >
                          Version {process.env.REACT_APP_VERSION}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </div>
            </div>
          )}

          <main
            style={{
              flexGrow: 1,
              position: 'relative',
              height: '100%',
              width: '100%',
              margin: '100px auto',
              maxWidth: '1200px',
              padding: '0 20px',
            }}
          >
            {children}
          </main>
        </div>
      </UserContext.Provider>
    </ModalProvider>
  )
}
