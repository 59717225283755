import React from "react";
import { Button } from "@material-ui/core";
import { openModal } from "./Popup";
// import Icon from "@material-ui/core";

import { UserContext } from "../util/PageWrapper";

import { useContext } from "react";
import { VisibilityOffRounded } from "@material-ui/icons";

export default function ({ text, children }) {
  const user = useContext(UserContext);

  return (
    user.first_name === "Daffodil" &&
    user.last_name === "Software" && (
      <Button
        variant="contained"
        style={{ width: "10rem", color: "white", backgroundColor: "red" }}
        onClick={() => {
          openModal({
            content: children,
          });
        }}
      >
        <VisibilityOffRounded style={{ marginRight: "10px" }} />
        {text}
      </Button>
    )
  );
}
