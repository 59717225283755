import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import CancelOrderForm from './CancelOrderForm'

import Loading from './../../reusable/Loading'

const GET_ORDER = gql`
  query order($invoice_number: Int!) {
    order(invoice_number: $invoice_number) {
      _id
      invoice_number
    }
  }
`

export default function CancelOrderNew({
  match: {
    params: { invoiceNumber },
  },
  history,
}) {
  // get order details for cancellation
  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { invoice_number: parseInt(invoiceNumber) },
  })

  if (loading) return <Loading />

  if (error) return null

  const { order } = data

  return (
    <CancelOrderForm
      invoiceNumber={invoiceNumber}
      orderId={order._id}
      history={history}
    />
  )
}
