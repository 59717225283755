import React, { Fragment } from "react";
import { useQuery } from "@apollo/react-components";
import { Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import NumberFormat from "react-number-format";
import { GET_UI_LIST_ENTRIES } from "../../../helpers/queries/CustomerVisitOutcome";

import {
  TextField,
  Grid,
  MenuItem,
  Typography,
  IconButton,
  Tooltip,
  Divider,
  InputAdornment,
} from "@material-ui/core";

import { Add, Delete } from "@material-ui/icons";

export default function ProductForm() {
  const { data: productTypesData } = useQuery(GET_UI_LIST_ENTRIES, {
    variables: { type: "producttypes" },
  });

  let entries = [];
  if (productTypesData?.entries) {
    entries = productTypesData.entries;
  }

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        // calculate what codes are required
        let sizeCodeRequired = true;
        let colourCodeRequired = true;

        values.productSizes.forEach((size) => {
          if (size.sizeCode) {
            colourCodeRequired = false;
          }
        });

        //check if each colour has required
        values.productColours.forEach((colour) => {
          if (colour.colourCode) {
            sizeCodeRequired = false;
          }
        });
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Field name="productName">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    label="Name"
                    fullWidth
                    variant="outlined"
                    required
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field name="productType">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    select
                    label="Type"
                    fullWidth
                    required
                    variant="outlined"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                  >
                    {entries.map((entry) => (
                      <MenuItem value={entry.value}>{entry.value}</MenuItem>
                    ))}
                  </TextField>
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <FieldArray name="productColours">
                {({ fields }) => (
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" style={{ marginRight: "15px" }}>
                        Colours
                      </Typography>
                      <Tooltip title="Add" placement="top">
                        <IconButton
                          aria-label="Add"
                          onClick={() =>
                            fields.push({ colourName: "", colourCode: "" })
                          }
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    {fields.map((name, index) => (
                      <Fragment key={index}>
                        <Grid item xs={12} md={6}>
                          <Field name={`${name}.colourName`}>
                            {({ input, meta }) => (
                              <TextField
                                {...input}
                                label="Name"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                required
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : ""
                                }
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Field name={`${name}.colourCode`}>
                            {({ input, meta }) => (
                              <TextField
                                {...input}
                                label="Code"
                                fullWidth
                                required={colourCodeRequired}
                                margin="dense"
                                variant="outlined"
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : ""
                                }
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {values.productColours.length > 1 && (
                            <Tooltip title="Remove" placement="top">
                              <IconButton
                                aria-label="Remove"
                                onClick={() => fields.remove(index)}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                )}
              </FieldArray>
            </Grid>
            <Grid item xs={12}>
              <FieldArray name="productSizes">
                {({ fields }) => (
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" style={{ marginRight: "15px" }}>
                        Sizes
                      </Typography>
                      <Tooltip title="Add" placement="top">
                        <IconButton
                          aria-label="Add"
                          onClick={() =>
                            fields.push({
                              sizeDimension: "",
                              sizeCode: "",
                              sizePrice: "",
                              sizeDelivery: "",
                              sizeFitting: "",
                            })
                          }
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    {fields.map((name, index) => (
                      <Grid item xs={12} key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={1}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body1">{index + 1}</Typography>
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={7}>
                                <Field name={`${name}.sizeDimension`}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      label="Dimension"
                                      fullWidth
                                      variant="outlined"
                                      margin="dense"
                                      required
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <Field name={`${name}.sizeCode`}>
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      label="Code"
                                      fullWidth
                                      margin="dense"
                                      variant="outlined"
                                      required={sizeCodeRequired}
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field name={`${name}.sizePrice`}>
                                  {({ input, meta }) => (
                                    <NumberFormat
                                      customInput={TextField}
                                      variant="outlined"
                                      fullWidth
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            £
                                          </InputAdornment>
                                        ),
                                      }}
                                      required
                                      {...input}
                                      label="Price"
                                      displayType="tel"
                                      decimalScale={2}
                                      fixedDecimalScale
                                      error={!!meta.submitError}
                                      helperText={
                                        meta.submitError ? meta.submitError : ""
                                      }
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field name={`${name}.sizeDelivery`}>
                                  {({ input, meta }) => (
                                    <NumberFormat
                                      customInput={TextField}
                                      variant="outlined"
                                      fullWidth
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            £
                                          </InputAdornment>
                                        ),
                                      }}
                                      {...input}
                                      label="Delivery"
                                      displayType="tel"
                                      decimalScale={2}
                                      fixedDecimalScale
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field name={`${name}.sizeFitting`}>
                                  {({ input, meta }) => (
                                    <NumberFormat
                                      customInput={TextField}
                                      variant="outlined"
                                      fullWidth
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            £
                                          </InputAdornment>
                                        ),
                                      }}
                                      {...input}
                                      label="Fitting"
                                      displayType="tel"
                                      decimalScale={2}
                                      fixedDecimalScale
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                    />
                                  )}
                                </Field>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={1}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {values.productSizes.length > 1 && (
                              <Tooltip title="Remove" placement="top">
                                <IconButton
                                  aria-label="Remove"
                                  onClick={() => fields.remove(index)}
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </FieldArray>
            </Grid>
          </Grid>
        );
      }}
    </FormSpy>
  );
}
