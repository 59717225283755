import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
} from '@material-ui/core'

export default function SuccessfulPayment() {
  return (
    <Fragment>
      <Typography component="h1" variant="h4" gutterBottom>
        Thank You
      </Typography>
      <Card>
        <CardContent>
          <Typography component="h2" variant="h5" gutterBottom>
            Your order has been confirmed
          </Typography>
          <Divider />
          <Typography variant="body1" paragraph>
            Thank you for your order, our sales team will now process your
            information. A representative will be in touch to discuss your
            delivery and/ or fitting date. If you have any queries in the
            meantime, please call 0800 122 3144
          </Typography>
          <Typography variant="body1" paragraph>
            Please pass this device back to the sales agent
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/orders"
          >
            Return To Dashboard
          </Button>
        </CardContent>
      </Card>
    </Fragment>
  )
}
