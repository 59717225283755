import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import { Typography, Card, CardContent, Grid } from '@material-ui/core'

import { UserContext } from '../../util/PageWrapper'
import ParkedOrdersTable from './ParkedOrdersTable'
import Loading from '../../reusable/Loading'

const GET_PARKED_ORDERS = gql`
  query parkedOrders($agentId: String) {
    parkedOrders(agentId: $agentId) {
      _id
      time_started
      agent {
        _id
        first_name
        last_name
      }
      status
      first_name
      last_name
      payment_start_time
      locked
    }
  }
`

export default function ParkedOrdersAgent() {
  const user = useContext(UserContext)

  const {
    loading: parkedOrdersLoading,
    error: parkedOrdersError,
    data: parkedOrdersData,
  } = useQuery(GET_PARKED_ORDERS, {
    variables: {
      agentId: user._id,
    },
  })

  let view

  if (parkedOrdersError) {
    view = (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Failed to load parked orders, please try again
          </Typography>
        </Grid>
      </Grid>
    )
  } else if (parkedOrdersLoading) {
    view = <Loading />
  } else if (parkedOrdersData) {
    view = <ParkedOrdersTable parkedOrders={parkedOrdersData.parkedOrders} />
  }

  return (
    <Card>
      <CardContent>{view}</CardContent>
    </Card>
  )
}
