import React, { Fragment, useContext } from 'react'
import MUIDataTable from 'mui-datatables'
import { FormSpy } from 'react-final-form'
import { Field } from 'react-final-form'

import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core'

import { UserContext } from '../../util/PageWrapper'

export default function CancelOrderReview() {
  const user = useContext(UserContext)

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const columns = [
          {
            name: 'Payment ID',
            options: {
              display: 'false',
            },
          },
          'Time',
          'Payment Type',
          'Status',
          'Amount',
        ]
        const options = {
          selectableRows: 'none',
          print: false,
          download: false,
          responsive: 'standard',
          filter: false,
          elevation: 0,
          viewColumns: false,
          search: false,
          rowHover: false,
          customToolbar: () => null,
        }

        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                style={{ color: 'grey', fontWeight: 'bold' }}
              >
                Reason For Cancelling*
              </Typography>
              <Typography variant="body1">
                {values.reasonForCancelling}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                style={{ color: 'grey', fontWeight: 'bold' }}
              >
                Will a new order be placed?
              </Typography>
              <Typography variant="body1">
                {values.newOrderPlaced ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            {!!values.cancellationNotes && (
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  style={{ color: 'grey', fontWeight: 'bold' }}
                >
                  Notes
                </Typography>
                <Typography variant="body1">
                  {values.cancellationNotes}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} style={{ zIndex: '3' }}>
              <Typography component="h3" variant="h6" gutterBottom>
                Payments To Be Refunded
              </Typography>
              {values.paymentsToRefund && values.paymentsToRefund.length ? (
                <div style={{ marginTop: '-50px' }}>
                  <MUIDataTable
                    title=""
                    data={values.paymentsToRefund}
                    columns={columns}
                    options={options}
                  />
                </div>
              ) : (
                <Typography variant="body1">No Payments Selected</Typography>
              )}
            </Grid>
            {!user.can_cancel && (
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                  }}
                >
                  WARNING: You are about to submit this account for
                  cancellation. This is a REQUEST ONLY; the account will REMAIN
                  ACTIVE until the request is processed by the Cancellations
                  Team or Hub. Upon submission, the client will receive a
                  confirmation email.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Field name="confirmCancellation" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ name: input.name }}
                        {...input}
                        value={input.name}
                      />
                    }
                    label={
                      <Fragment>
                        {user.can_cancel ? (
                          <Typography
                            variant="body1"
                            style={{
                              color: !input.value ? 'red' : 'black',
                            }}
                          >
                            I confirm that the above information is correct and
                            I understand cancelling an order cannot be undone.
                          </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            style={{
                              color: !input.value ? 'red' : 'black',
                            }}
                          >
                            I confirm that the information on this page is
                            correct and I have read and understand the above
                            warning.
                          </Typography>
                        )}
                      </Fragment>
                    }
                  />
                )}
              </Field>
            </Grid>
          </Grid>
        )
      }}
    </FormSpy>
  )
}
