import React, { useState } from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import validator from 'validator'

import Icon from '@mdi/react'
import { mdiAlertCircleOutline } from '@mdi/js'

import Typography from './Typography'
import FlexBox from './FlexBox'
import Spacer from './Spacer'

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ fullwidth }) => {
    if (!fullwidth) return '320px'
  }};
  .disabled-select {
    user-select: none;
  }
  .secondary-label {
    margin-left: 16px;
  }
  input,
  textarea,
  .disabled-select {
    resize: none;
    width: ${({ small }) => {
      if (small) return '88px'

      return '100%'
    }};
    max-width: ${({ fullwidth }) => {
      if (!fullwidth) return '320px'
    }};
    height: ${({ rows }) => {
      if (!rows) return '56px'
    }};
    border-width: 1px;
    border-style: solid;
    transition: all ease-in-out 150ms;
    border-color: ${({ error }) => {
      if (error) return 'red'

      return '#E0E0E0'
    }};
    border-radius: 4px;
    background-color: ${({ readOnly, disabled }) => {
      if (readOnly || disabled) return '#E0E0E0'

      return 'white'
    }};
    padding: ${({ error, small }) => {
      if (error && !small) return '16px 40px 16px 16px'

      return '16px'
    }};
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 26px;
    color: ${({ readOnly, disabled }) => {
      if (readOnly || disabled) return '#7b7b7b'

      return 'black'
    }};
    cursor: ${({ readOnly, disableSelect }) => {
      if (readOnly || disableSelect) return 'not-allowed'
    }};
    margin-bottom: 24px;
    &:active,
    &:focus {
      border-color: ${({ readOnly, disabled }) => {
        if (!readOnly && !disabled) return '#f7941d'
      }};
      outline: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
    }
  }
  .input-container {
    position: relative;
  }
  .optional-text {
    margin-left: 4px;
  }
  .helper-text {
    margin-top: -24px;
  }
  svg {
    position: absolute;
    top: 16px;
    right: 8px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`

export default function Input({
  required,
  name,
  label,
  type,
  helperText,
  fullwidth,
  value,
  disableSelect,
  small,
  secondaryLabel,
  decimal,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false)

  //validation function, returns error message if theres a problem

  const validate = (value) => {
    //required validator
    if (required && (!JSON.stringify(value) || !JSON.stringify(value).trim())) {
      return 'Required'
    }

    //validate based on type
    switch (type) {
      //email validation
      case 'email':
        if (
          value &&
          (!validator.isEmail(value) ||
            value.includes('.con') ||
            value.includes('.coma'))
        ) {
          return 'Invalid Email'
        }
        break

      case 'phone':
        if (value && !validator.isMobilePhone(value, 'en-GB')) {
          return 'Invalid Phone Number'
        }
        break

      case 'number':
        // check if decimal has been passed as a prop, if not, don't allow decimals
        if (!decimal && JSON.stringify(value).includes('.')) {
          return 'Must be a whole number'
        }
        break

      case 'postcode':
        if (
          value &&
          (!validator.isPostalCode(value, ['GB']) || value.trim().length <= 4)
        ) {
          return 'Invalid Postcode'
        }
        break

      default:
        break
    }
  }

  // calculate actual type to use for inout type
  let actualType

  switch (type) {
    case 'email':
    case 'password':
    case 'number':
      actualType = type

      break

    case 'phone':
      actualType = 'number'
      break

    case 'postcode':
    default:
      actualType = 'text'
      break
  }

  // if this input is set at view only, dont generate the form controls with it

  if (rest.readOnly)
    return (
      <StyledInput
        {...rest}
        disableSelect={disableSelect}
        small={small}
        fullwidth={fullwidth ? 1 : 0}
      >
        <FlexBox justifyContent="space-between">
          <FlexBox>
            <Typography variant="label">{label}</Typography>
            {required && (
              <Typography variant="label" className="optional-text">
                *
              </Typography>
            )}
          </FlexBox>
          {type === 'password' && (
            <Typography
              variant="label"
              onClick={() => {
                setShowPassword(!showPassword)
              }}
            >
              {showPassword ? 'Hide' : 'Show'}
            </Typography>
          )}
        </FlexBox>
        <Spacer height="8px" />
        <div className="input-container">
          <FlexBox>
            {type === 'textarea' ? (
              <textarea value={value} {...rest} />
            ) : !!disableSelect ? (
              <div
                {...rest}
                className="disabled-select"
                type={showPassword ? 'text' : actualType}
              >
                {value}
              </div>
            ) : (
              <input
                value={value}
                {...rest}
                type={showPassword ? 'text' : actualType}
              />
            )}
            {secondaryLabel && (
              <Typography variant="bodySmall" className="secondary-label">
                {secondaryLabel}
              </Typography>
            )}
          </FlexBox>
        </div>
        {!!helperText ? (
          <Typography
            variant="caption"
            colour="#7b7b7b"
            className="helper-text"
          >
            {helperText}
          </Typography>
        ) : null}
      </StyledInput>
    )

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        //get error

        let errorMessage

        if (meta.error && meta.touched) {
          errorMessage = meta.error
        } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
          errorMessage = meta.submitError
        }

        return (
          <StyledInput
            {...rest}
            fullwidth={fullwidth ? 1 : 0}
            error={!!errorMessage}
            small={small}
          >
            <FlexBox justifyContent="space-between">
              <FlexBox>
                <Typography variant="label">{label}</Typography>
                {required && (
                  <Typography variant="label" className="optional-text">
                    *
                  </Typography>
                )}
              </FlexBox>
              {type === 'password' && (
                <Typography
                  variant="label"
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </Typography>
              )}
            </FlexBox>
            <Spacer height="8px" />
            <div className="input-container">
              <FlexBox alignItems="baseline">
                {type === 'textarea' ? (
                  <textarea {...input} wrap="hard" {...rest} />
                ) : (
                  <input
                    {...input}
                    {...rest}
                    type={showPassword ? 'text' : actualType}
                  />
                )}

                {!!errorMessage && type !== 'textarea' && !small && (
                  <Icon
                    path={mdiAlertCircleOutline}
                    title="Error"
                    size="24px"
                    color="red"
                  />
                )}
                {secondaryLabel && (
                  <Typography variant="bodySmall" className="secondary-label">
                    {secondaryLabel}
                  </Typography>
                )}
              </FlexBox>
            </div>
            {!!errorMessage ? (
              <Typography
                variant="caption"
                colour="red"
                className="helper-text"
              >
                {errorMessage}
              </Typography>
            ) : !!helperText ? (
              <Typography
                variant="caption"
                colour="#7b7b7b"
                className="helper-text"
              >
                {helperText}
              </Typography>
            ) : null}
          </StyledInput>
        )
      }}
    </Field>
  )
}
