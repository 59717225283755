import React, { Fragment, useContext } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import gql from "graphql-tag";
import { Mutation } from "@apollo/react-components";

import { UserContext } from "../util/PageWrapper";
import { Typography, Card, CardContent, Divider } from "@material-ui/core";
import { Button } from "@web-applications/daffodil-component-library";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { orange, lightGreen } from "@material-ui/core/colors";
import { openSnackbar } from "./Notifier";

import DeleteActivity from "../../helpers/DeleteActivity";

const secondary = lightGreen[500];
const primary = orange[500];

const columnWidthTheme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 250,
        },
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: "auto",
      },
      responsiveScrollFullHeight: {
        zIndex: 1,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "13px",
      },
    },
    MUIDataTablePagination: {
      root: {
        borderBottom: "0",
      },
    },
    MuiGridListTile: {
      root: {
        width: "auto !important",
      },
    },
  },
});

export default function ActivityTable({
  title,
  notes = [],
  activities = [],
  texts = [],
  emails = [],
  orderId,
}) {
  const user = useContext(UserContext);

  console.log("orderId", orderId);
  console.log("user", user.role_id);

  const formattedNotes = notes.map((note) => ({
    time: moment(note.created_date, "x").unix(),
    formatted_time: moment(note.created_date, "x").format("DD/MM/YY, HH:mm:ss"),
    user: `${note.created_by.first_name} ${note.created_by.last_name}`,
    download_content: note.content,
    details: (
      <Fragment>
        <Typography variant="caption" style={{ display: "block" }}>
          {`Created By: ${note.created_by.first_name} ${note.created_by.last_name}`}
        </Typography>
        <Typography variant="caption" style={{ display: "block" }}>
          {`Created Date: ${moment(note.created_date, "x").format(
            "DD/MM/YY, HH:mm:ss"
          )}`}
        </Typography>
      </Fragment>
    ),
    content: <Typography variant="body1">{note.content}</Typography>,
    type: "Order Note",
  }));

  const formattedActivities = activities.map((activity) => ({
    time: moment(activity.time, "x").unix(),
    formatted_time: moment(activity.time, "x").format("DD/MM/YY, HH:mm:ss"),
    user: !!activity.user
      ? `${activity.user.first_name} ${activity.user.last_name}`
      : "Unknown",
    download_content: activity.content,
    details: (
      <Fragment>
        <Typography variant="caption" style={{ display: "block" }}>
          {!!activity.user
            ? `User: ${activity.user.first_name} ${activity.user.last_name}`
            : `User: Unknown`}
        </Typography>
        <Typography variant="caption" style={{ display: "block" }}>
          {`Time: ${moment(activity.time, "x").format("DD/MM/YY, HH:mm:ss")}`}
        </Typography>
      </Fragment>
    ),
    content: <Typography variant="body1">{activity.content}</Typography>,
    type: "Order Activity",

    ...(orderId &&
      user.role_id > 4 && {
        action: <DeleteActivity activity={activity} orderId={orderId} />,
      }),
  }));

  const formattedTexts = texts.map((text) => ({
    time: text.sent_time
      ? moment(text.sent_time).unix()
      : moment(text.created_date).unix(),
    formatted_time: text.sent_time
      ? moment(text.sent_time).format("DD/MM/YY, HH:mm:ss")
      : moment(text.created_date).format("DD/MM/YY, HH:mm:ss"),
    user: "System",
    download_content: `Text Message: ${text.message}`,
    details: (
      <Fragment>
        <Typography
          variant="caption"
          style={{ display: "block" }}
        >{`Status: ${text.status}`}</Typography>
        <Typography variant="caption" style={{ display: "block" }}>
          {`Mobile Number: ${text.mobile_number}`}
        </Typography>
        <Typography variant="caption" style={{ display: "block" }}>
          {`Time: ${
            text.sent_time
              ? moment(text.sent_time).format("DD/MM/YY, HH:mm:ss")
              : moment(text.created_date).format("DD/MM/YY, HH:mm:ss")
          }`}
        </Typography>
      </Fragment>
    ),
    content: (
      <Typography variant="body1">{`Text Message: ${text.message}`}</Typography>
    ),
    type: "Text Message",
  }));

  let resendButtonExists = false;

  const formattedEmails = emails.map((email) => {
    let resendButton;

    if (email.email_template === "orderConfirmation" && !resendButtonExists) {
      resendButtonExists = true;
      resendButton = (
        <Mutation
          mutation={gql`
            mutation resendOrderConfirmation($order_id: String!) {
              resendOrderConfirmation(order_id: $order_id) {
                _id
              }
            }
          `}
        >
          {(resendOrderConfirmation) => (
            <Button
              variant="contained"
              color="secondary"
              style={{ marginTop: "5px" }}
              onClick={() => {
                resendOrderConfirmation({
                  variables: {
                    order_id: email.order._id,
                  },
                }).then(() => {
                  openSnackbar({
                    message: "Order confirmation resent",
                    type: "success",
                  });
                });
              }}
            >
              Resend Order Confirmation
            </Button>
          )}
        </Mutation>
      );
    }

    return {
      time: email.sent_time
        ? moment(email.sent_time).unix()
        : moment(email.created_date).unix(),
      formatted_time: email.sent_time
        ? moment(email.sent_time).format("DD/MM/YY, HH:mm:ss")
        : moment(email.created_date).format("DD/MM/YY, HH:mm:ss"),
      user: "System",
      download_content: `Email Subject: ${email.subject}`,
      details: (
        <Fragment>
          <Typography
            variant="caption"
            style={{ display: "block" }}
          >{`Status: ${email.status}`}</Typography>
          <Typography variant="caption" style={{ display: "block" }}>
            {`Email Address: ${email.target_email}`}
          </Typography>
          <Typography variant="caption" style={{ display: "block" }}>
            {`Time: ${
              email.sent_time
                ? moment(email.sent_time).format("DD/MM/YY, HH:mm:ss")
                : moment(email.created_date).format("DD/MM/YY, HH:mm:ss")
            }`}
          </Typography>
        </Fragment>
      ),
      content: (
        <Fragment>
          <Typography variant="body1">{`Email Subject: ${email.subject}`}</Typography>
          {resendButton}
        </Fragment>
      ),
      type: "Email",
    };
  });

  const tableData = formattedNotes.concat(
    formattedActivities,
    formattedTexts,
    formattedEmails
  );

  tableData.sort((a, b) => parseInt(b.time) - parseInt(a.time));

  const tableDataArray = tableData.map((note) => [
    {
      columnDetails: {
        name: "Time",
        options: {
          filter: false,
          sort: false,
          display: "excluded",
          download: false,
        }
      },
      columnData: note.time,
    },
    {
      columnDetails: {
        name: "Time",
        options: {
          filter: false,
          sort: false,
          display: "excluded",
        },
      },
      columnData: note.formatted_time,
    },
    {
      columnDetails: {
        name: "User",
        options: {
          filter: false,
          sort: false,
          display: "excluded",
        },
      },
      columnData: note.user,
    },
    {
      columnDetails: {
        name: "Content",
        options: {
          filter: false,
          sort: false,
          display: "excluded",
        },
      },
      columnData: note.download_content,
    },
    {
      columnDetails: {
        name: "Details",
        options: {
          filter: false,
          sort: false,
          display: "true",
          download: false,
        },
      },
      columnData: note.details,
    },
    {
      columnDetails: {
        name: "Content",
        options: {
          filter: false,
          sort: false,
          display: "true",
          download: false,
        },
      },
      columnData: note.content,
    },
    {
      columnDetails: {
        name: "Type",
        options: {
          filter: true,
          sort: false,
          display: "true",
        },
      },
      columnData: note.type,
    },
    ...(orderId && user.role_id > 4
      ? [
          {
            columnDetails: {
              name: "Actions",
              options: {
                filter: false,
                sort: false,
                display: true,
                download: false,
              },
            },
            columnData: note.action,
          },
        ]
      : []),
  ]);

  if (!tableDataArray.length) {
    return (
      <Card>
        <CardContent>
          <Typography component="h2" variant="h6" gutterBottom>
            Activity
            <Divider />
          </Typography>

          <Typography variant="body1">
            There are no activities to display
          </Typography>
        </CardContent>
      </Card>
    );
  } else {
    const columnsArray = tableDataArray[0].map(
      (column) => column.columnDetails
    );

    const noteData = tableDataArray.map((columns) => {
      const columnData = columns.map((column) => column.columnData);

      return columnData;
    });

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: true,
      responsive: "standard",
      downloadOptions: {
        filename: `${title}.csv`,
      },
      sort: false,
      filter: true,
      elevation: 0,
    };

    return (
      <MuiThemeProvider theme={columnWidthTheme}>
        <MUIDataTable
          title={title}
          data={noteData}
          columns={columnsArray}
          options={options}
        />
      </MuiThemeProvider>
    );
  }
}
