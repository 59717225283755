import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import moment from 'moment'
import createDecorator from 'final-form-calculate'
import { Form, Field } from 'react-final-form'
import { DatePicker } from '@material-ui/pickers'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  ErrorMessage,
  Loading,
  Typography,
} from '@web-applications/daffodil-component-library'

import Spacer from '../../../../../newComponents/Spacer'
import Table from '../../../../../newComponents/Table'
import Button from '../../../../../newComponents/Button'
import Divider from '../../../../../newComponents/Divider'
import { GET_PAYMENTS as GET_ALL_ORDER_PAYMENTS } from '../../PaymentsTable'
import { openSnackbar } from '../../../../../components/reusable/Notifier'

const GET_REFUNDABLE_PAYMENTS = gql`
  query getCardRefundablePayments($orderId: ID!) {
    getOrder(orderId: $orderId) {
      _id
      order_status
    }
    getCardRefundablePayments(orderId: $orderId) {
      _id
      time
      payment_type
      amount
    }
  }
`

const REFUND_PAYMENTS = gql`
  mutation refundPayments($paymentIds: [ID!]!, $newDepositDate: DateTime) {
    refundPayments(paymentIds: $paymentIds, newDepositDate: $newDepositDate) {
      message
      activity {
        _id
        time
        content
        user {
          _id
          first_name
          last_name
        }
      }
      order {
        _id
        to_pay
        order_status
        deposit_date
        completed_date
      }
      payments {
        _id
        payment_number
        time
        timezone
        approval_code
        status
        invoice_number
        amount
        transaction_id
        reference_number
        payment_type
        card_number
        refunded
        cancelled_due_to_restructure
        customer_paid
      }
    }
  }
`

export default function CardRefunds({ orderId, hideModal }) {
  const [refundPayments, { loading: refundPaymentsLoading }] = useMutation(
    REFUND_PAYMENTS,
    {
      onError: ({ message }) => {
        openSnackbar({
          message: message.replace('GraphQL error: ', ''),
          type: 'error',
        })
      },
      onCompleted: ({ refundPayments: { message } }) => {
        openSnackbar({
          message: message,
          type: 'success',
        })
        hideModal()
      },
      update(
        cache,
        {
          data: {
            refundPayments: { activity, payments },
          },
        }
      ) {
        // Order is automatically updated in the cache. Activity and payments
        // need upating manually

        // add activity to acitivity list
        const { getActivities } = cache.readQuery({
          query: gql`
            query getActivities($orderId: ID) {
              getActivities(orderId: $orderId) {
                _id
                time
                content
                user {
                  _id
                  first_name
                  last_name
                }
              }
            }
          `,
          variables: {
            orderId,
          },
        })

        cache.writeQuery({
          query: gql`
            query getActivities($orderId: ID) {
              getActivities(orderId: $orderId) {
                _id
                time
                content
                user {
                  _id
                  first_name
                  last_name
                }
              }
            }
          `,
          variables: {
            orderId,
          },
          data: {
            getActivities: [...getActivities, activity],
          },
        })

        cache.writeQuery({
          query: GET_ALL_ORDER_PAYMENTS,
          variables: {
            orderId,
          },
          data: {
            getPayments: payments,
          },
        })
      },
    }
  )

  const {
    loading: paymentsLoading,
    error: paymentsError,
    data: paymentsData,
  } = useQuery(GET_REFUNDABLE_PAYMENTS, {
    variables: { orderId },
    fetchPolicy: 'network-only',
  })

  if (paymentsLoading) return <Loading />

  if (paymentsError) return <ErrorMessage error={paymentsError} />

  const formattedData = paymentsData.getCardRefundablePayments.map(
    (payment) => ({
      id: payment._id,
      time: moment(payment.time).format('DD/MM/YY, HH:mm:ss'),
      paymentType: payment.payment_type,
      amount: new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(payment.amount),
    })
  )

  const totalAvailable = paymentsData.getCardRefundablePayments
    .map(({ amount }) => parseFloat(amount))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  return (
    <>
      <Typography variant="bodyLarge">
        Which payments are being refunded?
      </Typography>

      <Spacer height="32px" />
      <Form
        onSubmit={({ paymentsToRefund, newDepositDate }) => {
          const paymentIds = paymentsToRefund.map(({ id }) => id)

          refundPayments({
            variables: {
              paymentIds,
              newDepositDate,
            },
          })
        }}
        initialValues={{ totalToRefund: 0, refundingCardDeposit: false }}
        decorators={[
          createDecorator({
            //calculate the total amount selected
            field: 'paymentsToRefund',
            updates: async (value) => {
              const totalAmount = value
                .map(({ amount }) => parseFloat(amount.replace('£', '').replace(',', '')))
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )

              let refundingCardDeposit = false
              //only check for a new
              if (paymentsData.getOrder.order_status !== 'Cancelled') {
                refundingCardDeposit = value.filter(({ paymentType }) =>
                  paymentType.includes('Deposit')
                )

                refundingCardDeposit = refundingCardDeposit.length > 0
              }

              return {
                totalToRefund: totalAmount,
                refundingCardDeposit,
              }
            },
          }),
        ]}
        validate={({ refundingCardDeposit, newDepositDate }) => {
          if (refundingCardDeposit && !newDepositDate)
            return { newDepositDate: 'Required' }

          return {}
        }}
        render={({
          handleSubmit,
          pristine,
          invalid,
          values: { totalToRefund, refundingCardDeposit },
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Scrollbars autoHeight autoHeightMax={500}>
              <Field name="paymentsToRefund">
                {({ input: { onChange } }) => (
                  <Table
                    onSelectedRowsChange={onChange}
                    headers={[
                      {
                        Header: 'Payment ID',
                        accessor: 'id',
                        disableFilters: true,
                        disabledSortBy: true,
                      },
                      {
                        Header: 'Time',
                        accessor: 'time',
                        disableFilters: true,
                        disabledSortBy: true,
                      },
                      {
                        Header: 'Payment Type',
                        accessor: 'paymentType',
                        disableFilters: true,
                        disabledSortBy: true,
                      },
                      {
                        Header: 'Amount',
                        accessor: 'amount',
                        disableFilters: true,
                        disabledSortBy: true,
                      },
                    ]}
                    data={formattedData}
                  />
                )}
              </Field>
              <Spacer height="32px" />
              <Typography variant="bodySmall">
                {`Total available to refund: ${new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                }).format(totalAvailable)}`}
              </Typography>
              <Spacer height="8px" />
              <Typography variant="bodySmall">
                {`Total to be refunded: ${new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                }).format(totalToRefund)}`}
              </Typography>
              <Spacer height="32px" />
              {refundingCardDeposit && (
                <>
                  <Field name="newDepositDate">
                    {({ input, meta }) => (
                      <DatePicker
                        format="DD/MM/YYYY"
                        name={input.name}
                        onChange={input.onChange}
                        value={input.value ? input.value : null}
                        onBlur={input.onBlur}
                        fullWidth
                        required
                        disablePast
                        autoOk
                        label="New Deposit Date"
                        leftArrowIcon={<KeyboardArrowLeft />}
                        inputVariant="outlined"
                        rightArrowIcon={<KeyboardArrowRight />}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ''
                        }
                      />
                    )}
                  </Field>
                  <Spacer height="32px" />
                </>
              )}
            </Scrollbars>
            <Divider />
            <Spacer height="32px" />
            <Button
              colour="#8BC34A"
              disabled={pristine || invalid}
              type="submit"
              loading={refundPaymentsLoading}
            >
              Refund
            </Button>
          </form>
        )}
      />
    </>
  )
}
