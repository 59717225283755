import React from 'react'

import AuthenticatedRoute from '../components/util/AuthenticatedRoute'

import Reports from '../components/pages/Reports/Reports'
import FailedPaymentLinkReport from '../components/pages/Reports/FailedPaymentLinkReport'
import FailedPaymentReportIndividual from '../components/pages/Reports/FailedPaymentReportIndividual'

const ReportRoutes = [
  <AuthenticatedRoute
    path="/reports"
    exact
    component={Reports}
    title="Reports"
  />,
  <AuthenticatedRoute
    path="/reports/failed-payment-conversion"
    exact
    component={FailedPaymentLinkReport}
    title="Failed Payment Conversions"
  />,
  <AuthenticatedRoute
    path="/reports/failed-payment-conversion/individual"
    exact
    component={FailedPaymentReportIndividual}
    title="Failed Payment Conversions"
  />,
]

export default ReportRoutes
