import React, { Fragment, useContext } from "react";
import { Form } from "react-final-form";
import { Mutation, Query, ApolloConsumer } from "@apollo/react-components";
import validator from "validator";
import createDecorator from "final-form-calculate";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  IconButton,
  CardHeader,
  CardActions,
} from "@material-ui/core";

import { Save, Clear } from "@material-ui/icons";

import { openSnackbar } from "./../../reusable/Notifier";
import { openModal, closeModal } from "./../../reusable/Popup";
import Loading from "./../../reusable/Loading";
import ActivityTable from "./../../reusable/ActivityTable";

import {
  GET_USERS,
  UPDATE_USER,
  GET_USER,
  ARCHIVE_USER,
  GET_STORE,
  UNARCHIVE_USER,
  ADMIN_PASSWORD_RESET,
} from "./../../../helpers/queries/User";

import UserEdit from "./UserEdit";

import { UserContext } from "../../util/PageWrapper";
import Error from "./../../../newComponents/Error";

const GET_ACTIVITIES = gql`
  query getActivities($userId: ID) {
    getActivities(userId: $userId) {
      _id
      time
      content
      user {
        _id
        first_name
        last_name
      }
    }
  }
`;

function SendPasswordReset({ email }) {
  return (
    <Mutation mutation={ADMIN_PASSWORD_RESET}>
      {(adminPasswordResetRequest) => (
        <Button
          variant="contained"
          style={{
            marginLeft: "auto",
            backgroundColor: "red",
            color: "white",
          }}
          color="secondary"
          onClick={() => {
            openModal({
              content: (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="Close Pop-up"
                        onClick={closeModal}
                      >
                        <Clear />
                      </IconButton>
                    }
                    title="Are You Sure?"
                  />
                  <CardContent>
                    <Typography variant="body1" gutterBottom>
                      Are you sure you want to send this user a password reset
                      email?
                    </Typography>
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        adminPasswordResetRequest({
                          variables: {
                            admin: true,
                            email: email,
                          },
                        })
                          .then(() => {
                            openSnackbar({
                              message: "Reset password email sent to " + email,
                              type: "success",
                            });
                            closeModal();
                          })
                          .catch((error) => {
                            openSnackbar({
                              message:
                                "Failed to send reset password email, please try again",
                              type: "error",
                            });
                            closeModal();
                          });
                      }}
                    >
                      Continue
                    </Button>
                  </CardActions>
                </Card>
              ),
            });
          }}
        >
          Send Password Reset
        </Button>
      )}
    </Mutation>
  );
}

export default function UserView() {
  const currentUser = useContext(UserContext);

  const params = useParams();

  const { userId } = params;

  const {
    loading: activitiesLoading,
    error: activitiesError,
    data: activitiesData,
  } = useQuery(GET_ACTIVITIES, {
    variables: { userId: userId },
  });

  if (activitiesLoading) return <Loading />;

  if (activitiesError) return <Error />;

  const activities = activitiesData.getActivities || [];
  return (
    <Query
      query={GET_USER}
      variables={{
        userId: userId,
      }}
    >
      {({ loading, error, data }) => {
        if (error) {
          return null;
        }

        if (loading) {
          return <Loading />;
        }

        const { user } = data;

        //format stores for multiselect
        const formattedStores = user.stores.map((store) => {
          return {
            _id: store._id,
            name: store.name,
          };
        });

        return (
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography component="h1" variant="h4" gutterBottom>
                {`Edit User - ${user.first_name} ${user.last_name}`}
              </Typography>
              {userId !== currentUser._id && (
                <Fragment>
                  {user.archived ? (
                    <Mutation
                      mutation={UNARCHIVE_USER}
                      refetchQueries={[
                        {
                          query: GET_USERS,
                        },
                      ]}
                    >
                      {(unArchiveUser) => (
                        <Button
                          variant="contained"
                          style={{
                            marginLeft: "auto",
                            backgroundColor: "red",
                            color: "white",
                          }}
                          color="secondary"
                          onClick={() => {
                            unArchiveUser({
                              variables: {
                                userId: userId,
                              },
                            })
                              .then(({ data }) => {
                                // console.log("data", data);
                                const { unArchiveUser } = data;

                                openSnackbar({
                                  message:
                                    unArchiveUser.first_name +
                                    " " +
                                    unArchiveUser.last_name +
                                    " has been successfully unarchived",
                                  type: "success",
                                });
                                closeModal();
                              })
                              .catch(() => {
                                openSnackbar({
                                  message:
                                    "Failed to unarchive user, please try again",
                                  type: "error",
                                });
                                closeModal();
                              });
                          }}
                        >
                          Unarchive User
                        </Button>
                      )}
                    </Mutation>
                  ) : (
                    <>
                      <SendPasswordReset email={user.email} />
                      <Mutation
                        mutation={ARCHIVE_USER}
                        refetchQueries={[
                          {
                            query: GET_USERS,
                          },
                        ]}
                      >
                        {(archiveUser) => (
                          <Button
                            variant="contained"
                            style={{
                              marginLeft: "auto",
                              backgroundColor: "red",
                              color: "white",
                            }}
                            color="secondary"
                            onClick={() => {
                              openModal({
                                content: (
                                  <Card>
                                    <CardHeader
                                      action={
                                        <IconButton
                                          aria-label="Close Pop-up"
                                          onClick={closeModal}
                                        >
                                          <Clear />
                                        </IconButton>
                                      }
                                      title="Are You Sure?"
                                    />
                                    <CardContent>
                                      <Typography variant="body1" gutterBottom>
                                        Archiving a user can be undone.
                                      </Typography>
                                    </CardContent>
                                    <CardActions
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={closeModal}
                                      >
                                        Cancel
                                      </Button>

                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          archiveUser({
                                            variables: {
                                              userId: userId,
                                            },
                                          })
                                            .then(({ data }) => {
                                              const { archiveUser } = data;

                                              openSnackbar({
                                                message:
                                                  archiveUser.first_name +
                                                  " " +
                                                  archiveUser.last_name +
                                                  " has been successfully archived",
                                                type: "success",
                                              });
                                              closeModal();
                                            })
                                            .catch((error) => {
                                              openSnackbar({
                                                message:
                                                  "Failed to archive user, please try again",
                                                type: "error",
                                              });
                                              closeModal();
                                            });
                                        }}
                                      >
                                        Continue
                                      </Button>
                                    </CardActions>
                                  </Card>
                                ),
                              });
                            }}
                          >
                            Archive User
                          </Button>
                        )}
                      </Mutation>
                    </>
                  )}
                </Fragment>
              )}
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Mutation mutation={UPDATE_USER}>
                    {(updateUser) => (
                      <ApolloConsumer>
                        {(client) => (
                          <Form
                            initialValues={{
                              firstName: user.first_name,
                              lastName: user.last_name,
                              email: user.email,
                              role: user.role_id,
                              chosenStores: formattedStores,
                              canCancel: user.can_cancel,
                            }}
                            onSubmit={(values) => {
                              updateUser({
                                variables: {
                                  userId: userId,
                                  firstName: values.firstName,
                                  lastName: values.lastName,
                                  roleId: values.role,
                                  email: values.email,
                                  stores: JSON.stringify(values.chosenStores),
                                  canCancel: values.canCancel
                                    ? values.canCancel
                                    : false,
                                },
                              })
                                .then(({ data }) => {
                                  const { updateUser } = data;

                                  openSnackbar({
                                    message:
                                      updateUser.first_name +
                                      " " +
                                      updateUser.last_name +
                                      " has been successfully updated",
                                    type: "success",
                                  });
                                })
                                .catch(() => {
                                  openSnackbar({
                                    message:
                                      "Failed to update user, please try again",
                                    type: "error",
                                  });
                                });
                            }}
                            decorators={[
                              createDecorator({
                                //match billing address to customer address
                                field: "store",
                                updates: async (value, name, allValues) => {
                                  if (!value)
                                    return {
                                      [name]: "",
                                    };

                                  //get value and use it to find a store id
                                  const { data } = await client.query({
                                    query: GET_STORE,
                                    variables: {
                                      name: value,
                                    },
                                  });

                                  const { store } = data;

                                  if (!store) return {};

                                  const newStore = {
                                    _id: store._id,
                                    name: store.name,
                                  };

                                  const chosenStores =
                                    allValues.chosenStores || [];

                                  const chosenStoreNames = chosenStores.map(
                                    (store) => {
                                      return store.name;
                                    }
                                  );

                                  if (!chosenStoreNames.includes(store.name)) {
                                    chosenStores.push(newStore);
                                  }

                                  return {
                                    [name]: "",
                                    chosenStores: chosenStores,
                                  };
                                },
                              }),
                            ]}
                            mutators={{
                              removeStore: (args, state, utils) => {
                                const chosenStores =
                                  state.formState.values.chosenStores;

                                chosenStores.splice(args[0], 1);

                                utils.changeValue(
                                  state,
                                  "chosenStores",
                                  () => chosenStores
                                );
                              },
                            }}
                            validate={(values) => {
                              const errors = {};

                              const requiredFields = [
                                "firstName",
                                "lastName",
                                "email",
                                "role",
                              ];

                              requiredFields.forEach((requiredField) => {
                                if (!values[requiredField]) {
                                  errors[requiredField] = "Required";
                                }
                              });
                              if (!values.chosenStores.length) {
                                errors.store = "At least 1 store is required";
                              }

                              if (
                                values.email &&
                                !validator.isEmail(values.email) &&
                                !values.email.includes(".con") &&
                                !values.email.includes(".coma")
                              ) {
                                errors.email = "Invalid Email";
                              }

                              return errors;
                            }}
                            render={({
                              handleSubmit,
                              pristine,
                              invalid,
                              submitting,
                              values,
                            }) => (
                              <form onSubmit={handleSubmit} noValidate>
                                <UserEdit archived={user.archived} />
                                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                {!user.archived && (
                                  <Grid container spacing={3} justify="center">
                                    <Grid item xs={12}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={invalid || submitting}
                                        type="submit"
                                      >
                                        <Save style={{ marginRight: "10px" }} />
                                        Save
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </form>
                            )}
                          />
                        )}
                      </ApolloConsumer>
                    )}
                  </Mutation>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <ActivityTable title="Order Activity" activities={activities} />
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
}
