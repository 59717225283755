import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { openSnackbar } from "./../../reusable/Notifier";

import { closeModal } from "./../../reusable/Popup";

import {
  Card,
  CardContent,
  IconButton,
  Button,
  CardHeader,
  CardActions,
  TextField,
} from "@material-ui/core";

import { Clear } from "@material-ui/icons";

const CANCEL_NEW_ORDER = gql`
  mutation cancelNewOrder($parkedOrderId: String!, $reason: String!) {
    cancelNewOrder(parkedOrderId: $parkedOrderId, reason: $reason) {
      _id
      status
    }
  }
`;

export default function CancelNewOrderPopup({ parkedOrderId }) {
  const history = useHistory();

  const [cancelNewOrder, { loading }] = useMutation(CANCEL_NEW_ORDER, {
    onError: () => {
      openSnackbar({
        message: "Failed to cancel new order, please try again.",
        type: "error",
      });
      closeModal();
    },
    onCompleted: () => {
      openSnackbar({
        message: "This order was cancelled, returning to orders.",
        type: "success",
      });
      closeModal();
      history.push("/orders");
    },
  });

  const [reason, setReason] = useState("");

  return (
    <Card>
      <CardHeader
        action={
          <IconButton aria-label="Close Pop-up" onClick={closeModal}>
            <Clear />
          </IconButton>
        }
        title="Are you sure you wish to cancel this order?"
      />
      <CardContent>
        <TextField
          label="Reason"
          fullWidth
          required
          variant="outlined"
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
          }}
        />
      </CardContent>

      <CardActions
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button variant="contained" color="primary" onClick={closeModal}>
          No
        </Button>

        <Button
          variant="contained"
          color="secondary"
          disabled={loading || !reason}
          onClick={() => {
            cancelNewOrder({
              variables: {
                parkedOrderId: parkedOrderId,
                reason: reason,
              },
            });
          }}
        >
          Yes
        </Button>
      </CardActions>
    </Card>
  );
}
