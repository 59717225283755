import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'

import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'

import { closeModal } from './../../reusable/Popup'
import Loading from '../../reusable/Loading'
import { openSnackbar } from '../../reusable/Notifier'

const GET_CUSTOMER = gql`
  query getCustomerByEmail($email: String!) {
    getCustomerByEmail(email: $email) {
      _id
      customer_type
      address_line_1
      address_postcode
      first_name
      last_name
    }
  }
`

const ADD_PARKED_ORDER_EXISTING_CUSTOMER = gql`
  mutation addParkedOrderExistingCustomer(
    $parkedOrderId: ID!
    $customerId: ID!
  ) {
    addParkedOrderExistingCustomer(
      parkedOrderId: $parkedOrderId
      customerId: $customerId
    ) {
      _id
      customer_type
      existing_customer
      address_city
      address_county
      address_line_1
      address_line_2
      address_postcode
      email
      first_name
      last_name
      mobile
      phone
      preferred_contact
      title
      marketing_news
      marketing_offers
    }
  }
`

export default function EmailAlreadyInUsePopup({ email, parkedOrderId }) {
  const { loading, data } = useQuery(GET_CUSTOMER, {
    variables: { email: email },
  })

  const history = useHistory()

  const [
    addParkedOrderExistingCustomer,
    { loading: updateParkedOrderLoading },
  ] = useMutation(ADD_PARKED_ORDER_EXISTING_CUSTOMER, {
    onError: () => {
      openSnackbar({
        message: 'Failed to update customer, please try again',
        type: 'error',
      })
    },
    onCompleted: () => {
      closeModal()
      history.push(`/new-order/order-details/${parkedOrderId}`)
    },
  })

  let customerDetails

  if (data) {
    customerDetails = data.getCustomerByEmail
  }

  return (
    <Card>
      <CardHeader title="Email already in use" />
      <CardContent>
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1" paragraph>
                This email is already in use by another customer:
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0, 0.4)',
                      }}
                    >
                      First Name
                    </TableCell>
                    <TableCell
                      style={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0, 0.4)',
                      }}
                    >
                      Last Name
                    </TableCell>
                    <TableCell
                      style={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0, 0.4)',
                      }}
                    >
                      Address Line 1
                    </TableCell>
                    <TableCell
                      style={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0, 0.4)',
                      }}
                    >
                      Postcode
                    </TableCell>
                    <TableCell
                      style={{
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0, 0.5)',
                      }}
                    >
                      Customer Type
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}>
                      {customerDetails.first_name}
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                      {customerDetails.last_name}
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                      {customerDetails.address_line_1}
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                      {customerDetails.address_postcode}
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                      {customerDetails.customer_type}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                To continue with this order you must either change the email
                address or update the customer account shown above.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  closeModal()
                }}
              >
                Change Email Address
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={updateParkedOrderLoading}
                onClick={() => {
                  addParkedOrderExistingCustomer({
                    variables: {
                      parkedOrderId: parkedOrderId,
                      customerId: customerDetails._id,
                    },
                  })
                }}
              >
                Overwrite Above Customer Details And Continue With Order
              </Button>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}
