import React from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'

import Spacer from './../../../newComponents/Spacer'
import Typography from '../../../newComponents/Typography'
import Card from '../../../newComponents/Card'
import Error from '../../../newComponents/Error'
import Table, { SelectColumnFilter } from './../../../newComponents/Table'
import FlexBox from './../../../newComponents/FlexBox'
import IconButton from '../../../newComponents/IconButton'
import Dropdown from './../../../newComponents/Dropdown'
import MenuItem from './../../../newComponents/MenuItem'
import Loading from './../../reusable/Loading'
import { openSnackbar } from '../../reusable/Notifier'

import { mdiDownload, mdiDotsVertical } from '@mdi/js'

function useUrlQuery() {
  return new URLSearchParams(useLocation().search)
}

const GET_FAILED_PAYMENT_DAY = gql`
  query getFailedPaymentDay($date: String!, $time: String!, $daysAfter: Int!) {
    getFailedPaymentDay(date: $date, time: $time, daysAfter: $daysAfter) {
      invoice_number
      customer_name
      payment_amount
      mobile_number
      email
      payment_taken
      payment_source
    }
  }
`

const GET_FAILED_PAYMENT_DAY_DOWNLOAD = gql`
  query getFailedPaymentDaysDetailDownloadUrl(
    $dateFrom: String!
    $dateTo: String!
    $time: String
    $daysAfter: Int
  ) {
    getFailedPaymentDaysDetailDownloadUrl(
      dateFrom: $dateFrom
      dateTo: $dateTo
      time: $time
      daysAfter: $daysAfter
    )
  }
`

export default function FailedPaymentReportIndividual() {
  // get url parameters
  let query = useUrlQuery()

  const date = query.get('date')
  const time = query.get('time')
  const daysAfter = parseInt(query.get('daysAfter'))

  const { loading, error, data } = useQuery(GET_FAILED_PAYMENT_DAY, {
    variables: {
      date,
      time,
      daysAfter,
    },
  })

  const [
    getFailedPaymentDaysDetailDownloadUrl,
    { loading: downloadLoading },
  ] = useLazyQuery(GET_FAILED_PAYMENT_DAY_DOWNLOAD, {
    onError: () => {
      openSnackbar({
        message: 'Failed to get download, please try again.',
        type: 'error',
      })
    },
    onCompleted: ({ getFailedPaymentDaysDetailDownloadUrl }) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${getFailedPaymentDaysDetailDownloadUrl}`
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const formattedData = data.getFailedPaymentDay.map((payment) => ({
    invoiceNumber: payment.invoice_number,
    customerName: payment.customer_name,
    paymentAmount: new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(payment.payment_amount),
    phoneNumber: payment.mobile_number,
    emailAddress: payment.email,
    paymentTaken: payment.payment_taken ? 'Yes' : 'No',
    source: payment.payment_source ? payment.payment_source : '-',
    actions: (
      <Dropdown anchor="right">
        <IconButton
          dropdownTrigger
          path={mdiDotsVertical}
          title="Download"
          size="24px"
          color="#818181"
        />

        <MenuItem
          component={Link}
          to={`/orders/single/${payment.invoice_number}`}
          target="_blank"
        >
          View Order Details
        </MenuItem>
      </Dropdown>
    ),
  }))

  return (
    <Container>
      <Row>
        <Col col xs="12">
          <Spacer height="16px" />
          <Typography variant="h1">
            {`Message Batch sent at ${time} on ${date}`}
          </Typography>
          <Spacer height="52px" />
          <Card>
            <FlexBox justifyContent="space-between">
              <Typography variant="h2">Texts/Emails Sent</Typography>
              <IconButton
                path={mdiDownload}
                title="Download"
                size="24px"
                color="black"
                disabled={!formattedData.length || downloadLoading}
                onClick={() => {
                  getFailedPaymentDaysDetailDownloadUrl({
                    variables: {
                      dateFrom: date,
                      dateTo: date,
                      time,
                      daysAfter,
                    },
                  })

                  openSnackbar({
                    message: 'Your download will begin shortly',
                    type: 'success',
                  })
                }}
              />
            </FlexBox>
            <Spacer height="24px" />
            <Table
              headers={[
                {
                  Header: 'Order No.',
                  accessor: 'invoiceNumber',
                  filter: 'fuzzyText',
                },
                {
                  Header: 'Customer Name',
                  accessor: 'customerName',
                  filter: 'fuzzyText',
                },
                {
                  Header: 'Payment Amount',
                  accessor: 'paymentAmount',
                  filter: 'fuzzyText',
                },
                {
                  Header: 'Phone Number',
                  accessor: 'phoneNumber',
                  filter: 'fuzzyText',
                },
                {
                  Header: 'Email Address',
                  accessor: 'emailAddress',
                  filter: 'fuzzyText',
                },
                {
                  Header: 'Payment Taken',
                  accessor: 'paymentTaken',
                  Filter: SelectColumnFilter,
                  filter: 'includes',
                },
                {
                  Header: 'Source',
                  accessor: 'source',
                  Filter: SelectColumnFilter,
                  filter: 'includes',
                },
                {
                  accessor: 'actions',
                  disableFilters: true,
                  disableSortBy: true,
                },
              ]}
              data={formattedData}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
