import React from 'react'
import NumberFormat from 'react-number-format'
import {
  Loading,
  ErrorMessage,
  Button,
} from '@web-applications/daffodil-component-library'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { Clear } from '@material-ui/icons'
import {
  Grid,
  Card,
  CardContent,
  TextField,
  IconButton,
  CardHeader,
  CardActions,
  Typography,
  InputAdornment,
  MenuItem
} from '@material-ui/core'
import { Form, Field } from 'react-final-form'
import { GET_UI_LIST_ENTRIES } from '../../../../helpers/queries/CustomerVisitOutcome'
import { openSnackbar } from '../../../../components/reusable/Notifier'

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      order_status
      cancellation {
        _id
      }
    }
  }
`

const ADD_INCREASE = gql`
  mutation addIncrease(
    $amount: Float!
    $invoiceNumber: Int!
    $reason: String!
    $reasonCode: String!
    $reasonText: String!
  ) {
    addIncrease(
      amount: $amount
      invoiceNumber: $invoiceNumber
      reason: $reason
      reasonCode: $reasonCode
      reasonText: $reasonText
    ) {
      _id
      increases
      total_increase
    }
  }
`

export default function AddIncrease({ hideModal }) {
  const { invoiceNumber } = useParams()
  const {
    data: cvoData,
  } = useQuery(GET_UI_LIST_ENTRIES,{variables:{type:'discountsincreases'}})

  const [addIncrease, { loading: addIncreaseLoading }] = useMutation(
    ADD_INCREASE,
    {
      onError: () => {
        hideModal()
        openSnackbar({
          message: 'Adding increase failed',
          type: 'error',
        })
      },
      onCompleted: () => {
        hideModal()
        openSnackbar({
          message: 'Increase added successfully',
          type: 'success',
        })
      },
    }
  )

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  })

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  const { getOrder: order } = data

  if (order?.cancellation?._id || order?.order_status === 'Cancelled') {
    return (
      <Card style={{ minWidth: '600px' }}>
        <CardHeader
          action={
            <IconButton aria-label="Close Pop-up" onClick={hideModal}>
              <Clear />
            </IconButton>
          }
          title="Add Increase"
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Increases can't be added to orders that are cancelled or pending
                a cancellation.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  return (
    <Form
      validate={({ amount, reason, reasonCodePlusText }) => {
        const errors = {}

        // amount must be entered

        if (!amount) {
          errors.amount = 'Required'
        }

        //reason must be entered
        if (!reason) {
          errors.reason = 'Required'
        }

        if(!reasonCodePlusText){
          errors.reasonCode = 'Required'
        }

        return errors
      }}
      onSubmit={({ amount, reason, reasonCodePlusText }) => {
        const variables={
          amount: parseFloat(amount) || 0.0,
          invoiceNumber: parseInt(invoiceNumber),
          reason,
          reasonCode:reasonCodePlusText.split(',')[0],
          reasonText:reasonCodePlusText.split(',')[1]
        }
        console.log(variables)
        addIncrease({
          variables: variables,
        })
      }}
      render={({ handleSubmit, invalid, pristine }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Card style={{ minWidth: '600px' }}>
            <CardHeader
              action={
                <IconButton aria-label="Close Pop-up" onClick={hideModal}>
                  <Clear />
                </IconButton>
              }
              title="Add Increase"
            />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Field name="amount">
                    {({ input, meta }) => (
                      <NumberFormat
                        customInput={TextField}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                        }}
                        {...input}
                        label="Amount"
                        displayType="tel"
                        decimalScale={2}
                        fixedDecimalScale
                        required
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ''
                        }
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field name="reasonCodePlusText">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        select
                        fullWidth
                        variant="outlined"
                        label="Reason Code"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ''
                        }
                        size="small"
                        style={{marginTop:'7.5px'}}
                      >
                        {cvoData && cvoData.entries.map((outcome) => {
                          return (
                            <MenuItem
                              value={outcome.value+','+outcome.name}
                              key={outcome._id}
                            >
                              {outcome.name}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Field name="reason">
                    {({ input, meta }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        {...input}
                        label="Reason"
                        required
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ''
                        }
                      />
                    )}
                  </Field>
                </Grid>
                
              </Grid>
            </CardContent>

            <CardActions
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button variant="contained" color="secondary" onClick={hideModal}>
                Cancel
              </Button>

              <Button
                type="submit"
                loading={addIncreaseLoading}
                disabled={pristine || invalid}
              >
                Continue
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    />
  )
}
