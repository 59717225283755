import React, { Fragment, useContext, useState } from 'react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'

import { MoreVert, Lock } from '@material-ui/icons'

import DeleteParkedOrderPopup from './DeleteParkedOrderPopup'

import { openSnackbar } from './../../reusable/Notifier'
import { openModal } from '../../reusable/Popup'

import { UserContext } from '../../util/PageWrapper'

const RESTART_PARKED_ORDER = gql`
  mutation restartParkedOrder($parkedOrderId: String!) {
    restartParkedOrder(parkedOrderId: $parkedOrderId) {
      _id
    }
  }
`

const FIX_PARKED_ORDER = gql`
  mutation fixParkedOrder($parkedOrderId: String!) {
    fixParkedOrder(parkedOrderId: $parkedOrderId) {
      _id
    }
  }
`

export default function ParkedOrderOptions({ parkedOrder }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const user = useContext(UserContext)
  const history = useHistory()

  const [
    restartParkedOrder,
    { loading: restartParkedOrderLoading },
  ] = useMutation(RESTART_PARKED_ORDER, {
    onCompleted: ({ restartParkedOrder }) => {
      history.push(`/new-order/customer-details/${restartParkedOrder._id}`)
    },
    onError: () => {
      openSnackbar({
        message: 'Failed to restart parked order, please try again.',
        type: 'error',
      })
    },
  })

  const [fixParkedOrder, { loading: fixParkedOrderLoading }] = useMutation(
    FIX_PARKED_ORDER,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to fix parked order, please try again',
          type: 'error',
        })
      },
      onCompleted: () => {
        openSnackbar({
          message: 'Parked order has been fixed',
          type: 'success',
        })
      },
    }
  )

  //dont show any options if the order is locked and the user isn't allowed to do anything, just show the padlock

  if (
    user.role_id < 4 &&
    parkedOrder.locked
  ) {
    return (
      <Tooltip title="Order Is Locked" placement="top">
        <div
          style={{
            padding: '12px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#a09e9e',
          }}
        >
          <Lock />
        </div>
      </Tooltip>
    )
  } else {
    return (
      <Fragment>
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget)
          }}
        >
          <MoreVert />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null)
          }}
        >
          {!parkedOrder.locked && (
            <MenuItem
              onClick={() => {
                openModal({
                  content: (
                    <DeleteParkedOrderPopup parkedOrderId={parkedOrder._id} />
                  ),
                })
                setAnchorEl(null)
              }}
            >
              Delete Parked Order
            </MenuItem>
          )}
          {!parkedOrder.locked && (
            <MenuItem
              disabled={restartParkedOrderLoading}
              onClick={() => {
                restartParkedOrder({
                  variables: {
                    parkedOrderId: parkedOrder._id,
                  },
                })
              }}
            >
              Continue Parked Order
            </MenuItem>
          )}

          {(user.role_id > 3) &&
            parkedOrder.locked && (
              <MenuItem
                disabled={fixParkedOrderLoading}
                onClick={() => {
                  fixParkedOrder({
                    variables: {
                      parkedOrderId: parkedOrder._id,
                    },
                  })
                  setAnchorEl(null)
                }}
              >
                Unlock Parked Order
              </MenuItem>
            )}
        </Menu>
      </Fragment>
    )
  }
}
