import React, { Fragment } from 'react'
import gql from 'graphql-tag'
import { useParams, Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import {
  Loading,
  ErrorMessage,
} from '@web-applications/daffodil-component-library'
import {
  Typography,
  Grid,
  Divider,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { RemoveRedEye } from '@material-ui/icons'

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      billing_first_name
      billing_last_name
      billing_address_line_1
      billing_address_city
      billing_address_county
      billing_address_postcode
      billing_address_line_2
      billing_title
      customer {
        _id
        first_name
        last_name
        first_name
        last_name
        address_city
        address_county
        address_line_1
        address_line_2
        address_postcode
        title
        phone
        email
        mobile
        preferred_contact
      }
    }
  }
`

export default function CustomerDetails() {
  const { invoiceNumber } = useParams()

  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  })

  if (orderLoading) return <Loading />

  if (orderError) return <ErrorMessage error={orderError} />

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Typography component="h1" variant="h5" gutterBottom>
            Customer Details
          </Typography>
          <Tooltip
            title="View Customer"
            placement="top"
            style={{ marginLeft: '10px' }}
          >
            <IconButton
              aria-label="View"
              component={Link}
              to={'/customers/edit/' + orderData.getOrder.customer?._id}
            >
              <RemoveRedEye />
            </IconButton>
          </Tooltip>
        </div>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
          Customer
        </Typography>
        <Typography variant="body1" gutterBottom>
          {orderData.getOrder.customer?.title.replace(/^\w/, (c) =>
            c.toUpperCase()
          )}{' '}
          {orderData.getOrder.customer?.first_name}{' '}
          {orderData.getOrder.customer?.last_name} <br />
          {orderData.getOrder.customer?.address_line_1}
          <br />
          {!!orderData.getOrder.customer?.address_line_2 && (
            <Fragment>
              {orderData.getOrder.customer?.address_line_2} <br />
            </Fragment>
          )}
          {orderData.getOrder.customer?.address_city}
          <br />
          {orderData.getOrder.customer?.address_county}
          <br />
          {orderData.getOrder.customer?.address_postcode}
          <br />
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
          Billing Details
        </Typography>
        <Typography variant="body1" gutterBottom>
          {orderData.getOrder.billing_title.replace(/^\w/, (c) =>
            c.toUpperCase()
          )}{' '}
          {orderData.getOrder.billing_first_name}{' '}
          {orderData.getOrder.billing_last_name}
          <br />
          {orderData.getOrder.billing_address_line_1}
          <br />
          {!!orderData.getOrder.billing_address_line_2 && (
            <Fragment>
              {orderData.getOrder.billing_address_line_2} <br />
            </Fragment>
          )}
          {orderData.getOrder.billing_address_city}
          <br />
          {orderData.getOrder.billing_address_county}
          <br />
          {orderData.getOrder.billing_address_postcode}
          <br />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
          Contact Details
        </Typography>
        <Typography variant="body1">
          {`Preferred Contact: ${orderData.getOrder.customer?.preferred_contact.replace(
            /^\w/,
            (c) => c.toUpperCase()
          )}`}
        </Typography>
        <Typography variant="body1">
          {`Email: ${orderData.getOrder.customer?.email}`}
        </Typography>
        {!!orderData.getOrder.customer?.phone && (
          <Typography variant="body1">
            {`Phone: ${orderData.getOrder.customer?.phone}`}
          </Typography>
        )}
        {!!orderData.getOrder.customer?.mobile && (
          <Typography variant="body1">
            {`Mobile: ${orderData.getOrder.customer?.mobile}`}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}
