import React, { Fragment } from "react";
import { Field } from "react-final-form";
import moment from "moment";
import NumberFormat from "react-number-format";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import parse, { domToReact } from "html-react-parser";
import { Form } from "react-final-form";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import {
  Grid,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";

import NewOrderWrapper from "./NewOrderWrapper";

const GET_PARKED_ORDER = gql`
  query parkedOrder($parkedOrderId: String!) {
    parkedOrder(parkedOrderId: $parkedOrderId) {
      _id
      title
      first_name
      last_name
      address_line_1
      address_line_2
      address_city
      address_county
      address_postcode
      preferred_contact
      email
      phone
      mobile
      billing_title
      billing_first_name
      billing_address_line_1
      billing_address_line_2
      billing_address_city
      billing_address_county
      billing_address_postcode
      items
      deposit_method
      order_type
      finance_agreement_number
      finance_agreement_term_months
      first_payment_date
      billing_last_name
      release_date
      fitting_delivery_total
      deposit
      weekly_payment
      to_pay
      complete_payments
    }
  }
`;

const GET_SETTINGS = gql`
  query settings {
    settings {
      _id
      send_release_texts
      terms_and_conditions
    }
  }
`;

export default function OrderReview({ match: { params }, history }) {
  const { loading, error, data } = useQuery(GET_PARKED_ORDER, {
    variables: { parkedOrderId: params.parkedOrderId },
  });

  const {
    loading: settingsLoading,
    error: settingsError,
    data: settingsData,
  } = useQuery(GET_SETTINGS);

  let initialValues = {
    title: "",
    items: [],
    preferredContact: "",
    billingTitle: "",
  };

  if (data) {
    const { parkedOrder } = data;

    // console.log("parked order", parkedOrder);

    //set initial values to ones received in for the parked order

    initialValues = {
      title: parkedOrder?.title,
      firstName: parkedOrder?.first_name,
      lastName: parkedOrder?.last_name,
      addressLine1: parkedOrder?.address_line_1,
      addressLine2: parkedOrder?.address_line_2,
      addressCity: parkedOrder?.address_city,
      addressCounty: parkedOrder?.address_county,
      addressPostcode: parkedOrder?.address_postcode,
      preferredContact: parkedOrder?.preferred_contact,
      email: parkedOrder?.email,
      phone: parkedOrder?.phone,
      mobile: parkedOrder?.mobile,
      billingTitle: parkedOrder?.billing_title,
      billingFirstName: parkedOrder?.billing_first_name,
      billingLastName: parkedOrder?.billing_last_name,
      billingAddressLine1: parkedOrder?.billing_address_line_1,
      billingAddressLine2: parkedOrder?.billing_address_line_2,
      billingAddressCity: parkedOrder?.billing_address_city,
      billingAddressCounty: parkedOrder?.billing_address_county,
      billingAddressPostcode: parkedOrder?.billing_address_postcode,
      items: JSON.parse(parkedOrder?.items),
      depositMethod: parkedOrder?.deposit_method,
      orderType: parkedOrder?.order_type,
      financeAgreementNumber: parkedOrder?.finance_agreement_number,
      financeAgreementTerm: parkedOrder?.finance_agreement_term_months,
      firstPaymentDate: parkedOrder?.first_payment_date,
      releaseDate: moment(parkedOrder?.release_date, "x").format("DD/MM/YY"),
      fittingDeliveryTotal: parkedOrder?.fitting_delivery_total,
      deposit: parkedOrder?.deposit,
      weeklyPayment: parkedOrder?.weekly_payment,
      toPay: parkedOrder?.to_pay,
      completePayments: parkedOrder?.complete_payments,
    };
  }

  let html;

  if (settingsData) {
    html = stateToHTML(
      convertFromRaw(JSON.parse(settingsData.settings.terms_and_conditions))
    );
  }

  return (
    <Form
      onSubmit={() => {
        history.push(`/new-order/order-confirmation/${params.parkedOrderId}`);
      }}
      validate={(values) => {
        const errors = {};

        if (!values.termsAgreed) {
          errors.termsAgreed = "Required";
        }

        return errors;
      }}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <NewOrderWrapper
          pageNumber={5}
          nextButtonLabel="Next"
          loading={loading || settingsLoading}
          nextPageFunction={() => {
            handleSubmit();
          }}
          error={
            !!error || !!settingsError
              ? "Failed to get order details, please refresh the page. If this issue persists, the parked order may have already been completed or deleted."
              : null
          }
        >
          <form autoComplete="off">
            <Grid container spacing={3} justify="center">
              <Grid item xs={12}>
                <Typography component="h2" variant="h5" gutterBottom>
                  Order Review
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Please check that the details below are correct before
                  confirming your order.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography component="h3" variant="h6" gutterBottom>
                  Customer Details
                  <Divider />
                </Typography>
                <Typography variant="body1" paragraph>
                  {values.title.replace(/^\w/, (c) => c.toUpperCase())}{" "}
                  {values.firstName} {values.lastName} <br />
                  {values.addressLine1}
                  <br />
                  {!!values.addressLine2 && (
                    <Fragment>
                      {values.addressLine2} <br />
                    </Fragment>
                  )}
                  {values.addressCity}
                  <br />
                  {values.addressCounty}
                  <br />
                  {values.addressPostcode}
                  <br />
                </Typography>

                <Typography variant="body1">
                  Your preferred contact method is:{" "}
                  {values.preferredContact.replace(/^\w/, (c) =>
                    c.toUpperCase()
                  )}
                </Typography>
                {!!values.email && (
                  <Typography variant="body1">Email: {values.email}</Typography>
                )}
                {!!values.phone && (
                  <Typography variant="body1">Phone: {values.phone}</Typography>
                )}
                {!!values.mobile && (
                  <Typography variant="body1">
                    Mobile: {values.mobile}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography component="h3" variant="h6" gutterBottom>
                  Billing Details
                  <Divider />
                </Typography>
                <Typography variant="body1" paragraph>
                  {values?.billingTitle?.replace(/^\w/, (c) => c.toUpperCase())}{" "}
                  {values.billingFirstName} {values.billingLastName} <br />
                  {values.billingAddressLine1}
                  <br />
                  {!!values.billingAddressLine2 && (
                    <Fragment>
                      {values.billingAddressLine2} <br />
                    </Fragment>
                  )}
                  {values.billingAddressCity}
                  <br />
                  {values.billingAddressCounty}
                  <br />
                  {values.billingAddressPostcode}
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography component="h3" variant="h6" gutterBottom>
                  Order Items
                  <Divider />
                </Typography>

                {values.items.map((item, index) => (
                  <Fragment key={index}>
                    <Typography variant="body1">
                      {item.itemName}
                      <br />
                      {item.itemColour}
                      <br />
                      {item.itemSize}
                    </Typography>
                    <div style={{ display: "flex" }}>
                      <Typography variant="caption" paragraph>
                        {item.itemRoom}
                        <br />
                        Subfloor: {item.subfloor ? item.subfloor : "No"}
                        {item.itemType === "Carpet" && (
                          <Fragment>
                            <br />
                            Underlay: {item.underlay ? item.underlay : "No"}
                            <br />
                            Grippers: {item.grippers ? item.grippers : "No"}
                          </Fragment>
                        )}
                      </Typography>
                      {!!item.doorbars && (
                        <Typography
                          variant="caption"
                          paragraph
                          style={{ marginLeft: "25px" }}
                        >
                          {item.doorbars.map((doorbar, index) => (
                            <Fragment key={index}>
                              {doorbar.size} Doorbar in {doorbar.material}
                              <br />
                            </Fragment>
                          ))}
                        </Typography>
                      )}
                    </div>
                  </Fragment>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Typography component="h3" variant="h6" gutterBottom>
                  Payment Information
                  <Divider />
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={
                    values.depositMethod === "cash"
                      ? "Cash Now"
                      : values.depositMethod === "cardNow"
                      ? "Card Now"
                      : "Card Later"
                  }
                  name="depositMethodText"
                  label="Deposit Payment Method"
                  fullWidth
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field name="releaseDate">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="Expected Release Date"
                      fullWidth
                      disabled
                      variant="outlined"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4}>
                <Field name="fittingDeliveryTotal">
                  {({ input }) => (
                    <NumberFormat
                      customInput={TextField}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                      disabled
                      {...input}
                      label="Fitting/Delivery Total"
                      displayType="tel"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} md={4}>
                <Field name="deposit">
                  {({ input }) => (
                    <NumberFormat
                      customInput={TextField}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                      disabled
                      {...input}
                      label="Deposit"
                      displayType="tel"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  )}
                </Field>
              </Grid>

              {values.orderType !== "financeAgreement" &&
                values.orderType !== "cashOnly" && (
                  <Grid item xs={12} md={4}>
                    <Field name="weeklyPayment">
                      {({ input }) => (
                        <NumberFormat
                          customInput={TextField}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }}
                          disabled
                          {...input}
                          label={
                            values.orderType === "weekly"
                              ? "Weekly Payment"
                              : "Monthly Payment"
                          }
                          displayType="tel"
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      )}
                    </Field>
                  </Grid>
                )}

              <Grid item xs={12} md={4}>
                <Field name="toPay">
                  {({ input }) => (
                    <NumberFormat
                      customInput={TextField}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                      disabled
                      {...input}
                      label="To Pay"
                      displayType="tel"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  )}
                </Field>
              </Grid>

              {(values.orderType !== "financeAgreement" ||
                values.orderType !== "cashOnly") && (
                <Grid item xs={12} md={4}>
                  <TextField
                    name="firstPaymentDate"
                    value={moment(values.firstPaymentDate, "x").format(
                      "DD/MM/YYYY"
                    )}
                    label="First Payment Date"
                    fullWidth
                    variant="outlined"
                    disabled
                  />
                </Grid>
              )}

              {(values.orderType !== "financeAgreement" ||
                values.orderType !== "cashOnly") && (
                <Grid item xs={12} md={4}>
                  <Field name="completePayments">
                    {({ input }) => (
                      <TextField
                        {...input}
                        label="No. of Payments"
                        fullWidth
                        variant="outlined"
                        type="number"
                        disabled
                      />
                    )}
                  </Field>
                </Grid>
              )}

              {values.orderType === "financeAgreement" && (
                <Grid item xs={12} md={4}>
                  <Field name="financeAgreementNumber">
                    {({ input }) => (
                      <TextField
                        {...input}
                        label="Finance Agreement"
                        fullWidth
                        variant="outlined"
                        type="number"
                        disabled
                      />
                    )}
                  </Field>
                </Grid>
              )}

              {values.orderType === "financeAgreement" && (
                <Grid item xs={12} sm={6} md={3}>
                  <Field name="financeAgreementTerm">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        select
                        label="Finance Agreement Term"
                        fullWidth
                        disabled
                        variant="outlined"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                      >
                        {[4, 6, 10, 12, 18].map((option) => (
                          <MenuItem
                            value={option}
                          >{`${option} Months`}</MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography component="h3" variant="h6" gutterBottom>
                  Terms and Conditions
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <div
                  style={{ maxHeight: "400px", overflowY: "scroll" }}
                  className="terms-conditions"
                >
                  {!!html && (
                    <div
                      style={{
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        color: "rgba(0, 0, 0, 0.87)",
                        padding: "0 10px",
                      }}
                    >
                      {parse(html, {
                        replace: (domNode) => {
                          if (
                            domNode.type === "tag" &&
                            domNode.name === "p" &&
                            (!domNode.children.length ||
                              domNode.children[0].name === "br")
                          ) {
                            return <Fragment />;
                          }

                          if (domNode.name === "a") {
                            return <span>{domToReact(domNode.children)}</span>;
                          }
                        },
                      })}
                    </div>
                  )}
                </div>

                <Field name="termsAgreed" type="checkbox">
                  {({ input, meta }) => (
                    <Fragment>
                      <FormControlLabel
                        control={
                          <Checkbox
                            inputProps={{ name: "termsAgreed" }}
                            {...input}
                            value="termsAgreed"
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            style={{
                              color:
                                meta.touched && meta.error ? "red" : "black",
                            }}
                          >
                            I agree to the terms and conditions above
                          </Typography>
                        }
                      />
                      {meta.touched && meta.error && (
                        <Typography variant="body2" style={{ color: "red" }}>
                          You must agree to the terms and conditions
                        </Typography>
                      )}
                    </Fragment>
                  )}
                </Field>
              </Grid>
            </Grid>
          </form>
        </NewOrderWrapper>
      )}
    />
  );
}
