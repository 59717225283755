import React from 'react'

import AuthenticatedRoute from '../components/util/AuthenticatedRoute'

import CancelOrderNew from '../components/pages/CancelOrder/CancelOrderNew'
import CancelOrderRequest from '../components/pages/CancelOrder/CancelOrderRequest'
import CancellationsPage from '../components/pages/CancelOrder/CancellationsPage'

const CancellationRoutes = [
  <AuthenticatedRoute
    path="/cancel-order/:invoiceNumber"
    exact
    component={CancelOrderNew}
    title="Cancel Order"
  />,
  <AuthenticatedRoute
    path="/cancel-request/:cancellationId"
    exact
    component={CancelOrderRequest}
    title="Cancellation Request"
  />,
  <AuthenticatedRoute
    path="/cancellations"
    exact
    component={CancellationsPage}
    title="Cancellations"
  />,
]

export default CancellationRoutes
