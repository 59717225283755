import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import PageWrapper from './PageWrapper'

export default function UnsecureRoute({
  component: Component,
  title,
  ...props
}) {
  return (
    <Route
      {...props}
      render={(innerProps) => (
        <PageWrapper>
          <Helmet>
            <title>{title || 'Pay Per Week'}</title>
          </Helmet>
          <Component {...innerProps} />
        </PageWrapper>
      )}
    />
  )
}
