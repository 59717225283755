import React from 'react'
import { useMutation, gql } from '@apollo/client'
import { Form, Field } from 'react-final-form'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  validateInput,
  Input,
  validatePhoneNumber,
} from '@web-applications/daffodil-component-library'
import Icon from '@mdi/react'
import { mdiContentSave } from '@mdi/js'

import { openSnackbar } from './../../components/reusable/Notifier'

const CREATE_FITTER = gql`
  mutation createFitter(
    $name: String!
    $email: EmailAddress
    $contactNumber: String
  ) {
    createFitter(name: $name, email: $email, contactNumber: $contactNumber) {
      id
      name
      email
      contactNumber
    }
  }
`

export default function NewFitter({ history }) {
  const [createFitter, { createFitterLoading }] = useMutation(CREATE_FITTER, {
    onCompleted: ({ createFitter: { name } }) => {
      openSnackbar({
        message: `Fitter ${name} has been created`,
        type: 'success',
      })
      history.push('/admin-settings/users')
    },
    onError: () => {
      openSnackbar({
        message: 'Failed to create fitter, please try again.',
        type: 'error',
      })
    },
  })

  return (
    <Container>
      <Row>
        <Col>
          <Form
            onSubmit={({ name, email, contactNumber }) => {
              createFitter({
                variables: {
                  name,
                  email,
                  contactNumber,
                },
              })
            }}
            validate={({ contactNumber }) => {
              const errors = {}

              if (contactNumber)
                errors.contactNumber = validatePhoneNumber(contactNumber, 'GB')

              return errors
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader>
                    <Typography variant="h4">New Fitter</Typography>
                  </CardHeader>
                  <CardBody container>
                    <Row>
                      <Col lg={4} xs={12}>
                        <Field
                          component={Input}
                          name="name"
                          required
                          fullwidth
                          label="Full Name"
                          validate={(value) =>
                            validateInput(value, 'text', true)
                          }
                        />
                      </Col>
                      <Col lg={3} xs={12}>
                        <Field
                          component={Input}
                          name="contactNumber"
                          type="number"
                          fullwidth
                          label="Contact Number"
                        />
                      </Col>
                      <Col lg={5} xs={12}>
                        <Field
                          component={Input}
                          name="email"
                          type="email"
                          fullwidth
                          label="Email"
                          validate={(value) =>
                            validateInput(value, 'email', false)
                          }
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      disabled={pristine || invalid}
                      type="Submit"
                      loading={createFitterLoading}
                      icon={
                        <Icon
                          path={mdiContentSave}
                          title="Save Fitter"
                          size="24px"
                        />
                      }
                    >
                      Save
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            )}
          />
        </Col>
      </Row>
    </Container>
  )
}
