import 'react-app-polyfill/ie11' // For IE 11 support
import React from 'react'
import ReactDOM from 'react-dom'

import 'draft-js/dist/Draft.css'
import './assets/overrides.css'

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))
