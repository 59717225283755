import React from "react";
import { Typography, Card, Grid } from "@material-ui/core";
import { closeModal } from "../../reusable/Popup";
import { mdiClose } from "@mdi/js";
import FlexBox from "../../../newComponents/FlexBox";
import { Form, Field } from "react-final-form";
import { TextField } from "@material-ui/core";
import IconButton from "../../../newComponents/IconButton";
import Spacer from "../../../newComponents/Spacer";
import { useMutation } from "@apollo/react-hooks";
import { VisibilityOffRounded } from "@material-ui/icons";
import { gql } from "@apollo/react-hooks";
import { Button } from "@material-ui/core";
import { openSnackbar } from "../../reusable/Notifier";

const PRUNE_ORDERS = gql`
  mutation PruneOrders {
    PruneOrders {
      _id
      order_status
      completed_date
      to_pay
    }
  }
`;
export default function PruneOrders() {
  // const [password, setPassword] = useState("")
  const [PruneOrders, { loading }] = useMutation(PRUNE_ORDERS, {
    onError: () => {
      openSnackbar({
        message: "Failed to Prune Orders",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Orders Pruned",
        type: "success",
      });
    },
  });

  const handlePruneOrders = async (values) => {
    if (values.confirm_prune !== "CONFIRM_PRUNE") {
      openSnackbar({
        message: "Invalid confirmation phrase",
        type: "error",
      });
      return;
    }
    try {
      await PruneOrders();
    } catch (err) {
      console.error("Client: Error purging bugged orders", err);
    }
  };

  return (
    <Card style={{}}>
      <Grid>
        <FlexBox justifyContent="space-between" style={{ margin: "1rem" }}>
          <Typography variant="h4">
            <VisibilityOffRounded style={{ marginRight: "10px" }} />
            Warning!
          </Typography>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              closeModal();
            }}
            path={mdiClose}
            title="Close"
            size="24px"
          />
        </FlexBox>
      </Grid>
      <Grid
        container
        spacing={3}
        // justify="center"
        style={{ margin: "2rem" }}
      >
        <Typography variant="body1">
          This action will result in the mass completion of active orders with a
          balance of less than £0.01
        </Typography>
        <Spacer height="16px" />
        <Form
          onSubmit={handlePruneOrders}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Typography variant="body1">
                Enter "CONFIRM_PRUNE" below to confirm this mass action
              </Typography>
              <Spacer height="16px" />
              <Field name="confirm_prune">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="confirm_prune"
                    label="CONFIRM_PRUNE"
                    fullWidth
                    required
                    type="text"
                    variant="filled"
                  />
                )}
              </Field>
              <Button
                variant="contained"
                type="submit"
                style={{
                  marginTop: "1rem",
                  color: "white",
                  backgroundColor: "red",
                }}
              >
                <VisibilityOffRounded style={{ marginRight: "10px" }} />
                {loading ? "Processing" : "Prune Orders"}
              </Button>
            </form>
          )}
        />
      </Grid>
    </Card>
  );
}
