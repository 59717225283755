import React from 'react'

import AuthenticatedRoute from '../components/util/AuthenticatedRoute'

import User from '../components/pages/User/User'

const UserRoutes = [
  <AuthenticatedRoute
    path="/user/:userId"
    exact
    component={User}
    title="User"
  />,
]

export default UserRoutes
