import React, { Fragment } from 'react'
import { Field, FormSpy } from 'react-final-form'
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { openSnackbar } from '../reusable/Notifier'

import {
  TextField,
  Grid,
  MenuItem,
  Typography,
  Divider,
  Checkbox,
  Button,
  FormControlLabel,
} from '@material-ui/core'

const FIND_ADDRESS = gql`
  query findAddress($postcode: String!) {
    findAddress(postcode: $postcode) {
      addressLine1
      addressLine2
      city
      county
      postcode
    }
  }
`

export default function BillingAddress() {
  const [findAddress, { loading, data }] = useLazyQuery(FIND_ADDRESS, {
    onError: () => {
      openSnackbar({
        message: 'Failed to find address, please try again.',
        type: 'error',
      })
    },
  })

  let foundAdresses = []

  if (data) {
    foundAdresses = data.findAddress
  }

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <Grid container spacing={3} justify="center">
          {window.location.pathname.includes('/new-order') && (
            <Fragment>
              <Grid item xs={12}>
                <Typography component="h2" variant="h5" gutterBottom>
                  Billing Details
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Field name="addressMatchesResidence" type="checkbox">
                  {({ input }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: 'addressMatchesResidence' }}
                          {...input}
                          value="addressMatchesResidence"
                        />
                      }
                      label="Same as Customer Address"
                    />
                  )}
                </Field>
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12} sm={4} md={2}>
            <Field name="billingTitle">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  select
                  label="Title"
                  fullWidth
                  variant="outlined"
                  name="billingTitle"
                  disabled={values.addressMatchesResidence}
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                >
                  <MenuItem value="miss">Miss</MenuItem>
                  <MenuItem value="mrs">Mrs</MenuItem>
                  <MenuItem value="mr">Mr</MenuItem>
                  <MenuItem value="ms">Ms</MenuItem>
                  <MenuItem value="dr">Dr</MenuItem>
                </TextField>
              )}
            </Field>
          </Grid>
          <Grid item md={5} sm={4} xs={12}>
            <Field name="billingFirstName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  name="billingFirstName"
                  disabled={values.addressMatchesResidence}
                  label="First Name"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item md={5} sm={4} xs={12}>
            <Field name="billingLastName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  name="billingLastName"
                  disabled={values.addressMatchesResidence}
                  label="Last Name"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name="billingAddressLine1">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  name="billingAddressLine1"
                  disabled={values.addressMatchesResidence}
                  label="Address Line 1"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name="billingAddressLine2">
              {({ input }) => (
                <TextField
                  {...input}
                  name="billingAddressLine2"
                  disabled={values.addressMatchesResidence}
                  label="Address Line 2"
                  fullWidth
                  variant="outlined"
                />
              )}
            </Field>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name="billingAddressCity">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  name="billingAddressCity"
                  disabled={values.addressMatchesResidence}
                  label="City"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name="billingAddressCounty">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  name="billingAddressCounty"
                  disabled={values.addressMatchesResidence}
                  label="County"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Field name="billingAddressPostcode">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  name="billingAddressPostcode"
                  disabled={values.addressMatchesResidence}
                  label="Postcode"
                  fullWidth
                  required
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {!!values.billingAddressPostcode &&
              !values.addressMatchesResidence && (
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  size="large"
                  disabled={loading}
                  onClick={() => {
                    findAddress({
                      variables: {
                        postcode: values.billingAddressPostcode,
                      },
                    })
                  }}
                >
                  {loading ? 'Searching' : 'Find Address'}
                </Button>
              )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {!!foundAdresses.length && !values.addressMatchesResidence && (
              <Field name="chosenBillingAddress">
                {({ input }) => (
                  <TextField
                    {...input}
                    select
                    label="Addresses"
                    disabled={values.addressMatchesResidence}
                    fullWidth
                    required
                    variant="outlined"
                  >
                    {foundAdresses.map((address, index) => (
                      <MenuItem
                        key={index}
                        value={`${address.addressLine1}, ${address.addressLine2}, ${address.city}, ${address.county}, ${address.postcode}`}
                      >{`${address.addressLine1}, ${address.addressLine2}, ${address.city}, ${address.county}, ${address.postcode}`}</MenuItem>
                    ))}
                  </TextField>
                )}
              </Field>
            )}
          </Grid>
        </Grid>
      )}
    </FormSpy>
  )
}
