import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { IconButton } from '@material-ui/core'

import { Add } from '@material-ui/icons'

import { openSnackbar } from './../reusable/Notifier'

const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      _id
      name
    }
  }
`

export default function FileUpload({ uploadFileFunction }) {
  const [uploadFile, { loading }] = useMutation(UPLOAD_FILE, {
    onError: () => {
      openSnackbar({
        message: 'Failed to upload file, please try again',
        type: 'error',
      })
    },
    onCompleted: ({ uploadFile }) => {
      uploadFileFunction({
        name: uploadFile.name,
        id: uploadFile._id,
      })
    },
  })

  const onDrop = useCallback(
    (files) => {
      files.forEach((file) => {
        //upload file to server
        uploadFile({
          variables: {
            file: file,
          },
        })
      })
    },
    [uploadFile]
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <IconButton color="primary" aria-label="Add File" disabled={loading}>
        <Add />
      </IconButton>
    </div>
  )
}
