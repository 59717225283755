import React, { useState, Fragment } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import styled from 'styled-components'

//images

import linkExpired from './../../../assets/images/link-expired-img.svg'

import Typography from '../../../newComponents/Typography'
import Spacer from '../../../newComponents/Spacer'
import Card from '../../../newComponents/Card'
import Button from '../../../newComponents/Button'
import FlexBox from '../../../newComponents/FlexBox'
import Divider from '../../../newComponents/Divider'
import Loading from '../../reusable/Loading'

import { openSnackbar } from '../../reusable/Notifier'

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

const GET_FAILED_PAYMENT_METHOD = gql`
  query getFailedPaymentMethod($linkReference: Int!) {
    getFailedPaymentMethod(linkReference: $linkReference)
  }
`

const GET_FAILED_PAYMENT_LINK_DETAILS = gql`
  query getFailedPaymentLinkDetails($linkReference: Int!) {
    getFailedPaymentLinkDetails(linkReference: $linkReference) {
      _id
      payment {
        _id
        invoice_number
        amount
      }
    }
  }
`

export default function FailedPaymentSuccess({
  match: {
    params: { linkReference },
  },
}) {
  const [cardUpdated, setCardUpdated] = useState(false)
  const [dontUpdateCard, setDontUpdateCard] = useState(false)
  //check if payment was paid with a new card or not

  const { loading, error, data } = useQuery(GET_FAILED_PAYMENT_METHOD, {
    variables: { linkReference: parseInt(linkReference) },
  })
  const {
    loading: failedPaymentLinkLoading,
    error: failedPaymentLinkError,
    data: failedPaymentLinkData,
  } = useQuery(GET_FAILED_PAYMENT_LINK_DETAILS, {
    variables: { linkReference: parseInt(linkReference) },
  })

  const UPDATE_FAILED_PAYMENT_CARD = gql`
    mutation updateFailedPaymentCard($linkReference: Int!) {
      updateFailedPaymentCard(linkReference: $linkReference)
    }
  `

  const [
    updateFailedPaymentCard,
    { loading: updateFailedPaymentCardLoading },
  ] = useMutation(UPDATE_FAILED_PAYMENT_CARD, {
    onError: () => {
      openSnackbar({
        message: 'Failed to save card details, please try again.',
        type: 'error',
      })
    },
    onCompleted: () => {
      setCardUpdated(true)
    },
  })

  if (loading || failedPaymentLinkLoading) return <Loading />

  if (error || failedPaymentLinkError) {
    return (
      <ContentWrapper>
        <FlexBox flexDirection="column">
          <Typography variant="h1" center>
            Something Went Wrong
          </Typography>
          <Spacer height="56px" />
          <img src={linkExpired} alt="Something Went Wrong" />
          <Spacer height="56px" />
          <Typography variant="bodySmall" center>
            Please refresh the page. To make this payment over the phone, please
            call our payments team on:
          </Typography>
          <Spacer height="24px" />
          <Typography variant="h1" component="a" href="tel:01709732641">
            01709 732 641
          </Typography>
          <Spacer height="8px" />
          <Typography variant="bodySmall" colour="#999B9F">
            (select option 1)
          </Typography>
        </FlexBox>
      </ContentWrapper>
    )
  }

  return (
    <ContentWrapper>
      <FlexBox flexDirection="column" alignItems="flex-start">
        <Typography variant="h1">Success</Typography>
        <Spacer height="16px" />
        <Typography variant="bodySmall">
          Your payment has been successfull.
        </Typography>
        <Spacer height="32px" />
      </FlexBox>
      <Card>
        <Typography variant="h2">
          {`Order No. ${failedPaymentLinkData.getFailedPaymentLinkDetails.payment.invoice_number}`}
        </Typography>

        <Spacer height="24px" />
        <Typography variant="label">Amount Paid</Typography>
        <Spacer height="8px" />
        <Typography variant="h2">
          {new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(
            failedPaymentLinkData.getFailedPaymentLinkDetails.payment.amount
          )}
        </Typography>
        <Spacer height="24px" />
        <Divider />
        <Spacer height="24px" />
        {data.getFailedPaymentMethod === 'Existing Card' && (
          <Typography variant="bodySmall">
            You may now close your browser
          </Typography>
        )}

        {data.getFailedPaymentMethod === 'New Card' &&
          !cardUpdated &&
          !dontUpdateCard && (
            <Fragment>
              <Typography variant="bodySmall">
                Would you like to save this card as your default payment method?
                This will mean that all future payments for this order will be
                taken from this card.
              </Typography>
              <Spacer height="24px" />
              <Button
                colour="#86B80B"
                loading={updateFailedPaymentCardLoading}
                fullwidth
                onClick={() => {
                  updateFailedPaymentCard({
                    variables: { linkReference: parseInt(linkReference) },
                  })
                }}
              >
                Save Card
              </Button>
              <Spacer height="24px" />
              <Typography
                center
                variant="label"
                onClick={() => {
                  setDontUpdateCard(true)
                }}
              >
                No thanks, keep my current card
              </Typography>
            </Fragment>
          )}
        {cardUpdated && (
          <Typography variant="bodySmall">
            We have updated your payment details. You may now close your
            browser.
          </Typography>
        )}
        {dontUpdateCard && (
          <Typography variant="bodySmall">
            Your payment details have not been updated and the next payment will
            be taken from the current card. You may now close your browser.
          </Typography>
        )}
      </Card>
    </ContentWrapper>
  )
}
