import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { mdiClose, mdiCashMultiple, mdiCreditCardMultiple } from "@mdi/js";
import Icon from "@mdi/react";
import { Loading, Button } from "@web-applications/daffodil-component-library";
import { useParams } from "react-router-dom";

import Card from "../../../../../newComponents/Card";
import Typography from "../../../../../newComponents/Typography";
import Spacer from "../../../../../newComponents/Spacer";
import IconButton from "../../../../../newComponents/IconButton";
import FlexBox from "../../../../../newComponents/FlexBox";
import CardRefunds from "./CardRefunds";
import ManualRefunds from "./ManualRefunds";

import { UserContext } from "../../../../../components/util/PageWrapper";

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      total_paid
      cancellation {
        _id
        completed
      }
    }
  }
`;

export default function RefundsModal({ hideModal, user }) {
  const [page, setPage] = useState("selection");
  const [currentUser, setCurrentUser] = useState(null);

  //user passed as props, context unavailable in here and children
  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  const { invoiceNumber } = useParams();

  // Check if order is pending a cancellation. Orders that are in this state
  // cannot be refunded as they may have requested refunds and this will cause
  // issues. Instead users should be prompted to either process the cancellation
  // or abort the cancellation and continue a normal refund instead,
  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
  });

  const hasActiveCancellation =
    !!orderData?.getOrder?.cancellation &&
    !orderData.getOrder.cancellation.completed;

  let selectionView;

  if (orderLoading) {
    selectionView = <Loading />;
  } else if (orderError) {
    selectionView = (
      <Typography variant="bodySmall">
        Something went wrong whilst trying to load this order, please refresh
        the page and try again.
      </Typography>
    );
  } else if (hasActiveCancellation) {
    selectionView = (
      <Typography variant="bodySmall">
        This order is on hold due to a pending cancellation. Please abort or
        complete the cancellation to process refunds.
      </Typography>
    );
  } else {
    selectionView = (
      <>
        <Typography variant="bodySmall">
          What type of refund would you like to add?
        </Typography>
        <Spacer height="24px" />
        <FlexBox justifyContent="space-between">
          <Button
            icon={<Icon path={mdiCashMultiple} size="24px" />}
            onClick={() => {
              setPage("manual");
            }}
          >
            Manual
          </Button>
          <Spacer height="32px" />
          <Button
            icon={<Icon path={mdiCreditCardMultiple} size="24px" />}
            onClick={() => {
              setPage("card");
            }}
          >
            Card
          </Button>
        </FlexBox>
      </>
    );
  }
  if (!user) {
    return <Loading />;
  }
  return (
    <Card style={{ minWidth: "40%", maxWidth: "60%" }}>
      <FlexBox justifyContent="space-between">
        <Typography variant="h2">Refunds</Typography>
        <Spacer height="32px" />
        <IconButton
          color="grey"
          onClick={hideModal}
          path={mdiClose}
          title="Close"
          size="24px"
        />
      </FlexBox>
      <Spacer height="32px" />
      {page === "selection" && selectionView}

      {page === "card" && (
        <CardRefunds orderId={orderData.getOrder._id} hideModal={hideModal} />
      )}
      {/* {currentUser &&
        console.log("CURRENT USER PROP:", currentUser, "USER", user)} */}
      {page === "manual" && (
        <ManualRefunds
          user={currentUser}
          orderId={orderData.getOrder._id}
          hideModal={hideModal}
          totalPaid={orderData?.getOrder?.total_paid}
        />
      )}
    </Card>
  );
}
