import React from 'react'
import {
  Loading,
  ErrorMessage,
  Button,
} from '@web-applications/daffodil-component-library'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { Clear } from '@material-ui/icons'
import {
  Grid,
  Card,
  CardContent,
  TextField,
  IconButton,
  CardHeader,
  CardActions,
  MenuItem
} from '@material-ui/core'
import { Form, Field } from 'react-final-form'
import { GET_UI_LIST_ENTRIES } from '../../../../helpers/queries/CustomerVisitOutcome'
import { openSnackbar } from '../../../../components/reusable/Notifier'

const UPDATE_INCREASE = gql`
  mutation updateIncrease(
    $_id: ID!
    $invoiceNumber: Int!
    $reasonCode: String!
    $reasonText: String!
  ) {
    updateIncrease(
      _id: $_id
      invoiceNumber: $invoiceNumber
      reasonCode: $reasonCode
      reasonText: $reasonText
    ) {
      _id
      increases
    }
  }
`

export default function EditIncrease({ hideModal,_id,initialReasonCodePlusText }) {
  const { invoiceNumber } = useParams()
  const {
    error,
    loading,
    data: cvoData,
  } = useQuery(GET_UI_LIST_ENTRIES,{variables:{type:'discountsincreases'}})

  const [updateIncrease, { loading: updateIncreaseLoading }] = useMutation(
    UPDATE_INCREASE,
    {
      onError: () => {
        hideModal()
        openSnackbar({
          message: 'Updating increase failed',
          type: 'error',
        })
      },
      onCompleted: () => {
        hideModal()
        openSnackbar({
          message: 'Increase updated successfully',
          type: 'success',
        })
      },
    }
  )

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  return (
    <Form
      initialValues={{
        reasonCodePlusText:initialReasonCodePlusText
      }}
      validate={({ reasonCodePlusText }) => {
        const errors = {}

        if(!reasonCodePlusText){
          errors.reasonCode = 'Required'
        }

        return errors
      }}
      onSubmit={({ reasonCodePlusText }) => {
        const variables={
          _id,
          invoiceNumber: parseInt(invoiceNumber),
          reasonCode:reasonCodePlusText.split(',')[0],
          reasonText:reasonCodePlusText.split(',')[1]
        }
        updateIncrease({
          variables: variables,
        })
      }}
      render={({ handleSubmit, invalid, pristine }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Card style={{ minWidth: '600px' }}>
            <CardHeader
              action={
                <IconButton aria-label="Close Pop-up" onClick={hideModal}>
                  <Clear />
                </IconButton>
              }
              title="Edit Increase"
            />
            <CardContent>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Field name="reasonCodePlusText">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        select
                        fullWidth
                        variant="outlined"
                        label="Reason Code"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ''
                        }
                        size="small"
                        style={{marginTop:'7.5px'}}
                      >
                        {cvoData && cvoData.entries.map((outcome) => {
                          return (
                            <MenuItem
                              value={outcome.value+','+outcome.name}
                              key={outcome._id}
                            >
                              {outcome.name}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    )}
                  </Field>
                </Grid>
                
              </Grid>
            </CardContent>

            <CardActions
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button variant="contained" color="secondary" onClick={hideModal}>
                Cancel
              </Button>

              <Button
                type="submit"
                loading={updateIncreaseLoading}
                disabled={pristine || invalid}
              >
                Continue
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    />
  )
}
