import styled from 'styled-components'

const StyledCard = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  padding: 32px;
`

export default StyledCard
