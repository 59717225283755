import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { Query, Mutation } from '@apollo/react-components'
import createDecorator from 'final-form-calculate'

import { openSnackbar } from './../../reusable/Notifier'
import Loading from './../../reusable/Loading'

import {
  GET_ORDER,
  UPDATE_BILLING_ADDRESS,
} from './../../../helpers/queries/Order'

import BillingAddress from '../../forms/BillingAddress'

import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
} from '@material-ui/core'

import { Save } from '@material-ui/icons'

import { UserContext } from '../../util/PageWrapper'

export default function SuccessUpdateCard({ match, history }) {
  const user = useContext(UserContext)

  const invoiceNumber = parseInt(match.params.invoice_number)

  return (
    <Query
      query={GET_ORDER}
      variables={{
        invoice_number: invoiceNumber,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading />
        }

        if (error) {
          return null
        }

        const { order } = data

        return (
          <Card>
            <CardContent>
              <Typography component="h2" variant="h5" gutterBottom>
                Card Details Updated Succesfully
                <Divider />
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                style={{ marginBottom: '30px' }}
              >
                Please confirm that the following billing details are correct:
              </Typography>
              <Mutation mutation={UPDATE_BILLING_ADDRESS}>
                {(updateBillingAddress) => (
                  <Form
                    initialValues={{
                      billingTitle: order.billing_title,
                      billingFirstName: order.billing_first_name,
                      billingLastName: order.billing_last_name,
                      billingAddressLine1: order.billing_address_line_1,
                      billingAddressLine2: order.billing_address_line_2,
                      billingAddressCity: order.billing_address_city,
                      billingAddressCounty: order.billing_address_county,
                      billingAddressPostcode: order.billing_address_postcode,
                    }}
                    mutators={{
                      addBillingAddresses: (args, state, utils) => {
                        utils.changeValue(
                          state,
                          'billingAddresses',
                          () => args[0]
                        )
                      },
                    }}
                    decorators={[
                      createDecorator({
                        //match billing address to customer address
                        field: 'chosenBillingAddress',
                        updates: async (value, name, allValues) => {
                          if (value) {
                            const address = value.split(',')

                            return {
                              billingAddressLine1: address[0].trim(),
                              billingAddressLine2: address[1].trim(),
                              billingAddressCity: address[2].trim(),
                              billingAddressCounty: address[3].trim(),
                              billingAddressPostcode: address[4].trim(),
                            }
                          }

                          return {}
                        },
                      }),
                    ]}
                    onSubmit={(values) => {
                      updateBillingAddress({
                        variables: {
                          invoice_number: invoiceNumber,
                          billing_title: values.billingTitle,
                          billing_first_name: values.billingFirstName,
                          billing_last_name: values.billingLastName,
                          billing_address_line_1: values.billingAddressLine1,
                          billing_address_line_2: values.billingAddressLine2,
                          billing_address_city: values.billingAddressCity,
                          billing_address_county: values.billingAddressCounty,
                          billing_address_postcode:
                            values.billingAddressPostcode,
                          agent_id: user._id,
                        },
                      }).then(({ data }) => {
                        const { updateBillingAddress } = data

                        if (updateBillingAddress.error) {
                          openSnackbar({
                            message: updateBillingAddress.error.message,
                            type: 'error',
                          })
                        } else {
                          //redirect to single order page
                          history.push('/orders/single/' + invoiceNumber)
                        }
                      })
                    }}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <BillingAddress />
                        <Grid container spacing={3} justify="center">
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              <Save style={{ marginRight: '10px' }} />
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                )}
              </Mutation>
            </CardContent>
          </Card>
        )
      }}
    </Query>
  )
}
