import React from 'react'
import { useMutation, useQuery, gql } from '@apollo/client'
import { Form, Field } from 'react-final-form'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { parsePhoneNumberFromString } from 'libphonenumber-js/min'
import { useModal } from 'react-modal-hook'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  validateInput,
  Input,
  validatePhoneNumber,
  Loading,
  ErrorMessage,
  FlexBox,
  Modal,
  Spacing,
} from '@web-applications/daffodil-component-library'
import Icon from '@mdi/react'
import { mdiClose, mdiContentSave, mdiDelete } from '@mdi/js'

import { openSnackbar } from '../../components/reusable/Notifier'

const EDIT_FITTER = gql`
  mutation editFitter(
    $fitterId: ID!
    $name: String!
    $email: EmailAddress
    $contactNumber: String
  ) {
    editFitter(
      fitterId: $fitterId
      name: $name
      email: $email
      contactNumber: $contactNumber
    ) {
      id
      name
      email
      contactNumber
    }
  }
`

const DELETE_FITTER = gql`
  mutation deleteFitter($fitterId: ID!) {
    deleteFitter(fitterId: $fitterId) {
      id
      name
    }
  }
`

const GET_FITTER = gql`
  query getFitter($fitterId: ID!) {
    getFitter(fitterId: $fitterId) {
      id
      name
      email
      contactNumber
    }
  }
`

export default function EditFitter({
  history,
  match: {
    params: { fitterId },
  },
}) {
  const [editFitter, { editFitterLoading }] = useMutation(EDIT_FITTER, {
    onCompleted: ({ editFitter: { name } }) => {
      openSnackbar({
        message: `Fitter ${name} has been updated`,
        type: 'success',
      })
      history.push('/admin-settings/users')
    },
    onError: () => {
      openSnackbar({
        message: 'Failed to update fitter, please try again.',
        type: 'error',
      })
    },
  })

  const { loading, error, data: fitterData } = useQuery(GET_FITTER, {
    variables: {
      fitterId,
    },
  })

  const [deleteFitter, { deleteFitterLoading }] = useMutation(DELETE_FITTER, {
    onCompleted: ({ deleteFitter: { name } }) => {
      openSnackbar({
        message: `Fitter ${name} has been deleted`,
        type: 'success',
      })
      history.push('/admin-settings/users')
    },
    onError: () => {
      openSnackbar({
        message: 'Failed to delete fitter, please try again.',
        type: 'error',
      })
    },
  })

  const [showDeleteFitterModal, hideDeleteFitterModal] = useModal(() => (
    <Modal lite>
      <Card>
        <CardHeader>
          <FlexBox>
            <Typography variant="h4">Are You Sure?</Typography>
            <Spacing multiplier={1} />
            <Button
              loading={deleteFitterLoading}
              variant="ghost"
              type="button"
              onClick={hideDeleteFitterModal}
              icon={<Icon path={mdiClose} title="Delete Fitter" size="24px" />}
            />
          </FlexBox>
        </CardHeader>
        <CardBody>
          <Typography variant="bodySmall">
            This will remove the fitter from all assigned orders. This cannot be
            undone.
          </Typography>
        </CardBody>
        <CardFooter lite>
          <Button
            variant="destructive"
            type="button"
            icon={<Icon path={mdiDelete} title="Delete Fitter" size="24px" />}
            onClick={() => {
              deleteFitter({
                variables: {
                  fitterId,
                },
              })
            }}
          >
            Delete Fitter
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  ))

  if (loading) return <Loading />

  if (error) return <ErrorMessage error={error} />

  const { getFitter: fitter } = fitterData

  return (
    <Container>
      <Row>
        <Col>
          <Form
            onSubmit={({ name, email, contactNumber }) => {
              editFitter({
                variables: {
                  fitterId,
                  name,
                  email,
                  contactNumber,
                },
              })
            }}
            initialValues={{
              name: fitter.name,
              email: fitter.email,
              contactNumber: fitter.contactNumber
                ? parsePhoneNumberFromString(fitter.contactNumber)
                    .format('IDD', {
                      fromCountry: 'GB',
                      humanReadable: true,
                    })
                    .replace(' ', '')
                : undefined,
            }}
            validate={({ contactNumber }) => {
              const errors = {}

              if (contactNumber)
                errors.contactNumber = validatePhoneNumber(contactNumber, 'GB')

              return errors
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader>
                    <Typography variant="h4">{`Edit Fitter - ${fitter.name}`}</Typography>
                  </CardHeader>
                  <CardBody container>
                    <Row>
                      <Col lg={4} xs={12}>
                        <Field
                          component={Input}
                          name="name"
                          required
                          fullwidth
                          label="Full Name"
                          validate={(value) =>
                            validateInput(value, 'text', true)
                          }
                        />
                      </Col>
                      <Col lg={3} xs={12}>
                        <Field
                          component={Input}
                          name="contactNumber"
                          type="number"
                          fullwidth
                          label="Contact Number"
                        />
                      </Col>
                      <Col lg={5} xs={12}>
                        <Field
                          component={Input}
                          name="email"
                          type="email"
                          fullwidth
                          label="Email"
                          validate={(value) =>
                            validateInput(value, 'email', false)
                          }
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <FlexBox justifyContent="space-between">
                      <Button
                        disabled={pristine || invalid}
                        type="Submit"
                        loading={editFitterLoading}
                        icon={
                          <Icon
                            path={mdiContentSave}
                            title="Save Fitter"
                            size="24px"
                          />
                        }
                      >
                        Save
                      </Button>
                      <Button
                        loading={editFitterLoading}
                        variant="destructive"
                        type="button"
                        onClick={showDeleteFitterModal}
                        icon={
                          <Icon
                            path={mdiDelete}
                            title="Delete Fitter"
                            size="24px"
                          />
                        }
                      >
                        Delete Fitter
                      </Button>
                    </FlexBox>
                  </CardFooter>
                </Card>
              </form>
            )}
          />
        </Col>
      </Row>
    </Container>
  )
}
