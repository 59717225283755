import React from 'react'
import { Form } from 'react-final-form'
import { useMutation, useQuery } from '@apollo/react-hooks'
import validator from 'validator'
import moment from 'moment'
import createDecorator from 'final-form-calculate'
import gql from 'graphql-tag'
import { openModal } from './../../reusable/Popup'

import {
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
} from '@material-ui/core'

import { Save } from '@material-ui/icons'

import Customer from './../../forms/Customer'

import { openSnackbar } from './../../reusable/Notifier'
import Loading from './../../reusable/Loading'

import CustomerActivity from './CustomerActivity'
import OverwriteCustomerEmail from './OverwriteCustomerEmail'

const GET_CUSTOMER = gql`
  query customer($_id: String!) {
    customer(_id: $_id) {
      _id
      first_name
      last_name
      title
      email
      address_line_1
      address_line_2
      address_city
      address_county
      address_postcode
      phone
      mobile
      preferred_contact
      customer_type
      created_date
      marketing_news
      marketing_offers
      doorstepDate
      doorstepOutcome
      created_by {
        _id
        first_name
        last_name
        role_id
      }
      updated_date
      updated_by {
        _id
        first_name
        last_name
        role_id
      }
    }
  }
`

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $_id: String!
    $title: String!
    $first_name: String!
    $last_name: String!
    $address_line_1: String!
    $address_line_2: String
    $address_city: String!
    $address_county: String!
    $address_postcode: String!
    $email: String!
    $phone: String
    $mobile: String
    $preferred_contact: String!
    $customer_type: String!
    $marketing_news: Boolean!
    $marketing_offers: Boolean!
    $doorstepDate: String
    $doorstepOutcome: String
  ) {
    updateCustomer(
      _id: $_id
      title: $title
      first_name: $first_name
      last_name: $last_name
      address_line_1: $address_line_1
      address_line_2: $address_line_2
      address_city: $address_city
      address_county: $address_county
      address_postcode: $address_postcode
      email: $email
      phone: $phone
      mobile: $mobile
      preferred_contact: $preferred_contact
      customer_type: $customer_type
      marketing_news: $marketing_news
      marketing_offers: $marketing_offers
      doorstepDate: $doorstepDate
      doorstepOutcome: $doorstepOutcome
    ) {
      _id
      first_name
      last_name
      title
      email
      address_line_1
      address_line_2
      address_city
      address_county
      address_postcode
      phone
      mobile
      preferred_contact
      customer_type
      created_date
      marketing_news
      marketing_offers
      doorstepDate
      doorstepOutcome
      updated_date
      updated_by {
        _id
        first_name
        last_name
        role_id
      }
    }
  }
`

export default function EditCustomer({ match }) {
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { _id: match.params.customerId },
  })

  const [updateCustomer, { loading: updateCustomerLoading }] = useMutation(
    UPDATE_CUSTOMER,
    {
      onCompleted: ({ updateCustomer }) => {
        openSnackbar({
          message: `Customer ${updateCustomer.first_name} ${updateCustomer.last_name} has been updated.`,
          type: 'success',
        })
      },
    }
  )

  if (loading) {
    return (
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          <Card style={{ minHeight: '500px' }}>
            <Loading />
          </Card>
        </Grid>
      </Grid>
    )
  }

  if (error) {
    return (
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          <Card>
            <Typography variant="body1">
              Failed to get customer details, please refresh the page.
            </Typography>
          </Card>
        </Grid>
      </Grid>
    )
  }

  let customer
  let postcode

  if (data) {
    customer = data.customer

    postcode = customer.address_postcode.match(
      /^([a-zA-Z]{1,2}\d{1,2}[a-zA-Z]?)\s*(\d[a-zA-Z]{2})$/
    )
    postcode.shift()
    postcode = postcode.join(' ')
  }

  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3} justify="center">
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Typography component="h2" variant="h5">
                    {`${customer.first_name} ${customer.last_name}`}
                  </Typography>
                  <div style={{ marginLeft: 'auto' }}>
                    <Typography variant="subtitle2" align="right">
                      Created By -{' '}
                      {moment(customer.created_date, 'x').format(
                        'DD/MM/YYYY, H:mm:ss'
                      )}{' '}
                      by {customer.created_by.first_name}{' '}
                      {customer.created_by.last_name}
                    </Typography>
                    <Typography variant="subtitle2" align="right">
                      Last Updated -{' '}
                      {moment(customer.updated_date, 'x').format(
                        'DD/MM/YYYY, H:mm:ss'
                      )}{' '}
                      by {customer.updated_by.first_name}{' '}
                      {customer.updated_by.last_name}
                    </Typography>
                  </div>
                </div>
                <Divider />
              </Grid>
            </Grid>

            <Form
              initialValues={{
                addressCity: customer.address_city,
                addressCounty: customer.address_county,
                addressLine1: customer.address_line_1,
                addressLine2: customer.address_line_2,
                addressPostcode: postcode,
                email: customer.email,
                firstName: customer.first_name,
                lastName: customer.last_name,
                mobile: customer.mobile,
                phone: customer.phone,
                preferredContact: customer.preferred_contact,
                title: customer.title,
                customerType: customer.customer_type,
                marketingNews: customer.marketing_news,
                marketingOffers: customer.marketing_offers,
                doorstepDate:customer.doorstepDate,
                doorstepOutcome:customer.doorstepOutcome
              }}
              onSubmit={(values) => {
                const response = updateCustomer({
                  variables: {
                    _id: customer._id,
                    title: values.title,
                    first_name: values.firstName,
                    last_name: values.lastName,
                    address_line_1: values.addressLine1,
                    address_line_2: values.addressLine2,
                    address_city: values.addressCity,
                    address_county: values.addressCounty,
                    address_postcode: values.addressPostcode,
                    email: values.email,
                    phone: values.phone,
                    mobile: values.mobile,
                    preferred_contact: values.preferredContact,
                    customer_type: values.customerType,
                    marketing_news: values.marketingNews || false,
                    marketing_offers: values.marketingOffers || false,
                    doorstepDate:values.doorstepDate,
                    doorstepOutcome:values.doorstepOutcome
                  },
                }).catch((errors) => {
                  if (errors.message.includes('Email Already Used')) {
                    openModal({
                      content: (
                        <OverwriteCustomerEmail
                          originalCustomerId={customer._id}
                          newCustomerEmail={
                            errors.graphQLErrors[0].extensions.email
                          }
                        />
                      ),
                    })
                  } else {
                    openSnackbar({
                      message: 'Failed to update customer, please try again',
                      type: 'error',
                    })
                  }
                })

                if (!response.data) return response
              }}
              validate={(values) => {
                const errors = {}

                const requiredFields = [
                  'firstName',
                  'lastName',
                  'addressLine1',
                  'addressCity',
                  'email',
                  'addressCounty',
                  'addressPostcode',
                  'title',
                  'preferredContact',
                  'customerType',
                ]

                requiredFields.forEach((requiredField) => {
                  if (!values[requiredField]) {
                    errors[requiredField] = 'Required'
                  }
                })

                if (
                  values.email &&
                  !validator.isEmail(values.email) &&
                  !values.email.includes('.con') &&
                  !values.email.includes('.coma')
                ) {
                  errors.email = 'Invalid Email'
                }

                if (
                  values.addressPostcode &&
                  (!validator.isPostalCode(values.addressPostcode, ['GB']) ||
                    values.addressPostcode.trim().length <= 4)
                ) {
                  errors.addressPostcode = 'Invalid Postcode'
                }

                if (
                  values.phone &&
                  !validator.matches(
                    values.phone,
                    /^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{5}\)?[\s-]?\d{4,5}|8(?:00[\s-]?11[\s-]?11|45[\s-]?46[\s-]?4\d))(?:(?:[\s-]?(?:x|ext\.?\s?|#)\d+)?)$/gm
                  )
                ) {
                  errors.phone = 'Invalid Phonenumber'
                }

                return errors
              }}
              decorators={[
                createDecorator({
                  //match billing address to customer address
                  field: 'chosenAddress',
                  updates: async (value) => {
                    if (value) {
                      const address = value.split(',')

                      return {
                        addressLine1: address[0].trim(),
                        addressLine2: address[1].trim(),
                        addressCity: address[2].trim(),
                        addressCounty: address[3].trim(),
                        addressPostcode: address[4].trim(),
                      }
                    }

                    return {}
                  },
                }),
              ]}
              render={({
                handleSubmit,
                pristine,
                invalid,
                dirtySinceLastSubmit,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Customer />
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          pristine ||
                          (invalid && !dirtySinceLastSubmit) ||
                          updateCustomerLoading
                        }
                        type="submit"
                      >
                        <Save style={{ marginRight: '10px' }} />
                        {updateCustomerLoading ? 'Saving' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CustomerActivity customerId={customer._id} />
      </Grid>
    </Grid>
  )
}
