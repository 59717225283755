import React, { Fragment, useContext } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import { KeyboardArrowRight } from '@material-ui/icons'
import {
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  Button,
} from '@material-ui/core'

import CancelOrderWizard from './CancelOrderWizard'
import CancelOrderReason from './CancelOrderReason'
import CancelOrderPayments from './CancelOrderPayments'
import CancelOrderReview from './CancelOrderReview'
import { openSnackbar } from './../../reusable/Notifier'
import { openModal } from './../../reusable/Popup'
import RemoveCancellationPopup from './RemoveCancellationPopup'

import { UserContext } from '../../util/PageWrapper'

const REQUEST_CANCELLATION = gql`
  mutation requestCancellation(
    $orderId: String!
    $reason: String!
    $paymentsToRefund: String
    $newOrderPlaced: Boolean!
    $cancellationNotes: String
  ) {
    requestCancellation(
      orderId: $orderId
      reason: $reason
      paymentsToRefund: $paymentsToRefund
      newOrderPlaced: $newOrderPlaced
      cancellationNotes: $cancellationNotes
    ) {
      _id
      order {
        _id
        order_status
        cancellation {
          _id
        }
      }
    }
  }
`

const CANCEL_ORDER = gql`
  mutation cancelOrder(
    $orderId: String!
    $reason: String!
    $paymentsToRefund: String
    $newOrderPlaced: Boolean!
    $cancellationNotes: String
    $cancellationId: String
  ) {
    cancelOrder(
      orderId: $orderId
      reason: $reason
      paymentsToRefund: $paymentsToRefund
      newOrderPlaced: $newOrderPlaced
      cancellationNotes: $cancellationNotes
      cancellationId: $cancellationId
    ) {
      _id
      order {
        _id
        order_status
        cancellation {
          _id
        }
      }
    }
  }
`

export default function CancelOrderForm({
  invoiceNumber,
  orderId,
  history,
  initialValues,
  cancellationId,
}) {
  const user = useContext(UserContext)

  const [requestCancellation, { loading: requestLoading }] = useMutation(
    REQUEST_CANCELLATION,
    {
      onError: () => {
        openSnackbar({
          message: 'Failed to request cancellation, please try again',
          type: 'error',
        })
      },
      onCompleted: () => {
        openSnackbar({
          message: 'Cancellation request has been sent',
          type: 'success',
        })
        history.push(`/orders/single/${invoiceNumber}`)
      },
    }
  )

  const [cancelOrder, { loading: cancelLoading }] = useMutation(CANCEL_ORDER, {
    onError: () => {
      openSnackbar({
        message: 'Failed to cancel order, please try again',
        type: 'error',
      })
    },
    onCompleted: () => {
      openSnackbar({
        message: `Order ${invoiceNumber} has been cancelled.`,
        type: 'success',
      })
      history.push(`/orders/single/${invoiceNumber}`)
    },
  })

  let onSubmit
  let submitText

  if (user.can_cancel) {
    submitText = 'Cancel Order'
    onSubmit = (values) => {
      let formattedPaymentIds = []

      if (values.paymentsToRefund && values.paymentsToRefund.length) {
        formattedPaymentIds = values.paymentsToRefund.map(
          (payment) => payment[0]
        )
      }

      cancelOrder({
        variables: {
          orderId: orderId,
          reason: values.reasonForCancelling,
          paymentsToRefund: JSON.stringify(formattedPaymentIds),
          newOrderPlaced: values.newOrderPlaced,
          cancellationNotes: values.cancellationNotes,
          cancellationId: cancellationId,
        },
      })
    }
  } else {
    submitText = 'Request Cancellation'
    onSubmit = (values) => {
      let formattedPaymentIds = []

      if (values.paymentsToRefund && values.paymentsToRefund.length) {
        formattedPaymentIds = values.paymentsToRefund.map(
          (payment) => payment[0]
        )
      }

      requestCancellation({
        variables: {
          orderId: orderId,
          reason: values.reasonForCancelling,
          paymentsToRefund: JSON.stringify(formattedPaymentIds),
          newOrderPlaced: values.newOrderPlaced,
          cancellationNotes: values.cancellationNotes,
        },
      })
    }
  }

  return (
    <Fragment>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
      >
        <Typography component="h1" variant="h4">
          Cancellations
        </Typography>
        <KeyboardArrowRight
          style={{ color: 'grey', fontSize: '27px', margin: '0 12px' }}
        />
        <Typography component="h1" variant="h4" style={{ color: 'grey' }}>
          Order {invoiceNumber}
        </Typography>
        {cancellationId && user.can_cancel && (
          <Button
            variant="contained"
            style={{
              backgroundColor: 'red',
              color: 'white',
              marginLeft: 'auto',
            }}
            onClick={() => {
              openModal({
                content: (
                  <RemoveCancellationPopup
                    cancellationId={cancellationId}
                    history={history}
                  />
                ),
              })
            }}
          >
            Abort Cancellation
          </Button>
        )}
      </div>
      <Card>
        <CardContent>
          <Typography component="h2" variant="h5" gutterBottom>
            Cancel Order
          </Typography>
          <Divider />
          <Grid
            container
            spacing={3}
            justify="center"
            style={{ marginTop: '15px' }}
          >
            <Grid item xs={12}>
              <CancelOrderWizard
                steps={[
                  'Reason for Cancellation',
                  'Select Payments',
                  'Review Cancellation',
                ]}
                mutators={{
                  setPaymentsToRefund: (args, state, utils) => {
                    utils.changeValue(state, 'paymentsToRefund', () => args[0])
                    utils.changeValue(state, 'selectedIndexes', () => args[1])
                  },
                }}
                onSubmit={onSubmit}
                submitText={submitText}
                initialValues={
                  initialValues
                    ? initialValues
                    : { newOrderPlaced: false, confirmCancellation: false }
                }
                disableSubmit={requestLoading || cancelLoading}
              >
                <CancelOrderWizard.Page
                  validate={(values) => {
                    const errors = {}

                    if (!values.reasonForCancelling) {
                      errors.reasonForCancelling = 'Required'
                    }

                    if (!values.cancellationNotes) {
                      errors.cancellationNotes = 'Required'
                    }

                    return errors
                  }}
                >
                  <CancelOrderReason />
                </CancelOrderWizard.Page>
                <CancelOrderWizard.Page>
                  <CancelOrderPayments orderId={orderId} />
                </CancelOrderWizard.Page>
                <CancelOrderWizard.Page>
                  <CancelOrderReview />
                </CancelOrderWizard.Page>
              </CancelOrderWizard>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  )
}
